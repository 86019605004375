<template>
  <div
       v-if="loadedPage"
       class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find communication..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminCommunications()"
        @enterKey="findAdminCommunications()"/>
      
      <div class="add-entity-button" @click="showCommunicationDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>

    <aList
      entityType="Communications"
      columnsWidth="400"
      :entities="communications"
      :keys="communicationKeys"
      routerName="AdminCommunication"
      routerId="communicationId"/>

    <modal v-if="showCommunicationDialog" size="xlarge"
          @close="showCommunicationDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Communication"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminCommunications",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminCommunications();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["communication", "communications", "userStatus"])
  },
  data() {
    return {
      showCommunicationDialog: false,
      loadedPage: false,
      query: '',
      communicationKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Sent',
          key: 'sent',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Company',
          key: 'company.name'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminCommunications() {
      this.$store.dispatch('findAdminCommunications', {
        query: this.query,
        public: true
      }).then(() => {
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Communications');
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminCommunications";
</style>