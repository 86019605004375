<template lang="html">
  <div class='tab' v-show='isActive'>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'TabComponent',
    props: {
      title: {
        type: String,
        default: 'Tab'
      }
    },
    data () {
      return {
        isActive: true,
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>