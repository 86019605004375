<template>
  <div v-if="loadedPage">
    <CollectionView
      :collection="goodiebag"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import EntityMixin from '../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';
import { isMobile, isBrowser, isIOS, isAndroid } from 'mobile-device-detect';
import CollectionView from '../stories/components/CollectionView/CollectionView.vue';

export default {
  name: 'HomeGoodiebag',
  components: {CollectionView},
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin, EntityMixin],
  props: ['goodiebagId'],
  mounted() {
    if (this.goodiebagId == '5ff6b20a69f828610fb824a0') {
      // this.$dpAnalytics({ view: 'HomeGoodiebag', subject: 'force redirect', action: `${window.location.search}` });
      this.$router.replace({ name: 'HomeGoodiebag', params: { goodiebagId: '607fe824f74f068bb22a97f6' } });
      return;
    }
    if (!this.goodiebagId) {
      this.$router.replace({ name: 'Home' });
    } else if (this.authenticated) {
      this.$router.replace({ name: 'UserGoodiebag', params: { goodiebagId: this.goodiebagId } });
    } else {
      this.$store.dispatch("getNarGoodiebag", { goodiebagId: this.goodiebagId })
        .then(() => {
          if (this.goodiebag.banner) {
            this.gotoParent(this.goodiebag);
            return;
          }
          this.loadedPage = true;
          this.setLoadedPageData(true);
          this.$store.dispatch('getNarCompany', { companyId: this.goodiebag.company._id }).then(() => {
            this.setPageTitle(this.goodiebag.company.name, this.goodiebag.name);
            // this.$dpAnalytics({ view: 'HomeGoodiebag', subject: this.goodiebag._id, action: 'enter' });
            this.pushDataLayer({
              parentCompany: this.goodiebag.company ? this.goodiebag.company.parentCompany : 'Unknown',
              company: this.goodiebag.company,
              goodiebag: this.goodiebag,
              offer: 'None',
              action: 'Enter'
            });
          });
          if (this.goodiebag.company.parentCompany) {
            this.$store.dispatch('parentCompanies/getNarParentCompany', { parentCompanyId: this.goodiebag.company.parentCompany._id || this.goodiebag.company.parentCompany });
          }
          this.startCountdown = true;
          setTimeout(() => {
            let elements = document.getElementsByClassName("autoplayMedia");
            for (let i = 0; i < elements.length; i += 1) {
              // if (this.isAnnoyingClient) {
              //   // dont autoplay
              // } else {
                 this.autoplay(elements[i]);
              // }
            }
          }, 200);
        })
        .catch(() => {
          this.$router.replace({ name: 'Home' });
        });
    }

  },
  watch: {
    goodiebagId(newVal, oldVal) {
      if (newVal !== oldVal) {
        location.reload();
      }
    },
  },
  computed: {
    ...mapGetters(["authenticated"]),
  },
  destroyed() {
    this.$store.dispatch('resetGoodiebag');
  },
  data() {
    return {
      loadedPage: false,
      startCountdown: false,
      isMobile,
      isBrowser,
      isIOS,
      isAndroid,
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeGoodiebag";
</style>
