<template>
  <div :class="{ 'admin-home-page': !authenticated }">
    <div
      class="admin-welcome-title"
      v-if="!authenticated">
      <div class="a-title">
        Welcome to the Admin of Enginio
      </div>
      <div
        class="btn btn-activate"
        style="max-width: 150px;"
        @click="setLoginView">
        Login
      </div>
    </div>
    <div
      class="admin-welcome-user"
      v-else-if="loadedPage">
      <div class="welcome-user-header">Welcome {{ user.name }}!</div>
      <div class="welcome-sub-header">What do you want to do today?</div>

      <div class="welcome-bookmarked-header">Bookmarked collections</div>
      <div class="admin-goodiebags-wrapper">
        <div
          v-for="bookmarkedGoodiebag in bookmarkedGoodiebags"
          :key="bookmarkedGoodiebag._id"
          class="goodiebag-box clickable"
          :style="`background-image: url(${bookmarkedGoodiebag.media ? bookmarkedGoodiebag.media.url : ''})`"
          @click="$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: bookmarkedGoodiebag._id } })">
          <div
            class="goodiebag-live-status"
            :class="{
              'goodiebag-live': bookmarkedGoodiebag.public,
              'goodiebag-offline': !bookmarkedGoodiebag.public,
            }"></div>
          <div class="goodiebag-box-text">
            <div class="goodiebag-box-name">{{ bookmarkedGoodiebag.name }}</div>
            <div
              v-if="bookmarkedGoodiebag.childGoodiebags && bookmarkedGoodiebag.childGoodiebags.length"
              class="goodiebag-box-info">
              {{ bookmarkedGoodiebag.childGoodiebags.length }} {{ bookmarkedGoodiebag.childGoodiebags.length === 1 ? 'collection' : 'collections' }}
            </div>
            <div
              v-if="bookmarkedGoodiebag.offers && bookmarkedGoodiebag.offers.length"
              class="goodiebag-box-info">
              {{ bookmarkedGoodiebag.offers.length }} {{ bookmarkedGoodiebag.offers.length === 1 ? 'activation' : 'activations' }}
            </div>
            <div
              class="goodiebag-box-edit preview-action"
              @click="$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: bookmarkedGoodiebag._id } })">
            </div>
          </div>
        </div>
      </div>

      <div class="welcome-bookmarked-header">Bookmarked activations</div>
      <div class="admin-goodiebags-wrapper">
        <div
          v-for="bookmarkedOffer in bookmarkedOffers"
          :key="bookmarkedOffer._id"
          class="goodiebag-box clickable"
          :style="`background-image: url(${(bookmarkedOffer.thumbnail && bookmarkedOffer.thumbnail.url ? bookmarkedOffer.thumbnail.url : undefined)
            || (bookmarkedOffer.media && bookmarkedOffer.media.length ? bookmarkedOffer.media[0].url : '')})`"
          @click="$router.push({ name: 'AdminOffer', params: { offerId: bookmarkedOffer._id } })">
          <div
            class="goodiebag-live-status"
            :class="{
              'goodiebag-live': bookmarkedOffer.public,
              'goodiebag-offline': !bookmarkedOffer.public,
            }"></div>
          <div class="goodiebag-box-text">
            <div class="goodiebag-box-name">{{ bookmarkedOffer.first_header }}</div>
            <div class="goodiebag-box-info">
              {{ bookmarkedOffer.quick_text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: "AdminHome",
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setPageTitle('Admin');
    if (this.authenticated) {
      this.$store.dispatch('getUser').then(() => {
        this.loadedPage = true;
      });
    } else {
      this.setLoginView();
    }
    /*if (this.authenticated) {
      this.$router.replace({ name: "AdminGoodiebag", params: { parentGoodiebagId: 1 } });
      return;
    }*/
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'user'
    ]),
    bookmarkedGoodiebags() {
      if (this.user && this.user._id) {
        return this.user.bookmarked_goodiebags;
      }
      return [];
    },
    bookmarkedOffers() {
      if (this.user && this.user._id) {
        return this.user.bookmarked_offers;
      }
      return [];
    },
  },
  data() {
    return {
      loadedPage: false,
    };
  },
  methods: {
    setLoginView() {
      this.internalLinkModal = false;
      let el = this;
      setTimeout(function () {
        el.$emit('setLoginView', {});
      }, 10); // 500
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminHome";
</style>
