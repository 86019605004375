<template>
  <div>
    <ParticipationList :admin="true"/>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import ParticipationList from '../stories/components/ParticipationList/ParticipationList.vue';

export default {
  name: "AdminGameParticipations",
  mixins: [BeforeEnterMixin],
  components: {
    ParticipationList,
  },
};
</script>

<style lang="scss" scoped>
</style>
