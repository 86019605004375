<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <EntityEditor
      v-if="chain && chain._id"
      :title= "`Change chain '${ chain.name }' (${ chain.name })`"
      :admin="true"
      :entity="chain"
      entity-type="PVChain"/>

    <div v-if="chain && chain._id">
      <aList
        entityType="Retailers"
        columnsWidth="400"
        :entities="chain.retailers"
        :keys="retailerKeys"
        routerName="AdminPVRetailer"
        routerId="retailerId"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import aList from "../components/AList";

export default {
  name: "AdminPVChain",
  components: {
    EntityEditor,
    aList,
  },
  mixins: [BeforeEnterMixin],
  props: ['chainId'],
  mounted() {
    this.$store.dispatch('pvChains/getAdminPVChain', { entityId: this.chainId }).then(() => {
      this.loadedPage = true;
    });
  },
  computed: {
    ...mapGetters('pvChains', ['chain']),
  },
  data() {
    return {
      loadedPage: false,
      retailerKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
