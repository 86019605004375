<template>
  <div class="property-box-container">
    <div class="header">
      <div class="text">
        <p class="title">{{ title }}</p>
        <p v-if="this.description" class="description">{{ description }}</p>
      </div>

      <div class="actions">
        <button v-if="showDeleteButton" class="button-yellow" @click="$emit('delete', '')">
          <iconComponent symbol="trashcan" fill="#ea7801" stroke="#ea7801" :width="18"/>
          Delete step</button>
        <button v-if="buttonText" class="button-solid" @click="$emit('next', '')">{{ buttonText }}</button>
      </div>
      <slot name="header-right"></slot>

    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "PropertyBox",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    buttonText: {
      type: String,
      default: '',
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    disableStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabled: this.disableStep,
    }
  },
  watch: {
    disabled(value) {
      console.log(value)
      this.$emit('disabled', value);
    },
  },
}
</script>

<style lang="scss" scoped>
.property-box-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px rgba(31, 41, 55, 0.06), 0px 4px 6px 0px rgba(31, 41, 55, 0.10);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #CCC;
    background-color: #F2F2F2;

    .text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: left;
      color: #111;

      .title {
        font-family: 'CircularXXWeb-Medium';
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
}
</style>
