import { render, staticRenderFns } from "./AdminDrafts.vue?vue&type=template&id=bfe3d532&scoped=true&"
import script from "./AdminDrafts.vue?vue&type=script&lang=js&"
export * from "./AdminDrafts.vue?vue&type=script&lang=js&"
import style0 from "./AdminDrafts.vue?vue&type=style&index=0&id=bfe3d532&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe3d532",
  null
  
)

export default component.exports