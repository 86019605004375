<template>
  <div class="table-toolbar-container">
    <div class="toolbar-filter-selections">
      <p>{{ labels.filter ?? 'Showing' }}</p>
      <select
        v-if="hasPrimary"
        :class="primaryClass"
        :value="filterPrimary"
        @change="filterPrimary = $event.target.value"
      >
        <option class="toolbar-select-placeholder" :value="undefined" disabled>{{ labels.primary || 'Select' }}</option>
        <option v-for="item in uniquePrimary" :key="item" :value="item">{{ item }}</option>
      </select>
      <select
        v-if="hasSecondary"
        :class="secondaryClass"
        :value="filterSecondary"
        @change="filterSecondary = $event.target.value"
      >
        <option class="toolbar-select-placeholder" :value="undefined" disabled>{{ labels.secondary || 'Select' }}</option>
        <option v-for="item in uniqueSecondary" :key="item" :value="item">{{ item }}</option>
      </select>
      <button class="toolbar-actions">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9.49492 18.5455C9.49492 19.9234 10.6215 21.05 11.9995 21.05C13.3774 21.05 14.504 19.9234 14.504 18.5455C14.504 17.1675 13.3774 16.0409 11.9995 16.0409C10.6215 16.0409 9.49492 17.1675 9.49492 18.5455ZM9.49492 12C9.49492 13.3779 10.6215 14.5045 11.9995 14.5045C13.3774 14.5045 14.504 13.3779 14.504 12C14.504 10.6221 13.3774 9.49545 11.9995 9.49545C10.6215 9.49545 9.49492 10.6221 9.49492 12ZM9.49492 5.45455C9.49492 6.83248 10.6215 7.95909 11.9995 7.95909C13.3774 7.95909 14.504 6.83248 14.504 5.45455C14.504 4.07661 13.3774 2.95 11.9995 2.95C10.6215 2.95 9.49492 4.07661 9.49492 5.45455ZM10.4131 18.5455C10.4131 17.6638 11.1178 16.9591 11.9995 16.9591C12.8811 16.9591 13.5858 17.6638 13.5858 18.5455C13.5858 19.4271 12.8811 20.1318 11.9995 20.1318C11.1178 20.1318 10.4131 19.4271 10.4131 18.5455ZM10.4131 12C10.4131 11.1183 11.1178 10.4136 11.9995 10.4136C12.8811 10.4136 13.5858 11.1183 13.5858 12C13.5858 12.8817 12.8811 13.5864 11.9995 13.5864C11.1178 13.5864 10.4131 12.8817 10.4131 12ZM10.4131 5.45455C10.4131 4.57289 11.1178 3.86818 11.9995 3.86818C12.8811 3.86818 13.5858 4.57289 13.5858 5.45455C13.5858 6.3362 12.8811 7.04091 11.9995 7.04091C11.1178 7.04091 10.4131 6.3362 10.4131 5.45455Z" fill="#111111" stroke="#111111" stroke-width="0.1"/>
        </svg>
      </button>
    </div>
    <form @submit.prevent="handleSearch">
      <div class="toolbar-search">
        <label for="table-search">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3226 13.8833C11.3996 14.5841 10.2484 15 9 15C5.96243 15 3.5 12.5376 3.5 9.5C3.5 6.46243 5.96243 4 9 4C12.0376 4 14.5 6.46243 14.5 9.5C14.5 10.7484 14.0841 11.8996 13.3833 12.8226L16.2803 15.7197C16.5732 16.0126 16.5732 16.4874 16.2803 16.7803C15.9874 17.0732 15.5126 17.0732 15.2197 16.7803L12.3226 13.8833ZM13 9.5C13 11.7091 11.2091 13.5 9 13.5C6.79086 13.5 5 11.7091 5 9.5C5 7.29086 6.79086 5.5 9 5.5C11.2091 5.5 13 7.29086 13 9.5Z" fill="#8A8A8A"/>
          </svg>
        </label>
        <input v-model="searchValue" id="table-search" type="text" placeholder="Search" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PartnerTableToolbar',
  props: {
    labels: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filterPrimary: undefined,
      filterSecondary: undefined,
      searchValue: this.search,
    };
  },
  computed: {
    hasPrimary() {
      return this.filters.some(filter => filter.primary);
    },
    hasSecondary() {
      return this.filters.some(filter => filter.secondary);
    },
    primaryClass() {
      return {
        'toolbar-select-placeholder': !this.filterPrimary,
      }
    },
    secondaryClass() {
      return {
        'toolbar-select-placeholder': !this.filterSecondary,
      }
    },
    uniquePrimary() {
      const primary = this.filters?.map(filter => filter.primary) || [];
      return primary.length > 0 ? [...new Set(primary)] : [];
    },
    uniqueSecondary() {
      const secondary = this.filters?.map(filter => filter.secondary) || [];
      return secondary.length > 0 ? [...new Set(secondary)] : [];
    },
  },
  methods: {
    handleFilterSelection() {
      const found = this.filters.find(filter => filter.primary === this.filterPrimary && filter.secondary === this.filterSecondary);
      if (found && found.id !== this.filter) {
        this.$emit('changeFilter', found.id);
      }
    },
    handleSearch() {
      this.$emit('changeSearch', this.searchValue);
    }
  },
  watch: {
    filter: {
      handler(value) {
        if (!value?.length > 0) {
          this.filterPrimary = null;
          this.filterSecondary = null;
          return;
        }

        const found = this.filters.find(filter => filter.id === value);
        if (found && (
          (this.hasPrimary && found.primary !== this.filterPrimary) ||
          (this.hasSecondary && found.secondary !== this.filterSecondary)
        )) {
          this.filterPrimary = found.primary;
          this.filterSecondary = found.secondary;
        }
      },
      immediate: true
    },
    filterPrimary() {
      this.handleFilterSelection();
    },
    filterSecondary() {
      this.handleFilterSelection();
    },
    search: {
      handler(value) {
        if (value !== this.searchValue) {
          this.searchValue = value;
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table-toolbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;

  font-size: 13px;
  font-weight: 600;
  line-height: 20px;

  select {
    position: relative;
    display: inline-block;
    padding: 6px 24px 6px 12px;
    border-radius: 8px;
    border: 0;
    outline: 0;
    box-shadow: 0px 1px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #B5B5B5 inset;

    font-family: inherit;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #303030;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.71967 8.96967C6.01256 8.67678 6.48744 8.67678 6.78033 8.96967L10.25 12.4393L13.7197 8.96967C14.0126 8.67678 14.4874 8.67678 14.7803 8.96967C15.0732 9.26256 15.0732 9.73744 14.7803 10.0303L10.7803 14.0303C10.4874 14.3232 10.0126 14.3232 9.71967 14.0303L5.71967 10.0303C5.42678 9.73744 5.42678 9.26256 5.71967 8.96967Z" fill="%234A4A4A"/></svg>');
    background-position: calc(100% - 6px);
    background-repeat: no-repeat;
  }

  .toolbar-select-placeholder {
    color: #8A8A8A;
  }

  .toolbar-filter-selections {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .toolbar-actions {
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  .toolbar-search {
    display: flex;
    gap: 4px;
    width: 470px;
    height: 32px;
    padding: 6px 12px;
    border: 1px solid #8A8A8A;
    border-radius: 8px;

    label {
      margin: 0
    }

    input {
      flex: 1;
      padding: 0;
      border: 0;
      outline: 0;

      font-size: 13px;
      font-weight: 450;
      line-height: normal;
      color: #616161;
    }
  }
}
</style>