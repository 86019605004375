<template>
  <div v-if="loadedPage">
    <ContentCreator
      :company="company"
      :contentId="contentId"
      @changeContent="changeContent"/>
    <!--<EmailTemplate v-if="user && isAdmin(user)" />
    <div v-else>Sorry, you must be an admin to proceed</div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import EmailTemplate from '../stories/components/EmailTemplate/EmailTemplate.vue';
import ContentCreator from '../stories/components/Content/ContentCreator.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'AdminCompanyContentCreator',
  components: {
    // EmailTemplate,
    ContentCreator,
  },
  props: ['companyId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
  computed: {
    ...mapGetters([
      "company",
    ]),
    ...mapGetters(["user"])
  },
  watch: {
    companyId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    },
  },
  data() {
    return {
      loadedPage: false,
      contentId: null,
    };
  },
  methods: {
    async loadPage() {
      this.loadedPage = false;
      this.getContentId();
      await this.$store.dispatch('getAdminCompany', { entityId: this.companyId });
      this.setPageTitle('Admin', 'Company');
      this.loadedPage = true;
    },
    getContentId() {
      const { contentId } = this.$route.query;
      this.contentId = contentId;
    },
    changeContent(contentId) {
      const query = { ...this.$route.query };
      query.contentId = contentId;
      this.$router.replace({ query });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
