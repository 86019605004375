<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h1>{{ transl.HomePolicy.cookieheader }}</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h3>{{ transl.HomePolicy.cookieheader }}</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div class="data-text">
          <h3 class="pre-title-small">
            ABOUT COOKIES ON ENGINIO
          </h3>

          <p>
            Cookies or similar technology (“<b>cookies</b>”) are used when you visit Enginio, become a
            member
            or
            participate in an activity. We at NRG Nordic Retail Group AB (”<b>NRG</b>”) and/or the company
            whose site
            at Enginio you visit (the “<b>Company</b>") are responsible for the use of cookies.
          </p>

          <p>
            NRG and the Company (“<b>we</b>” or “<b>us</b>”) care about your privacy and want you to feel
            safe
            when you use
            Enginio. You are free to <u>contact</u> any of us if you have questions regarding our usage of
            cookies.
          </p>

          <b>In short: Cookies – what do we do with them?</b>
          <ul>
            <li><u>make the Enginio platform function properly,</u></li>
            <li><u>enable you to use your Enginio account,</u> and</li>
            <li><u>remember your choice.</u></li>
          </ul>

          <p>
            If you consent, we as well use cookies to:
          </p>

          <ul>
            <li><u>analyse how the Enginio platform is used,</u></li>
            <li><u>show relevant marketing on other sites, </u> e.g. on Facebook, and</li>
            <li><u>improve our newsletters.</u></li>
          </ul>

          <p>
            If you click on the links above, you will find more detailed information about our specific use
            of
            cookies.
          </p>

          <p>
            In this text you can read about how we use cookies and how you can avoid them. Certain cookies
            (so-called “persistent cookies”) will be stored on your browser even in-between sessions, while
            other cookies (so-called “temporary cookies” or “session cookies”) are temporarily stored on
            your
            device’s memory. The usage period for each cookie is listed in the tables below.
          </p>

          <b>You can avoid cookies</b>

          <ul>
            <li>Through the settings in your browser, you can block certain types of cookies. If you do so,
              your browser will automatically deny storage of these cookies or inform you each time that a
              server requests to use such cookies.</li>

            <li>When you use Enginio, you can choose not to consent to our use of certain cookies.
              Furthermore, you can withdraw your consent at any time.</li>

            <li>After using the Enginio platform, you can check and delete the persistent cookies that have
              been placed on your browser. To help you do this, we have when possible stated the name of
              each
              cookie in the tables below.</li>
          </ul>

          <p>You can delete cookies in your browser. If you block or delete cookies necessary for the
            functionality of Enginio, all functions in Enginio will not work as intended and you will not be
            able to use all functions in Enginio. In the tables below, we explain what happens if you block
            or
            delete different cookies.</p>

          <b>Who is responsible for the use of cookies?</b>

          <p>Both NRG Nordic Retail Group AB (Swedish registration number 556608-5642, “NRG”) and the
            Company are responsible for the use of cookies.</p>

          <ul>
            <li>When you use Enginio and participate in activities, the Company is responsible,</li>

            <li>When providing your membership, NRG is responsible,</li>

            <li>When analysing the Enginio platform and providing online marketing, NRG and the Company are
              jointly responsible, and</li>

            <li>When analysing newsletters, NRG and the Company is individually responsible.</li>
          </ul>

          <p>
            Should you have any questions regarding our use of cookies, please feel free to contact the
            Company
            or NRG. You can reach NRG at at support@enginio.io or Linnégatan 9-11 114 47, Stockholm.
          </p>

          <b>Your privacy is important to us</b>

          <p>To protect your privacy, we have taken measures to avoid identifying you when you use the
            Enginio platform. For instance, only an encrypted version of your IP address is used. This
            measure
            is done
            in order to avoid the possibility to identify who you are. When information that can be
            connected
            to you as an individual, directly or indirectly, is processed, we will inform you about this in
            our privacy policies that you can find here.</p>

          <b>Third-party cookies and third-party services</b>

          <p>We mainly use normal cookies such as so-called "http cookies", but also make use of more
            advanced pixel cookies from Google, Facebook, and several other providers. These third-parties
            will use the information collected for their own purposes. In the table below, we have indicated
            when this is the case and referred to where you can get more information about their use of
            cookies and what information these third-parties collect.</p>

          <p>The use of third party services means that your personal data risks being transferred outside
            the EU/EEA in some cases. In our privacy policies, we also explain what it means for the
            information to be transferred outside the EU/EEA.</p>

          <b>You have the right to complain</b>

          <p>If you want to make a complaint regarding our use of cookies, you can contact any supervisory
            authority.</p>

          <ul>
            <li>The supervisory authority in Sweden is the Swedish Post and Telecom Authority (Post- och
              telestyrelsen, “PTS”).</li>
            <li>The supervisory authority in Denmark is the Danish Data Protection Agency (Datatilsynet).
            </li>
            <li>The supervisory authority in Finland is the Office of the Data Protection Ombudsman
              (Tietosuojavaltuutetun toimisto).</li>
          </ul>

          <b>Cookies we use if you give your consent</b>

          <p>The following cookies are only used if you have given us your consent, i.e. they are not
            strictly
            essential for enabling you to use the Enginio platform. You can withdraw your consent at any
            time.
          </p>

          <Table caption="To improve the Enginio platform and the Company’s activities"
                 :headers="['Purpose and description']"
                 :body="{rows: 3, columns: 1, items: [this.inOrder, this.inOrder2, this.inOrder3]}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 3, columns: 2, items: ['_ga', '_gid', '_gat_UA-', 'Cookie from Google used for two years after your visit', 'Cookie from Google used for one day after your visit', 'Cookie from Google used for one minute after your visit']}"
                 footer="If you block/delete the above cookies, we will have less data to develop Enginio." />

          <Table caption="To show you relevant marketing"
                 :headers="['Purpose and description']"
                 :body="{rows: 3, columns: 1, items: [this.weUse, this.weUse2, this.weUse3]}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 2, columns: 2, items: ['_fbp fr', '[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Cookies from Facebook used for three months', 'Cookie from TikTok used for XX Tid saknas']}" />

          <Table
                 :body="{rows: 2, columns: 1, items: ['We also use marketing services and cookies belonging to Schibsted to show you relevant marketing and to follow up on such marketing when you visit Schibsted’s websites. This means that you see advertising banners from us on Schibsted’s websites. This is done only if you have given your consent to this at a Schibsted site', 
                                                                  'Here you can choose which marketing you want to see on Schibsted sites.']}"
                 footer="If you block/delete these cookies, you will see less relevant marketing." />

          <Table caption="To improve our newsletters"
                 :headers="['Purpose and description']"
                 :body="{rows: 2, columns: 1, items: [this.newsletters, this.newsletters2]}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Cookie from Mailchimp used for XX Tid saknas']}"
                 footer="If you block/delete the above cookies, we will have less data to develop Enginio." />

          <b>Cookies that are essential for enabling you to use the Enginio platform</b>

          <p>The Enginio platform make use of the below cookies in order to function. These cookies are
            necessary
            for us to provide a service you have explicitly requested and therefore your consent is not
            required
            for our use of these cookies.</p>

          <Table caption="For the functionality of the Enginio platform"
                 :headers="['Purpose and description']"
                 :body="{rows: 2, columns: 1, items: ['To technically make Enginio work in a satisfying and secure manner we use cookies to enable us to keep track of your session and authenticate your device.',
                                                                   'These cookies create a unique ID for your session and make the Enginio platform work in the best way, i.e. that we avoid spam and ensure sufficient security by recognizing you during the visit.']}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Used during your visit']}" />

          <Table :headers="['Purpose and description']"
                 :body="{rows: 1, columns: 1, items: ['To adapt the Enginio platform to your device, we use cookies that collect information about your device. Based on such information, we adjust the viewing display so that you see the amount of content that are suitable for the device you are using.']}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Used during your visit']}" />

          <Table :headers="['Purpose and description']"
                 :body="{rows: 1, columns: 1, items: ['In order for us to be able to show movies on the Enginio platform, we use cookies from YouTube and Vimeo.']}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Used during your visit']}"
                 footer="If you block/delete these cookies, the Engino platform will not function the way it is intended to." />

          <Table caption="For you to be able to use your Enginio account"
                 :headers="['Purpose and description']"
                 :body="{rows: 1, columns: 1, items: ['In order for you to be able to use the Enginio platform in logged-in mode, we use cookies which remember that you have logged in after you have entered your login details.']}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['UserID', 'Used for one day after your visit']}" />

          <!--<Table :headers="['Purpose and description']"
                 :body="{rows: 1, columns: 1, items: ['In order to remember your trusted devices connected to your Enginio account, we use cookies from FingerprintJS.']}" />-->

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Used when you log in and sign up as well as when you change phone number – the key will be stored for [XX] after your visit']}"
                 footer="If you block/delete these cookies, the Engino platform will not function the way it is intended to." />

          <Table caption="To remember your choices and adapt the Enginio platform to suit you"
                 :headers="['Purpose and description']"
                 :body="{rows: 1, columns: 1, items: ['To remember which country\'s version of the Enginio platform you want to see we use cookies that save such information. This enables us to show you the platform in the language that you have chosen.']}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Used during your visit']}" />

          <Table :headers="['Purpose and description']"
                 :body="{rows: 1, columns: 1, items: ['To keep track of whether you have given your consent or not we use a cookie to remember if you have chosen to consent to our use of cookies. ']}" />

          <Table :headers="['Name of cookie', 'Usage period']"
                 :body="{rows: 1, columns: 2, items: ['[ange om möjligt namnet på cookien här. Om det inte finns ett namn på ”cookien” får ni ta bort denna ruta]', 'Used for six months after you have consented']}"
                 footer="If you block/delete these cookies, we cannot remember your choices and will instead show you a version of the Enginio platform that is not customized for you. In addition, we need to collect new information when you visit the Enginio platform next time." />

          <hr>
          <p>This cookie policy was adopted by us on 2022-02-28.</p>
        </div>
      </div>

      <div class="right-card">
        <div class="--container">
          <div
               class="data-link unclickable">
            <div class="button-text">Cookies policy</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.visitors' })">
            <div class="button-text">Privacy Policy - Visitors</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.members' })">
            <div class="button-text">Privacy Policy - Members</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.participants' })">
            <div class="button-text">Privacy Policy - Participants</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.terms' })">
            <div class="button-text">Terms and conditions</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <!--<div
             class="data-link"
             @click="$router.push({ name: 'HomeData.companies' })">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Table from '../stories/components/Table/Table.vue';

export default {
  name: 'HomeDataCookies',
  components: { Table },
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle(this.transl.HomePolicy.cookieheader);
    this.setCompany({});
  },
  computed: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
  data() {
    return {
      inOrder:
        "In order to analyse how you interact with Enginio we use cookies from Google's analytics service Google Analytics. These cookies use a random ID for your device to distinguish you as a visitor in order to see patterns in your usage of the Enginio platform.",
      inOrder2:
        'We collect information about your encrypted IP address, your location and other information on how you use Enginio and the activities, e.g. what you click on. The information is used to optimize functioning, loading speed and to modify Enginio and the activities to become better adapted to our users.',
      inOrder3:
        'You can avoid cookies from Google Analytics by downloading and installing this browser program.',
      weUse:
        'We use cookies to show you relevant marketing and to follow up on such marketing when you visit other websites or social media. We use our own cookies and cookies from marketing services belonging to Facebook (including Instagram) and TikTok.',
      weUse2:
        'This means that you see search results and advertising banners from us based on the analysis of how you used Enginio and based on other information that Facebook and TikTok have about you (so-called "profiling"). Based on this we can also adapt the Enginio platform to better suit our users.',
      weUse3:
        'Here on Facebook under the heading “Facebook Ads Controls” you can choose which marketing you want to see on Facebook (including Instagram). Here you can choose which marketing you want to see on TikTok.',
      newsletters:
        'In order to analyse how you interact with our newsletters we use cookies from Mailchimp.',
      newsletters2:
        'We collect information about how you use our newsletters, e.g. what you click on. The information is used to modify and improve our newsletters to become better adapted to our receivers.',
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeDataCookies";
</style>
