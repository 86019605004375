<template>
  <div v-if="loadedPage"
       class="entity-editor__links-gallery-container">
    <!-- div  modal label input -->
    <p class="entity-editor__hyperlink-route">To create a new hyperlink go to<span
              class="entity-editor__route"
              @click="$router.push({ name: 'AdminHyperlinks' })"><u>Manage Hyperlinks</u></span>
        <iconComponent symbol="shop" />
      </p>
    <div>
      <CKEditor
        :value="label"
        @input="setHyperlinkLabel($event)">
      </CKEditor>
    </div>
    <div class="entity-editor__links-gallery-wrapper"
         v-for="(hyperlink, index) in parentHyperlinks"
         :key="hyperlink._id">
      <div class="entity-editor__links-gallery-hyperlink-wrapper">
        <div class="entity-editor__links-gallery-hyperlink-name">
          {{index+1}}. {{hyperlink.name}}
        </div>
        <img
             class="entity-editor__links-gallery-media"
             :src="hyperlink.media?.url" alt="" />
      </div>
      <div class="entity-editor__links-gallery-link-url">
        <InputField
                    label="Link"
                    :value="parentLinks[index]"
                    @input="setLink(index, $event)" />
      </div>
      <div
           class="btn entity-editor__links-gallery-icon"
           @click="removeLink(index)">
        <iconComponent symbol="trashcan" />
      </div>
    </div>
    <div v-if="addNew">
      <InputField
                  class="entity-editor__links-gallery-add-new"
                  label="Choose Hyperlink"
                  inputType="select"
                  v-model="newHyperlink"
                  :selectOptions="hyperlinks"
                  :selectSettings="{ optionTitle: 'name', optionKey: '_id' }"
                  @input="pickHyperlink($event)" />
    </div>
    <div class="btn btn-activate btn-width"
         @click="addNew = !addNew">
      <iconComponent symbol="plusMath" fill="white" stroke="white" /> Add hyperlink to list
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputField from '../InputField/InputField.vue';
import CKEditor from '../CKEditor/CKEditor.vue';

export default {
  name: 'LinksGalleryComponent',
  components: {
    InputField,
    CKEditor,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    parentHyperlinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    parentLinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    ...mapGetters(['hyperlink', 'hyperlinks']),
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
  },
  mounted() {
    this.loadedPage = true;
    this.findAdminHyperlinks();
  },
  data() {
    return {
      selected: '',
      loadedPage: false,
      showLinksGalleryDialog: false,
      addNew: false,
      newHyperlink: 'choose',
    };
  },
  methods: {
    findAdminHyperlinks(query) {
      this.$store.dispatch(`find${this.userType}Hyperlinks`, { query }).then(() => {
        this.loadedPage = true;
      });
    },
    setHyperlinkLabel(value) {
      this.$emit('changedHyperlinkLabel', value);
    },
    pickHyperlink(hyperlink) {
      this.$emit('pickedHyperlink', hyperlink);
    },
    setLink(index, value) {
      this.$emit('changedLink', { index, value });
    },
    removeLink(index) {
      this.$emit('deletedLink', { index });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './LinksGallery.scss';

.btn-width {
  width: 225px;
  float: center;
}
</style>
