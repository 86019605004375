<template>
  <div
    v-if="loadedPage"
    class="new-company">
    <div class="link-company">
      <h3>Link company to your Account</h3>
      <md-field>
        <label>Insert the code that you have recieved</label>
        <md-input v-model="code"></md-input>
      </md-field>
      <div class="btn btn-activate" @click="linkCompany()">
        Link
      </div>
    </div>
    <div v-if="companies.length" class="linked-companies">
      <h3>Or, login to any of the accounts below:</h3>
      <div class="grid-wrapper">
        <div
          v-for="company in companies"
          :key="company._id"
          class="company-box"
          :style="`background-image: url(${company.media ? company.media.url : ''}); margin-bottom: 2rem;`"
          @click="pickCompany(company._id)">
          <div class="company-box-name">
            <div>{{ company.name }}</div>
          </div>
          <img
            v-if="company.icon?.url"
            class="company-logo-start"
            :src="`${company.icon?.url}`"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from '../resources/eventBus';
import { mapGetters } from 'vuex';

export default {
  name: "CompanyLink",
  mixins: [BeforeEnterMixin],
  mounted() {
    this.$store.dispatch('getUserCompanies').then(() => {
      this.loadedPage = true;
    });
  },
  computed: {
    ...mapGetters([
      'user',
      'userStatus',
      'companies',
    ]),
  },
  data() {
    return {
      loadedPage: false,
      code: '',
      attempts: 0,
    };
  },
  methods: {
    pickCompany(companyId) {
      this.$store.dispatch('pickCompany', { companyId }).then(() => {
        this.$store.dispatch('setAuthenticated', true);
        this.$router.replace({ name: 'CompanyHome' });
      });
    },
    linkCompany() {
      this.$store.dispatch("linkCompany", { code: this.code })
      .then(() => {
        this.$store.dispatch('setAuthenticated', true);
        this.$router.replace({ name: 'CompanyHome' });
      })
      .catch((error) => {
        if (this.attempts >= 10) {
          return EventBus.$emit('logout');
        }
        this.attempts += 1;
        alert(error?.response?.data?.message || 'Incorrect code, please try again!');
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/CompanyLink";
</style>
