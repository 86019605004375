<template>
  <div class="content-video">
    <video width="320" height="240" controls>
      <source :src="src" :type="mediaType" :width="width">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: 'ContentVideo',
  props: {
    src: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      default: 'video/mp4',
    },
    width: {
      type: String,
      default: '100%',
    },
  }
}
</script>

<style scoped>
@import './Content.css';
</style>