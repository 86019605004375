/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import linkApi from '../../api/link.api';

// initial state
const state = {
  link: {},
  links: [],
  linksStatus: '',
};

// getters
const getters = {
  link: state => state.link,
  links: state => state.links,
  linksStatus: state => state.linksStatus,
};

// actions
const actions = {
  findAdminLinks({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.findAdminLinks(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
      });
  },

  addAdminLinksIn({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.addAdminLinksIn(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
        return response.links;
      });
  },

  changeAdminLinksIn({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.changeAdminLinksIn(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
        return response.links;
      });
  },

  deleteAdminLinksIn({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.changeAdminLinksIn(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
        return response.links;
      });
  },

  // Partner
  findCompanyLinks({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.findCompanyLinks(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
      });
  },

  addCompanyLinksIn({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.addCompanyLinksIn(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
      });
  },

  changeCompanyLinksIn({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.changeCompanyLinksIn(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
      });
  },

  deleteCompanyLinksIn({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return linkApi.changeCompanyLinksIn(payload)
      .then((response) => {
        commit('LINKS', response.links);
        commit('LINK_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  LINKS(state, links) {
    state.links = links;
  },
  ADD_LINK(state, links) {
    state.links.unshift(links);
  },
  SAVE_LINK(state, links) {
    for (let i = 0; i < state.links.length; i += 1) {
      if (state.links[i]._id === links._id) {
        Vue.set(state.links, i, links);
      }
    }
  },
  DELETE_LINK(state, linksId) {
    for (let i = 0; i < state.links.length; i += 1) {
      if (state.links[i]._id === linksId) {
        state.links.splice(i, 1);
      }
    }
  },
  LINK_STATUS(state, status) {
    state.linksStatus = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
