<template>
  <div class="survey-editor-container">
    <SurveyQuestion
      v-for="(question, index) in survey"
      :key="index"
      :number="index + 1"
      :question="question"
      :selected="hasSelectedProp ? selectedIndex === index : undefined"
      :removable="checkRemovability(question)"
      @change="handleChangeQuestion(index, $event)"
      @delete="handleDeleteQuestion(index)"
      @select="handleSelectQuestion(index)"
    />

    <div class="add-question-container">
      <button v-if="!showQuestionMenu" class="button-yellow add-question-button" @click="showQuestionMenu = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 3C7.03709 3 3 7.03709 3 12C3 16.9629 7.03709 21 12 21C16.9629 21 21 16.9629 21 12C21 7.03709 16.9629 3 12 3ZM16.6957 12.3913H12.3913V16.6957H11.6087V12.3913H7.30435V11.6087H11.6087V7.30435H12.3913V11.6087H16.6957V12.3913Z" fill="#EA7801"/>
        </svg>
        Add new question
      </button>
      <button v-else class="button-yellow add-question-button" @click="showQuestionMenu = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 3C7.03748 3 3 7.03709 3 12C3 16.9629 7.03748 21 12 21C16.9625 21 21 16.9629 21 12C21 7.03709 16.9625 3 12 3ZM16.6957 12.3913H7.30435V11.6087H16.6957V12.3913Z" fill="#EA7801"/>
        </svg>
        Close
      </button>
      
      <ul v-if="this.showQuestionMenu" class="add-question-menu">
        <template v-for="(option, index) in addQuestionOptions">
          <li class="add-question-option" :key="option.label" @click="handleAddQuestion(option.props)">
            <SurveyIcon :type="option.icon" />
            {{ option.label }}
          </li>
          <hr v-if="option.category !== addQuestionOptions[index + 1]?.category && index < (addQuestionOptions.length - 1)" :key="option.label" />
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import SurveyIcon from './SurveyIcon.vue';
import SurveyQuestion from './SurveyQuestion.vue';

export default {
  name: 'SurveyEditor',
  components: { SurveyQuestion, SurveyIcon },
  props: {
    survey: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      default: undefined,
    },
    requirements: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showQuestionMenu: false,
    }
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change);
    },
    handleAddQuestion(props) {
      this.showQuestionMenu = false;
      this.handleChange(this.survey.concat([{
        label: '',
        model: '',
        date: props.date ?? false,
        checkbox: props.checkbox ?? false,
        radio: props.radio ?? false,
        slider: props.slider ?? false,
        textarea: props.textarea ?? false,
        upload: props.upload ?? false,
        maxsize: props.maxsize ?? 0,
        other: props.other ?? false,
        slider_options: {
          options: {},
          media: [],
          fe_media: [],
        },
        options: props.options ?? [],
        helper: '',
        valdiator: '',
        required: props.required ?? false,
      }]));

      if (this.selectedIndex !== undefined) {
        this.$emit('selectQuestion', this.survey.length);
      }
    },
    handleChangeQuestion(index, value) {
      const questions = [...this.survey];
      questions[index] = value;
      this.handleChange(questions);
    },
    handleDeleteQuestion(index) {
      const questions = [...this.survey];
      questions.splice(index, 1);
      this.handleChange(questions)
    },
    handleSelectQuestion(index) {
      if (this.selectedIndex !== undefined) {
        this.$emit('selectQuestion', index);
      }
    },
    checkRemovability(question) {
      // ToDo: Implement more checks
      if (!question?.upload || !this.requirements?.upload) {
        return true;
      }

      return this.survey.filter(q => q.upload).length > 1;
    },
  },
  computed: {
    hasSelectedProp() {
      return this.selectedIndex !== undefined;
    },
    addQuestionOptions() {
      return [
        {
          label: 'Single choice',
          category: 'choice',
          icon: 'radio',
          props: {
            radio: true,
            options: ['']
          },
        },
        {
          label: 'Multiple choice',
          category: 'choice',
          icon: 'checkbox',
          props: {
            checkbox: true,
            options: ['']
          },
        },
        {
          label: 'Text',
          category: 'text',
          icon: 'text',
          props: {
            //
          },
        },
        {
          label: 'Slider',
          category: 'slider',
          icon: 'slider',
          props: {
            slider: true,
          },
        },
        {
          label: 'Image',
          category: 'image',
          icon: 'image',
          props: {
            upload: true,
            maxsize: 1000000, // ToDo: Look up desired maxsize
          },
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.survey-editor-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 326px;


  .add-question-container {
    position: relative;

    .add-question-button {
      margin-top: 8px;
      padding: 0;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  
    .add-question-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 225px;
      padding: 10px 16px;
  
      border-radius: 4px;
      border: 2px solid $brand-yellow;
  
      .add-question-option {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: #333333;
      }

      hr {
        width: 100%;
        margin: 0;
        border: 1px solid #F2F2F2;
      }
    }

  }
}

</style>