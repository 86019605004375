<template>
  <div class="content-iframe">
    <iframe
      :src="src"
      width="100%"
      height="500">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'ContentIframe',
  props: {
    src: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>
@import './Content.css';
</style>