/* eslint-disable no-shadow, no-param-reassign */

// initial state
const state = {
  fingerprint: '',
};

// getters
const getters = {
  fingerprint: state => state.fingerprint,
};

// mutations
const mutations = {
  SET_FINGERPRINT(state, fingerprint) {
    state.fingerprint = fingerprint;
  },
};

export default {
  state,
  getters,
  mutations,
};