<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <div class="admin-search-container">
      <!--<InputField label="Find flagged" @input="findPVFlagged($event)" />-->

      <div class="add-entity-button" @click="showCompanyModal = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>

    <aList
      entityType="PV Flagged"
      columnsWidth="400"
      :entities="flagged"
      :keys="receiptKeys"
      @click="$router.push({ name: 'AdminReceipt', params: { receiptId: flagged[$event.index]._id } })"/>

    <modal v-if="showCompanyModal"
           :header="{text: 'Add pv company', textPosition: 'center', bgColor: '#F2F2F2'}"
           class="modal-view"
           modalType="fullscreen">
      <slot>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from "../resources/eventBus";
import aList from "../components/AList";
// import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: "AdminPVFlagged",
  components: {
    aList,
    // InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findPVFlagged();
    this.setPageTitle('Admin', 'PV Flagged');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('pvFlagged', ["flagged"])
  },
   data() {
    return {
      showCompanyModal: false,
      loadedPage: false,
      receiptKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'activation name',
          key: 'activation.name'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findPVFlagged(query) {
      this.$store.dispatch('pvFlagged/findAdminFlagged', { query }).then(() => {
        this.loadedPage = true;
      });
    },
    block(phoneNr, email) {
      if (!phoneNr && !email) {
        this.doAlert('you must specify either phonenr or email');
      } else {
        this.$store.dispatch('addAdminBlock', { phoneNr, email }).then(() => {
          this.loadedPage = true;
        });
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.company-list {
}
.single-list{
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
