<template>
  <div class="expired-date-offer"
       v-if="expiresAtState === 'expired' && loadedPage && !addAdress && !offer.closed">
    <div>
      <iconComponent symbol="timeOut" :width="150" :stroke-width="0" fill="#EA7801" />

      <div class="expired-date-header">{{ transl.Offer.expired }}</div>
      <div class="expired-date-text">{{ transl.Offer.watchout }}</div>

      <div class="btn btn-activate" @click="gotoCollection()">
        {{ backToText }}
      </div>
    </div>
  </div>

  <div v-else-if="loadedPage">
    <div
       v-if="offer && iframeWebsiteUrl"
       class="iframe-offer">
      <div v-if="notVerifiedEmail || !user.verified_email" class="competition-info">
        <iconComponent symbol="emailWithPlus" :width="150" :stroke-width="0" fill="#EA7801" />

        <div class="internal-link-title-3">
          {{ transl.Offer.youmustverify }}
        </div>

        <div class="internal-link-title-4 internal-link-center">
          {{ transl.Offer.mustverifyemail }}
        </div>

        <div class="btn btn-activate" @click="verifyEmail()">
          {{ transl.Offer.gotoprofile }}
        </div>

        <Hero class="ok-stamp" type="Integrity_badge_Light" />

        <div class="modal-terms">
          {{ transl.Offer.shareyou2 }}
          <span @click="openUrl(faqUrl)">FAQ.</span>
        </div>
      </div>
      <iframe
              v-if="iframeReady"
              :src="iframeWebsiteUrl"
              allow="camera;">
      </iframe>


    </div>

    <div
        v-else-if="offer && goodiebag && loadedPage && (expiresAtState !== 'expired' || expiredOffer || offer.closed)"
        class="user-offer-wrapper">
      <div class="user-offer">
        <div class="main-offer">

          <div
            class="collection-left-button"
            @click="$router.go(-1)">
            <div class="collection-back-button">
              <iconComponent symbol="arrowLeft" fill="black" stroke="black" :strokeWidth="2"/>
            </div>
          </div>
          <div
            v-if="offer.company.club"
            class="collection-club-button"
            @click="gotoClubProfile()">
            <div class="collection-club-icon">
              <iconComponent symbol="person" fill="white" stroke="white"/>
            </div>
            <!--<div class="collection-club-name">{{ user.name }}</div>-->
          </div>

          <div v-if="offer.media && offer.media.length === 1 && offer.media[0].fileType.includes('image')"
            class="offer-header-image offer-single-image">
            <img :src="offer.media[0].url" alt="" />

            <div v-if="!offer.unlocked" class="offer-jwt-lock">
              <iconComponent symbol="icons8Lock" width="30%" :stroke-width="0" fill="#FFFFFF" />
            </div>

            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="offer.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />

            <img v-if="offer.icon?.url || goodiebag.icon?.url"
                :src="`${!offer.unique_icon && goodiebag.icon?.url ? goodiebag.icon?.url : offer.icon?.url}`"
                class="offer-header-logo"
                @click="gotoCompany()" />
          </div>

          <video v-else-if="offer.media && offer.media.length === 1 && offer.media[0].fileType.includes('video')"
                class="news-autoplay offer-header-image autoplayMedia" controls muted playsinline>
            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="offer.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />

            <source :src="`${offer.media && offer.media[0] ? offer.media[0].url : ''}`"
                    :type="offer.media[0].fileType" />
            {{ transl.Offer.novideo }}
          </video>

          <div v-else-if="offer.media && offer.media.length > 1" class="offer-header-image carousel">
            <aCarousel
              :media-list="offer.media"
              :single-image="true"
              :single-arrows="true"/>

            <img v-if="offer.icon?.url || goodiebag.icon?.url"
                :src="`${!offer.unique_icon && goodiebag.icon?.url ? goodiebag.icon?.url : offer.icon?.url}`"
                class="offer-header-logo"
                @click="$router.replace({ name: 'UserGoodiebag', params: { goodiebagId: getId(goodiebag) } })" />

            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip" style="background-color: #E6E6E6;">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="offer.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />
          </div>

          <iframe v-else-if="offer.iframe_media && offer.iframe_media.length"
                  class="offer-header-image offer-header-iframe"
                  :src="`https://player.vimeo.com/video/${offer.iframe_media[0].id}`" frameborder="0"
                  allow="autoplay; fullscreen" allowfullscreen> </iframe>

          <div class="offer-content-wrapper content-page">
            <div class="offer-fast-icons">
              <div v-if="offer.variations" class="offer-fast-text">
                <iconComponent symbol="style" />
                <span>{{ transl.Offer.variants }}</span>
              </div>

              <div v-else-if="offer.save_text" class="offer-fast-text">
                <iconComponent symbol="newReleases" />
                <span>{{ offer.save_text }}</span>
              </div>

              <div v-else class="offer-fast-text">
                <span style="padding: 0;">{{ offer.quick_text }}</span>
              </div>

              <div v-if="expiresDate" class="offer-fast-text-two">
                <iconComponent symbol="calendarToday" :width="20" />
                <span>{{ expiresDate }}</span>
              </div>

              <div class="offer-fast-icons-actions">
                <div class="btn-icon-text btn-description offer-fast-share" v-if="!offer.closed"
                    @click="createFavorite">
                  <iconComponent v-if="favorite" symbol="heartFilled" />
                  <iconComponent v-else symbol="heart" />
                  <!--<div class="md-description">{{ transl.Offer.save }}</div>-->
                </div>

                <div class="btn-icon-text btn-description offer-fast-share"
                    v-if="offer.share && !offer.closed && !offer.jwt_lock" v-clipboard:copy="currentUrl"
                    v-clipboard:success="onCopy" v-clipboard:error="onError">
                  <iconComponent symbol="share" />
                  <!--<div class="md-description">{{ transl.Offer.share }}</div>-->
                </div>
              </div>
            </div>

            <div class="offer-header">{{ offer.first_header }}</div>
            <div class="offer-header-two" style="margin-bottom: 1.5rem;">
              <span v-html="offer.second_header"></span>
            </div>

            <div class="offer-survey" v-if="answeredSurvey">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />
              <div style="padding-top: 1rem;">
                {{ transl.Offer.thanks }}
                <br />
                {{ transl.Offer.thanks2 }}
              </div>
            </div>

            <div class="offer-survey"
                v-else-if="offer.survey && offer.survey.length && !afterNow(offer.starts_at)">
              <div v-for="(question, index) in offer.survey" :key="question.label"
                  class="offer-survey-question">
                <label class="offer-survey-label subtitle-small"
                      :for="question.label">{{ index + 1 }}. {{ question.label }}</label>

                <div class="offer-survey-radio-wrapper"
                    v-if="question.options && question.options.length && question.radio">
                  <md-radio v-for="option in question.options" :key="option" :value="option" :name="question.model"
                            v-model="surveyAnswers[question.model]" class="md-primary">{{ option }}</md-radio>
                  <!--<md-radio v-if="question.other" key="other" :value="surveyOther[question.model]" :id="`${question.model}other`" :name="question.model"
                            @change="setRadio(question, 'other')" class="md-primary">
                    <InputField
                          :label="transl.Offer.other" :outlined="false" :filled="true"
                          @input="setRadio(question, 'other', true)"
                          v-model="surveyOther[question.model]"/>
                  </md-radio>-->
                </div>

                <div class="offer-survey-checkbox-wrapper"
                    v-else-if="question.options && question.options.length && question.checkbox">
                  <div
                    v-for="option in question.options"
                    :key="option"
                    class="offer-survey-checkbox-regular">
                    <input type="checkbox" class="offer-survey-checkbox" :value="option" :id="question.model"
                          @change="setCheckbox(question, option)" />
                    <span style="margin: 0 16px;">{{ option }}</span>
                  </div>
                  <div
                    v-if="question.other"
                    class="offer-survey-checkbox-regular offer-survey-checkbox-other">
                    <input
                      type="checkbox" class="offer-survey-checkbox" :id="`${question.model}other`"
                      @change="setCheckbox(question, 'other', surveyOther[question.model])"/>
                    <InputField
                      :label="transl.Offer.other" :outlined="false" :filled="true"
                      @input="setCheckbox(question, 'other', $event, true)"
                      style="margin-left: 16px; width: 93%;"
                      v-model="surveyOther[question.model]"/>
                  </div>
                </div>

                <div v-else-if="question.options && question.options.length && question.slider"
                    style="padding: 1rem 0 3.5rem 0;">
                  <img v-if="question.slider_options?.media && question.slider_options.media.length"
                    :src="question.slider_options.media[question.options.indexOf(surveyAnswers[question.model] || question.options[0])].url"
                    style="padding-bottom: 1rem" />
                  <VueSlider
                    v-model="surveyAnswers[question.model]"
                    v-bind="question.slider_options.options"
                    :data="question.options">
                  </VueSlider>
                </div>

                <md-field v-else-if="question.options && question.options.length">
                  <md-select v-model="surveyAnswers[question.model]" :name="question.label"
                            :id="question.model">
                    <md-option v-for="option in question.options" :key="option" :value="option">{{ option }}
                    </md-option>
                  </md-select>
                </md-field>

                <span v-else-if="question.checkbox" class="offer-survey-checkbox-wrapper"> <input
                        type="checkbox"
                        :id="question.model" v-model="surveyAnswers[question.model]" />{{ question.label }}
                </span>

                <md-datepicker v-else-if="question.date" v-model="surveyAnswers[question.model]">
                </md-datepicker>

                <Uploader v-else-if="question.upload"
                          role="UserSurvey"
                          entity="Offer"
                          mediaType="Picture"
                          :entityId="offer._id"
                          :keepFeFile="true"
                          :aspectHeight="NaN"
                          :aspectWidth="NaN"
                          @pickedFile="surveyAnswers[question.model] = 'there is a file'">
                </Uploader>

                <div v-else :required="question.required">
                  <!--:class="{ 'incorrect-input' : surveyAnswers[question.model] !== '' && question.validator && !validator[question.validator](surveyAnswers[question.model]) }"-->
                  <InputField v-if="!question.checkbox || !question.radio || !question.slider || !question.date || !question.textarea"
                              label="" :outlined="true" :filled="false"
                              v-model="surveyAnswers[question.model]" />
                  <InputField v-if="question.textarea" inputType="textarea" placeholder="" label=""
                              v-model="surveyAnswers[question.model]" />
                  <span class="md-helper-text the-issue">{{ question.helper }}</span>
                </div>
              </div>
            </div>

            <div v-if="answeredSurvey || donatedOffer || offer.public_soft" style="font-style: italic; margin-bottom: 1rem;">
              <div class="btn btn-activate btn-fixed" @click="gotoCollection()">
                {{ backToText }}
              </div>
            </div>

            <div v-else-if="offer.external_link && !expiredOffer && offer.unlocked && !offer.internal_link && !offer.winner_adress"
                class="btn btn-activate btn-fixed"
                @click="activateInternalOffer(true, false, offer.external_link)">
              {{ offer.link_button_title || transl.Offer.gotooffer }}
            </div>

            <div v-else-if="((offer.links_gallery && offer.links_gallery.links && offer.links_gallery.links.length) || offer.pdf)
              && !expiredOffer && offer.unlocked && !offer.internal_link && !offer.winner_adress"
                class="btn btn-activate btn-fixed" @click="internalLinkModal = true">
              {{ offer.link_button_title }}
            </div>

            <!--v-if=... && (offer.internal_link || offer.winner_adress || (offer.survey && offer.survey))-->
            <!--<div
                class="btn btn-activate btn-fixed"
                v-else-if="!acceptMerchandise"
                @click="idfyedRedirect(`offers/${offer.unique_name || offer._id}`, 'givenname,sn,email,mobile', )">
                {{ offer.link_button_title || 'Till Erbjudandet' }}
              </div>-->

            <div class="btn btn-activate btn-fixed"
                v-else-if="(offer.internal_link || offer.winner_adress) && !expiredOffer && offer.unlocked"
                @click="internalLinkModal = true">
              {{ offer.link_button_title || transl.Offer.gotooffer }}
            </div>

            <div class="btn btn-activate btn-fixed"
                v-else-if="offer.survey && offer.survey.length && offer.unlocked"
                @click="activateInternalOffer()">
              {{ offer.link_button_title }}
            </div>

            <div v-else-if="hasCTA && offer.unlocked" style="font-style: italic; margin-bottom: 1rem;">
              <div class="btn btn-activate btn-fixed" @click="gotoCollection()">
                {{ backToText }}
              </div>
            </div>

            <div v-if="canDonate" style="font-style: italic; margin-bottom: 1rem;">
              <div class="btn btn-transparent btn-fixed btn-donate" @click="donateOffer()">
                {{ transl.Offer.donate }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="sideOffers && sideOffers.length" class="sideoffers-header-mobile">
          {{ transl.Offer.moreoffers }}
          <br />
          {{ transl.Offer.from }} {{ goodiebag.company.name }}
        </div>

        <div class="sideoffers-wrapper" v-if="sideOffers && sideOffers.length">
          <div class="sideoffers-header">
            {{ transl.Offer.moreoffers }}
            <br />
            {{ transl.Offer.from }} {{ goodiebag.company.name }}
          </div>

          <div class="sideoffer-box" :class="{ 'sideoffer-box-no-text-box': sideOffer.hide_text_box }"
              v-for="sideOffer in sideOffers"
              :key="sideOffer._id" @click="gotoCurrentOffer(sideOffer)">
            <div v-if="sideOffer.thumbnail && sideOffer.thumbnail.fileType && sideOffer.thumbnail.fileType.includes('image')"
                class="sideoffer-box-pic" :style="`background-image: url(${sideOffer.thumbnail.url});`"></div>

            <div v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('image')"
                class="sideoffer-box-pic"
                :style="`background-image: url(${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : ''});`">
            </div>

            <video v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('video')"
                  class="news-autoplay autoplayMedia"
                  :style="!sideOffer.hide_text_box ? 'border-radius: 6px 6px 0 0;' : 'border-radius: 6px;'"
                  :controls="false" muted playsinline>
              <source :src="`${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : ''}`"
                      :type="sideOffer.media[0].fileType" />
              {{ transl.Offer.novideo }}
            </video>

            <!--<img TODO: enable
              v-if="sideOffer.icon?.url && sideOffer.unique_icon"
              :src="`${sideOffer.icon?.url}`"/>-->

            <div class="card-tag">
              <md-chip v-if="sideOffer.offer_tag && sideOffer.offer_tag.length" class="card-chip">
                {{ sideOffer.offer_tag }}
              </md-chip>
            </div>

            <div v-if="!sideOffer.hide_text_box" class="sideoffer-box-text">
              <div class="sideoffer-box-name">{{ sideOffer.first_header }}</div>

              <div v-if="sideOffer.variations" class="sideoffer-box-info body-2">
                <iconComponent symbol="style" />
                <span>{{ transl.Offer.variants }}</span>
              </div>

              <div v-else-if="sideOffer.save_text" class="sideoffer-box-info body-2">
                <iconComponent symbol="newReleases" />
                <span>{{ sideOffer.save_text }}</span>
              </div>

              <div v-else class="sideoffer-box-info body-2">
                <span style="padding: 0;">{{ sideOffer.quick_text }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-show="recurringWinVideo" class="recurring-video-modal">
          <video class="recurring-video" id="recurring-win-video" :controls="false" muted playsinline>
            <source :src="offer.recurring_win_video?.url" type="video/webm" />
            {{ transl.Offer.novideo }}
          </video>
        </div>

        <div v-show="recurringLooseVideo" class="recurring-video-modal">
          <video class="recurring-video" id="recurring-loose-video" :controls="false" muted playsinline>
            <source :src="offer.recurring_loose_video?.url" type="video/webm" />
            {{ transl.Offer.novideo }}
          </video>
        </div>
      </div>

      <modal v-if="internalLinkModal" class="modal" @close="internalLinkModal = false"
          :header="{text: offer.first_header, textPosition: 'center', bgColor: '#F2F2F2'}"
          slotTransName="slot" slotTransMode="out-in"
          :slotTransAppear="true" modalType="bottom">
        <slot>
          <div class="modal" v-if="internalLinkModal && isMobile">
            <div v-if="activateErrorMsg" class="competition-info">
              <iconComponent v-if="activateErrorMsg !== ' '" symbol="clover" :width="150" :stroke-width="0"
                            fill="#EA7801" />

              <div class="internal-link-title-2">
                {{ activateErrorMsg }}
              </div>

              <div class="btn btn-activate" :class="{ 'try-again-button-disabled': disableTryAgain }"
                  v-if="activateErrorButton === 'retry' || activateErrorButton === 'tooEarly'"
                  @click="activateInternalOffer(offer.winner_adress)">
                {{ transl.Offer.tryagain }}
              </div>

              <div class="btn btn-activate" v-else-if="activateErrorButton === 'goodiebag'"
                  @click="gotoCollection()">
                {{ backToText }}
              </div>

              <div v-else-if="activateErrorButton === 'support'" class="support-modal-button"
                  @click="$emit('openSupportModal', {})">
                {{ transl.Offer.contactsupport }}
              </div>
            </div>

            <div v-else-if="notVerifiedEmail || !user.verified_email" class="competition-info">
              <iconComponent symbol="emailWithPlus" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.youmustverify }}
              </div>

              <div class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.mustverifyemail }}
              </div>

              <div class="btn btn-activate" @click="verifyEmail()">
                {{ transl.Offer.gotoprofile }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />

              <div class="modal-terms">
                {{ transl.Offer.shareyou2 }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="!offer.unlocked && offer.jwt_lock_explanation">
              <iconComponent symbol="lock" :width="75" :stroke-width="1" fill="#EA7801" stroke="#EA7801" />

              <div class="internal-link-title-4" v-html="offer.jwt_lock_explanation"></div>

              <div
                class="btn btn-activate"
                @click="offer.jwt_lock_button_url ? openUrl(offer.jwt_lock_button_url) : gotoCollection()">
                {{ offer.jwt_lock_button_text ? offer.jwt_lock_button_text : backToText }}
              </div>
            </div>

            <div v-else-if="offer.pdf">
              <vuePdf :page="pageNr" :src="`${windowOrigin}/api/nar/file/${offer.pdf}`"
                      @num-pages="pageCount = $event">
              </vuePdf>

              <div class="pdf-viewer-buttons">
                <div class="btn btn-activate" @click="changePage(pageNr - 1)">
                  <iconComponent symbol="arrowLeft" :width="30" />
                </div>

                <div class="btn btn-activate" @click="changePage(pageNr + 1)">
                  <iconComponent symbol="arrowRight" :width="30" />
                </div>

                <div class="btn btn-activate" @click="openUrl(`${windowOrigin}/api/nar/file/${offer.pdf}`, true)">
                  <iconComponent symbol="magnifyingGlass" :width="30" />
                </div>
              </div>
            </div>

            <div v-else-if="chooseStore" class="competition-info">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.findstore.interpolate({ companyname: company.name }) }}
                <a v-if="company.closest_stores" :href="company.closest_stores" target="_blank">
                  {{ transl.Offer.weuse2 }}</a>
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.where.interpolate({ offerthe_prize: offer.the_prize ? offer.the_prize : "vinsten" }) }}
                <select v-model="selectedStore" name="storeOptions" id="storeOptions">
                  <option v-for="storeOption in storeOptions" :key="storeOption" :value="storeOption">
                    {{ storeOption }}
                  </option>
                </select>
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.usecode }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer(true, selectedStore === 'ICA')">
                {{ transl.Offer.complete }}
              </div>
            </div>

            <div v-else-if="sentEmail" class="competition-info">
              <iconComponent symbol="email" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.winfast }}
              </div>

              <div class="btn btn-activate" @click="internalLinkModal = false">
                {{ transl.Offer.close }}
              </div>
            </div>

            <div v-else-if="internalLinkEAN" class="competition-info">
              <iconComponent symbol="email" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.sendemail }}
              </div>

              <div class="btn btn-activate" @click="internalLinkModal = false">
                {{ transl.Offer.close }}
              </div>
            </div>

            <div v-else-if="offer.links_gallery && offer.links_gallery.links && offer.links_gallery.links.length"
                class="competition-info btn-fixed-bg">
              <div class="internal-link-title-2 selectable-text align-left" v-html="offer.links_gallery.label">
              </div>

              <div class="modal-external-links">
                <div class="modal-external-link"
                  v-for="(hyperlink, index) in offer.links_gallery.hyperlinks"
                  :key="hyperlink._id">
                  <img
                    :src="hyperlink.media?.url"
                    @click="activateInternalOffer(true, false, offer.links_gallery.links[index])" />
                </div>
              </div>

              <div class="btn btn-activate btn-fixed" @click="internalLinkModal = false">
                {{ transl.Offer.back }}
              </div>
            </div>

            <div v-else-if="!internalLinkInterleaved && !internalLinkAdressWinner && !addAdress && !expiredOffer && offer.recurring_time && (offer.winner_adress || offer.cta_email)"
                class="competition-info">
              <iconComponent symbol="start" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.howcompete }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.tocompete.interpolate({ time: offer.recurring_starts_at, count: offer.recurring_total }) }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer(true)">
                {{ transl.Offer.compete }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />
              <div class="modal-terms">
                {{ transl.Offer.shareyou }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="!internalLinkInterleaved && !internalLinkAdressWinner && !addAdress && !expiredOffer && offer.recurring_time"
                class="competition-info">
              <iconComponent symbol="start" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.howcompete }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.tocompete.interpolate({ time: offer.recurring_starts_at, count: offer.recurring_total }) }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer()">
                {{ transl.Offer.compete }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />

              <div class="modal-terms">
                {{ transl.Offer.shareyou }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="!internalLinkInterleaved && !internalLinkAdressWinner && !expiredOffer && !addAdress"
                id="activate-info">
              <iconComponent symbol="onlineShop" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.activateat }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.whatstore }}
                <select v-model="selectedStore" name="storeOptions" id="storeOptions">
                  <option v-for="storeOption in storeOptions" :key="storeOption" :value="storeOption">
                    {{ storeOption }}
                  </option>
                </select>
              </div>

              <div class="internal-link-title-3">
                {{ transl.Offer.usesoon.interpolate({ offerexpiration: offer.expiration ? offer.expiration / 60 : "20" }) }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer(true, selectedStore === 'ICA')">
                {{ transl.Offer.activate }}
              </div>

              <div class="btn btn-transparent" @click="internalLinkModal = false">
                {{ transl.Offer.noactivate }}
              </div>
            </div>

            <div v-else-if="offer.closed" class="expired-offer">
              <div class="internal-link-title-2">
                {{ transl.Offer.expired }}
              </div>

              <div class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.keepeye }}
              </div>

              <div class="btn btn-activate" @click="gotoCollection()">
                {{ backToText }}
              </div>
            </div>

            <div v-else-if="expiredOffer" class="expired-offer" style="padding: 0 1rem">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />

              <div>{{ transl.Offer.alreadyused }}</div>

              <div class="internal-link-title-4 internal-link-center">{{ transl.Offer.noworry }}</div>

              <div class="btn btn-activate" @click="gotoCollection()">
                {{ backToText }}
              </div>
            </div>

            <div v-else-if="addAdress" class="competition-info">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div v-if="name && lastName && adress && postalCode && city" class="internal-link-title-4">
                {{ transl.Offer.correctinfoq.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div v-else class="internal-link-title-4">
                {{ transl.Offer.fillinfo.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <InputField id="given-name" :label="transl.Offer.firstname" v-model="name" />
              <InputField id="family-name" :label="transl.Offer.lastname" v-model="lastName" />
              <InputField id="address-level1" :label="transl.Offer.adress" v-model="adress" />
              <InputField id="postal-code" :label="transl.Offer.postalcode" v-model="postalCode" />
              <InputField id="address-level2" :label="transl.Offer.city" v-model="city" />

              <div class="btn btn-activate" @click="changeUser()">
                {{ transl.Offer.save }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />
              <div class="modal-terms">
                {{ transl.Offer.shareyou2 }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="internalLinkAdressWinner" class="competition-info">
              <iconComponent symbol="gift" :width="150" :stroke-width="0"
                            fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.soonprize.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.waitforemail }}
              </div>

              <div class="btn btn-activate" @click="gotoCollection()">
                {{ transl.Offer.discoveroffers }}
              </div>
            </div>

            <div v-else class="competition-info">
              <!--<iconComponent
              v-if="offer.recurring_time && !offer.winner_adress"
              symbol="confetti"
              :width="150"
              :stroke-width="0"
              fill="#EA7801"/>
            <div
              v-if="offer.recurring_time && !offer.winner_adress"
              class="internal-link-title-3">
              {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
            </div>-->

              <div class="interleaved-code">
                <div class="interleaved-code-text1">KUPONGEN LÖSES IN AV CLEARON AB</div>
                <div v-if="interleaved_code" class="interleaved-code-code">
                  {{ interleaved_code.replace(/\B(?=(\d{2})+(?!\d))/g, " ") }}</div>
                <!--<div class="interleaved-code-text2">VISA UPP KODEN I KASSAN NÄR DU SKA BETALA</div>-->
                <!--<img
                class="interleaved-code-clearon"
                src="../assets/images/clearon.svg">-->
              </div>

              <div v-if="offer.recurring_time && !offer.winner_adress"
                  class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.readuse.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div v-else class="internal-link-title-2">
                {{ transl.Offer.readshow }}
              </div>

              <div class="ean-time-code">
                {{ transl.Offer.elapsedtime.interpolate({ elapsedtime: expiresInterleavedTime }) }}
              </div>
            </div>
          </div>

          <div class="modal" v-if="internalLinkModal && !isMobile">
            <div v-if="activateErrorMsg" class="competition-info">
              <iconComponent v-if="activateErrorMsg !== ' '" symbol="clover" :width="150" :stroke-width="0"
                            fill="#EA7801" />

              <div class="internal-link-title-2">
                {{ activateErrorMsg }}
              </div>

              <div class="btn btn-activate" :class="{ 'try-again-button-disabled': disableTryAgain }"
                  v-if="activateErrorButton === 'retry' || activateErrorButton === 'tooEarly'"
                  @click="activateInternalOffer(offer.winner_adress)">
                {{ transl.Offer.tryagain }}
              </div>

              <div class="btn btn-activate" v-else-if="activateErrorButton === 'goodiebag'"
                  @click="gotoCollection()">
                {{ backToText }}
              </div>

              <div v-else-if="activateErrorButton === 'support'" class="support-modal-button"
                  @click="$emit('openSupportModal', {})">
                {{ transl.Offer.contactsupport }}
              </div>
            </div>

            <div v-else-if="notVerifiedEmail || !user.verified_email" class="competition-info">
              <iconComponent symbol="emailWithPlus" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.youmustverify }}
              </div>

              <div class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.mustverifyemail }}
              </div>

              <div class="btn btn-activate" @click="verifyEmail()">
                {{ transl.Offer.gotoprofile }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />

              <div class="modal-terms">
                {{ transl.Offer.shareyou2 }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="!offer.unlocked && offer.jwt_lock_explanation">
              <iconComponent symbol="lock" :width="75" :stroke-width="1" fill="#EA7801" stroke="#EA7801" />

              <div class="internal-link-title-4" v-html="offer.jwt_lock_explanation"></div>

              <div
                class="btn btn-activate"
                @click="offer.jwt_lock_button_url ? openUrl(offer.jwt_lock_button_url) : gotoCollection()">
                {{ offer.jwt_lock_button_text ? offer.jwt_lock_button_text : backToText }}
              </div>
            </div>

            <div v-else-if="offer.pdf">
              <vuePdf :page="pageNr" :src="`${windowOrigin}/api/nar/file/${offer.pdf}`"
                      @num-pages="pageCount = $event">
              </vuePdf>

              <div class="pdf-viewer-buttons">
                <div class="btn btn-activate" @click="changePage(pageNr - 1)">
                  <iconComponent symbol="arrowLeft" :width="30" />
                </div>

                <div class="btn btn-activate" @click="changePage(pageNr + 1)">
                  <iconComponent symbol="arrowRight" :width="30" />
                </div>

                <div class="btn btn-activate" @click="openUrl(`${windowOrigin}/api/nar/file/${offer.pdf}`, true)">
                  <iconComponent symbol="magnifyingGlass" :width="30" />
                </div>
              </div>
            </div>

            <div v-else-if="chooseStore" class="competition-info">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.findstore.interpolate({ companyname: company.name }) }}
                <a v-if="company.closest_stores" :href="company.closest_stores" target="_blank">
                  {{ transl.Offer.weuse2 }}</a>
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.where.interpolate({ offerthe_prize: offer.the_prize ? offer.the_prize : "vinsten" }) }}

                <select v-model="selectedStore" name="storeOptions" id="storeOptions">
                  <option v-for="storeOption in storeOptions" :key="storeOption" :value="storeOption">
                    {{ storeOption }}
                  </option>
                </select>
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.usecode }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer(true, selectedStore === 'ICA')">
                {{ transl.Offer.complete }}
              </div>
            </div>

            <div v-else-if="sentEmail" class="competition-info">
              <iconComponent symbol="email" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.winfast }}
              </div>

              <div class="btn btn-activate" @click="internalLinkModal = false">
                {{ transl.Offer.close }}
              </div>
            </div>

            <div v-else-if="internalLinkEAN" class="competition-info">
              <iconComponent symbol="email" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.sendemail }}
              </div>

              <div class="btn btn-activate" @click="internalLinkModal = false">
                {{ transl.Offer.close }}
              </div>
            </div>

            <div v-else-if="offer.links_gallery && offer.links_gallery.links && offer.links_gallery.links.length"
                class="competition-info btn-fixed-bg">
              <div class="internal-link-title-2 selectable-text align-left" v-html="offer.links_gallery.label">
              </div>

              <div class="modal-external-links">
                <div class="modal-external-link"
                  v-for="(hyperlink, index) in offer.links_gallery.hyperlinks"
                  :key="hyperlink._id">
                  <img
                    :src="hyperlink.media?.url"
                    @click="activateInternalOffer(true, false, offer.links_gallery.links[index])" />
                </div>
              </div>

              <div class="btn btn-activate btn-fixed" @click="internalLinkModal = false">
                {{ transl.Offer.back }}
              </div>
            </div>

            <div v-else-if="!internalLinkInterleaved && !internalLinkAdressWinner && !addAdress && !expiredOffer && offer.recurring_time && (offer.winner_adress || offer.cta_email)"
                class="competition-info">
              <iconComponent symbol="start" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.howcompete }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.tocompete.interpolate({ time: offer.recurring_starts_at, count: offer.recurring_total }) }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer(true)">
                {{ transl.Offer.compete }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />

              <div class="modal-terms">
                {{ transl.Offer.shareyou }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="!internalLinkInterleaved && !internalLinkAdressWinner && !addAdress && !expiredOffer && offer.recurring_time"
                class="competition-info">
              <iconComponent symbol="start" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.howcompete }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.tocompete.interpolate({ time: offer.recurring_starts_at, count: offer.recurring_total }) }}
              </div>

              <div class="btn btn-activate" @click="activateInternalOffer()">
                {{ transl.Offer.compete }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />

              <div class="modal-terms">
                {{ transl.Offer.shareyou }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="!internalLinkInterleaved && !internalLinkAdressWinner && !expiredOffer && !addAdress"
                class="competition-info">
              <div class="internal-link-title-2">
                {{ transl.Offer.ops }}
              </div>

              <div class="internal-link-title-4" style="padding: 0 1rem; text-align: center;">
                {{ transl.Offer.usedata }}
              </div>

              <div class="internal-link-title-4" style="padding: 1rem; text-align: center;">
                {{ transl.Offer.clickfav }}
              </div>

              <div class="btn btn-activate" @click="createFavorite">
                <iconComponent style="color: white !important;" v-if="favorite" symbol="heartFilled" />
                <iconComponent style="color: white !important;" v-else symbol="heart" />
              </div>

              <div class="internal-link-title-1" style="padding: 1rem; text-align: center;">
                {{ transl.Offer.gotomobile.interpolate({ enginio: windowOrigin }) }}
              </div>
            </div>

            <div v-else-if="offer.closed" class="expired-offer">
              <div class="internal-link-title-2">
                {{ transl.Offer.expired }}
              </div>

              <div class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.keepeye }}
              </div>

              <div class="btn btn-activate"
                  style="margin-top: 2.5rem; width: 80%; color: white; background-color: black; border-radius: 6px;"
                  @click="gotoCollection()">
                {{ backToText }}
              </div>
            </div>

            <div v-else-if="expiredOffer" class="expired-offer" style="padding: 0 1rem">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />

              <div>{{ transl.Offer.alreadyused }}</div>

              <div class="internal-link-title-4 internal-link-center">{{ transl.Offer.noworry }}</div>

              <div class="btn btn-activate" @click="gotoCollection()">
                {{ backToText }}
              </div>
            </div>

            <div v-else-if="addAdress" class="competition-info">
              <iconComponent symbol="confetti" :width="150" :stroke-width="0" fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.congratz.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div v-if="name && lastName && adress && postalCode && city" class="internal-link-title-4">
                {{ transl.Offer.correctinfoq.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div v-else class="internal-link-title-4">
                {{ transl.Offer.fillinfo.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <md-field>
                <label>{{ transl.Offer.firstname }}</label>
                <md-input id="given-name" v-model="name"></md-input>
              </md-field>

              <md-field>
                <label>{{ transl.Offer.lastname }}</label>
                <md-input id="family-name" v-model="lastName"></md-input>
              </md-field>

              <md-field>
                <label>{{ transl.Offer.adress }}</label>
                <md-input id="address-level1" v-model="adress"></md-input>
              </md-field>

              <md-field>
                <label>{{ transl.Offer.postalcode }}</label>
                <md-input id="postal-code" v-model="postalCode"></md-input>
              </md-field>

              <md-field>
                <label>{{ transl.Offer.city }}</label>
                <md-input id="address-level2" v-model="city"></md-input>
              </md-field>

              <div class="btn btn-activate" @click="changeUser()">
                {{ transl.Offer.save }}
              </div>

              <Hero class="ok-stamp" type="Integrity_badge_Light" />

              <div class="modal-terms">
                {{ transl.Offer.shareyou2 }}
                <span @click="openUrl(faqUrl)">FAQ.</span>
              </div>
            </div>

            <div v-else-if="internalLinkAdressWinner" class="competition-info">
              <iconComponent symbol="gift" :width="150" :stroke-width="0"
                            fill="#EA7801" />

              <div class="internal-link-title-3">
                {{ transl.Offer.soonprize.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div class="internal-link-title-4">
                {{ transl.Offer.waitforemail }}
              </div>

              <div class="btn btn-activate" @click="gotoCollection()">
                {{ transl.Offer.discoveroffers }}
              </div>
            </div>

            <div v-else class="expired-offer competition-info">
              <div v-if="!offer.recurring_time" style="padding-bottom: 1rem;" class="internal-link-title-3">
                {{ transl.Offer.alreadyused }}</div>

              <div v-if="offer.recurring_time && !offer.winner_adress"
                  class="internal-link-title-4 internal-link-center">
                {{ transl.Offer.readuse.interpolate({ offerthe_prize: offer.the_prize }) }}
              </div>

              <div v-if="interleaved_code" class="internal-link-title-1 internal-link-center">
                {{ transl.Offer.gotomobile.interpolate({ enginio: windowOrigin }) }}
              </div>

              <div class="btn btn-activate"
                  v-if="(offer.recurring_time && !offer.winner_adress) || interleaved_code"
                  @click="$router.push('/u-profile/active-offers')">
                {{ transl.Offer.activeoffers }}
              </div>

              <div class="btn btn-activate" v-else @click="gotoCollection()">
                {{ backToText }}
              </div>

              <div v-if="(offer.recurring_time && !offer.winner_adress) || interleaved_code"
                  class="internal-link-title-4"
                  style="padding: 1rem; text-align: center; color: grey; font-size">
                {{ transl.Offer.clickactive }}
              </div>
            </div>
          </div>
        </slot>
      </modal>
    </div>

    <modal v-if="tocModal" @close="tocModal = false" size="medium" padding="0" :terms="true">    
      <slot>
        <div class="terms-wrapper">
          <div v-if="offer.terms_text" v-html="offer.terms_text" class="html-text"></div>

          <div class="terms-buttons">
            <div
              v-if="offer.terms_merchandise && !acceptTerms"
              class="terms-merchandise-grid">
              <inputField
                class="terms-merchandise-checkbox"
                v-model="acceptMerchandise"
                inputType="checkbox"/>
              <span
                class="terms-merchandise-text"
                v-html="offer.terms_merchandise"></span>
            </div>

            <div v-if="!acceptTerms" class="btn btn-activate" @click="setAcceptTerms()">
              {{ transl.UserPV.accept }}
            </div>

            <div class="btn btn-secondary" @click="tocModal = false">
              {{ transl.UserPV.close }}
            </div>
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import validator from 'validator';
import moment from 'moment-timezone';
import VueSlider from 'vue-slider-component';
import vuePdf from 'vue-pdf';
// import { debounce as _debounce, } from 'lodash-es';
// import { Jumper } from 'vue-loading-spinner';
// import EventBus from '../resources/eventBus';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import EntityMixin from '../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';
import Uploader from '../stories/components/Uploader/Uploader.vue';
import aCarousel from '../components/ACarousel.vue';
import Hero from '../stories/components/Hero/Hero.vue';
import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: 'UserOffer',
  components: {
    aCarousel,
    VueSlider,
    vuePdf,
    Hero,
    InputField,
    Uploader,
  },
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin, EntityMixin],
  props: ['offerId'],
  mounted() {
    this.loadPage(true);
  },
  watch: {
    offerId(newVal, oldVal) {
      if (newVal !== oldVal) {
        location.reload();
      }
    },
  },
  computed: {
    ...mapGetters(['user', 'userStatus', 'surveyUpload',]),
    ...mapGetters(['hyperlinks']),
    favorite() {
      let favorite = false;
      if (this.user.favorite_offers) {
        for (let i = 0; i < this.user.favorite_offers.length; i += 1) {
          if (this.user.favorite_offers[i]._id === this.offer._id) {
            favorite = true;
          }
        }
      }
      return favorite;
    },
    expiresDate() {
      return this.getExpiresDate(this.offer);
    },
    hasCTA() {
      if (this.offer && this.offer._id) {
        if (this.offer.pdf) {
          return true;
        } else if (this.offer.survey && this.offer.survey.length) {
          return true;
        } else if (this.offer.recurring_time) {
          return true;
        } else if (this.offer.winner_adress) {
          return true;
        } else if (this.offer.internal_link) {
          return true;
        } else if (this.offer.external_link) {
          return true;
        } else if (
          this.offer.links_gallery &&
          this.offer.links_gallery.label
        ) {
          return true;
        } else if (this.offer.clearon_id || this.offer.clearon_ean_id) {
          return true;
        } else if (this.offer.cta_email) {
          return true;
        }
      }
      return false;
    },
    sideOffers() {
      if (this.goodiebag.offers && this.goodiebag.offers.length) {
        let el = this;
        const interactionsForThisGoodiebag = this.user.interactions.filter(
          (interaction) => interaction.goodiebag === this.goodiebag._id
        );

        const excludeCurrentOffer = this.goodiebag.offers.filter(function (offer) {
          const isVisited = offer.points_visited ? interactionsForThisGoodiebag.some(item => item.offer === offer._id) : false;
          return (
            offer._id !== el.offer._id &&
            !offer.closed &&
            !isVisited &&
            offer.clickable &&
            !offer.jwt_lock &&
            (offer.media.length > 0 || Boolean(offer.thumbnail?.url))
          );
        });
        let indexes = [0, 1, 2];
        if (excludeCurrentOffer.length > 3) {
          indexes = [];
          while (indexes.length < 3) {
            let r =
              Math.floor(Math.random() * excludeCurrentOffer.length - 1) + 1;
            if (indexes.indexOf(r) === -1) {
              indexes.push(r);
            }
          }
        }
        const sideOffers = excludeCurrentOffer.filter(function (offer, index) {
          return indexes.indexOf(index) !== -1;
        });
        return this.shuffle(sideOffers);
      } else {
        return [];
      }
    },
    cannotDonate() {
      if (this.goodiebag && !this.goodiebag.donations) {
        return true;
      }
      return false;
    },
    backToText() {
      return `${this.transl.Offer.back} ${
        this.goodiebag.offers && this.goodiebag.offers.length === 1
          ? ` ${this.transl.Offer.tohome}`
          : ''
      }`;
    },
    iframeWebsiteUrl() {
      let iframeWebsiteUrl = '';
      if (this.offer && this.offer.iframe_website) {
        if (this.offer.iframe_website.includes('?')) {
          iframeWebsiteUrl = `${this.offer.iframe_website}&relayId=${this.$route.query.relayId}&token=${this.iframeToken}`;
        } else {
          iframeWebsiteUrl = `${this.offer.iframe_website}?relayId=${this.$route.query.relayId}&token=${this.iframeToken}`;
        }
      } else {
        iframeWebsiteUrl = false;
      }
      return iframeWebsiteUrl;
    },
    requiresLogin() {
      if (this.offer && this.offer._id) {
        return (this.offer.must_login ||
          this.offer.winner_adress ||
          this.offer.cta_email ||
          this.offer.recurring_time ||
          this.offer.clearon_id ||
          this.offer.clearon_ean_id) ? true : false;
      } else {
        return true;
      }
    },
    hasNotAcceptedRequiredTerms() {
      return !this.acceptTerms && this.offer.terms_must_accept;
    },
  },
  destroyed() {
    clearTimeout(this.timeInterval);
    document.removeEventListener('message', () => {});
    this.$store.dispatch('resetOffer');
  },
  data() {
    return {
      currentUrl: location.href.replace('u-', ''),
      currentExactUrl: location.href,
      expiresTime: '',
      timeInterval: null,
      internalLinkModal: false,
      firstTime: true,
      internalLinkInterleaved: false,
      internalLinkEAN: false,
      storeOptions: [
        'ICA',
        'Hemköp, Coop, Pekås',
        'Willys, City Gross, ÖoB',
        '7 eleven, Pressbyrån, Direkten, Matöppet',
      ],
      selectedStore: 1,
      internalLinkAdressWinner: false,
      addAdress: false,
      name: '',
      lastName: '',
      adress: '',
      postalCode: '',
      city: '',
      activeOfferExpiresAt: '',
      activeOfferIndex: -1,
      activeOffer: false,
      lookAtActiveOffer: true,
      interleaved_code: '',
      ean_link: '',
      expiresInterleavedTime: '',
      chooseStore: false,
      sentEmail: false,
      expiredOffer: false,
      expiresAtState: '',
      isMobile,
      loadedPage: false,
      iframeReady: false,
      iframeToken: '',
      activeOfferBuffer: false,
      activateErrorMsg: null,
      activateErrorButton: 'retry',
      disableTryAgain: false,
      validator,
      surveyAnswers: {},
      surveyOther: {},
      answeredSurvey: false,
      recurringWinVideo: false,
      recurringLooseVideo: false,
      canDonate: false,
      donatedOffer: false,
      excludeICACollections: [
        '60268a6dc1b5801f79eea8fb',
        '5ebd032f8fd9a28deefaa1cd',
      ],
      pageCount: 0,
      pageNr: 1,
      notVerifiedEmail: false,
      tocModal: false,
      acceptTerms: false,
      acceptMerchandise: true,
    };
  },
  methods: {
    loadPage(firstTime) {
      if (!this.offerId) {
        this.$router.replace({ name: 'UserStart' });
      } else {
        this.$store
          .dispatch('getOffer', {
            offerId: this.offerId,
          })
          .then(() => {
            this.getAlreadyActivated();
            this.expiresAtState = this.getExpiredState(this.offer);
            this.startExpiresTime();
            this.setUserDetails();
            if (this.excludeICACollections.includes(this.offer.goodiebag._id)) {
              this.storeOptions = [
                'Hemköp, Coop, Pekås',
                'Willys, City Gross, ÖoB',
                '7 eleven, Pressbyrån, Direkten, Matöppet',
              ];
            }
            if (this.offer.iframe_website) {
              this.loginAtIframe();
            }
            this.$store
              .dispatch('getGoodiebag', {
                goodiebagId: this.offer.goodiebag._id,
              })
              .then(() => {
                this.setPageTitle(this.goodiebag.company.name, this.offer.first_header);
                this.pushDataLayer({
                  parentCompany: this.goodiebag.company.parentCompany,
                  company: this.goodiebag.company,
                  goodiebag: this.goodiebag,
                  offer: this.offer,
                  action: 'Enter',
                });
                this.$store
                  .dispatch('getNarCompany', {
                    companyId: this.goodiebag.company._id,
                  })
                  .then(() => {
                    if (firstTime) {
                      /*this.$dpAnalytics({
                        view: 'UserOffer',
                        subject: this.offer._id,
                        action: 'enter',
                      });
                      this.$dpAnalytics({
                        view: 'UserOffer',
                        subject: this.goodiebag._id,
                        action: 'enter offer inside collection',
                      });*/
                    }
                  });
                this.$store.dispatch('parentCompanies/getNarParentCompany', {
                  parentCompanyId: this.goodiebag.company.parentCompany,
                });

                this.handleTerms();

                this.loadedPage = true;
                this.setLoadedPageData(true);

                if (!this.offer.unlocked && this.offer.jwt_lock_explanation) {
                  this.internalLinkModal = true;
                }

                if (firstTime) {
                  setTimeout(() => {
                    let elements =
                      document.getElementsByClassName('autoplayMedia');
                    for (let i = 0; i < elements.length; i += 1) {
                      this.autoplay(elements[i]);
                    }
                  }, 200);
                }
              });
          })
          .catch((error) => {
            this.findEntityIssue(error);
          });
      }
    },
    setUserDetails() {
      this.name = this.user.name;
      this.lastName = this.user.last_name;
      this.adress = this.user.adress;
      this.postalCode = this.user.postal_code;
      this.city = this.user.city;
    },
    changeUser() {
      this.$store
        .dispatch('changeUser', {
          name: this.name,
          lastName: this.lastName,
          adress: this.adress,
          postalCode: this.postalCode,
          city: this.city,
        })
        .then(() => {
          this.addAdress = false;
          this.internalLinkAdressWinner = true;
        });
    },
    shuffle(a) {
      let j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    onCopy() {
      this.setAlert(this.transl.Offer.copy);
      // this.$dpAnalytics({ view: 'UserOffer', subject: this.offer._id, action: 'share' });
      this.pushDataLayer({
        parentCompany: this.goodiebag.company.parentCompany,
        company: this.goodiebag.company,
        goodiebag: this.goodiebag,
        offer: this.offer,
        action: 'Share',
      });
    },
    onError() {
      this.setAlert(this.transl.Offer.nocopy);
    },
    createFavorite() {
      this.$store
        .dispatch('setFavoriteOffer', {
          offerId: this.offerId,
          favorite: !this.favorite,
        })
        .then(() => {
          /*this.$dpAnalytics({
            view: 'UserOffer',
            subject: this.offer._id,
            action: `favorite ${this.favorite}`,
          });*/
          this.pushDataLayer({
            parentCompany: this.goodiebag.company.parentCompany,
            company: this.goodiebag.company,
            goodiebag: this.goodiebag,
            offer: this.offer,
            action: 'Favorite',
          });
          // this.$ga.event({ eventCategory: 'UserOffer', eventAction: 'Add', eventLabel: 'Favorite', eventValue: this.favorite });
          this.setAlert(
            `${
              this.favorite
                ? this.transl.Offer.addedfavorite
                : this.transl.Offer.nofavorite
            }`
          );
        });
    },
    startExpiresTime() {
      this.timeInterval = setTimeout(() => {
        this.startExpiresTime();
      }, 1000);
      this.expiresTime = this.getExpiresTime(this.offer.expires_at, true);
      if (this.lookAtActiveOffer) {
        this.getActiveOffer();
      }
      if (this.activeOffer) {
        this.expiresInterleavedTime = this.getExpiresTime(
          this.activeOfferExpiresAt,
          false
        );
      }
    },
    getActiveOffer() {
      let activeOffers = this.user.active_offers;
      if (activeOffers) {
        const nowMoment = moment().unix();
        if (this.activeOfferIndex === -1) {
          for (let i = 0; i < activeOffers.length; i += 1) {
            if (activeOffers[i].offerId === this.offer._id) {
              this.activeOfferIndex = i;
              break;
            }
          }
        }
        if (this.activeOfferIndex !== -1) {
          const theActiveOffer = activeOffers[this.activeOfferIndex];
          if (theActiveOffer.survey && theActiveOffer.survey.length) {
            this.answeredSurvey = true;
          } else if (
            theActiveOffer.winner_adress &&
            (!theActiveOffer.winner_adress_street ||
              !theActiveOffer.winner_adress_postal_code ||
              !theActiveOffer.winner_adress_l)
          ) {
            if (this.firstTime) {
              this.internalLinkModal = true;
              this.firstTime = false;
            }
            this.addAdress = true;
            if (this.offer.value) {
              const usedOffers = this.user.used_offers;
              let alreadyUsedOffer = false;
              for (let m = 0; m < usedOffers.length; m++) {
                const usedOffer = usedOffers[m];
                if (usedOffer._id === this.offerId) {
                  alreadyUsedOffer = true;
                }
              }
              this.canDonate = !alreadyUsedOffer && !this.cannotDonate;
            }
          } else if (
            theActiveOffer.winner_adress &&
            theActiveOffer.winner_adress_street &&
            theActiveOffer.winner_adress_postal_code &&
            theActiveOffer.winner_adress_l
          ) {
            if (this.firstTime) {
              this.internalLinkModal = true;
              this.firstTime = false;
            }
            this.internalLinkAdressWinner = true;
          } else if (nowMoment < theActiveOffer.expires_at) {
            this.activeOfferExpiresAt = theActiveOffer.expires_at;
            this.expiresInterleavedTime = this.getExpiresTime(
              this.activeOfferExpiresAt,
              false
            );
            this.activeOffer = true;
            if (this.firstTime) {
              this.internalLinkModal = true;
              this.firstTime = false;
            }
            if (theActiveOffer.interleaved_code) {
              this.interleaved_code = theActiveOffer.interleaved_code;
              this.internalLinkInterleaved = true;
            } else if (theActiveOffer.cta_email) {
              this.sentEmail = true;
            } else {
              this.ean_link = theActiveOffer.ean_link;
              this.internalLinkEAN = true;
            }
          } else if (nowMoment > theActiveOffer.expires_at) {
            this.expiredOffer = true;
            if (this.firstTime) {
              this.internalLinkModal = true;
              this.firstTime = false;
            }
          }
        } else {
          let localCanDonate = true;
          const usedOffers = this.user.used_offers;
          for (let m = 0; m < usedOffers.length; m++) {
            const usedOffer = usedOffers[m];
            if (usedOffer._id === this.offerId) {
              return;
            }
          }
          const donatedOffers = this.user.donated_offers;
          for (let l = 0; l < donatedOffers.length; l++) {
            const donatedOffer = donatedOffers[l];
            if (donatedOffer === this.offerId) {
              this.donatedOffer = true;
              return;
            }
          }
          localCanDonate = this.offer.unlocked;

          if (this.offer.recurring_time) {
            localCanDonate = false;
          }
          const claimedOffers = this.user.claimed_offers;
          for (let k = 0; k < claimedOffers.length; k += 1) {
            if (claimedOffers[k] === this.offerId) {
              localCanDonate = true;
              if (this.offer.clearon_id) {
                this.internalLinkModal = true;
                this.chooseStore = true;
              } else if (this.offer.winner_adress) {
                this.internalLinkModal = true;
                this.addAdress = true;
              }
            }
          }
          if (this.offer.value) {
            this.canDonate = localCanDonate && !this.cannotDonate;
          }
        }
      }
      if (!this.activeOffer) {
        this.lookAtActiveOffer = false;
      }
      // this.loadedPage = true;
    },
    gotoExternalLink(url) {
      // this.$dpAnalytics({ view: 'UserOffer', subject: this.offer._id, action: `activate link ${url}` });
      this.pushDataLayer({
        parentCompany: this.goodiebag.company.parentCompany,
        company: this.goodiebag.company,
        goodiebag: this.goodiebag,
        offer: this.offer,
        action: `Activate link ${url}`,
      });
      this.openUrl(url);
    },
    donateOffer() {
      this.$store
        .dispatch('donateOffer', { offerId: this.offerId })
        .then(() => {
          this.canDonate = false;
          this.donatedOffer = true;
        });
    },
    getAlreadyActivated() {
      // localStorage.removeItem(`activated-${this.offer._id}`); // for testing purposes
      if (this.offer && this.offer._id) {
        const activated = localStorage.getItem(`activated-${this.offer._id}`);
        const alreadyActivated = activated ? true : false;
        if (!this.offer.must_login && this.offer.survey && this.offer.survey.length) {
          this.answeredSurvey = alreadyActivated;
        // } else {
        //   console.log('ERROR: cta not supported yet');
        }
      }
    },
    activateInternalOffer(getExit, eanCode, link) {
      if (this.disableTryAgain) {
        return;
      }
      if (this.hasNotAcceptedRequiredTerms) {
        this.tocModal = true;
        return;
      }
      if (!this.user.verified_email && this.requiresLogin) {
        this.internalLinkModal = true;
        this.notVerifiedEmail = true; // probably not needed
        return;
      }
      if (!getExit) {
        this.disableTryAgain = true;
        this.activateErrorMsg = ' ';
        setTimeout(() => {
          this.disableTryAgain = false;
        }, 5000);
      }
      if (this.surveyAnswers && Object.keys(this.surveyAnswers).length) {
        let { success, message } = this.checkRequiredQuestions();
        if (!success) {
          this.setAlert(message, 6);
          return;
        }
      }
      this.activeOfferBuffer = true;
      /*this.$dpAnalytics({
        view: 'UserOffer',
        subject: this.offer._id,
        action: 'activate',
      });*/
      this.pushDataLayer({
        parentCompany: this.goodiebag.company.parentCompany,
        company: this.goodiebag.company,
        goodiebag: this.goodiebag,
        offer: this.offer,
        action: 'Activate',
      });
      const el = this;
      this.$store
        .dispatch('activateOffer', {
          offerId: this.offer._id,
          answers: this.surveyAnswers,
          acceptMerchandise: this.acceptMerchandise,
          eanCode,
          getExit,
        })
        .then(
          ({
            interleaved_code = false,
            ean_link,
            winner_adress = false,
            cta_email = false,
            success,
            no_cta,
          }) => {
            /*el.$dpAnalytics({
            view: 'UserOffer',
            subject: el.offer._id,
            action: 'won',
          });*/
            el.pushDataLayer({
              parentCompany: el.goodiebag.company.parentCompany,
              company: el.goodiebag.company,
              goodiebag: el.goodiebag,
              offer: el.offer,
              action: 'Won',
            });
            el.activeOfferBuffer = false;
            el.activateErrorMsg = null;
            el.activateErrorButton = 'retry';
            if (el.offer.survey && el.offer.survey.length) {
              if (el.surveyUpload && el.surveyUpload.data) {
                el.$store.dispatch('userSurveyUpload')
                .then(() => {
                  el.answeredSurvey = true;
                  el.setAlert(el.transl.Offer.thanks);
                  if (!el.offer.must_login) {
                    localStorage.setItem(`activated-${el.offer._id}`, true);
                  }
                  el.$store.commit('SET_SURVEY_UPLOAD', {});
                })
                .catch((err) => {
                  el.setAlert(err.message, 3);
                  el.$store.commit('SET_SURVEY_UPLOAD', {});
                });
              } else {
                el.answeredSurvey = true;
                el.setAlert(el.transl.Offer.thanks);
                if (!el.offer.must_login) {
                  localStorage.setItem(`activated-${el.offer._id}`, true);
                }
              }
            } else if (interleaved_code) {
              el.interleaved_code = interleaved_code;
              el.lookAtActiveOffer = true;
              el.chooseStore = false;
              el.canDonate = false;
              el.getActiveOffer();
            } else if (ean_link) {
              el.ean_link = ean_link;
              el.lookAtActiveOffer = true;
              el.chooseStore = false;
              el.internalLinkEAN = true;
              el.canDonate = false;
              el.getActiveOffer();
            } else if (winner_adress) {
              el.showRecurringVideo(true);
              el.firstTime = false;
              el.addAdress = true;
            } else if (success && el.offer.recurring_time) {
              el.showRecurringVideo(true);
              el.firstTime = false;
              el.chooseStore = true;
            } else if (cta_email) {
              el.showRecurringVideo(true);
              el.sentEmail = true;
            } else if (no_cta) {
              el.canDonate = false;
              el.gotoExternalLink(link);
            } else {
              el.setAlert(el.transl.routes.serverissue);
            }
          }
        )
        .catch((err) => {
          const { error, message } = err.response.data;
          /*el.$dpAnalytics({
            view: 'UserOffer',
            subject: el.offer._id,
            action: 'lose',
          });*/
          el.pushDataLayer({
            parentCompany: el.goodiebag.company.parentCompany,
            company: el.goodiebag.company,
            goodiebag: el.goodiebag,
            offer: el.offer,
            action: 'Lose',
          });
          el.activeOfferBuffer = false;
          let activateError = message;
          if (error === 'SUPPORT') {
            activateError = 'support';
          } else if (error === 'EMAIL') {
            activateError = 'email';
          } else if (error === 'EMPTY') {
            activateError = 'goodiebag';
          } else if (error === 'TIME') {
            activateError = 'retry';
          } else if (error === 'USED') {
            if (link) {
              el.gotoExternalLink(link);
            } else {
              activateError = 'goodiebag';
            }
          } else if (error === 'EARLY_TIME') {
            activateError = 'tooEarly';
          }
          if (!link) {
            el.showRecurringVideo(false, activateError, message);
          }
          if (!el.offer.survey || !el.offer.survey.length || link) {
            el.setAlert('', 0); // removes alert from axios response-interceptor
          } else {
            el.setAlert(message, 3);
          }
        });
    }, // }, 1000, { leading: true }),
    showRecurringVideo(win, error, message) {
      if (error === 'email') {
        this.internalLinkModal = true;
        this.notVerifiedEmail = true; // probably not needed
        return;
      }
      if (
        this.offer.recurring_time &&
        this.offer.recurring_win_video &&
        this.offer.recurring_loose_video &&
        error !== 'tooEarly'
      ) {
        if (win) {
          this.internalLinkModal = false;
          this.recurringWinVideo = true;
          const element = document.getElementById('recurring-win-video');
          this.autoplay(element);
          setTimeout(() => {
            this.recurringWinVideo = false;
            this.internalLinkModal = true;
          }, 1000 * 9.9);
        } else {
          this.recurringLooseVideo = true;
          const element = document.getElementById('recurring-loose-video');
          this.autoplay(element);
          setTimeout(() => {
            this.recurringLooseVideo = false;
            this.activateErrorButton = error;
            this.activateErrorMsg = message;
          }, 1000 * 9.9);
        }
      } else {
        if (win) {
          this.internalLinkModal = true;
        } else {
          this.activateErrorButton = error;
          this.activateErrorMsg = message;
        }
      }
    },
    autoplay(element) {
      if (element.currentTime > 0) {
        element.load();
      }
      element.play().catch((error) => {
        console.log('error', error);
      });
      element.onended = () => {
        // element.currentTime = 1;
        // console.log('onended: play again!');
        element.play().catch((error) => {
          console.log('error', error);
        });
      };
    },
    checkRequiredQuestions() {
      let unfilled = false;
      let message = '';
      for (let i = 0; i < this.offer.survey.length; i++) {
        let question = this.offer.survey[i];
        if (question.required) {
          let answeredRequiredField = false;
          if (question.upload) {
            if (this.surveyUpload && this.surveyUpload.data) {
              answeredRequiredField = true;
            }
          } else {
            const objectKeys = Object.keys(this.surveyAnswers);
            for (let j = 0; j < objectKeys.length; j++) {
              const objectKey = objectKeys[j];
              if (objectKey === question.model && this.surveyAnswers[objectKey]) {
                answeredRequiredField = true;
                break;
              }
            }
          }
          if (!answeredRequiredField) {
            message += `${i+1}, `;
            unfilled = true;
          }
        }
      }
      if (unfilled) {
        message.slice(message.length - 2);
        message = this.transl.Offer.requiredquestion.interpolate({ message });
        return { success: false, message };
      } else {
        return { success: true };
      }
    },
    loginAtIframe() {
      if (!this.user.verified_email) {
        this.notVerifiedEmail = true; // probably not needed
        return;
      }
      axios
        .put(`/api/offers/${this.offer._id}/login`)
        .then((response) => {
          this.iframeToken = response.data.token;
          this.iframeReady = true;
          window.addEventListener(
            'message',
            (event) => {
              // console.log('enginio - iframe_website', this.offer.iframe_website);
              // console.log('enginio - event.origin', event.origin);
              if (this.offer.iframe_website.includes(event.origin)) {
                const { modal, back, snackbar } = event.data;
                // console.log('enginio - event.data.modal', modal);
                // console.log('enginio - event.data.back', back);
                // console.log('enginio - event.data.snackbar', snackbar);
                if (modal === 'support') {
                  this.$emit('openSupportModal', {});
                } else if (modal === 'terms') {
                  this.tocModal = !this.tocModal;
                } else if (back === 'collection') {
                  this.gotoCollection();
                } else if (snackbar && snackbar.message) {
                  this.setAlert(snackbar.message, snackbar.seconds);
                }
              }
            },
            false
          );
          /*const host = this.offer.iframe_website[this.offer.iframe_website.length-1] === '/' ? this.offer.iframe_website.slice(0, -1) : this.offer.iframe_website;
        axios.put(`${host}/api/nar/login`, {}, {
          headers: { 'Authorization': `Token ${this.iframeToken}` }
        }).then(() => {
          this.iframeReady = true;
          return;
        }).catch(() => {
          // handle issues?
          // this.setAlert();
          return;
        });*/
        })
        .catch(() => {
          this.internalLinkModal = true;
        });
    },
    gotoCollection() {
      this.$router.push({
        name: 'UserGoodiebag',
        params: { goodiebagId: this.getId(this.offer.goodiebag) },
      });
    },
    gotoCompany() {
      this.$router.push({
        name: 'UserCompany',
        params: { companyId: this.getId(this.offer.company || this.goodiebag.company) },
      });
    },
    gotoClubProfile() {
      this.$router.push({ name: 'UserClubProfile', params: { companyId: this.getId(this.offer.company) }})
    },
    gotoCurrentOffer(offer) {
      location.assign(`${this.windowOrigin}/u-a/${this.getId(offer)}`);
    },
    setCheckbox(question, option, value, notCheck) {
      if (question.checkbox && question.options && question.options.length) {
        if (
          !this.surveyAnswers[question.model] ||
          !this.surveyAnswers[question.model].length
        ) {
          this.surveyAnswers[question.model] = [];
        }
        const isOther = option == 'other';
        let isChecked = -1;
        for (let i = 0; i < this.surveyAnswers[question.model].length; i++) {
          const internalOption = this.surveyAnswers[question.model][i];
          if (option === internalOption) {
            isChecked = i;
          } else if (isOther && this.surveyOther[question.model] == internalOption) {
            isChecked = i;
          }
        }
        if (notCheck) {
          isChecked = -1;
          this.removeOldOther(question, value);
        }
        if (isChecked < 0) {
          if (isOther) {
            const otherOptionCheckbox = document.getElementById(`${question.model}other`);
            otherOptionCheckbox.checked = true;
            this.surveyAnswers[question.model].push(this.surveyOther[question.model]);
          } else {
            this.surveyAnswers[question.model].push(option);
          }
        } else {
          this.surveyAnswers[question.model].splice(isChecked, 1);
        }
      }
    },
    setRadio(question, option, notCheck) {
      const isOther = option == 'other';
      if (isOther) {
        const otherOptionRadio = document.getElementById(`${question.model}other`);
        if (notCheck) {
          otherOptionRadio.checked = true;
        } else {
          otherOptionRadio.checked = !otherOptionRadio.checked;
        }
        if (otherOptionRadio.checked) {
          this.surveyAnswers[question.model] = this.surveyOther[question.model];
        }
      }
    },
    removeOldOther(question, newValue) {
      for (let i = 0; i < this.surveyAnswers[question.model].length; i++) {
        const internalOption = this.surveyAnswers[question.model][i];
        let remove = true;
        for (let j = 0; j < question.options.length; j++) {
          const option = question.options[j];
          if (option == internalOption && internalOption != newValue) {
            remove = false;
          }
        }
        if (remove) {
          this.surveyAnswers[question.model].splice(i, 1);
        }
      }
    },
    changePage(newPageNr) {
      if (newPageNr <= this.pageCount && newPageNr > 0) {
        this.pageNr = newPageNr;
      }
    },
    verifyEmail() {
      localStorage.setItem(
        'UserEmailRedirect',
        JSON.stringify({ name: 'UserOffer', params: { offerId: this.offerId } })
      );
      this.$router.push({ name: 'UserEmail' });
    },
    getAcceptedTerms() {
      if (this.requiresLogin) {
        for (let i = 0; i < this.user.accepted_offers.length; i++) {
          const acceptedOfferId = this.user.accepted_offers[i];
          if (acceptedOfferId == this.offer._id) {
            return true;
          }
        }
        return false;
      } else {
        return localStorage.getItem(`accepted-terms-${this.offer._id}`) == 'true';
      }
    },
    getAcceptedMerchandise() {
      if (this.requiresLogin) {
        for (let i = 0; i < this.user.parentCompanies.length; i++) {
          const parentCompany = this.user.parentCompanies[i];
          if (parentCompany === this.goodiebag.company.parentCompany) {
            return true;
          }
        }
        return this.offer.terms_merchandise_default;
      } else {
        if (!this.acceptTerms) {
          return this.offer.terms_merchandise_default;
        } else {
          return localStorage.getItem(`accepted-merchandise-${this.offer._id}`) == 'true';
        }
      }
    },
    handleTerms() {
      if (
        this.user &&
        this.offer && this.offer._id &&
        this.goodiebag &&
        this.goodiebag.company &&
        this.goodiebag.company.parentCompany
      ) {
        this.acceptTerms = this.getAcceptedTerms();
        this.acceptMerchandise = this.getAcceptedMerchandise();
        if (this.hasNotAcceptedRequiredTerms) {
          this.tocModal = true;
        }
      } else {
        console.error('Did not handle terms!');
      }
    },
    setAcceptTerms() {
      this.acceptTerms = true;
      if (this.requiresLogin) {
        this.$store.dispatch('acceptOfferTerms', {
          offerId: this.offer._id,
          acceptMerchandise: this.acceptMerchandise,
          thirdPartyCrmName: this.thirdPartyCrmName,
        }).then(() => {
          this.tocModal = false;
        });
      } else {
        localStorage.setItem(`accepted-terms-${this.offer._id}`, true);
        localStorage.setItem(`accepted-merchandise-${this.offer._id}`, this.acceptMerchandise);
        this.tocModal = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/UserOffer";
</style>
