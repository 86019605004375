<template>
  <div class="--table-component">
    <table class="--standard-table" v-if="!interactive" :class="computedClasses">
    <caption v-if="caption">{{ caption }}</caption>

    <thead v-if="headers !== ''">
      <tr>
        <th v-for="(header, index) in isArray(headers)"
            :key="index + 'tableHeader'">{{ header }}</th>
      </tr>
    </thead>

    <tbody class="tbody">
      <tr v-for="(rows, index) in body.rows" :key="index + 'tableBody'">
        <td v-for="(column, e) in columns" :key="e + 'tableBody'">
          {{ column[rows-1] }}
        </td>
      </tr>
    </tbody>

    <tfoot v-if="footer !== ''">
      <tr v-for="content, index in isArray(footer)" :key="index + 'tableFooter'">
        <td :colspan="body.columns">{{ content }}</td>
      </tr>
    </tfoot>
  </table>

    <table class="--interactive-table" v-if="interactive">
      <caption v-if="caption">{{ caption }}</caption>
      <collapsable :header="{bgColor: '#F9D7B3', textPosition: 'left'}" v-for="(header, index) in isArray(headers)" :key="index + 'tableHeader'">
          <template v-slot:header >
            <thead v-if="headers !== ''">
              <tr>
                <th>{{ header.name }}</th>
              </tr>
            </thead>
          </template>
        </collapsable>

      <tfoot v-if="footer !== ''">
        <tr v-for="content, index in isArray(footer)" :key="index + 'tableFooter'">
          <td :colspan="body.columns">{{ content }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import Collapsable from '../Collapsable/Collapsable.vue';

export default {
  name: 'TableComponent',
  components: { Collapsable },
  data() {
    return {};
  },
  props: {
    caption: {
      type: String,
      required: false,
      default: '',
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
    body: {
      type: Object,
      required: false,
      default: () => ({
        rows: 1,
        columns: 1,
        items: [''],
        bodyHeader: [''],
      }),
    },
    footer: {
      type: String,
      required: false,
      default: '',
    },
    interactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    populateChild: {
      type: Object,
      required: true, // look if you can make it depend on other props
      default: () => ({
      }),
    },
/*     :populateChild="{api: 'goodiebag.api', method: 'getAdminGoodiebag'}" */
  },
  computed: {
    columns() {
      let columns = [];
      let mid = Math.ceil(
        this.body.items.length / this.body.columns
      );
      for (let col = 0; col < this.body.columns; col++) {
        columns.push(
          this.body.items.slice(col * mid, col * mid + mid)
        );
      }
      return columns;
    },
    computedClasses() {
      return {
        '--no-footer': this.footer === '',
        '--has-footer': this.footer !== '',
        [`--columns-${this.body.columns}`]:
          this.body.columns,
      };
    },
  },
  methods: {
    isArray(element) {
      const filteredTextData = [];
      if (Array.isArray(element)) {
        return element;
      } else {
        filteredTextData.push(element);
        return filteredTextData;
      }
    },
    getEntity(entityId) {
      // Use populateChild prop in order to get the correct
      // entity 
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  border-bottom: 0.5px solid #cccccc;
  border-right: 0.5px solid #cccccc;
  border-left: 0.5px solid #cccccc;
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;

  caption {
    background: #f9d7b3;
    border-radius: 8px 8px 0px 0px;
    padding: 16px 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: left;
  }

  thead {
    tr {
      th {
        height: 36px;
        background: #f2f2f2;
        padding: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: #111111;
        width: 50%;
        border-bottom: 0.5px solid #cccccc;
      }
    }
  }

  tbody {
    tr {
      td {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.001em;
        color: #111111;
        padding: 8px 16px;
      }
    }
  }

  tfoot {
    tr {
      background: #f2f2f2;
      td {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.001em;
        color: #111111;
        padding: 16px;
        border-top: 0.5px solid #cccccc;
      }
    }
  }
}

.--no-footer {
  border-radius: 0px;
}

.--has-footer {
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 24px;
}

.--columns-2 {
  thead {
    tr {
      th {
        border-top: none;
        border-left: none;
        border-bottom: 0.5px solid #cccccc;
        border-right: 0.5px solid #cccccc;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: none;
        border-left: none;
        border-bottom: 0.5px solid #cccccc;
        border-right: 0.5px solid #cccccc;
      }
    }
  }
}
</style>
