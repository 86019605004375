<template>
  <div :class="{
      'tabs__light': theme == 'light',
      'tabs__dark': theme == 'dark',
    }">
    <ul
      class="tabs__header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        :class="{
          'tab__selected': (index == selectedIndex),
        }"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      theme: {
        type: String,
        default: 'light'
      }
    },
    created () {
      this.tabs = this.$children;
    },
    mounted () {
      this.selectTab(0);
    },
    data () {
      return {
        selectedIndex: 0, // the index of the selected tab
        tabs: [],         // all of the tabs
      }
    },
    methods: {
      selectTab(i) {
        this.selectedIndex = i;
        // loop over all the tabs
        this.tabs.forEach((tab, index) => {
          tab.isActive = (index === i);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0 0 0 20px;
    padding: 0;
  }
  ul.tabs__header > li {
    padding: 15px 30px 0 30px;
    border-radius: 10px 10px 0 0;
    margin: 0;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    border-bottom: 15px solid transparent;
  }
  ul.tabs__header > li.tab__selected {
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    border-bottom: 15px solid transparent;
  }
  .tab {
    display: inline-block;
    min-width: 800px;
    width: min-content;
    min-height: 400px;
    max-width: 100vw;
    color: black;
    padding: 20px;
    border-radius: 10px;
  }
  .tabs__light .tab{
    background-color: #fff;
  }
  .tabs__light li {
    background-color: #ddd;
    color: #aaa;
  }
  .tabs__light li.tab__selected {
    background-color: #fff;
    color: #000000;
  }
  .tabs__dark .tab{
    background-color: #555;
    color: #eee;
  }
  .tabs__dark li {
    background-color: #ddd;
    color: #aaa;
  }
  .tabs__dark li.tab__selected {
    background-color: #555;
    color: white;
  }
</style>