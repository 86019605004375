<template>
  <div class="faq">
    <!-- Get all faq-subjects with content -->
    <div
        v-for="faq in displayedFaqs"
        :key="`${faq.subject}Question`"
        class="question-container"
        :class="{ 'disable-border': disableSubject }"
        :ref="faq.subject"
        :id="faq.subject">
      <h3 v-if="!disableSubject" class="question-subject">{{ faq.subject }}</h3>
      <div
          v-for="question in faq.questions"
          :key="question.header">
        <collapsable :collapsed="true" :header="{bgColor: '#FFFFFF', textPosition: 'left'}" :content="{bgColor: 'white'}">
          <template v-slot:header>
            <div
                class="question-header">
              <div class="show-desktop header-icon">
                <iconComponent :width="32" symbol="question"/>
              </div>
              <p>{{ question.header }}</p>
            </div>
          </template>

          <template v-slot:content>
            <div
                v-for="(text, index) in question.body"
                :key="index"
                class="question-text">
              <div
                  v-if="text.faqType == 'text-info'" v-html="text.text">
              </div>
              <div
                  v-if="text.faqType == 'list'">
                <div
                    v-for="(list, index) in text.texts"
                    :key="index">
                  <ul>
                    <li>{{ list }}</li>
                  </ul>
                </div>
              </div>
              <img v-if="text.faqType == 'image'" class="faq-image" :src="text.localUrl" :alt="text.text" />
            </div>
          </template>
        </collapsable>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Collapsable from '../Collapsable/Collapsable.vue'
import FAQDataMixin from './FAQDataMixin.vue'

// :src="text.localUrl"

export default defineComponent({
  name: 'FAQComponent',
  components: {
    Collapsable,
  },
  props: {
    /**
     * Subjects defined as list contains subjects in the FAQ, 
     * one or more subjects can be put in and they will show 
     * when using this prop. If no subject input the faq will 
     * not show. If used in modal one question will be shown 
     * as single FAQ, if more than one subject, they will be 
     * seperated with border around each subject & question/-s
     */
    subjects: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Questions defined as list contains questions in the FAQ.
     * Depends on a subject input from previous prop 'subject' 
     * One or more questions can be put in and they will be shown 
     * together with the subject/subjects. If no questions put in 
     * all questions will be shown.
     */
    questions: {
      type: Array,
      required: false,
      default: () => []
    },
    /** 
     * If we want to use FAQmodal and only show one subject, we 
     * have to disable the subject so that we instead use the 
     * modal header showing our subject-input
     */
    disableSubject: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [FAQDataMixin],
  computed: {
    displayedFaqs() {
      let faqList = [];

      if (this.subjects.length) {
        for (let j = 0; j < this.subjects.length; j++) {
          const subject = this.subjects[j];
          for (let i = 0; i < this.faqs.length; i++) {
            const faq = this.faqs[i];
            if (faq.key === subject) {
              let questions = [];
              if (this.questions.length) {
                for (let m = 0; m < this.questions.length; m++) {
                  const question = this.questions[m];
                  for (let l = 0; l < faq.questions.length; l++) {
                    const faqQuestion = faq.questions[l];
                    if (faqQuestion.key === question) {
                      questions.push(faqQuestion);
                    }
                  }
                }
              }
              else {
                questions = faq.questions;
              }
              faqList.push({
                subject: faq.subject,
                questions,
              });
            }
          }
        }
        return faqList
      } else return this.faqs
    }
  },
  mounted() {
    
  },
});
</script>

<style lang="scss" scoped>
@import "./FAQ.scss";
</style>