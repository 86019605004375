var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.company && _vm.company._id)?_c('div',{staticClass:"company-preview entity-preview"},[_c('div',{staticClass:"preview-width-picker"},[_c('h3',[_vm._v("Preview of Company \""+_vm._s(_vm.company.name)+"\"")]),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){_vm.previewWidth = 'computer'}}},[_c('iconComponent',{attrs:{"symbol":"computer"}})],1),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){_vm.previewWidth = 'tablet'}}},[_c('iconComponent',{attrs:{"symbol":"tablet"}})],1),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){_vm.previewWidth = 'smartphone'}}},[_c('iconComponent',{attrs:{"symbol":"smartphone"}})],1),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){return _vm.$emit('close')}}},[_c('iconComponent',{attrs:{"symbol":"cross"}})],1)],1),_c('div',{staticClass:"preview-default home-company iris",class:{
      'timeless-theme-goodiebag': true,
      'radiant-theme-goodiebag': false,
      'preview-tablet': _vm.previewWidth === 'tablet',
      'preview-smartphone': _vm.previewWidth === 'smartphone',
    }},[_c('div',{staticClass:"company-page"},[_c('div',{staticClass:"header-image",style:(`background-image: url(${_vm.company.media ? _vm.company.media.url : ''});`
        +`margin-bottom: ${_vm.company.icon?.url ? '2rem;' : '0;'}`)}),_c('div',{staticClass:"company-content-wrapper content-page"},[(_vm.company.icon?.url)?_c('img',{staticClass:"header-logo",attrs:{"src":`${_vm.company.icon?.url}`}}):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.company.name))]),_c('div',{staticClass:"header-info body-1"},[_vm._v(_vm._s(_vm.company.info))]),(!_vm.company.goodiebags || !_vm.company.goodiebags.length)?_c('div',[_vm._v(" "+_vm._s(_vm.transl.Company.soon)+" ")]):_vm._e(),(_vm.currentOffers && _vm.currentOffers.length)?_c('cardWrapper',{attrs:{"data":_vm.currentOffers,"theme":'iris'}}):_vm._e(),(_vm.company.legal_name)?_c('integrityBox',{attrs:{"badgeType":"Integrity_badge_Medium","textData":"Har du frågor om GDPR till varumärket? Se kontaktinformation nedan.","expandable":true,"labelList":[_vm.company.legal_name, _vm.company.email, _vm.company.org_number, _vm.company.address + ', ' + _vm.company.postal_code + ', ' + _vm.company.city],"iconList":['building', 'email', 'paper', 'mapPin']}}):_vm._e()],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }