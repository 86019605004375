<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h1>Companies at Enginio</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h3>Companies at Enginio</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div class="data-text">
          <div>
            <h3>Title</h3>
          </div>
          <div>
            body
          </div>
          <div>
            <b>bold</b>
          </div>
          <div class="a-line"></div>
          <div>
            This privacy policy was adopted by NRG Nordic Retail Group AB on 2022-03-01.
          </div>
        </div>
      </div>

      <div class="right-card">
        <div
             class="data-link"
             @click="$router.push({ name: 'HomeData.cookies' })">
          Cookies policy
          <iconComponent symbol="forward" />
        </div>
        <div
             class="data-link"
             @click="$router.push({ name: 'HomeData.visitors' })">
          Privacy Policy - Visitors
          <iconComponent symbol="forward" />
        </div>
        <div
             class="data-link"
             @click="$router.push({ name: 'HomeData.members' })">
          Privacy Policy - Members
          <iconComponent symbol="forward" />
        </div>
        <div
             class="data-link"
             @click="$router.push({ name: 'HomeData.participants' })">
          Privacy Policy - Participants
          <iconComponent symbol="forward" />
        </div>
        <div
             class="data-link"
             @click="$router.push({ name: 'HomeData.terms' })">
          Terms and conditions
          <iconComponent symbol="forward" />
        </div>
        <div
             class="data-link unclickable">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeDataCompanies',
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle('Companies at Enginio');
    this.setCompany({});
  },
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeDataCompanies";
</style>
