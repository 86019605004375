<template>
  <div class="admin-content--container">
    <iframe
      src="https://app.powerbi.com/view?r=eyJrIjoiN2E5MzJjOTMtYzQ3Ni00NDMwLWE0ODEtNDY2NjBiNzQwNzdkIiwidCI6IjE3ZmM0OGQ0LTZkNDgtNDVmNy1iZjJiLWUzNjc4MjM5OTc1ZiIsImMiOjh9&embedImagePlaceholder=true&pageName=ReportSectiond949bf72657dc30cdb70"
      frameborder="0" height="100%" width="100%"></iframe>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: "AdminDashboard",
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setPageTitle('Admin', 'Dashboard');
  },
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang='scss' scoped>
@import "../assets/scss/pages/AdminDashboard";
</style>