<template>
  <div
       v-if="loadedPage"
       class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="search"
        placeholder="Find user..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminUsers()"
        @enterKey="findAdminUsers()"/>

      <div style="width: 200px" class="btn btn-activate" @click="deleteLostUsers()">Delete Lost Users</div>
    </div>

    <aList
      entityType="Users"
      columnsWidth="400"
      :entities="users"
      :keys="userKeys"
      routerName="AdminUser"
      routerId="userId"/>

    <!--<div class="add-entity-button" @click="showUserDialog = true">
          <iconComponent symbol="circledPlus" />
        </div>

    <modal v-if="showUserDialog" size="xlarge"
          @close="showUserDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="User"
          :admin="true"/>
      </slot>
    </modal>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '../resources/eventBus';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from '../components/AList';
import InputField from '../stories/components/InputField/InputField.vue';
// import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: 'AdminUsers',
  components: {
    aList,
    InputField,
    // EntityEditor,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminUsers();
    this.setPageTitle('Admin', 'Users');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('users', ['users', 'userStatus'])
  },
   data() {
    return {
      showUserDialog: false,
      loadedPage: false,
      search: '',
      userKeys: [
        /*{
          title: 'Id',
          key: '_id'
        },*/
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Last Name',
          key: 'last_name'
        },
        {
          title: 'Phone',
          key: 'phone_nr'
        },
        {
          title: 'Email',
          key: 'email'
        },
        {
          title: 'Admin',
          key: 'admin'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminUsers() {
      this.$store.dispatch('users/findAdminUsers', { query: this.search }).then(() => {
        this.loadedPage = true;
      });
    },
    addUserChild(data) {
      this.parentUserId = data.userId;
      this.showUserDialog = true;
    },
    openUserDialog(data) {
      this.userId = data.userId;
      this.showUserDialog = true;
    },
    closeUserDialog() {
      this.parentUserId = false;
      this.showUserDialog = false;
    },
    deleteLostUsers() {
      this.$store.dispatch('users/deleteLostUsers').then((deleted) => {
        this.setAlert(deleted, 12);
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminUsers";
</style>