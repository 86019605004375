import axios from 'axios';

export default {
  findAdminHyperlinks: (payload) => {
    return axios.put(`/api/admin/hyperlinks`, payload)
      .then(response => ({
        hyperlinks: response.data.hyperlinks != null
          ? response.data.hyperlinks : [],
      }));
  },

  getAdminHyperlink: (payload) => {
    return axios.get(`/api/admin/hyperlinks/${payload.entityId}`)
      .then(response => ({
        hyperlink: response.data.hyperlink != null
          ? response.data.hyperlink : [],
      }));
  },

  addAdminHyperlink: (payload) => {
    return axios.post(`/api/admin/hyperlinks`, payload)
      .then(response => ({
        hyperlink: response.data.hyperlink != null
          ? response.data.hyperlink : {},
      }));
  },

  addAdminHyperlinkMedia: (payload) => {
    return axios.put(`/api/admin/hyperlinks/${payload.entityId}/picture`, payload.data, payload.config)
      .then(response => ({
        hyperlink: response.data.hyperlink != null
          ? response.data.hyperlink : {},
      }));
  },

  saveAdminHyperlink: (payload) => {
    return axios.put(`/api/admin/hyperlinks/${payload.entityId}`, payload)
    .then(response => ({
      hyperlink: response.data.hyperlink != null
        ? response.data.hyperlink : undefined,
    }));
  },

  deleteAdminHyperlink: (payload) => {
    return axios.delete(`/api/admin/hyperlinks/${payload.entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.hyperlink : '',
      }));
  },

  findCompanyHyperlinks: (payload) => {
    return axios.put(`/api/c/hyperlinks`, payload)
      .then(response => ({
        hyperlinks: response.data.hyperlinks != null
          ? response.data.hyperlinks : [],
      }));
  },
};
