<template>
  <div class="table-pagination-container">
    <p v-if="total > 0" class="table-pagination-label">{{ paginationLabel }}</p>
    <div v-if="totalPages > 1" class="table-pagination-buttons">
      <button class="button" :disabled="skip === 0" @click="$emit('paginate', { skip: skip - limit })">
        Previous
      </button>
      <button v-for="(page, index) in pageButtons" :key="`${page}-${index}`"
        :disabled="(skip === (page - 1) * limit) || page === '...'"
        :style="(skip === (page - 1) * limit && color) ? { backgroundColor: color } : {}"
        :class="[
          'button',
          { 'page-button': page !== '...' },
          { 'span-button': page === '...' }
        ]"
        @click="$emit('paginate', { skip: (page - 1) * limit })">
        {{ page }}
      </button>
      <button class="button" :disabled="skip + limit >= total" @click="$emit('paginate', { skip: skip + limit })">
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerTablePagination',
  props: {
    skip: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      return Math.floor(this.skip / this.limit) + 1
    },
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
    pageButtons() {
      const buttonSpan = 5

      if (this.totalPages <= buttonSpan + 2) {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1)
      }

      const buttons = []

      const midPoint = Math.floor(buttonSpan / 2)
      let startIndex = Math.max(1, Math.min(this.currentPage - midPoint, this.totalPages - buttonSpan + 1))
      let endIndex = Math.min(this.totalPages, startIndex + buttonSpan - 1)

      if (startIndex > 1) {
        buttons.push(1)
        if (startIndex > 2) {
          buttons.push('...')
        }
      }

      for (let i = startIndex; i <= endIndex; i++) {
        buttons.push(i)
      }

      if (endIndex < this.totalPages) {
        if (endIndex < this.totalPages - 1) {
          buttons.push('...')
        }
        buttons.push(this.totalPages)
      }

      return buttons
    },
    paginationLabel() {
      return this.total > 0
        ? `Showing ${this.skip + 1}-${Math.min(this.skip + this.limit, this.total)} of ${this.total}`
        : `No ${this.labels?.model || 'items'} found`
    },
  },
}
</script>

<style lang="scss" scoped>
.table-pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  min-height: 56px;
  margin-top: 12px;

  .table-pagination-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #333333;
  }

  .table-pagination-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #E1E3E5;
    background-color: white;

    .page-button:disabled {
      color: #FFFFFF;
      background-color: $brand-yellow;
    }

    .span-button:disabled {
      color: #333333;
    }
  }
}
</style>
