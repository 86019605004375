<template>
  <div class="activation-config-container">

    <div class="tab-content-container">
      <div class="form">

        <div class="input-container">
          <p class="label">Subject</p>
          <input v-model="updatedActivation.cta_email"/>
        </div>

        <div class="input-container">
          <p class="label">Body</p>
          <CKEditor v-model="updatedActivation.cta_email_template" :config="editorConfig">
          </CKEditor>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import CKEditor from '../CKEditor/CKEditor.vue';

export default {
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  components: {
    CKEditor,
  },
  watch: {
    'updatedActivation.cta_email'(value) {
      this.emitChanges('cta_email', value);
    },
    'updatedActivation.cta_email_template'(value) {
      this.emitChanges('cta_email_template', value);
    },
  },
  data() {
    return {
      updatedActivation: this.activation,
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', '-',
          'Outdent', 'Indent', '-', 'Blockquote', '-', 'Styles', '-', 'Format', '-',  ] ]
      }
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
  },
}

</script>

<style lang="scss">

@import "./ActivationStep";

.form {
  margin-top: 16px;
}

</style>
