/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import receiptApi from '../../api/pvReceipt.api';

// initial state
const state = {
  receipt: {},
  receiptQuery: '',

  receiptStatus: '',
  resetStatus: ''
};

// getters
const getters = {
  receipt: state => state.receipt,
  receiptQuery: state => state.receiptQuery,

  receiptStatus: state => state.receiptStatus,
  resetStatus: state => state.resetStatus,
};

// actions
const actions = {
  // ADMIN
  getAdminReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.getAdminReceipt(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmAdminReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.confirmAdminReceipt(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmAdminRegistration({ commit }, payload) {
    commit('RESET_STATUS', 'LOADING');
    return receiptApi.confirmAdminRegistration(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RESET_STATUS', 'FINISHED');
      });
  },

  addAdminReceiptComment({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.addAdminReceiptComment(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmAdminCashback({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.confirmAdminCashback(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  addAdminReceiptDelayedCtaBlock({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.addAdminReceiptDelayedCtaBlock(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmAdminReceiptAfterDone({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.confirmAdminReceiptAfterDone(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  saveAdminReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.saveAdminReceipt(payload)
      .then((response) => {
        commit('SAVE_RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  cloneAdminReceipt({ commit }, payload) {
    commit('CLONE_STATUS', 'LOADING');
    return receiptApi.cloneAdminReceipt(payload)
      .then((data) => {
        commit('CLONE_STATUS', 'FINISHED');
        return data.clone;
      });
  },

  deleteAdminReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.deleteAdminReceipt(payload)
      .then(() => {
        commit('RECEIPT_STATUS', 'FINISHED');
        return;
      });
  },

  testPVActions({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.testPVActions(payload)
      .then((response) => {
        commit('RECEIPT_STATUS', 'FINISHED');
        return response.message;
      });
  },

  forceAdminPVAction({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.forceAdminPVAction(payload)
      .then((response) => {
        commit('RECEIPT_STATUS', 'FINISHED');
        return response.message;
      });
  },

  tryAdminConsumerFlow({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.tryAdminConsumerFlow(payload)
      .then((response) => {
        commit('RECEIPT_STATUS', 'FINISHED');
        return response.token;
      });
  },

  addAdminPVReceiptMedia({ commit }, payload) {
    return receiptApi.addAdminPVReceiptMedia(payload)
      .then((response) => {
        console.log('response', response);
        commit('SAVE_RECEIPT', response.receipt);
        return true;
      });
  },

  // COMPANY
  getCompanyReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.getCompanyReceipt(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmCompanyReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.confirmCompanyReceipt(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmCompanyRegistration({ commit }, payload) {
    commit('RESET_STATUS', 'LOADING');
    return receiptApi.confirmCompanyRegistration(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RESET_STATUS', 'FINISHED');
      });
  },

  addCompanyReceiptComment({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.addCompanyReceiptComment(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  confirmCompanyCashback({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.confirmCompanyCashback(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  addCompanyReceiptDelayedCtaBlock({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.addCompanyReceiptDelayedCtaBlock(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  saveCompanyReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.saveCompanyReceipt(payload)
      .then((response) => {
        commit('SAVE_RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },

  cloneCompanyReceipt({ commit }, payload) {
    commit('CLONE_STATUS', 'LOADING');
    return receiptApi.cloneCompanyReceipt(payload)
      .then((data) => {
        commit('CLONE_STATUS', 'FINISHED');
        return data.clone;
      });
  },

  deleteCompanyReceipt({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.deleteCompanyReceipt(payload)
      .then(() => {
        commit('RECEIPT_STATUS', 'FINISHED');
        return;
      });
  },

  forceCompanyPVAction({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.forceCompanyPVAction(payload)
      .then((response) => {
        commit('RECEIPT_STATUS', 'FINISHED');
        return response.message;
      });
  },

  confirmCompanyReceiptAfterDone({ commit }, payload) {
    commit('RECEIPT_STATUS', 'LOADING');
    return receiptApi.confirmCompanyReceiptAfterDone(payload)
      .then((response) => {
        commit('RECEIPT', response.receipt);
        commit('RECEIPT_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  RECEIPT(state, receipt) {
    state.receipt = receipt;
  },
  RECEIPT_QUERY(state, receiptQuery) {
    state.receiptQuery = receiptQuery;
  },
  ADD_RECEIPT(state, receipt) {
    state.receipts.unshift(receipt);
  },
  ADD_CHILD_RECEIPT(state, { receipt, parentReceiptId }) {
    if (state.receipt._id === parentReceiptId) {
      state.receipt.childReceipts.push(receipt);
    }
  },
  SAVE_RECEIPT(state, receipt) {
    // for (let i = 0; i < state.receipts.length; i += 1) {
    //   if (state.receipts[i]._id === receipt._id) {
    //     Vue.set(state.receipts, i, receipt);
    //   }
    // }
    // if (state.receipt._id === receipt._id) {
    //   state.receipt = receipt;
    // }
    state.receipt = receipt;
  },
  DELETE_RECEIPT(state, receiptId) {
    for (let i = 0; i < state.receipts.length; i += 1) {
      if (state.receipts[i]._id === receiptId) {
        state.receipts.splice(i, 1);
      }
    }
    if (state.receipt._id === receiptId) {
      state.receipt = {};
    }
  },
  ADD_OFFER(state, data) {
    if (state.receipt._id === data.receiptId) {
      state.receipt.offers.push(data.offer);
    } else {
      for (let i = 0; i < state.receipts.length; i += 1) {
        if (state.receipts[i]._id === data.receiptId) {
          state.receipts[i].offers.push(data.offer);
        }
      }
    }
  },
  SAVE_OFFER(state, data) {
    for (let i = 0; i < state.receipts.length; i += 1) {
      if (state.receipts[i]._id === data.receiptId) {
        for (let j = 0; j < state.receipts[i].offers.length; j += 1) {
          if (state.receipts[i].offers[j]._id === data.offer._id) {
            Vue.set(state.receipts[i].offers, j, data.offer);
          }
        }
      }
    }
    if (state.offer._id === data.offer._id) {
      state.offer = data.offer;
    }
  },
  DELETE_OFFER(state, offerId) {
    for (let i = 0; i < state.receipts.length; i += 1) {
      for (let j = 0; j < state.receipts[i].offers.length; j += 1) {
        if (state.receipts[i].offers[j]._id === offerId) {
          state.receipts[i].offers.splice(j, 1);
        }
      }
    }
    if (state.offer._id === offerId) {
      state.offer = {};
    }
  },
  RECEIPT_STATUS(state, status) {
    state.receiptStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
