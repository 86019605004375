<template>
  <div
  class="home-email">
    <div
      v-if="registered"
      class="container container-sub">
      <p
      class="welcome-back bold">
        {{transl.Newsletter.welcomeback}}
      </p>
      <iconComponent
        symbol="confetti"
        class="confetti-style"
        stroke="#EA7801" fill="#EA7801"/>
      <div
        @click="goToLanding()"
        class="btn btn-icon-text landing">
        {{transl.Newsletter.backtolanding}}
      </div>
    </div>
    <div
        class="container">
      <div
        v-if="!registered"
        style="font-size: 1.1rem;">
        <p
          class="header bold">
            {{transl.Newsletter.sadtoloseyou}} &#128546;
        </p>
        <div
        class="unsubscribe-square">
          <p
          class="font-style">
            {{transl.Newsletter.youremail}}
          </p>
          <p
          class="font-style bold break-all">
            {{email}}
          </p>
          <p
          class="font-style">
            {{transl.Newsletter.nowunregistered}}
          </p>
          <p
          class="font-style">
            {{transl.Newsletter.wellmissyou}}
          </p>
        </div>
        <div
          @click="removeEmail()"
          class="btn btn-icon-text"
          v-html="transl.Newsletter.resubscribe">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeEmail',
  components: {
  },
  mixins: [BeforeEnterMixin],
  data() {
    return {
      userId: null,
      registered: false,
      email: '',
    };
  },
  mounted() {
    this.userId = this.$route.query && this.$route.query.userId ? this.$route.query.userId : '';
    this.getRegistered();
    this.setPageTitle();
  },
  methods: {
    removeEmail() {
      const register = !this.registered
      axios.put('/api/nar/email', { userId: this.userId, register})
        .then(() => {
          this.registered = register;
        });
    },
    getRegistered() {
      axios.get(`/api/nar/email?userId=${this.userId}`)
      .then((response) => {
        const {email, registered} = response.data;
        this.email = email;
        this.registered = registered;
        if(registered) {
        this.removeEmail();
        }
      })
    },
    goToLanding() {
      this.$router.replace({ name: 'Home' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/HomeEmail";
</style>
