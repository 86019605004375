import axios from 'axios';

export default {
  // Admin
  findAdminLinks: (data) => {
    return axios.put('/api/admin/links', data)
      .then(response => ({
        links: response.data.links != null
          ? response.data.links : [],
      }));
  },

  addAdminLinksIn: (payload) => {
    return axios.post(`/api/admin/links`, payload.data)
      .then(response => ({
        links: response.data.links != null
          ? response.data.links : [],
      }));
  },

  changeAdminLinksIn: (payload) => {
    return axios.put(`/api/admin/links/${payload.entityId}`, payload.data)
      .then(response => ({
        link: response.data.link != null
          ? response.data.link : {},
      }));
  },

  deleteAdminLinksIn: (payload) => {
    return axios.delete(`/api/admin/links/${payload.entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Partner
  findCompanyLinks: (data) => {
    return axios.put('/api/c/links', data)
      .then(response => ({
        links: response.data.links != null
          ? response.data.links : [],
      }));
  },

  addCompanyLinksIn: (payload) => {
    return axios.post(`/api/c/links`, payload.data)
      .then(response => ({
        links: response.data.links != null
          ? response.data.links : [],
      }));
  },

  changeCompanyLinksIn: (payload) => {
    return axios.put(`/api/c/links/${payload.entityId}`, payload.data)
      .then(response => ({
        link: response.data.link != null
          ? response.data.link : {},
      }));
  },

  deleteCompanyLinksIn: (payload) => {
    return axios.delete(`/api/c/links/${payload.entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
