<template>
  <div>
    <div><h1>Games</h1></div>
    <div class="game-picker-wrapper">
      <div class="game-container" @click="$router.push({ name: 'AdminGames', params: { gameType: 'QrHunt' } })">
        <h2>QR Hunt</h2>
        <iconComponent symbol="start" width="100px"/>
      </div>

      <div class="game-container" @click="$router.push({ name: 'AdminGames', params: { gameType: 'LoyaltyShop' } })">
        <h2>Loyalty shop</h2>
        <iconComponent symbol="shop" width="100px"/>
      </div>

      <div class="game-container" @click="$router.push({ name: 'AdminGames', params: { gameType: 'Spin' } })">
        <h2>Spin</h2>
        <iconComponent symbol="confetti" width="100px"/>
      </div>

      <div class="game-container" @click="$router.push({ name: 'AdminGames', params: { gameType: 'Scratch' } })">
        <h2>Scratch</h2>
        <iconComponent symbol="easy" width="100px"/>
      </div>

      <div class="game-container" @click="$router.push({ name: 'AdminGameParticipations' })">
        <h2>Participations</h2>
        <iconComponent symbol="user" width="100px"/>
      </div>
      <!--div class="game-container">
        <h3>Loading....</h3>
      </div>-->
    </div>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'AdminGamePicker',
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setPageTitle('Admin', 'Game picker');
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/pages/AdminGamePicker'
</style>
