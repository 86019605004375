<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find hyperlink..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminHyperlinks()"
        @enterKey="findAdminHyperlinks()"/>

      <div class="add-entity-button" @click="showHyperlinkDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>

    <aList
      entityType="Hyperlinks"
      columnsWidth="400"
      :entities="hyperlinks"
      :keys="hyperlinkKeys"
      routerName="AdminHyperlink"
      routerId="hyperlinkId"/>


    <modal v-if="showHyperlinkDialog" size="xlarge"
          @close="showHyperlinkDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Hyperlink"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from "../resources/eventBus";
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminHyperlinks",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminHyperlinks();
    this.setPageTitle('Admin', 'Hyperlinks');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["hyperlink", "hyperlinks", "userStatus"])
  },
   data() {
    return {
      showHyperlinkDialog: false,
      loadedPage: false,
      query: '',
      hyperlinkKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Base Url',
          key: 'url'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminHyperlinks() {
      this.$store.dispatch('findAdminHyperlinks', { query: this.query }).then(() => {
        this.loadedPage = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminHyperlinks";
</style>