<template>
  <div
    class="content-image"
    :style="{
      borderRadius,
    }">
    <img
      :src="src"
      :alt="alt"
      :width="width"
      :style="{
        borderRadius,
      }">
  </div>
</template>

<script>
export default {
  name: 'ContentImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    borderRadius: {
      type: String,
      default: '0',
    },
  }
}
</script>

<style scoped>
@import './Content.css';
</style>