<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage && company._id"
      :title="company.name"
      entity-type="Company"
      :entity="company"
      :admin="true"/>

    <div class="admin-entity--subentity">
      <div class="admin-entity--title">Child collections</div>
      <div v-if="company._id" class="admin-goodiebags-wrapper subentity">
        <div
          v-for="goodiebag in parentGoodiebags"
          :key="goodiebag._id"
          class="goodiebag-box"
          :style="`background-image: url(${goodiebag.media ? goodiebag.media.url : ''})`">
          <div
            class="goodiebag-live-status"
            :class="{
              'goodiebag-live': goodiebag.public,
              'goodiebag-offline': !goodiebag.public,
            }"></div>
          <div class="goodiebag-box-text">
            <div class="goodiebag-box-name">{{ goodiebag.name }}</div>
            <div
              v-if="goodiebag.goodiebags && goodiebag.goodiebags.length"
              class="goodiebag-box-info">
              {{ goodiebag.goodiebags.length }} {{ goodiebag.goodiebags.length === 1 ? 'collection' : 'collections' }}
            </div>
            <div
              v-if="goodiebag.offers && goodiebag.offers.length"
              class="goodiebag-box-info">
              {{ goodiebag.offers.length }} {{ goodiebag.offers.length === 1 ? 'activation' : 'activations' }}
            </div>
            <div
              class="goodiebag-box-fullscreen fullscreen-icon preview-action"
              @click="getGoodiebag(goodiebag._id)">
              <iconComponent symbol="fullscreen"/>
            </div>
            <div
              class="goodiebag-box-edit preview-action"
              @click="$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: goodiebag._id } })">
              <iconComponent symbol="pencil"/>
            </div>
          </div>
        </div>
        <div
          class="goodiebag-box goodiebag-box-add"
          @click="showGoodiebagDialog = true">
          <iconComponent symbol="circledPlus"/>
        </div>
      </div>

      <div class="admin-entity--title">PV Company</div>
      <div v-if="pvCompany._id" class="admin-goodiebags-wrapper subentity">
        <div class="goodiebag-box">
          <div
            class="goodiebag-live-status"
            :class="{
              'goodiebag-live': pvCompany.public,
              'goodiebag-offline': !pvCompany.public,
            }"></div>
          <div class="goodiebag-box-text">
            <div class="goodiebag-box-name">{{ pvCompany.name }}</div>
            <div
              v-if="pvCompany.goodiebags && pvCompany.goodiebags.length"
              class="goodiebag-box-info">
              {{ pvCompany.goodiebags.length }} {{ pvCompany.goodiebags.length === 1 ? 'collection' : 'collections' }}
            </div>
            <div
              v-if="pvCompany.offers && pvCompany.offers.length"
              class="goodiebag-box-info">
              {{ pvCompany.offers.length }} {{ pvCompany.offers.length === 1 ? 'activation' : 'activations' }}
            </div>
            <div
              class="goodiebag-box-fullscreen fullscreen-icon preview-action"
              @click="getGoodiebag(pvCompany._id)">
              <iconComponent symbol="fullscreen"/> 
            </div>
            <div
              class="goodiebag-box-edit preview-action"
              @click="$router.push({ name: 'AdminPVCompany', params: { companyId: pvCompany._id } })">
              <iconComponent symbol="pencil"/> 
            </div>
          </div>
        </div>
      </div>

      <modal v-if="showGoodiebagDialog" size="xlarge"
            @close="showGoodiebagDialog = false;" modalType="fullscreen">
        <slot>
          <EntityEditor
            entity-type="Goodiebag"
            :admin="true"
            :attach="attachGoodiebag"/>
        </slot>
      </modal>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import goodiebagApi from '../api/goodiebag.api';

export default {
  name: 'AdminCompany',
  components: {
    EntityEditor
  },
  props: ['companyId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters([
      "company",
    ]),
    ...mapGetters('pvCompanies', {
      pvCompany: "company",
    }),
    parentGoodiebags() {
      if (this.company && this.company.goodiebags && this.company.goodiebags.length) {
        let parentGoodiebags = [];
        for (let i = 0; i < this.company.goodiebags.length; i++) {
          const goodiebag = this.company.goodiebags[i];
          if (!goodiebag.parentGoodiebag) {
            parentGoodiebags.push(goodiebag);
          }
        }
        return parentGoodiebags;
      }
      return [];
    },
  },
  watch: {
    companyId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      search: '',
      showGoodiebagDialog: false,
      attachGoodiebag: [],
      previewGoodiebag: {},
    };
  },
  methods: {
    async loadPage() {
      this.loadedPage = false;
      await this.$store.dispatch('getAdminCompany', { entityId: this.companyId });
      this.setPageTitle('Admin', 'Company');
      this.setAttachments();
      if (this.company.pv_company_id) {
        try {
          await this.$store.dispatch('pvCompanies/getAdminPVCompany', { entityId: this.company.pv_company_id });
        } catch(error) {
          console.log('Could not load PV company', error);
        }
      }
      this.loadedPage = true;
    },
    setAttachments() {
      this.attachGoodiebag = [
        {
          key: 'company',
          value: {
            text: this.company.name,
            value: this.company._id,
          },
        },
        {
          key: 'goodiebag',
          value: {
            text: '',
            value: '',
          },
        }
      ];
    },
    getGoodiebag(entityId) {
      goodiebagApi.getAdminGoodiebag({ entityId }).then((data) => {
        this.previewGoodiebag = data.goodiebag;
        this.showPreviewGoodiebagModal = true;
      });
    },
    findAdminPVCompany(query) {
      let exclude;
      if (query == '') {
        exclude = 'individual';
      }
      this.$store.dispatch('pvActivations/findAdminPVCompany', { query, companyId: this.companyId, exclude }).then(() => {
        this.loadedPage = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminCompany";
</style>
