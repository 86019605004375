<template>
  <div>
    <div class="company-users-container">
      <div class="container-header">
        <h1>Users</h1>
      </div>

      <div class="content">
        <div class="content-header">
          <span class="action" @click="showSelectColumns = true">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path d="M6.59141 2.89259C6.56234 2.89322 6.53336 2.89596 6.50469 2.90079H3.00078C2.34494 2.90079 1.80078 3.44495 1.80078 4.10079V14.9008C1.80078 15.5566 2.34494 16.1008 3.00078 16.1008H6.5C6.56479 16.1115 6.63091 16.1115 6.6957 16.1008H11.3C11.3648 16.1115 11.4309 16.1115 11.4957 16.1008H15.0008C15.6566 16.1008 16.2008 15.5566 16.2008 14.9008V4.10079C16.2008 3.44495 15.6566 2.90079 15.0008 2.90079H11.4934C11.4597 2.89516 11.4256 2.89241 11.3914 2.89259C11.3623 2.89322 11.3334 2.89596 11.3047 2.90079H6.69336C6.65967 2.89516 6.62556 2.89241 6.59141 2.89259ZM3.00078 4.10079H6.00078V14.9008H3.00078V4.10079ZM7.20078 4.10079H10.8008V14.9008H7.20078V4.10079ZM12.0008 4.10079H15.0008V14.9008H12.0008V4.10079Z" fill="#EA7801"/>
            </svg>
            Select columns
          </span>
          <span class="action" @click="getUsers(undefined, 'true')">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path d="M3.89492 6.73949H4.04492H6.79731H6.79801V6.88949C6.89695 6.88841 6.98944 6.94003 7.03999 7.02606L3.89492 6.73949ZM3.89492 6.73949V6.88949V16.25V16.4H4.04492H13.9561H14.1061V16.25V6.88949V6.73949H13.9561H11.2037M3.89492 6.73949H11.2037M11.2037 6.73949H11.203V6.88949L11.2037 6.73949ZM8.96036 2.60193L8.96031 2.6014L8.94784 2.60366C8.93029 2.60686 8.91422 2.61116 8.90183 2.61479C8.7082 2.66127 8.5712 2.83624 8.57518 3.0368V11.0872L7.10269 9.61468C7.00021 9.50955 6.85114 9.46534 6.70871 9.4954C6.55001 9.52464 6.42318 9.64095 6.37865 9.79433L6.37853 9.79474C6.33418 9.94915 6.37966 10.1141 6.4958 10.2236L8.69654 12.4243L8.69652 12.4243L8.69893 12.4266L8.89681 12.6159L9.00049 12.7151L9.10417 12.6159L9.30205 12.4266L9.30208 12.4267L9.30443 12.4243L11.5069 10.2218C11.6752 10.0536 11.6752 9.78221 11.5069 9.61394C11.3386 9.44567 11.0673 9.44567 10.899 9.61394L9.4258 11.0872V3.03786C9.42979 2.91505 9.37968 2.79843 9.29276 2.7164C9.20338 2.63198 9.08216 2.59025 8.96036 2.60193ZM6.79801 7.5901V7.4401L10.961 7.30352C11.0115 7.38956 11.104 7.44118 11.203 7.4401V7.5901H11.2037H13.2554V15.5494H4.74554V7.5901H6.79731H6.79801Z" fill="#EA7801" stroke="#EA7801" stroke-width="0.3"/>
            </svg>
            Export list
          </span>

          <input type="search" placeholder="Search" style="width: 340px" v-model="searchText"/>
        </div>
        <div class="content-body">

          <PartnerTable
            :data="users"
            :fields="fields"
            :minimumRows="10"
            :pagination="pagination"
            :customStyling=true
            @sort="sort"
            @filter="search"
            @clickItem="$router.push({ name: 'CompanyUser', params: { userId: $event.id}});"
            @paginate="getUsers($event)"
          >
            <template v-slot:column-status="{ item }">
              <UserProgress :steps="item.steps || []" />
            </template>
          </PartnerTable>
        </div>
      </div>

      <modal
        v-if="showSelectColumns"
        @close="showSelectColumns = false"
        :size="{ width: 600 }"
        :padding="'0px'"
        :modalType="'fullscreen'">


        <div class="select-columns-container">
          <div class="header">
            <div class="heading">
              <div style="display: flex; align-items: center">
                <span v-html="columnsIcon"/>
                <span class="text">Select columns</span>
              </div>
              <button @click="showSelectColumns = false" class="button" >
                <iconComponent symbol="cross" :width="30"/>
              </button>
            </div>
          </div>

          <div class="content">

            <div v-for="colNumber in (columnFields.length / 3)" :key="colNumber" class="col">
              <div v-for="columnField in columnFields.slice((colNumber - 1) * 3, colNumber * 3)" :key="columnField.key" class="row">
                <InputField inputType="checkbox" :label="columnField.label" v-model="columnField.visible"/>
              </div>
            </div>

          </div>

          <div class="footer">
            <button class="button-yellow-outlined" @click="showSelectColumns = false">
              Cancel
            </button>
            <button class="button-solid" @click="setColumnFields();showSelectColumns = false">
              Apply
            </button>
          </div>

        </div>
      </modal>

      <UnsavedChanges
        :show="showUnsavedChanges"
        @save="save(); showUnsavedChanges = false"
        @close="showUnsavedChanges = false;"
      />

    </div>
  </div>
</template>

<script>

import axios from "axios";
import UnsavedChanges from "@/stories/components/UnsavedChanges/UnsavedChanges.vue";
import InputFieldComponent from "@/stories/components/InputField/InputField.vue";
import InputField from "@/stories/components/InputField/InputField.vue";
import PartnerTable from "@/stories/components/PartnerTable/PartnerTable.vue";
import UserProgress from "@/stories/components/DashboardUsers/UserProgress.vue";
import PartnerTablePagination from "@/stories/components/PartnerTable/PartnerTablePagination.vue";
import SvgMixin from "@/mixins/SvgMixin.vue";

export default {
  name: 'CompanyUsers',
  components: { InputField, UserProgress, PartnerTable, UnsavedChanges },
  mixins: [SvgMixin],
  props: ['companyId'],
  async mounted() {
   await this.getUsers();
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      this.showUnsavedChanges = true;
    } else {
      next();
    }
  },
  watch : {
    searchText(value) {

    }
  },
  data() {
    return {
      users: [],
      searchText: '',
      hasChanges: false,
      showUnsavedChanges: false,
      pagination: {
        limit: 10,
      },
      filtering: '',
      sorting: '',
      columnFields: [
        {
          key: 'firstName',
          label: 'First name',
          sort: 'asc',
          searchable: true,
          searchText: '',
          visible: true,
        },
        {
          key: 'lastName',
          label: 'Last name',
          sort: 'asc',
          searchable: true,
          searchText: '',
          visible: true,
        },
        {
          key: 'phone',
          label: 'Phone',
          sort: 'asc',
          searchable: true,
          searchText: '',
          visible: true,
        },
        {
          key: 'email',
          label: 'Email',
          sort: 'asc',
          searchable: true,
          searchText: '',
          visible: true,
        },
        {
          key: 'lastLogin',
          label: 'Last login',
          sort: 'asc',
          searchable: true,
          searchText: '',
          visible: true,
        },
        {
          key: 'latestActivation',
          label: 'Latest activation',
          //getter: (item) => item.latestActivation ? (item.latestActivation.thumbnail ?? '') + ' ' + item.latestActivation.name : '',
          getter: (item) => item.latestActivation ? item.latestActivation.name : '',
          sort: 'asc',
          searchable: true,
          searchText: '',
          visible: true,
        }
      ],
      showSelectColumns: false,
    }
  },
  methods: {
    async exportUsers(params) {
      try {
        const response = await axios({
          url: `/api/c/companies/users`,
          method: 'GET',
          responseType: 'blob',
          params,
        });

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `users.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        // Handle error
      }
    },
    async getUsers(pagination, asXlsx = 'false') {

      let params = {
        skip: 0,
        limit: this.pagination.limit,
        sortColumn: this.sorting.columnKey !== '' ? this.sorting.columnKey : 'firstName',
        sortOrder: this.sorting.order !== '' ? this.sorting.order : 'asc',
        asXlsx
      };

      if (pagination) {
        params.skip = pagination.skip;
      }

      if (this.filtering) {
        params.filterColumn = this.filtering.columnKey;
        params.filter = this.filtering.text;
      }

      if (asXlsx === 'true') {
        return await this.exportUsers(params);
      }

      const response = await axios.get(`/api/c/companies/users`,
        { params: params })
        .catch(error => {
        });

      if (!response) {
        return;
      }

      this.pagination.total = response.data.totalCount;
      this.pagination.skip = params.skip;
      this.users = response.data.users;
    },
    sort(sort) {
      this.sorting = sort;
      this.columnFields.find(column => column.key === sort.columnKey).sort = sort.order;

      this.filtering = ''; // reset filtering
      this.clearSearchFields();

      this.getUsers();
    },
    search(filter) {
      this.filtering = filter;
      this.getUsers();

      this.clearSearchFields(filter.columnKey);
    },
    save() {
      this.hasChanges = false;
    },
    clearSearchFields(columnKey) {
      this.columnFields.forEach(field => {
        if (field.key !== columnKey) {
          field.searchText = '';
        }
      });
    },
    setColumnFields() {

    }
  },
  computed: {
    fields() {
      return this.columnFields.filter(column => column.visible);
    }
  }
};
</script>

<style lang="scss">

$font-default: 'CircularXXWeb-Regular';

.company-users-container {
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 50px;

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;

    h1 {
      font-size: 32px;
      font-weight: 500;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 16px;

    border-radius: 8px;

    .content-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 32px;
      margin-bottom: 12px;

      .action {
        color: #EA7801;
        cursor: pointer;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .content-body {

      border: 1px solid #E5E5E5;
      border-radius: 8px;
    }
  }

  input, .input {
    min-height: 24px;
    padding: 2px 12px;
    border-radius: 8px;
    border: 1px solid #999;
    outline: 0;

    font-size: 12px;
    font-weight: 400;
    line-height: 22.4px;
    color: #111;

    ::placeholder {
      color: #999;
      border: 1px solid #999;
    }
  }

  .select-columns-container {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: column;

      .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #F2F2F2;
        min-height: 64px;
        padding: 16px 40px;
        gap: 10px;

        .text {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          padding-left: 10px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .col {
        display: flex;
        flex-direction: column;

        flex: 1;
        padding: 16px 40px;

        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 16px;
        }
      }

    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;
      justify-content: flex-end;
      background: #F2F2F2;
      padding: 24px;

      .button-yellow-outlined, .button-solid {
        width: 99px;
      }
    }
  }
}
</style>
