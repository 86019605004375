/* eslint-disable no-shadow, no-param-reassign */
import flagApi from '../../api/pvFlag.api';

// initial state
const state = {
  flaggedQuery: '',
  flagged: [],
  flaggedStatus: '',
  cloneStatus: '',
  resetStatus: ''
};

// getters
const getters = {
  flagged: state => state.flagged,
};

// actions
const actions = {
  // ADMIN
  findAdminFlagged({ commit }, payload) {
    commit('FLAGGED_STATUS', 'LOADING');
    return flagApi.findAdminFlagged(payload)
      .then((response) => {
        commit('FLAGGED', response.flagged);
        commit('FLAGGED_STATUS', 'FINISHED');
      });
  },
  resetFlagForReceipt({ commit }, payload) {
    commit('RESET_STATUS', 'LOADING');
    return flagApi.resetFlagForReceipt(payload)
      .then(() => {
        commit('RESET_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  CLONE_STATUS(state, status) {
    state.cloneStatus = status;
  },
  RESET_STATUS(state, status) {
    state.resetStatus = status;
  },
  BLOCK_STATUS(state, status) {
    state.flaggedStatus = status;
  },
  BLOCK_QUERY(state, flaggedQuery) {
    state.flaggedQuery = flaggedQuery;
  },
  FLAGGED(state, flagged) {
    state.flagged = flagged;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
