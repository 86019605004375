<template>
  <div
    v-if="loadedPage"
    class="activation-list single-list">
    <EntityEditor
      v-if="company && company._id"
      :title="company.name"
      :admin="true"
      :entity="company"
      entity-type="PVCompany"/>

    <div>
      <div class="btn btn-activate" style="width: 150px; margin-right: 20px;" @click="addActivationModal = true">Add</div>
      <input type="text" placeholder="Find activation" @input="findAdminCompanyActivation($event.target.value)">
      <input type="text" style="margin-left: 10px;" placeholder="Find by receipt details" @input="findAdminCompanyActivationByReceiptProperties($event.target.value)">
    </div>
   
    <div
      v-if="company && company._id">
      <aList
        entityType="Public Activations"
        columnsWidth="400"
        :entities="publicActivations"
        :keys="activationsKeys"
        routerName="AdminPVActivation"
        routerId="activationId"/>

      <aList
        entityType="Idividual Activations"
        columnsWidth="400"
        :entities="individualActivations"
        :keys="activationsKeys"
        routerName="AdminPVActivation"
        routerId="activationId"/>

    </div>

    <modal
      v-if="addActivationModal"
      :header="{text: 'Create Activation', textPosition: 'center', bgColor: '#F2F2F2'}"
      class="modal-view"
      modalType="fullscreen">
      <slot>
        <EntityEditor
          title="Create activation"
          :admin="true"
          entity-type="PVActivation"
          :attach="attach"/>
        <button class="btn btn-activate" @click="closeActivationDialog">Close</button>
      </slot>
    </modal>
  </div>
</template>

<script>
import validator from "validator";
import { mapGetters } from "vuex";
import aList from "../components/AList";
import EventBus from "../resources/eventBus";
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue'
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: "AdminPVCompany",
  components: {
    EntityEditor,
    aList,
  },
  mixins: [BeforeEnterMixin],
  props: ['companyId'],
  mounted() {
    this.findAdminCompanyActivation('');
  },
  computed: {
    ...mapGetters('pvCompanies', ["company"]),
    publicActivations() {
      let publicActivations = [];
      if (this.company && this.company.activations) {
        publicActivations = this.company.activations.filter((act) => {
          return !act.available_for_phone_nrs || !act.available_for_phone_nrs.length
        });
      }
      return publicActivations;
    },
    individualActivations() {
      let individualActivations = [];
      if (this.company && this.company.activations) {
        individualActivations = this.company.activations.filter((act) => {
          return act.available_for_phone_nrs && act.available_for_phone_nrs.length
        });
      }
      return individualActivations;
    },
  },
  data() {
    return {
      loadedPage: false,
      activationId: '',
      search: '',
      validator,
      addActivationModal: false,
      parentActivationId: '',
      activationsKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Info',
          key: 'info',
        },
        {
          title: 'Available for phone_nrs',
          key: 'available_for_phone_nrs',
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
      attach: [
        {
          key: 'companyId',
          value: this.companyId,
        }
      ],
    };
  },
  methods: {
    findAdminCompanyActivationByReceiptProperties(query) {
      if (query == '') {
        this.findAdminCompanyActivation('');
      }
      else{
        this.$store.dispatch('pvCompanies/findAdminPVCompanyByReceiptProperties', { query, companyId: this.companyId }).then(() => {
          this.loadedPage = true;
        });
      }
    },

    findAdminCompanyActivation(query) {
      let exclude;
      if (query == '') {
        exclude = 'individual';
      }
      this.$store.dispatch('pvCompanies/findAdminPVCompany', { query, companyId: this.companyId, exclude }).then(() => {
        this.loadedPage = true;
      });
    },
    closeActivationDialog() {
      this.parentActivationId = false;
      this.addActivationModal = false;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.activation-list {
}
.single-list{
}
.the-list {
  // display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .activation-item-image{
      width: fit-content;
      height: fit-content;
    }
    .activation-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.activation-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-activation-button{
  // z-index: 1000;
  // position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
