<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <EntityEditor
      v-if="activation && activation._id"
      :title= "`Activation '${ activation.name }' (${ activation.company.name })`"
      :admin="true"
      :entity="activation"
      @eventshowCloneModal="showModalCloneActivation(true)"
      entity-type="PVActivation"/>

    <div v-if="activation && activation._id">
      <collapsable
        v-if="activation.campaign_type"
        :collapsed="true"
        :header="{bgColor: '#F9D7B3', textPosition: 'left'}"
        :content="{ bgColor: 'white' }">
        <template v-slot:header>
          <h3>Tips!</h3>
        </template>

        <template v-slot:content>
          <div class="admin-entity-tips">
            <div>
              This is a <strong>{{ getReadableType }}</strong> campaign.

              <div class="admin-entity-tips-bullets">
                <div v-if="activation.campaign_type.includes('BUY_AND')
                  && !activation.campaign_type.includes('RETURN') && !activation.campaign_type.includes('REWARD')">
                  <p :class="{ 'missing-setting': !activation.receipt_step }">
                    There should be a <b>PV step</b>
                  </p>
                  <ul>
                    <li>Make sure that the terms match the converted terms</li>
                    <li>Make sure that the converted terms match the products</li>
                  </ul>
                  <p :class="{ 'missing-setting': !activation?.receipt_retailer_list?.length && !activation?.receipt_shop_exclude_list?.length }">
                    Add <b>Accepted Retailers</b> OR <b>Declined shops</b>
                  </p>
                  <ul>
                  </ul>
                  <p :class="{ 'missing-setting': !activation.registration_step }">
                    There could be a <b>PR step</b>, in order to make the activation more secure
                  </p>
                  <ul>
                  </ul>
                </div>
                <div v-if="activation.campaign_type.includes('RETURN')">
                  <p :class="{ 'missing-setting': !activation.survey_step }">
                    There should be a <b>Survey step</b>
                  </p>
                  <ul>
                  </ul>
                </div>
                <div v-if="activation.campaign_type.includes('AND_GET')">
                  <p :class="{ 'missing-setting': !activation.address_step }">
                    There should be an <b>Address step</b>
                  </p>
                  <ul>
                  </ul>
                </div>
                <p>Actions</p>
                <ul>
                  <li v-if="activation.campaign_type == '_REGISTRATION'" :class="{ 'missing-setting': missingEmailAction }">
                    You should have a <b>Send Email</b> action, which describes how the consumer can return the product and refer to the next activation
                    <ul>
                      <li :class="{ 'missing-setting': missingEmailActionDate }">
                        You should have a <b><span v-pre>{{action-n-date}}</span></b> in the email template, this helps the consumer to know when he can return the product
                      </li>
                    </ul>
                  </li>
                  <li v-else :class="{ 'missing-setting': missingEmailAction }">
                    You should have a <b>Send Email</b> action, which describes what happends next
                  </li>
                  <li
                    v-if="activation.campaign_type.includes('_REGISTRATION') && (activation.recurring || activation.recurring_daily)"
                    :class="{ 'missing-setting': missinglockActivationActionDelay0 }">
                    You should have a <b>Lock Activation</b> action with a delay of 0 days, which locks the {{ secondActivationName }} activation if the consumer have been there already
                  </li>
                  <li v-if="activation.campaign_type.includes('_POINTS')" :class="{ 'missing-setting': missingPointsAction }">
                    You should have a <b>Add Points</b> action, with a delay of 0 days, which adds points to the consumer
                  </li>
                  <li v-if="activation.campaign_type.includes('_REGISTRATION')" :class="{ 'missing-setting': missingUnlockActivationAction }">
                    You should have a <b>Unlock Activation</b> action, which unlocks the {{ secondActivationName }} activation
                  </li>
                  <li v-if="activation.campaign_type == 'BUY_AND_TRY_REGISTRATION'" :class="{ 'missing-setting': missinglockActivationAction }">
                    You should have a <b>Lock Activation</b> action, which locks the {{ secondActivationName }} activation
                  </li>
                </ul>
              </div>
            </div>
            <br/><p>Note: these tips are applied on the saved activation</p>
          </div>
        </template>
      </collapsable>

      <div style="padding: 10px; background: white;">
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; grid-gap: 10px; margin-bottom: 30px;">
          <h1 class="md-title">{{ activation.receiptsLength }} Receipts</h1>
          <h1 v-if="activation.receipt_step" class="md-title">{{ activation.confirmedReceiptLength }} PV done</h1>
          <h1 v-if="activation.registration_step" class="md-title">{{ activation.confirmedRegistrationLength }} PR done</h1>
          <h1 v-if="activation.survey_step" class="md-title">{{ activation.confirmedSurveyLength }} Survey done</h1>
          <h1 v-if="activation.address_step" class="md-title">{{ activation.confirmedAddressLength }} Address done</h1>
          <h1 v-if="activation.review_step" class="md-title">{{ activation.confirmedReviewLength }} Review done</h1>
          <h1 v-if="activation.cashback_step" class="md-title">{{ activation.confirmedCashbackLength }} Cashback done</h1>
          <h1 class="md-title">{{ activation.receiptsCtaDoneLength }} CTA_DONE</h1>
          <h1 class="md-title">{{ activation.limitedCtasAllLength }} Codes</h1>
        </div>
        <table
          v-if="Object.keys(activation.limitedCtasByType || {}).length"
          class="info-box">
          <tr>
            <td><b>Prize type</b></td>
            <td><b>Unclaimed</b></td>
            <td><b>Claimed</b></td>
          </tr>
          <tr
            v-for="(linmitedCtaType, key) in activation.limitedCtasByType"
            :key="key">
            <td>{{ key }}</td>
            <td>{{ linmitedCtaType.unclaimed }}</td>
            <td>{{ linmitedCtaType.claimed }}</td>
          </tr>
        </table>
        <div
          v-if="activation.review_step"  
          class="info-box">
          <div class="rating-item">
            <span class="rating-label">Number of Reviews:</span>
            <span class="rating-value">{{ activation.reviewsLength }}</span>
          </div>
          <div class="rating-item">
            <span class="rating-label">Average Rating:</span>
            <span class="rating-value">{{ activation.averageRating?.toFixed(2) }}</span>
          </div>
        </div>
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; grid-gap: 10px;">
          <span class="btn btn-activate" @click="showListModal = true">Get List</span>
          <span class="btn btn-activate" @click="show3rdPartyListModal = true">Get 3rd party List</span>
          <span class="btn btn-activate" @click="showStatsModal = true">Get Stats</span>
          <span class="btn btn-activate" @click="showCTAModal = true">Get CTA's</span>
          <span class="btn btn-activate" @click="changeCTAModal = true">Change CTA's</span>
          <a
            class="btn btn-activate"  
            :href="`${windowOrigin}/admin/pv/receipts?acti=${activation._id}`">
            Find Receipts
          </a>
          <span v-if="activation.address_step" class="btn btn-activate" @click="sendProductsModal = true">Send products</span>
        </div>
      </div>

      <modal
        v-if="showCloneModal"
        :header="{text: `Create copies of '${activation.name}'`, textPosition: 'center', bgColor: '#F2F2F2'}"
        class="modal-view"
        modalType="fullscreen">
        <slot>
          <form novalidate class="md-layout" @submit.prevent="onSubmit">
            <div v-for="(value, index) in clones" :key="index" class="md-layout-item md-small-size-100">
              <md-card>
                <md-card-content>
                  <md-field>
                    <label>Name:</label>
                    <md-input v-model="value.name"></md-input>
                  </md-field>
                  <md-field>
                    <label>Info:</label>
                    <md-input v-model="value.info"></md-input>
                  </md-field>
                  <md-field>
                    <label>Market:</label>
                    <md-select v-model="value.market">
                      <md-option
                        v-for="country in countries" v-bind:key="country.key"
                        :value="country.value">
                        {{country.title}}
                      </md-option>
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>Company:</label>
                    <md-select v-model="value.company_id">
                      <md-option
                        v-for="company in companies" v-bind:key="company._id"
                        :value="company._id">
                        {{company.name}}
                      </md-option>
                    </md-select>
                  </md-field>
                </md-card-content>

                <md-card-actions>
                  <button @click="removeClone(index)">
                    <md-icon style="color: #f44336 !important;">delete</md-icon>
                  </button>
                </md-card-actions>
              </md-card>
            </div>
          </form>
          <button class="btn btn-activate" @click.prevent="addNewClone">Add more copies</button>
          <div>
            <button class="btn btn-transparent" @click="dismissCloneModal()">Cancel</button>
            <button class="btn btn-activate" @click="createClones()">Save {{ clones.length }} copies</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showListModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showListModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get List' }">
        <slot>
          <h3>Filters</h3>
          <md-datepicker
            v-model="createdStart">
            <label>Created Start</label>
          </md-datepicker>
          <md-datepicker
            v-model="createdEnd">
            <label>Created End</label>
          </md-datepicker>
          <md-field>
            <label for="cta_done">cta_done?</label>
            <md-select
              v-model="ctaDone">
              <md-option value="all">Both</md-option>
              <md-option :value="true">cta_done == true</md-option>
              <md-option :value="false">cta_done == false</md-option>
            </md-select>
          </md-field>
          <md-field>
            <label for="administrated">administrated?</label>
            <md-select
              v-model="administrated">
              <md-option value="all">Both</md-option>
              <md-option :value="true">administrated == true</md-option>
              <md-option :value="false">administrated == false</md-option>
            </md-select>
          </md-field>
          <h3>Change selection</h3>
          <md-field>
            <label for="administrated">Set Administrated</label>
            <md-select
              v-model="setAdministrated">
              <md-option value="all">Set all</md-option>
              <md-option value="cta_done">Set all (cta_done == true)</md-option>
              <md-option :value="false">Don't set</md-option>
            </md-select>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getList()">Download List</button>
            <button class="btn btn-transparent" @click="showListModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="show3rdPartyListModal"
        size="xlarge"
        modalType="fullscreen"
        @close="show3rdPartyListModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get 3rd party List' }">
        <slot>
          <div style="text-align: left;">
            <h3>Important!</h3>
            <p>When you click ”download this list”, the following is happening:</p>
            <p>This list will contain receipts of:</p>
            <ul>
              <li>All receipts with CTA_Done == True (meaning that they have all completed the registration)</li>
              <li>All receipts with administrated == False (meaning that they have not been administrated before)</li>
              <li>The receipts you get will be set to administrated = true</li>
            </ul>
            <p>And the following action will be taken on the receipts in this list:</p>
            <ul>
              <li>The receipts in the list you get will be set to administrated == True (meaning that we have handled this list of consumers)</li>
            </ul>
            <p>So it is important to take action on the list you take out since they will be set as administrated in Enginio.</p>
            <p>All of this means that the next time you click the same button, you will not get the same consumers in the list, since they have already been administrated. The new list will contain all new receipts with CTA_Done == True and all receipts with administrated == False.</p>
          </div>
          <div>
            <button class="btn btn-activate" @click="get3rdPartyList()">Download List</button>
            <button class="btn btn-transparent" @click="show3rdPartyListModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showStatsModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showStatsModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get Stats' }">
        <slot>
          <h3>Do you want to filter on a store?</h3>
          <md-field>
            <label>Store</label>
            <md-input v-model="receiptStore"></md-input>
          </md-field>
          <md-field>
            <label>Top Stores, per store address</label>
            <md-input v-model="receiptStoreTop" placeholder="20"></md-input>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getStats()">Download Stats</button>
            <button class="btn btn-transparent" @click="showStatsModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showCTAModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showCTAModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get CTA\'s' }">
        <slot>
          <md-field>
            <label for="administrated">won?</label>
            <md-select
              v-model="won">
              <md-option value="all">Both</md-option>
              <md-option :value="true">won == true</md-option>
              <md-option :value="false">won == false</md-option>
            </md-select>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getUnclaimed()">Download CTA's</button>
            <button class="btn btn-transparent" @click="showCTAModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="changeCTAModal"
        size="xlarge"
        modalType="fullscreen"
        @close="changeCTAModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Change CTA prize_types' }">
        <slot>
          <div
            v-for="(_, key) in activation.limitedCtasByType"
            :key="key"
            style="margin-top: 10px">
            <div style="text-align: left">Old: {{ key }}</div>
            <inputField
              label="New"
              :value="editCtas[key]"
              @input="updateEditCtas(key, $event)"/>
          </div>
          <div v-if="warningMessage" style="color: red; margin-top: 10px;">
            {{ warningMessage }}
          </div>
          <div style="margin-top: 10px;">
            <b>IMPORTANT!</b> If this activation shares limited codes, they will all change
          </div>

          <div>
            <button class="btn btn-activate" @click="changeCTAs()">Change CTA's</button>
            <button class="btn btn-transparent" @click="changeCTAModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="sendProductsModal"
        size="xlarge"
        modalType="fullscreen"
        @close="sendProductsModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Send products' }">
        <slot>
          <div>
            <div>We will ask the delivery partner: {{ activation.address_delivery_partner }}, if they can send out the product to the consumers who have CTA_DONE</div>
            <inputField
              label="Send only to the ones who our partner have approved?"
              inputType="checkbox"
              v-model="partnerApproved"/>
          </div>

          <div>
            <button class="btn btn-activate" @click="sendProducts()">Send products</button>
            <button class="btn btn-activate" @click="getTracking()">Get tracking</button>
            <button class="btn btn-transparent" @click="sendProductsModal = false">Close</button>
          </div>
        </slot>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import Collapsable from '../stories/components/Collapsable/Collapsable.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import countries from '../assets/countries.json';
import compaignTypes from '../assets/campaignTypes.json';

export default {
  name: "AdminPVActivation",
  components: {
    EntityEditor,
    Collapsable,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  props: ['activationId'],
  mounted() {
    this.$store.dispatch('pvActivations/getAdminPVActivation', { activationId: this.activationId }).then(() => {
      this.loadedPage = true;
      if (this.isDeveloping && this.activation.market
        && !this.activation.market.includes('local') && !this.activation.market.includes('test')
        && !this.activation.market.includes('dev') && !this.activation.market.includes('default')) {
        this.setAlert(`Activation market is "${this.activation.market}", make sure that it is the same as process.env.DEFAULT_MARKET!`, 10);
      }
      if (!this.activation.campaign && this.activation.cashback_step) {
        this.setAlert(`No Campaign is set, very important for cashback campaigns!`, 8);
      }

      for (let key in this.activation.limitedCtasByType) {
        this.$set(this.editCtas, key, key);
      }
    });
  },
  computed: {
    ...mapGetters('pvActivations', ["activation"]),
    ...mapGetters('pvCompanies', ["companies"]),
    prefilledClone() {
      return {
        name: "Copy of " + this.activation.name,
        market: this.activation.market,
        company_id: this.activation.company._id,
        info: this.activation.info
      };
    },
    missingEmailAction() {
      return !this.activation.cta_actions.find(action => action.type === 'SEND-EMAIL');
    },
    missingEmailActionDate() {
      return !this.activation.cta_actions.find(action => /{{action-\d+-date}}/.test(action.email_template));
    },
    missingUnlockActivationAction() {
      return !this.activation.cta_actions.find(action => action.type === 'UNLOCK-ACTIVATION');
    },
    missinglockActivationAction() {
      return !this.activation.cta_actions.find(action => action.type === 'LOCK-ACTIVATION' && action.delayed_duration !== 0);
    },
    missinglockActivationActionDelay0() {
      return !this.activation.cta_actions.find(action => action.type === 'LOCK-ACTIVATION' && action.delayed_duration === 0);
    },
    missingPointsAction() {
      return !this.activation.cta_actions.find(action => action.type.includes('-POINTS') && action.delayed_duration === 0);
    },
    secondActivationName() {
      let name = 'return';
      if (this.activation.campaign_type.includes('REVIEW_AND')) {
        name = 'review';
      }
      return name;
    },
    getReadableType() {
      return this.compaignTypes.find(type => type.value === this.activation.campaign_type)?.title;
    },
  },
  watch: {
    editCtas: {
      handler() {
        const values = Object.values(this.editCtas);
        const duplicates = values.filter((value, index, self) => self.indexOf(value) !== index && self.lastIndexOf(value) === index);
        let warningMessage = '';
        console.log('duplicates', duplicates);
        if (duplicates.length > 0) {
          warningMessage = 'WARNING: If you specify the same prize_type, they will be merged!';
        } else {
          warningMessage = '';
        }
        this.warningMessage = warningMessage;
      },
      deep: true,
    },
  },
  data() {
    return {
      loadedPage: false,
      receiptId: '',
      search: '',
      showReceiptModal: false,
      showOfferModal: false,
      showCloneModal: false,
      parentReceiptId: '',
      showListModal: false,
      show3rdPartyListModal: false,
      ctaDone: 'all',
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
      administrated: 'all',
      setAdministrated: false,
      showStatsModal: false,
      won: 'all',
      showCTAModal: false,
      changeCTAModal: false,
      sendProductsModal: false,
      partnerApproved: true,
      editCtas: {},
      receiptStore: '',
      receiptStoreTop: 20,
      clones: [],
      countries,
      compaignTypes,
      warningMessage: '',
    };
  },
  methods: {
    getList() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/receipts/csv`
        +`?createdStart=${this.createdStart/1000}&createdEnd=${this.createdEnd/1000 + 3600*24}&setAdministrated=${this.setAdministrated}&administrated=${this.administrated}&ctaDone=${this.ctaDone}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.activation.name}_receipts_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    get3rdPartyList() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/receipts/3rdparty/csv`
        +`?setAdministrated=cta_done&administrated=false&ctaDone=true`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `thirdparty_${this.activation.name}_receipts_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getStats() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/statistics/csv?store=${this.receiptStore}&top=${this.receiptStoreTop}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.activation.name}_statistics_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getUnclaimed() {
      axios.get(`/api/pv/admin/activations/${this.activationId}/limited-ctas/csv?won=${this.won}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.activation.name}_unclaimed_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getCompanies() {
      this.$store.dispatch('pvCompanies/findAdminPVCompanies');
    },
    showModalCloneActivation(show) {
      // Get list of all companies so admin can clone activation to another company
      this.getCompanies();

      //pre-fill at least 1 clone for a nice UX
      let starterClone = { ...this.prefilledClone };
      this.clones = [starterClone];
      this.showCloneModal = show;
    },
    dismissCloneModal() {
      // don't leave any left-overs
      this.clones = [];
      //close the dialog
      this.showCloneModal = false;
    },
    addNewClone() {
      // Cloning the Object with Spread Operator
      let exampleClone = { ...this.prefilledClone };
      this.clones.push(exampleClone);
    },
    removeClone(index) {
      this.clones.splice(index, 1);
    },
    createClones() {
      this.$store
      .dispatch("pvActivations/cloneAdminPVActivation", { entityId: this.activationId, clones: this.clones})
      .then((nrOfCreatedCopies) => {
        this.dismissCloneModal();
        this.setAlert("Succesfully created " + nrOfCreatedCopies + " copies!");
        this.$router.back();
      }).catch((err) => {
        this.setAlert("Failed to create copies with error: " + err);
      });
    },
    updateEditCtas(key, value) {
      this.$set(this.editCtas, key, value);
    },
    changeCTAs() {
      this.loadedPage = false;
      this.$store
      .dispatch("pvActivations/changeCTAs", { entityId: this.activationId, ctas: this.editCtas })
      .then(() => {
        this.loadedPage = true;
        this.changeCTAModal = false;
        for (let key in this.activation.limitedCtasByType) {
          this.$set(this.editCtas, key, key);
        }
        this.setAlert("Succesfully changed CTA's!");
      }).catch((err) => {
        this.setAlert("Failed to change CTA's with error: " + err);
      });
    },
    sendProducts() {
      this.loadedPage = false;
      this.$store
      .dispatch("pvActivations/sendProducts", { entityId: this.activationId, partnerApproved: this.partnerApproved })
      .then((numberSent) => {
        this.loadedPage = true;
        this.sendProductsModal = false;
        this.setAlert(`Succesfully sent ${numberSent} products!`);
      }).catch((err) => {
        this.loadedPage = true;
        this.setAlert("Failed to send products with error: " + err);
      });
    },
    getTracking() {
      this.loadedPage = false;
      this.$store
      .dispatch("pvActivations/getTracking")
      .then((numberSent) => {
        this.loadedPage = true;
        this.sendProductsModal = false;
        this.setAlert(`Succesfully sent ${numberSent} products!`);
      }).catch((err) => {
        this.loadedPage = true;
        this.setAlert("Failed to send products with error: " + err);
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
.info-box {
  text-align: left;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;

  td {
    padding: 5px 5px 0 5px;
  }
}

.rating-item {
  display: flex;
  margin-bottom: 10px;
}

.rating-label {
  font-weight: bold;
  color: #333;
}

.rating-value {
  margin-left: 20px;
  font-size: 1.2em;
  color: #555;
}
</style>
