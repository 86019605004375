<template>
  <div
    class="activation-module-container"
    :class="{
      'activation-module-selected': this.selected,
      'activation-module-disabled': this.disabled
    }"
    @click="handleClick">
    <div class="module-header">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" v-if="!this.selected">
        <path d="M12 3C7.03407 3 3 7.03407 3 12C3 16.9659 7.03407 21 12 21C16.9659 21 21 16.9659 21 12C21 7.03407 16.9659 3 12 3ZM12 3.78261C16.543 3.78261 20.2174 7.45703 20.2174 12C20.2174 16.543 16.543 20.2174 12 20.2174C7.45703 20.2174 3.78261 16.543 3.78261 12C3.78261 7.45703 7.45703 3.78261 12 3.78261Z" fill="black"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" v-else>
        <path fill="#000" d="M12 2.95c-4.994 0-9.05 4.056-9.05 9.05 0 4.994 4.056 9.05 9.05 9.05 4.994 0 9.05-4.056 9.05-9.05 0-4.994-4.056-9.05-9.05-9.05Zm0 .883A8.161 8.161 0 0 1 20.167 12 8.161 8.161 0 0 1 12 20.167 8.161 8.161 0 0 1 3.833 12 8.161 8.161 0 0 1 12 3.833Z"/>
        <path fill="#000" d="M12 19c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7Z"/>
      </svg>
      <p class="module-name">{{ name }}</p>
    </div>
    <div class="module-description">
      <slot name="icon"></slot>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivationModule",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "Module name",
    },
    description: {
      type: String,
      default: "Description",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit("select", this.id);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$module-border-radius: 8px;
.activation-module-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 216px;
  height: 260px;
  padding: 8px;
  border-radius: $module-border-radius;
  border: 1px solid #CCC;
  background-color: #FFF;
  cursor: pointer;

  .module-header {
    display: flex;
    gap: 4px;
    align-items: center;

    .selected-icon {
      width: 24px;
      height: 24px;
    }

    .module-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      color: #333;
    }
  }

  .module-description {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 199px;
    height: 190px;
    padding: 16px;
    border-radius: $module-border-radius;
    background-color: #F2F2F2;
    
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 22.4px;
    text-align: left;
    color: #111;
  }
}

.activation-module-selected {
  border: 2px solid $brand-yellow;
  padding: 7px;
}

.activation-module-disabled {
  cursor: default;
  opacity: 0.5;
}
</style>