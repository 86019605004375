<template>
  <WizardModal
    :canSkipTo="nextStep"
    :heading="'Create campaign'"
    :index="this.index"
    :show="this.show"
    :steps="['Name and description', 'Theme', 'Images']"
    @changeIndex="index = $event;"
    @close="$emit('close')"
    @disappeared="$emit('disappeared'); index = 0;"
    @finish="$emit('createCampaign', data)"
  >
    <div class="content">
      <div class="name-and-description" v-if="this.index === 0">
        <div class="input-container">
          <label :for="'name'">Campaign name</label>
          <input
            required
            :id="'name'"
            :type="'text'"
            :value="this.data.name"
            :placeholder="'e.g. Buy and get'"
            @input="emitChanges('name', $event.target.value)"
          />
          <span>Maximum characters: 50</span>
        </div>
        <div class="input-container">
          <label :for="'info'">Campaign description</label>
          <textarea
            required
            :id="'info'"
            :type="'text'"
            :value="this.data.info"
            :placeholder="''"
            @input="emitChanges('info', $event.target.value)"
          ></textarea>
          <span>Maximum characters: 500</span>
        </div>
      </div>

      <div class="theme" v-if="this.index === 1">
        <CampaignTheme
          :name="'Nemo'"
          :thumbnailImage="require('../../assets/theme_nemo_thumbnail.png')"
          :largeImage="require('../../assets/theme_nemo_large.png')"
          :selected="this.data.theme === 'nemo'"
          @select="emitChanges('theme', 'nemo');"
        />
        <CampaignTheme
          :name="'Iris'"
          :thumbnailImage="require('../../assets/theme_iris_thumbnail.png')"
          :largeImage="require('../../assets/theme_iris_large.png')"
          :selected="this.data.theme === 'iris'"
          @select="emitChanges('theme', 'iris');"
        />
      </div>

      <div class="images" v-if="this.index === 2">
        <ImageSelect  :heading="'Background'"
                      :selected-image-file="this.data.background"
                      :aspect-ratio="'16:9'"
                      :showInfo=true
                      :width=292
                      :height=277
                      @imageSelected="emitChanges('background', $event)"
                      @imageDeleted="emitChanges('background', null)"
                      class="image"/>
        <ImageSelect  :heading="'Logo'"
                      :selected-image-file="this.data.logo"
                      :aspect-ratio="'1:1'"
                      :showInfo=true
                      :width=292
                      :height=277
                      @imageSelected="emitChanges('logo', $event)"
                      @imageDeleted="emitChanges('logo', null)"
                      class="image"/>
        <ImageSelect  :heading="'Start page'"
                      :selected-image-file="this.data.startpage"
                      :aspect-ratio="'4:5'"
                      :showInfo=true
                      :width=292
                      :height=277
                      @imageSelected="emitChanges('startPage', $event)"
                      @imageDeleted="emitChanges('startPage', null)"
                      class="image"/>
      </div>
    </div>
  </WizardModal>
</template>

<script>
import ImageSelect from '../ImageSelect/ImageSelect.vue';
import WizardModal from '../WizardModal/WizardModal.vue';
import CampaignTheme from './CampaignTheme.vue';

export default {
  name: 'CampaignWizard',
  components: {
    CampaignTheme,
    WizardModal,
    ImageSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({
        name: '',
        info: '',
        theme: null,
        background: null,
        logo: null,
        startPage: null,
      }),
    }
  },
  watch: {
    'selectedBackgroundImage'(image) {
      this.emitChanges('background', image);
    },
    'selectedLogoImage'(image) {
      this.emitChanges('logo', image);
    },
    'selectedStartPageImage'(image) {
      this.emitChanges('startPage', image);
    },
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
  },
  computed: {
    nextStep() {
      if (this.data.theme)
        return undefined;

      if (this.data.name?.length > 1)
        return 1;

      return 0;
    }
  },
  data() {
    return {
      index: 0,
      fullscreenSource: null,
      selectedBackgroundImage: null,
      selectedLogoImage: null,
      selectedStartPageImage: null,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  // flex: 1;
  padding: 24px 40px;
  background-color: #FFF;
  width: 994px;
  height: 461px;

  & > * {
    flex: 1;
  }

  .name-and-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 40px 24px;

    & > * {
      width: 498px;
    }
  }

  .theme, .images {
    display: flex;
    align-items: flex-start;
    gap: 18px;
    padding: 40px 0 0 0;
  }

  .image {
    width: 33%;
  }
}
</style>
