
<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="hyperlink.name"
      entity-type="Hyperlink"
      :entity="hyperlink"
      :admin="true"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import hyperlinkApi from '../api/hyperlink.api';

export default {
  name: 'AdminHyperlink',
  components: {
    EntityEditor
  },
  props: ['hyperlinkId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
    this.setPageTitle('Admin', 'Hyperlink');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["hyperlink", "hyperlinks", "userStatus"]),
  },
  watch: {
    hyperlinkId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      search: '',
      showHyperlinkDialog: false,
      showOfferDialog: false,
      previewHyperlink: {},
      previewOffer: {},
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminHyperlink', { entityId: this.hyperlinkId }).then(() => {
        this.setAttachments();
      });
    },
    setAttachments() {
      this.loadedPage = true;
    },
    getHyperlink(entityId) {
      hyperlinkApi.getAdminHyperlink({ entityId }).then((data) => {
        this.previewHyperlink = data.hyperlink;
        this.showPreviewHyperlinkModal = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminHyperlink";
</style>
