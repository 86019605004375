<template>
  <div class="user-activation-steps-container">
    <div class="activation-steps-line"></div>
    <div v-for="(step, index) in steps" :key="index" class="user-activation-step" @click="$emit('selectStep', step)">
      <div v-if="selectedIndex === index" class="activation-step-selection"></div>
      <ActivationStepIcon :src="step.icon" />
      <ActivationStepStatus :number="index + 1" :status="step.status" />
      <ActivationStepLabel :text="step.name" />
    </div>
  </div>
</template>

<script>
import ActivationStepIcon from '@/stories/components/ActivationStep/ActivationStepIcon.vue';
import ActivationStepStatus from '@/stories/components/ActivationStep/ActivationStepStatus.vue';
import ActivationStepLabel from '@/stories/components/ActivationStep/ActivationStepLabel.vue';

export default {
  name: 'UserActivationSteps',
  components: {
    ActivationStepIcon,
    ActivationStepStatus,
    ActivationStepLabel,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
    },
  },
}
</script>

<style lang="scss" scoped>
$container-padding: 64px;
$step-width: 85px;
$selection-width: 93px;
$icon-width: 77px;
$icon-height: 79px;


.user-activation-steps-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 78px;
  padding: 0 $container-padding;

  .user-activation-step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 85px;
    cursor: pointer;

    .activation-step-selection {
      position: absolute;
      top: -8px;
      bottom: -0px;
      left: calc(-1*($selection-width - $step-width) / 2);
      width: $selection-width;
      border-radius: 8px;
      background-color: #F6F9FF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .activation-step-icon, .activation-step-status, .activation-step-label {
      z-index: 2;
    }
  }

  .activation-steps-line {
    position: absolute;
    top: calc($icon-height + 8px + 27px / 2);
    left: calc($container-padding + $icon-width / 2);
    right: calc($container-padding + $icon-width / 2);
    height: 1px;
    background-color: #000;
    z-index: 1;
  }
}
</style>