<template>
  <div class="dashboard-stat-card-container">
    <div class="dashboard-stat-card-icon-container">
      <slot name="icon"></slot>
    </div>
    <div class="dashboard-stat-card-value">{{ value }}</div>
    <div class="dashboard-stat-card-label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'DashboardStatCard',
  props: {
    label: {
      type: String,
      default: 'Info'
    },
    value: {
      type: [String, Number],
      default: ''
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard-stat-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 325px;
  height: 132px;

  padding: 16px 27px;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid #F9D7B3;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  background-color: white;
}

.dashboard-stat-card-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.dashboard-stat-card-value {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
  text-align: center;
  color: #111111;
}

.dashboard-stat-card-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  color: #666666;
}
</style>