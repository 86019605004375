<template>
  <div class="close-wrapper">
    <div
      v-if="verifyEmail"
      class="close-content">
      <h2>
        {{ message }}
      </h2>

      <div v-if="redirect && redirect.name" class="message-bubble">
        <div class="checkmark-wrapper">
          <iconComponent symbol="checkmark" fill="white" stroke="white" :width="40"/>
        </div>
        <div
          v-if="redirect && redirect.name"
          class="btn btn-activate"
          @click="redirectUser()">
          {{ transl.Login.continue }}
        </div>
      </div>
      <div
        v-else-if="success"
        class="message-bubble">
        <div class="checkmark-wrapper">
          <iconComponent symbol="checkmark" fill="white" stroke="white" :width="40"/>
        </div>
        {{ transl.Login.closetab }}
      </div>
      <div
        v-else
        class="message-bubble">
        <div class="cross-wrapper">
          <iconComponent symbol="cross" fill="white" stroke="white" :width="40"/>
        </div>
        <div>
          {{ transl.Login.closeverifyagain }}
        </div>
        <div>
          <b>{{ transl.Login.closelatestemail }}</b>
        </div>
      </div>
		</div>
	</div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeClose',
  mixins: [BeforeEnterMixin],
  data() {
    return {
      message: '',
      success: false,
      verifyEmail: false,
      redirect: {},
    };
  },
  mounted() {
    const {
      message, success,
      verifyEmail,
      redirect,
    } = this.$route.query;

    if (verifyEmail) {
      this.verifyEmail = true;
    }
    if (redirect && redirect !== '') {
      this.redirect = JSON.parse(redirect);
    }
    this.message = message;
    this.success = success === 'true' ? true : false;
    this.setPageTitle();
  },
  methods: {
    redirectUser() {
      this.$router.replace(this.redirect);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/HomeClose";
</style>
