<template>
  <div class="links-manager">
    <!-- <h1 v-for="goodiebag in goodiebags"></h1> -->
    <!-- <div>{{ entity.links_in }}</div> -->
    <div class="links-manager-header">
      <label style="font-weight: 700; padding-right: 16px;">Base URL:</label><label>{{ entityUrl }}</label>
      <div>
        <div v-if="!entity.links_done" @click="setFinished()" class="btn btn-activated">Set Finished</div>
        <div v-else class="finished-tag">Finished</div>
      </div>
    </div>

    <collapsable
      class="links-manager-create-links"
      :header="{bgColor: '#F9D7B3', textPosition: 'left'}"
      :content="{bgColor: 'white'}"
      :collapsed="false">

      <template v-slot:header>
        <div class="links-manager-inputs-title">
          <h3>Create trackable links</h3>
          <iconComponent symbol="info" class="info-icon" />
        </div>
      </template>

      <template v-slot:content>
        <div class="container-checkbox">
          <InputField inputType="checkbox" v-model="useShortLink"/>
          <label class="container-static-label">Generate short link</label>
        </div>
        <div class="links-manager-inputs-container">
          <div class="input-field-container">
            <InputField label="Source" inputType="select" :selectOptions="sources" v-model="sourceValue"/>
            <iconComponent symbol="info" class="info-icon" />
          </div>
          <div class="input-field-container">
            <InputField label="Medium" inputType="select" :selectOptions="mediums" v-model="mediumValue"/>
            <iconComponent symbol="info" class="info-icon" />
          </div>
          <div class="input-field-container">
            <InputField label="Campaign" id="utm_campaign" v-model="campaign"/>
            <iconComponent symbol="info" class="info-icon" />
          </div>
          <div class="input-field-container">
            <InputField label="Term" id="utm_term" v-model="term"/>
            <iconComponent symbol="info" class="info-icon" />
          </div>
          <div class="input-field-container">
            <InputField label="Content" id="utm_content" v-model="content"/>
            <iconComponent symbol="info" class="info-icon" />
          </div>

          <div></div><div></div><div></div>
          <div class="btn-generate-links">
            <div
              class="btn btn-activate"
              @click="generateLinks()">Generate</div>
          </div>
        </div>
        <div class="generated-links-textBlock">
          <div class="generated-links-title">
            <p>Generated links</p>
          </div>
          <div
            v-for="generatedLink in generatedLinks"
            :key="generatedLink.url"
            class="links-manager-inputs-container">
            <div class="input-field-container">
              <InputField label="Link name" id="utm_name" v-model="generatedLink.name"/>
              <iconComponent symbol="info" class="info-icon" />
            </div><div></div><div></div>
            <div class="input-field-container">
              <InputField label="Source" inputType="select" :selectOptions="sources" v-model="generatedLink.source"/>
              <iconComponent symbol="info" class="info-icon" />
            </div>
            <div class="input-field-container">
              <InputField label="Medium" inputType="select" :selectOptions="mediums" v-model="generatedLink.medium"/>
              <iconComponent symbol="info" class="info-icon" />
            </div>
            <div class="input-field-container">
              <InputField label="Campaign" id="utm_campaign" v-model="generatedLink.campaign"/>
              <iconComponent symbol="info" class="info-icon" />
            </div>
            <div class="input-field-container">
              <InputField label="Term" id="utm_term" v-model="generatedLink.term"/>
              <iconComponent symbol="info" class="info-icon" />
            </div>
            <div class="input-field-container">
              <InputField label="Content" id="utm_content" v-model="generatedLink.content"/>
              <iconComponent symbol="info" class="info-icon" />
            </div>
            <div class="a-line"></div>
          </div>
          <div v-if="!generatedLinks.length" class="generated-links-data">
            <p>There are no generated links yet.</p>
          </div>
        </div>
        <div class="btn-create-links">
          <div
            class="btn btn-activate"
            @click="addGeneratedLinks()">Create</div>
        </div>
      </template>

    </collapsable>

    <div class="links-manager-header links-manager-created-links">
      <h3>Created trackable links</h3>
      <div
           class="links-manager-created-link"
           v-for="link in entity.links_in" :key="link._id">
        <p class="created-link-title">{{link.name}}</p>
        <div class="created-link-data">
          <label class="created-link-data-title">Short link:</label>
          <label>{{ `${enginioIoServer}/l/${link.short_id}` }}</label>
          <!--<div class="btn-copy-link"
            v-clipboard:copy="`${enginioIoServer}/l/${link.short_id}`" v-clipboard:success="onCopy()" v-clipboard:error="onError()">
            Copy link
          </div>-->
        </div>
        <div class="created-link-data">
          <label class="created-link-data-title">URL:</label>
          <label>{{link.url}}</label>
          <!--<div class="btn-copy-link"
            v-clipboard:copy="link.url" v-clipboard:success="onCopy()" v-clipboard:error="onError()">
            Copy link
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '../InputField/InputField.vue';
import EventBus from '../../../resources/eventBus';
import { mapMutations } from 'vuex';
import iconComponent from '../IconComponent/IconComponent.vue';
import Collapsable from '../Collapsable/Collapsable.vue';

export default {
  name: 'LinksmanagerComponent',
  components: { InputField, iconComponent, Collapsable },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    EventBus.$emit('newAdmin', true);
  },
  computed: {
    entityUrl() {
      const firstPath =
        this.entityType == 'Company'
          ? 'e'
          : this.entityType == 'Goodiebag'
          ? 'c'
          : 'a';
      return `${this.windowOrigin}/${firstPath}/${this.getId(this.entity)}`;
    },
    getUser() {
      return this.admin ? 'Admin' : 'Company';
    },
  },
  data() {
    return {
      sources: ["facebook", "instagram", "youtube", "snapchat", "linkedin", "tiktok", "top signs", "stickers", "wobbler", "tearoff", "freezer", "local", "retailer", "partner", "redirect", "mailchimp", "newspaper", "bag", "card", "badge", "flyer", "rollup", "enginio", "package"],
      mediums: ["some", "email", "pos", "onpack", "web", "ads", "newsletter", "physical", "event", "qr-code", "other", "organic", "paid", "digitalpos", "online"],
      useShortLink: true,
      sourceValue: '',
      mediumValue: '',
      campaign: '',
      term: '',
      content: '',
      generatedLinks: [],
      enginioIoServer: process.env.NODE_ENV !== 'production'
        ? 'http://localhost(:enginioio-port)'
        : 'https://enginio.io',
    };
  },
  methods: {
    ...mapMutations({
      saveCompanyLinks: 'SAVE_COMPANY_LINKS',
      saveGoodienbagLinks: 'SAVE_GOODIEBAG_LINKS',
      saveOfferLinks: 'SAVE_OFFER_LINKS',
    }),
    generateLinks() {
      /*for (let i = 0; i < mediumValue.length; i++) {
        const medium = mediumValue[i];
      }*/
      const generatedLink = this.generateLink(false, this.sourceValue, this.mediumValue, this.campaign, this.term, this.content);
      if (generatedLink) {
        this.generatedLinks.push(generatedLink);
      }
    },
    generateLink(name, source, medium, campaign, term, content) {
      const aSource = this.getUrlFriendlyString(source);
      const aMedium = this.getUrlFriendlyString(medium);
      const aCampaign = this.getUrlFriendlyString(campaign);
      const aTerm = this.getUrlFriendlyString(term);
      const aContent = this.getUrlFriendlyString(content);
      let url = `${this.entityUrl}?utm_source=${aSource}&utm_medium=${aMedium}`;
      if (!aSource || !aMedium) {
        this.setAlert('You must have a source and medium');
        return;
      }
      if (aCampaign) {
        url = `${url}&utm_campaign=${aCampaign}`;
      }
      if (aTerm) {
        url = `${url}&utm_term=${aTerm}`;
      }
      if (aContent) {
        url = `${url}&utm_content=${aContent}`;
      }
      return {
        name: name ? name : `${aSource} ${aMedium}`,
        enginioEntityId: this.entity._id,
        enginioEntityType: this.entityType,
        host: this.windowOrigin,
        url,
        source: aSource,
        medium: aMedium,
        campaign: aCampaign,
        term: aTerm,
        content: aContent,
      };
    },
    async addGeneratedLinks() {
      for (let i = 0; i < this.generatedLinks.length; i++) {
        const { name, source, medium, campaign, term, content } = this.generatedLinks[i];
        this.generatedLinks[i] = this.generateLink(name, source, medium, campaign, term, content);
      }
      const linksIn = await this.$store.dispatch(`add${this.getUser}LinksIn`, {
        data: { links: this.generatedLinks },
      });
      if (this.entityType == 'Company') {
        this.saveCompanyLinks(linksIn);
      } else if (this.entityType == 'Goodiebag') {
        this.saveGoodienbagLinks(linksIn);
      } else if (this.entityType == 'Offer') {
        this.saveOfferLinks(linksIn);
      }
    },
    async changeLinkIn(link) {
      this.$store.dispatch(`change${this.getUser}LinksIn`, {
        entityId: this.entity._id,
        linkId: link._id,
        data: { link },
      });
    },
    async deleteLinkIn(linkId) {
      this.$store.dispatch(`delete${this.getUser}LinksIn`, {
        entityId: this.entity._id,
        linkId: linkId,
      });
    },
    setFinished() {
      this.$store.dispatch(`finish${this.getUser}${this.entityType}Links`, {
        entityId: this.entity._id,
      });
    },
    getUrlFriendlyString(str) {
      return encodeURIComponent(
        str
          .replace(/[^a-z0-9_]+/gi, '-')
          .replace(/^-|-$/g, '')
          .toLowerCase()
      );
    },
    onCopy() {
      this.setAlert(this.transl.App.copy);
    },
    onError() {
      this.setAlert(this.transl.App.nocopy);
    },
  },
};
</script>

<style lang="scss">
.links-manager-create-links {
  .collapsable-content {
    padding: 10px 50px;
  }
}
</style>

<style lang="scss" scoped>
@import './LinksManager.scss';
</style>