<template>
  <div class="steps-container">
    <template v-for="(label, index) in steps">
      <div class="step" :key="index">
        <button
          class="badge"
          :class="{
            'badge--selected': index === stepIndex,
            'badge--checked': index !== stepIndex && index < stepIndex
            }"
          @click="$emit('selectStep', index)"
          :disabled="typeof canSkipTo === 'number' && canSkipTo < index"
          >
          <div v-if="stepIndex && index < stepIndex">

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M3.33301 8.66602L5.99967 11.3327L12.6663 4.66602" stroke="#00B412" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
          <div v-else>
            {{ index + 1 }}
          </div>
        </button>
        <div class="label" :class="{ 'label--selected': index === stepIndex }">
          {{ label }}
        </div>
      </div>
      <hr
        v-if="index !== steps.length - 1"
        class="separator"
        :key="(index + 'separator')"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'WizardSteps',
  props: {
    stepIndex: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    canSkipTo: {
      type: Number,
    },
  },
}
</script>

<style lang="scss" scoped>
.steps-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    gap: 12px;

    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 16px;

      font-size: 14px;
      font-weight: 400px;
      letter-spacing: 0.014px;
      border: 0;
      background-color: #FFF;
      color: #666;
      cursor: pointer;
    }

    .badge:disabled {
      cursor: not-allowed;
    }

    .badge--selected {
      font-weight: 700;
      background-color: $brand-yellow;
      color: #FFF;
    }

    .badge--checked {
      border: 1px solid #00B412;
    }

    .label {
      font-size: 14px;
      font-weight: 400px;
      letter-spacing: 0.014px;
      color: #333;
    }

    .label--selected {
      font-weight: 700;
      color: $brand-yellow;
    }

  }
  .separator {
    width: 40px;
    border: 1px solid #FFF;
    margin: 15px 0;
  }
}
</style>
