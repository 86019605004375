<template>
  <div class="partner-table-container">
    <table>
      <thead>
        <tr>
          <th v-for="field in fields" :key="field.key" :class="{ 'users-styling' : customStyling }">
            <!--
              You can override the render of a table header cell, using the v-slot attribute key "header-<field key>", example:
                <template v-slot:header-name="{ field }">
                  <p>{{ field.label }}</p>
                </template>
            -->
            <slot :name="`header-${field.key}`" :field="field">
              <div class="table-header-item" :class="{ 'user-item-header-styling' : customStyling }">
                {{ field.label }}

                <div v-if="field.sort" @click="$emit('sort', { columnKey: field.key, order: field.sort === 'asc' ? 'desc' : 'asc' })">
                  <svg v-if="field.sort === 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                    <path d="M10.3237 7H5.67626C5.11302 7 4.79751 6.39719 5.14549 5.98592L7.46923 3.23956C7.73949 2.92015 8.26051 2.92015 8.53077 3.23956L10.8545 5.98592C11.2025 6.39719 10.887 7 10.3237 7Z" fill="#BABEC3"/>
                    <path d="M5.67626 9L10.3237 9C10.887 9 11.2025 9.60281 10.8545 10.0141L8.53077 12.7604C8.26051 13.0799 7.73949 13.0799 7.46923 12.7604L5.14549 10.0141C4.79751 9.60281 5.11302 9 5.67626 9Z" fill="#5C5F62"/>
                  </svg>
                  <svg v-else  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                    <path d="M10.3237 7H5.67626C5.11302 7 4.79751 6.39719 5.14549 5.98592L7.46923 3.23956C7.73949 2.92015 8.26051 2.92015 8.53077 3.23956L10.8545 5.98592C11.2025 6.39719 10.887 7 10.3237 7Z" fill="#5C5F62"/>
                    <path d="M5.67626 9L10.3237 9C10.887 9 11.2025 9.60281 10.8545 10.0141L8.53077 12.7604C8.26051 13.0799 7.73949 13.0799 7.46923 12.7604L5.14549 10.0141C4.79751 9.60281 5.11302 9 5.67626 9Z" fill="#BABEC3"/>
                  </svg>
                </div>
              </div>

              <div class="table-header-item-search">
                <input
                  type="search"
                  v-if="field.searchable"
                  v-model="field.searchText"
                  @input="$emit('filter', { columnKey: field.key, text: $event.target.value });"
                  placeholder="Search"
                />
              </div>
            </slot>
          </th>
        </tr>
      </thead>
      <tbody style="padding-left: 100px;">
        <tr
          v-for="(item, index) in tableData"
          :key="item?.id || typeof item !== 'object' ? index : `${index}-${item}`"
          :class="{
            'table-row--selected': selectedIndex === index,
            'table-row--clickable': !!item && hasClickListener
          }">
          <!-- Render an empty row if item is empty -->
          <template v-if="!item">
            <td :colspan="fields.length" :class="{ 'users-styling' : customStyling }">
              <div class="table-row-placeholder"></div>
            </td>
          </template>
          <template v-else>
            <td v-for="field in fields" :key="field.key" @click="handleClick(item)" :class="{ 'users-styling' : customStyling }">
              <!--
                You can override the render of a table row cell, using the v-slot attribute key "column-<field key>", example:
                <template v-slot:column-name="{ item }">
                  <p>{{ item.name }}</p>
                </template>
              -->
              <slot :name="`column-${field.key}`" :item="item" :field="field">
                {{ field.getter ? field.getter?.(item) : item[field.key] }}
                <button
                  v-if="field.copyable"
                  v-clipboard:copy="field.getter ? field.getter?.(item) : item[field.key]"
                  v-clipboard:success="() => setAlert('Copied to clipboard', 1)"
                  @click="(e) => e.stopPropagation()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M2.5 1C1.67578 1 1 1.67578 1 2.5V10.5C1 11.3242 1.67578 12 2.5 12H4V12.5C4 13.3242 4.67578 14 5.5 14H13.5C14.3242 14 15 13.3242 15 12.5V4.5C15 3.67578 14.3242 3 13.5 3H12V2.5C12 1.67578 11.3242 1 10.5 1H2.5ZM2.5 2H10.5C10.7812 2 11 2.21875 11 2.5V10.5C11 10.7812 10.7812 11 10.5 11H2.5C2.21875 11 2 10.7812 2 10.5V2.5C2 2.21875 2.21875 2 2.5 2ZM12 4H13.5C13.7812 4 14 4.21875 14 4.5V12.5C14 12.7812 13.7812 13 13.5 13H5.5C5.21875 13 5 12.7812 5 12.5V12H10.5C11.3242 12 12 11.3242 12 10.5V4Z" fill="#777777"/>
                  </svg>
                </button>
              </slot>
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <PartnerTablePagination v-if="pagination" v-bind="pagination" @paginate="$emit('paginate', $event)" />
  </div>
</template>

<script>
import PartnerTablePagination from './PartnerTablePagination.vue';

export default {
  name: 'PartnerTable',
  components: { PartnerTablePagination },
  props: {
    data: {
      type: Array,
      default: () => [],
      /** An array of objects */
    },
    fields: {
      type: Array,
      default: () => [],
      /* Example
        [{
            key: 'name',
            label: 'Name',
            copyable: true
            getter: (item) => item.name + ' ' + item.lastname,
            sort: 'asc', // make it sortable asc or desc,
            searchable: true,
        }]
       * */
    },
    minimumRows: {
      type: Number,
      /** Optional, will pad the table with empty rows to match the minimum */
    },
    pagination: {
      type: Object
      /* Optional, if not provided, pagination will not be rendered. Pagination is available as a standalone component. Example:
       {
          skip: 0,
          limit: 10,
          total: 100,
       }
      */
    },
    selectedIndex: {
      type: Number
    },
    customStyling: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasClickListener() {
      return !!this.$listeners.clickItem;
    },
    tableData() {
      if (typeof this.minimumRows !== 'number' || this.minimumRows === 0) {
        return this.data;
      }

      const paddedRows = Array.from({ length: this.minimumRows - this.data.length }, () => null);
      return [...this.data, ...paddedRows];
    },
  },
  methods: {
    handleClick(item) {
      if (!item) return;

      this.$emit('clickItem', item);
    },
  },
}
</script>

<style lang="scss" scoped>
$cell-padding: 0 16px;

.partner-table-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;

  table {
    border-collapse: collapse;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    button {
      border: 0;
      outline: 0;
      padding: 0;
      background-color: transparent;
      margin-left: 4px;
    }

    img {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 2px;
      border: 1px solid #C9CCCF;
    }
  }

  th {
    height: 36px;
    padding: $cell-padding;
    vertical-align: middle;
    border-top: 1px solid #E1E3E5;
    border-bottom: 1px solid #E1E3E5;
    background-color: #FAFBFB;

    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #6D7175;

    &.users-styling {
      padding: 0 8px;
      border-top: none;
    }

    .table-header-item {
      display: flex;
      align-items: center;
      gap: 8px;

      &.user-item-header-styling {
        padding: 10px 0 10px 8px;
      }

      svg {
        cursor: pointer;
      }
    }

    .table-header-item-search {

      padding: 0 0 8px 0;
      width: 100%;
    }
  }

  tr {
    background-color: #FFFFFF;
  }

  tr:nth-child(odd) {
    background-color: #F2F2F2;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #E1E3E5;
  }

  tr.table-row--selected {
    background-color: #EDF3FE;
  }

  tr.table-row--clickable {
    cursor: pointer;
  }

  td {
    height: 56px;
    padding: $cell-padding;
  }

  th, td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px; // Consider adding a prop to override this
  }

  th:first-child, td:first-child {
    &.users-styling {
      padding-left: 40px;
    }
  }

  th:last-child, td:last-child {
    &.users-styling {
      padding-right: 40px;
    }
  }

  input, .input {
    min-height: 24px;
    width: 100%;
    padding: 2px 12px;
    border-radius: 8px;
    border: 1px solid #999;
    outline: 0;

    font-size: 12px;
    font-weight: 400;
    line-height: 22.4px;
    color: #111;

    ::placeholder {
      color: #999;
      border: 1px solid #999;
    }
  }
}
</style>
