<template>
  <div class="collection-view-container" :class="{ 'collection-view-container-scroll': this.scrolling }">
    <div
      v-if="collection && collection._id && !collection.company.signup"
      :class="{ 'iris': collection.theme !== 'nemo', 'nemo': collection.theme === 'nemo'}">
      <div
        :class="{
          'collection-view': !preview,
          'collection-view-contained': preview,
          'company-page': isCompany,
        }"
        :style="theBackgroundStyle">
        <div
          class="header-image"
          :class="{'blurry': !collection.company.disable_blurry_header}"
          :style="theHeaderStyle">
        </div>
        <div class="collection-content-wrapper content-page">
          <div
            v-if="!isCompany && !collection.replace_company"
            class="collection-left-button"
            @click="$router.go(-1)">
            <div class="collection-back-button">
              <iconComponent symbol="arrowLeft" fill="black" stroke="black" :strokeWidth="2"/>
            </div>
          </div>
          <div
            v-if="authenticated && collection.company.club"
            class="collection-club-button"
            @click="gotoClubProfile()">
            <div class="collection-club-icon">
              <iconComponent symbol="person" fill="white" stroke="white"/>
            </div>
            <!--<div class="collection-club-name">{{ user.name }}</div>-->
          </div>
          <img
            v-if="theIcon"
            class="header-logo"
            :class="{
              'clickable': !isCompany
            }"
            :src="theIcon"
            @click="gotoCompany(collection)"/>
          <slot v-if="showHeader === false" name="headerTitle"></slot>
          <h2 class="collection-header" v-if="showHeader">{{ collection.name }}</h2>
          <div v-if="showHeader" class="header-info body-1">{{ collection.info }}</div>
          <div class="no-content-header" v-if="!currentOffers.length">
            <div class="no-content-header-our-activations" v-if="collection.theme === 'iris'">
              <iconComponent symbol="rocket"/>
              {{ transl.Goodiebag.ouractivations }}
            </div>
            <div class="no-content-header-soon">{{ transl.Company.soon }}</div>
          </div>

          <slot v-if="$slots.content" name="content"></slot>

          <CardWrapper
            v-if="!$slots.content && currentOffers && currentOffers.length"
            :data="currentOffers"
            :theme="collection.theme"
            :secondary-data="closedOffers"
            :start-countdown="startCountdown"
            :preview="preview"
            @cardClick="gotoCurrentEntity($event)"
          />
        </div>
      </div>

      <integrityBox
        v-if="collection.company.legal_name && showIntegrity"
        badgeType="Integrity_badge_Medium"
        textData="Har du frågor om GDPR till varumärket? Se kontaktinformation nedan."
        :expandable="true"
        :labelList="[
          collection.company.legal_name,
          collection.company.email,
          collection.company.org_number,
          collection.company.address + ', ' + collection.company.postal_code + ', ' + collection.company.city
        ]"
        :iconList="['building', 'email', 'paper', 'mapPin']" />
    </div>

    <div v-if="collection.signupView || collection.startView">
      <SignupView :data="collection"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardWrapper from '../CardWrapper/CardWrapper.vue';
import IntegrityBox from '../IntegrityBox/IntegrityBox.vue';
import SignupView from "@/stories/components/CollectionView/SignupView.vue";

export default {
  name: 'CollectionView',
  components: { CardWrapper, IntegrityBox, SignupView },
  props: {
    collection: {
      type: Object,
      default: () => ({})
    },
    authenticated: {
      type: Boolean,
      default: false
    },
    showIntegrity: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    scrolling: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["user"]), // do we want to use vuex in a component?
    theBackgroundStyle() {
      return this.collection.theme === 'nemo'
        && this.collection.media ? `background-image: url(${this.collection.media.url});`: ''
    },
    theHeaderStyle () {
      return `background-image: url(${this.collection.theme !== 'nemo' && this.collection.media ? this.collection.media.url : ''});`;
        // margin-bottom: ${this.theIcon ? '2rem;' : '0;'}`;
    },
    theIcon() {
      return this.collection.icon?.url || this.collection.company.icon?.url;
    },
    isCompany() {
      return this.collection.model_type == 'Company';
    },
    currentOffers() {
      if (!this.collection?._id) {
        return []
      }

      const isVisitedFeature = this.authenticated && this.user && this.user.interactions ? true : false;
      const interactionsForThisGoodiebag = isVisitedFeature ? this.user.interactions.filter(
        (interaction) => interaction.goodiebag === this.collection._id
      ) : [];

      const offers = this.collection.offers?.filter(offer => {
        if (offer.closed) {
          return;
        }
        const isVisitedOfferFeature = isVisitedFeature && offer.points_visited ? true : false;
        offer.isVisited = isVisitedOfferFeature ? interactionsForThisGoodiebag.some(item => item.offer === offer._id) : false;
        offer.brand_color = this.collection?.company?.brand_400_color || 'white';
        return offer;
      }) || [];

      const subcollections = (this.collection?.childGoodiebags || this.collection?.goodiebags)?.map(subcollection => {
        if (subcollection.banner) {
          const bannerOffers = [...(subcollection.offers || []), ...(subcollection.banner_offers || [])];
          let feMedia = bannerOffers?.map(offer => {
            const m = offer.banner || offer.thumbnail || offer.media;
            return {
              file: m.file,
              fileType: m.fileType,
              url: m.url,
              _id: offer._id,
              name: offer.banner_subheader || subcollection.name,
              unique_name: offer.unique_name,
              quick_text: offer.banner_header || offer.first_header,
              button_text: offer.banner_button_text,
            }
          });
          return {
            unique_name: subcollection.unique_name,
            media: feMedia,
            columns: subcollection.columns || 3,
            carousel: true,
            clickable: true,
            first_header: subcollection.name,
            quick_text: subcollection.hide_subtitle ? '' : this.customQuickText(subcollection),
            direct_link: subcollection.direct_link,
            order: subcollection.order,
            unlocked: subcollection.unlocked,
            brand_color: this.collection?.company?.brand_400_color || 'white',
            _id: subcollection._id,
          };
        } else {
          return {
            unique_name: subcollection.unique_name,
            thumbnail: subcollection.thumbnail || {},
            media: [subcollection.media || {}],
            columns: subcollection.columns || 2,
            clickable: true,
            first_header: subcollection.name,
            inlineCollection: true,
            quick_text: subcollection.hide_subtitle ? '' : this.customQuickText(subcollection),
            direct_link: subcollection.direct_link,
            order: subcollection.order,
            unlocked: subcollection.unlocked,
            brand_color: this.collection?.company?.brand_400_color || 'white',
            _id: subcollection._id,
          }
        }
      }) || [];

      if (this.collection.donation_media?.url) {
        offers.unshift({
          media: [this.collection.donation_media],
          clickable: false,
          columns: 2,
          donation: true,
          htmlText: `
            <div style="position: absolute; top: 50%; left: 20px; transform: translateY(-50%); font-weight: 600; text-align: left; font-size: 18px;">
              <div>${this.transl.Goodiebag.donationstatus}</div>
              <div style="font-size: 30px; line-height: 30px; padding: 1rem 0; color: white; text-shadow: 2px 2px 4px black;">
                ${this.collection.donation_amount ? this.collection.donation_amount + 1000 : 1000}
              </div>
              <div>${this.collection.company.name} ${this.transl.Goodiebag.products}</div>
            </div>
          `,
        });
      }

      const infinity = 8.64e15;
      return offers.concat(subcollections).sort((a, b) => {
        return (b.isVisited ? -infinity : (b.order ?? 0)) - (a.isVisited ? -infinity : (a.order ?? 0));
      });

      // Old sort
      // offers.sort(function (a, b) {
      //   if (!a.order) {
      //     a.order = 0;
      //   } else if (!b.order) {
      //     b.order = 0;
      //   }
      //   return b.order - a.order;
      // });
    },
    closedOffers() {
      return this.collection.offers?.filter(offer => offer.closed) || [];
    },
    userType() {
      return !this.authenticated ? 'Home' : 'User';
    },
    companyRoute() {
      return `${this.userType}Company`;
    },
    collectionRoute() {
      return `${this.userType}Goodiebag`;
    },
    offerRoute() {
      return `${this.userType}Offer`;
    },
  },
  data() {
    return {
      startCountdown: false
    }
  },
  methods: {
    customQuickText(collection) {
      let quickText = '';
      const hasOffers = collection.offers?.length > 0;
      const hasGoodiebags = collection.childGoodiebags?.length > 0;
      if (hasGoodiebags) {
        quickText += `${collection.childGoodiebags.length} ${this.transl.Company.campaigns}`;
        if (hasOffers) {
          quickText += ', ';
        }
      }
      if (hasOffers) {
        quickText += `${collection.offers.length} ${this.transl.Company.activations}`;
      }
      return quickText;
    },
    gotoCurrentEntity(entity) {
      if (entity.direct_link) {
        const url = entity.direct_link;
        this.pushDataLayer({
          parentCompany: entity.company ? entity.company.parentCompany : 'Unknown',
          company: entity.company,
          goodiebag: entity.goodiebag,
          offer: entity,
          action: `Activate link ${url}`
        });
        this.openUrl(url);
      } else if (entity.entityType == 'company') {
        this.$router.push({ name: this.companyRoute, params: { companyId: this.getId(entity) } });
      } else if (entity.entityType == 'goodiebag') {
        this.$router.push({ name: this.collectionRoute, params: { goodiebagId: this.getId(entity) } });
      } else {
        this.$router.push({ name: this.offerRoute, params: { offerId: this.getId(entity) } });
      }
    },
    gotoParent(collection) {
      if (collection.parentGoodiebag && collection.parentGoodiebag._id) {
        this.$router.replace({ name: this.collectionRoute, params: { goodiebagId: this.getId(collection.parentGoodiebag) }});
      } else {
        this.$router.replace({ name: this.companyRoute, params: { companyId: this.getId(collection.company) }});
      }
    },
    gotoCompany(collection) {
      this.$router.replace({ name: this.companyRoute, params: { companyId: this.getId(collection.company) }});
    },
    gotoClubProfile() {
      this.$router.push({ name: 'UserClubProfile', params: { companyId: this.getId(this.collection.company) }})
    }
  },
}
</script>

<style lang="scss">
.collection-view-container {
  .integrity-box {
    max-width: 1152px;
    margin: auto;
  }
}
</style>

<style lang="scss" scoped>
@import "./CollectionThemeIris";
@import "./CollectionThemeNemo";

.collection-view-container {
  height: 100%;
  container-type: inline-size;
}
.collection-view-container-scroll {
  overflow-y: auto;
}
</style>
