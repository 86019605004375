<template>
  <PartnerModal
    :show="show"
    :showCloseButton="showCloseButton"
    @close="closeModal"
    @disappeared="$emit('disappeared')"
  >
    <div class="wizard-header">
      <h1 class="heading">
        {{ heading }}
      </h1>
      <WizardSteps
        :stepIndex="this.currentIndex"
        :steps="this.steps"
        @selectStep="setIndex"
        :canSkipTo="this.canSkipTo"
      />
    </div>
    <div class="wizard-content">
      <slot></slot>
    </div>
    <div class="wizard-footer">
      <div>
        <button v-if="this.currentIndex >= 1" @click="goBack" class="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M12.6471 2.88775C12.572 2.70849 12.3955 2.5936 12.2 2.60027C12.0764 2.60258 11.9605 2.65428 11.876 2.7418L5.45192 9.1659C5.26785 9.34996 5.26785 9.64823 5.45192 9.8323L11.8759 16.2563C11.9937 16.3786 12.1695 16.4282 12.3337 16.3843C12.499 16.3416 12.6277 16.2129 12.6703 16.0476C12.7142 15.8835 12.6646 15.7077 12.5423 15.5899L6.45153 9.4991L12.5428 3.40783C12.6821 3.27155 12.7222 3.06557 12.6471 2.88775Z" fill="#333333" stroke="#333333" stroke-width="0.3"/>
          </svg>
          Back
        </button>
      </div>
      <button
        class="button-solid --next"
        @click="proceed"
        :disabled="this.currentIndex >= this.canSkipTo"
      >
        {{ this.currentIndex >= steps.length - 1 ? this.finishLabel : 'Next' }}
      </button>
    </div>
  </PartnerModal>
</template>

<script>
import PartnerModal from '../PartnerModal/PartnerModal.vue';
import WizardSteps from '../CampaignWizard/WizardSteps.vue';

export default {
  name: "WizardModal",
  components: {
    PartnerModal,
    WizardSteps,
  },
  props: {
    canSkipTo: {
      type: Number,
    },
    finishLabel: {
      type: String,
      default: 'Create'
    },
    heading: {
      type: String,
      default: 'Wizard'
    },
    index: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    steps: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      internalIndex: 0,
    }
  },
  watch: {
    index(newIndex) {
      if (typeof newIndex === 'number') {
        this.internalIndex = newIndex;
      }
    }
  },
  computed: {
    currentIndex() {
      return this.index ?? this.internalIndex;
    }
  },
  methods: {
    closeModal() {
      if (typeof this.index !== 'number') {
        this.internalIndex = 0;
      }
      this.$emit('close');
    },
    proceed() {
      if (this.currentIndex >= this.steps.length - 1) {
        this.$emit('finish');
      } else {
        if (typeof this.index === 'number') {
          this.$emit('changeIndex', this.index + 1);
        } else {
          this.internalIndex++;
        }
      }
    },
    goBack() {
      if (this.currentIndex > 0) {
        if (typeof this.index === 'number') {
          this.$emit('changeIndex', this.index - 1);
        } else {
          this.internalIndex--;
        }
      }
    },
    setIndex(newIndex) {
      if (typeof this.index === 'number') {
        this.$emit('changeIndex', newIndex);
      } else {
        this.internalIndex = newIndex;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wizard-header {
    display: flex;
    flex-direction: column;
    height: 128px;
    padding: 16px 40px;
    background-color: #F2F2F2;

    .heading {
      display: inline-flex;

      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
      text-align: left;
      color: #000000;
      margin-bottom: 8px;

      button {
        position: absolute;
        right: 43px;
      }
    }
  }

  .wizard-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden auto;
  }

  .wizard-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    padding: 24px 40px;
    background-color: #F2F2F2;

    .--next {
      min-width: 148px;
    }
  }

  .image-fullscreen-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 92px 55px;
    background-color: #FFF;

    .image-fullscreen {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    button {
      position: absolute;
      top: 33px;
      right: 43px;
    }
  }
</style>