<template>
  <div
    v-if="(mediaArray && mediaArray.length) || (media && (media.length || media.url)) || (thumbnail && thumbnail.url)"
    :class="classes"
    :style="cssProps"
    @click="(clickable || collection) && !carousel ? $emit('cardClick') : ()=>{}">
    <div :class="classes">
      <div class="offer-store-links" v-if="appstore && gplay">
        <a
          v-if="isBrowser || isIOS"
          class="download-appstore"
          @click="openNewTab(appstore)">
          <img
            alt="Ladda ned på Appstore"
            src="../../assets/appstore.png"
            class="store-links-img"/>
        </a>
        <a
          v-if="isBrowser || isAndroid"
          class="download-appstore"
          @click="openNewTab(gplay)">
          <img
            alt="Ladda ned på Google Play"
            src="../../assets/googleplay.png"
            class="store-links-img"/>
        </a>
      </div>

      <aCarousel
        v-if="carousel"
        :media-list="media"
        :single-image="true"
        :single-ratio="carouselRatio"
        :single-bullets="true"
        @slideClick="$emit('cardClick', $event)"/>
      <div
        v-else-if="mediaArray && !collection && media[0].fileType.includes('image') && appstore && gplay"
        class="offer-appstore-pic">
      <img :src="mediaArray ? media[0].url : ''" alt="">
      <div v-if="isLocked" class="overlay-icon-box"></div>
      <div v-if="isLocked" class="overlay-icon-locked">
        <iconComponent class="show-mobile" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
        <iconComponent class="show-tablet-and-desktop" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
      </div>
      </div>

      <div
        v-else-if="thumbnailImg"
        class="offer-pic">
        <img :src="thumbnailImg" alt="">
      <div v-if="isLocked" class="overlay-icon-box"></div>
      <div v-if="isLocked" class="overlay-icon-locked">
        <iconComponent class="show-mobile" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
        <iconComponent class="show-tablet-and-desktop" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
      </div>
      </div>

      <div
        v-else-if="mediaArray && hide_text_box && !collection && media[0].fileType.includes('image') && !appstore && !gplay"
        class="offer-pic">
      <img :src="mediaArray ? media[0].url : ''" alt="">
      <div v-if="isLocked" class="overlay-icon-box"></div>
      <div v-if="isLocked" class="overlay-icon-locked">
        <iconComponent class="show-mobile" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
        <iconComponent class="show-tablet-and-desktop" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
      </div>
      </div>
      <div
        v-else-if="mediaArray && !collection && media[0].fileType.includes('image') && !appstore && !gplay"
        class="offer-pic">
      <img :src="mediaArray ? media[0].url : ''" alt="">
      <div v-if="isLocked" class="overlay-icon-box"></div>
      <div v-if="isLocked" class="overlay-icon-locked">
        <iconComponent class="show-mobile" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
        <iconComponent class="show-tablet-and-desktop" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
      </div>
      </div>

      <div
        v-else-if="!mediaArray && !collection && media && media.fileType && media.fileType.includes('image')"
        class="offer-pic"
      :style="`background-image: url(${media.url}); height: 100%`">
      <div v-if="isLocked" class="overlay-icon-box"></div>
      <div v-if="isLocked" class="overlay-icon-locked">
        <iconComponent class="show-mobile" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
        <iconComponent class="show-tablet-and-desktop" symbol="icons8Lock" width="35%" :stroke-width="0" fill="#FFFFFF" />
      </div>
      </div>

      <video
        v-else-if="mediaArray && media[0].fileType.includes('video')"
        class="news-autoplay autoplayMedia"
        :style="video"
        :controls="false"
        muted
        playsinline>
        <source
        :src="`${media[0].url}`"
          :type="media[0].fileType"/>
        {{ transl.Goodiebag.novideo }}
      </video>

    <img v-if="icon && icon.url && unique_icon" :src="`${icon.url}`" />
      <div v-if="tag" class="card-tag">
        <md-chip class="card-chip">
          {{ tag }}
        </md-chip>
      </div>

    <div v-if="isVisited && !isLocked">
      <div class="show-mobile overlay-icon-checkbox" :style="`background: ${brandColor}`">
        <iconComponent
          symbol="checkbox"
          width="30px"
          stroke="#fff"
          fill="#fff"/>
      </div>
      <div class="show-tablet-and-desktop overlay-icon-checkbox" :style="`background: ${brandColor}`">
        <iconComponent
          symbol="checkbox"
          width="40px"
          stroke="#fff"
          fill="#fff"/>
      </div>
    </div>

      <div v-if="htmlText !== ''" v-html="htmlText" class="offer-html-text"></div>
      <div v-else-if="!hide_text_box && clickable && !carousel" class="offer-box-text">
        <div
          v-if="!(theme === 'nemo' && (tag == this.tags.game || tag == this.tags.contest))"
          class="offer-box-name body-1-bold">{{ first_header }}</div>

        <div v-if="variations" class="offer-box-info body-2 variations">
          <iconComponent symbol="style"/>
          <span>{{ transl.Goodiebag.variants }}</span>
        </div>

        <div v-else-if="save_text" class="offer-box-info body-2">
          <iconComponent symbol="newReleases"/>
          <span class="md-16-icon">{{ save_text }}</span>
        </div>

        <div v-else-if="!(theme === 'nemo' && (tag == this.tags.game || tag == this.tags.contest)) && !expiration_date[index] && !expiration_time[index]" class="offer-box-info body-2">
          <span style="padding: 0">{{ quick_text }}</span>
        </div>

        <div v-if="expiration_time[index] && !expiration_date[index]" :class="expiration_time[index] ? 'offer-date-text' : 'offer-date-text hidden'">
          <iconComponent symbol="timer"/>
          <span>{{ expiration_time[index] }}</span>
        </div>

        <div v-else :class="expiration_date[index] ? 'offer-date-text' : 'offer-date-text hidden'">
        <iconComponent symbol="calendarToday" :width="20"/>
          <span>{{ expiration_date[index] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile, isBrowser, isIOS, isAndroid } from "mobile-device-detect";
import iconComponent from '../IconComponent/IconComponent.vue';
import aCarousel from '../../../components/ACarousel.vue';

export default {
  name: "CardComponent",
  components: {
    iconComponent,
    aCarousel,
  },
  props: {
    tag: {
      type: String,
      default: "",
    },
    thumbnail: {
      type: Object,
      default: () => ({ file: "", fileType: "", url: "" }),
      validator: (thumbnail) => [].every((key) => key in thumbnail),
    },
    media: {
      type: [Array, Object],
      default: () => [{ file: "", fileType: "", url: "" }],
    },
    carousel: {
      type: Boolean,
      default: false,
    },
    carouselRatio: {
      type: Number,
      default: 1,
    },
    collection: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    inlineCollection: {
      type: Boolean,
      default: false,
    },
    appstore: {
      type: String,
      default: "",
    },
    gplay: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      default: () => ({ file: "", fileType: "", url: "" }),
    },
    unique_icon: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 1,
    },
    first_header: {
      type: String,
      default: "",
    },
    second_header: {
      type: String,
      default: "",
    },
    save_text: {
      type: String,
      default: "",
    },
    quick_text: {
      type: String,
      default: "",
    },
    variations: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    expiration_time: {
      type: Array,
      default: () => [{ "": "" }],
    },
    expiration_date: {
      type: Array,
      default: () => [{ "": "" }],
    },
    closed_offer: {
      type: Boolean,
      default: false,
    },
    jwt_lock: {
      type: Boolean,
      default: false,
    },
    unlocked: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'iris',
    },
    htmlText: {
      type: String,
      default: "",
    },
    hide_text_box: {
      type: Boolean,
      default: false,
    },
    donation: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    isVisited: {
      type: Boolean,
      default: false,
    },
    brandColor: {
      type: String,
      default: '',
    },
    sliderHeightMobile: {
      type: String,
      default: 'auto',
    },
    sliderHeightDesktop: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
      isIOS,
      isAndroid,
    };
  },
  mounted() {},
  computed: {
    classes() {
      return {
        // "iris": this.theme == 'iris' || !this.theme,
        // "nemo": this.theme == 'nemo',
        // "offer-container": !this.preview,
        // "offer-container-contained": this.preview,
        "offer-box": true,
        "offer-inline-collection": this.inlineCollection,
        "offer-box-goodiebag-collection": this.collection,
        "offer-box-spel": this.tag == this.tags.game,
        "offer-box-tavling": this.tag == this.tags.contest,
        "offer-closed-box": this.closed_offer,
        "no-text-box": this.hide_text_box,
        [`offer-box--column-${this.columns}`]: this.columns,
        "offer-box--not-clickable": !this.clickable,
        "offer-full-height-pic": this.donation,
      };
    },
    cssProps() {
      return {
        '--parallax-mobile-height': this.sliderHeightMobile,
        '--parallax-desktop-height': this.sliderHeightDesktop,
      };
    },
    thumbnailImg() {
      if (this.thumbnail && this.thumbnail.fileType && this.thumbnail.fileType.includes('image')) {
        return this.thumbnail.url;
      }
      return false;
    },
    video() {
      if (!this.hide_text_box) {
        return {
          "border-radius": "6px 6px 0 0;",
        };
      } else {
        return {
          "border-radius": "6px;",
        };
      }
    },
    mediaArray() {
      if (Array.isArray(this.media) && this.media[0] && this.media[0].url) {
        return true;
      } else {
        return false;
      }
    },
    image() {
      return {
        "background-image": `url(${this.media[0].url});`,
      };
    },
    tags() {
      if (this.transl && this.transl.tags) {
        return this.transl.tags;
      } else {
        return {};
      }
    },
    isLocked() {
      return this.jwt_lock && !this.unlocked;
    }
  },
  methods: {
    openNewTab(url) {
      this.openUrl(url, true);
    },
  },
};
</script>

<style lang="scss">
.offer-box {
  .vueperslides{
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.15));
    .vueperslides__parallax-wrapper {
      height: var(--parallax-desktop-height);
      border-radius: 10px;

      @media (max-width: $small-screen-maxwidth) {
        height: var(--parallax-mobile-height);
      }
    }
  }
}

.overlay-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.overlay-icon-box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, .8);
  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background-color: #27262654;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}

.overlay-icon-locked {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
}

.overlay-icon-checkbox {
  position: absolute;
  border-radius: 50%;
  padding: 5px;
  top: 10px;
  right: 10px;
  margin: 0;
}
</style>

<style lang="scss" scoped>
// .iris, .iris-contained {
  @import "./Card.scss";
// }
// .nemo, .nemo-contained {
  @import "./CardNemoTheme.scss";
// }
</style>
