<template>
  <div class="activation-config-container">
    <div class="tabs">
      <button class="button-tab with-margin-left" :class="{'selected' : activeTab === 1}" @click="activeTab = 1">Content</button>
      <button class="button-tab" :class="{'selected' : activeTab === 2}" @click="activeTab = 2">Uploads</button>
      <button class="button-tab" :class="{'selected' : activeTab === 3}" @click="activeTab = 3">Advanced settings</button>
    </div>

    <div class="tab-content-container">
      <div class="form" v-if="activeTab === 1">

        <div class="input-container">
          <p class="label">Unique name</p>
          <input type="text" v-model="updatedActivation.unique_name">
        </div>

        <div class="input-container">
          <p class="label">Quick text</p>
          <input type="text" v-model="updatedActivation.quick_text">
          <p class="helper-text">50 characters</p>
        </div>

        <div class="input-container">
          <p class="label">Title</p>
          <input type="text" v-model="updatedActivation.first_header">
          <p class="helper-text">50 characters</p>
        </div>

        <div class="input-container">
          <p class="label">Description</p>
          <CKEditor v-model="updatedActivation.second_header" :config="editorConfig">
          </CKEditor>
          <p class="helper-text">500 characters</p>
        </div>

        <div class="input-container">
          <p class="label">Button title</p>
          <input type="text" v-model="updatedActivation.link_button_title">
        </div>
      </div>

      <div class="form" v-if="activeTab === 2">

        <div class="image-slider">
          <span v-html="infoIcon" />
          <InputField inputType="toggle"
                      :label="'Image slider'"
                      :value="updatedActivation.media_image_slider"
                      @inputClick="updatedActivation.media_image_slider = !updatedActivation.media_image_slider"/>
        </div>

        <ImageSelect v-if="!updatedActivation.media_image_slider" :heading="'Header image'"
                      :selected-image-file="updatedActivation.media[0]"
                      :aspect-ratio="'16:9'"
                      :key="'ContentTab'"
                      @imageSelected="setImage($event)"
                      @imageDeleted="updatedActivation.media = []"
                      class="image" />

        <ImageSlider v-else :heading="'Header images'" :value="updatedActivation.media" @input="setMedia($event)" />

        <div class="images-container">
            <ImageSelect  :heading="'Thumbnail'"
                          :selected-image-file="updatedActivation.thumbnail"
                          :aspect-ratio="'16:9'"
                          :height=300
                          :width=273
                          @imageSelected="updatedActivation.thumbnail = $event"
                          @imageDeleted="updatedActivation.thumbnail = {}"
                          class="image" />

            <ImageSelect  :heading="'Unique icon'"
                          :selected-image-file="updatedActivation.icon"
                          :show-all-image-restrictions=false
                          :aspect-ratio="'1:1'"
                          :height=300
                          :width=273
                          @imageSelected="updatedActivation.icon = $event"
                          @imageDeleted="updatedActivation.icon = null"
                          class="image" />
        </div>

        <div class="banner-container">
          <ImageSelect  :heading="'Banner'"
                        :selected-image-file="updatedActivation.banner"
                        :aspect-ratio="'16:9'"
                        :width=550
                        @imageSelected="updatedActivation.banner = $event"
                        @imageDeleted="updatedActivation.banner = {}"
                        class="image" />

          <div class="input-container">
            <p class="label">Banner title</p>
            <input type="text" v-model="updatedActivation.banner_header">
            <p class="helper-text">60 characters</p>
          </div>

          <div class="input-container">
            <p class="label">Banner button text</p>
            <input type="text" v-model="updatedActivation.banner_button_text">
            <p class="helper-text">10 characters</p>
          </div>
        </div>
      </div>

      <div class="form" v-if="activeTab === 3">

        <div class="input-container">
          <p class="label">Collection cards colums wide</p>
          <select class="select" :value="updatedActivation.columns" @change="updatedActivation.columns = $event.target.value">
            <option v-for="column in [1,2,3]" :key="column" :value="column">
              {{ column }}
            </option>
          </select>
        </div>

        <div class="input-container">
          <p class="label">Collection cards order</p>
          <input type="text" v-model="updatedActivation.order">
        </div>

        <div class="input-container">
          <p class="label">Offer tag on collection cards</p>
          <select class="select" :value="updatedActivation.offer_tag" @change="updatedActivation.offer_tag = $event.target.value">
            <option v-for="tag in tags" :key="tag" :value="tag">
              {{ tag }}
            </option>
          </select>
        </div>

        <div class="input-container">
          <p class="label">Expiration</p>
          <input type="text" v-model="updatedActivation.expiration">
        </div>

        <InputField inputType="checkbox" :label="'Hide text on collection cards'" v-model="updatedActivation.hide_text_box"/>
        <InputField inputType="checkbox" :label="'Ability to share'" v-model="updatedActivation.share"/>
        <InputField inputType="checkbox" :label="'Clickable and hide text'" v-model="updatedActivation.clickable"/>
        <InputField inputType="checkbox" :label="'Login requirements for CTA'" v-model="updatedActivation.must_login"/>

      </div>
    </div>

  </div>
</template>

<script>

import ImageSelect from "@/stories/components/ImageSelect/ImageSelect.vue";
import InputField from "@/stories/components/InputField/InputField.vue";
import svgMixin from "@/mixins/SvgMixin.vue";
import ImageSlider from "@/stories/components/ImageSlider/ImageSlider.vue";
import CKEditor from '../CKEditor/CKEditor.vue';

export default {
  mixins: [svgMixin],
  components: {
    InputField,
    ImageSelect,
    ImageSlider,
    CKEditor,
  },
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.quick_text'(value) {
      this.emitChanges('quick_text', value);
    },
    'updatedActivation.first_header'(value) {
      this.emitChanges('first_header', value);
    },
    'updatedActivation.second_header'(newValue, oldValue) {
      if (oldValue === this.activation.second_header)
        return // the property was initalized no changes are made yet
      this.emitChanges('second_header', newValue);
    },
    'updatedActivation.link_button_title'(value) {
      this.emitChanges('link_button_title', value);
    },
    'updatedActivation.media': {
      handler(newValue, oldValue) {
        if (newValue === oldValue || oldValue === undefined) {
          return
        }

        this.emitChanges('media', newValue);
      },
      immediate: false,
      deep: true,
    },
    'updatedActivation.thumbnail'(newValue, oldValue) {
      if (newValue === oldValue || oldValue === undefined) {
        return
      }
      this.emitChanges('thumbnail', newValue);
    },
    'updatedActivation.banner'(newValue, oldValue) {
      if (newValue === oldValue || oldValue === undefined) {
        return
      }
      this.emitChanges('banner', newValue);
    },
    'updatedActivation.icon'(newValue, oldValue) {
      if (newValue === oldValue || oldValue === undefined) {
        return
      }
      this.emitChanges('icon', newValue);

      //also set the flag for the unique icon
      this.emitChanges('unique_icon', newValue !== null);
    },
    'updatedActivation.banner_header'(value) {
      this.emitChanges('banner_header', value);
    },
    'updatedActivation.banner_button_text'(value) {
      this.emitChanges('banner_button_text', value);
    },
    'updatedActivation.unique_icon'(value) {
      this.emitChanges('unique_icon', value);
    },
    'updatedActivation.unique_name'(value) {
      this.emitChanges('unique_name', value);
    },
    'updatedActivation.columns'(value) {
      this.emitChanges('columns', value);
    },
    'updatedActivation.order'(value) {
      this.emitChanges('order', value);
    },
    'updatedActivation.offer_tag'(value) {
      this.emitChanges('offer_tag', value);
    },
    'updatedActivation.expiration'(value) {
      this.emitChanges('expiration', value);
    },
    'updatedActivation.hide_text_box'(value) {
      this.emitChanges('hide_text_box', value);
    },
    'updatedActivation.share'(value) {
      this.emitChanges('share', value);
    },
    'updatedActivation.clickable'(value) {
      this.emitChanges('clickable', value);
    },
    'updatedActivation.must_login'(value) {
      this.emitChanges('must_login', value);
    },
    'updatedActivation.media_image_slider'(newValue, oldValue) {
      if (oldValue === undefined) {
        return
      }
      this.emitChanges('media_image_slider', newValue);
    },
  },
  mounted() {
    if (!this.updatedActivation.media_image_slider)
      this.$set(this.updatedActivation, 'media_image_slider', false);
  },
  data() {
    return {
      updatedActivation: this.activation,
      activeTab: 1,
      editorConfig: {
      toolbar: [ [ 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', '-',
        'Outdent', 'Indent', '-', 'Blockquote', '-', 'Styles', '-', 'Format', '-',  ] ]
      },
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
    setImage(image) {
      this.$set(image.fileObject, 'add', image.fileObject.name);

      if (this.updatedActivation.media.length === 0) {
        this.updatedActivation.media.push(image);
      } else {
        this.updatedActivation.media[0] = image;
      }

      this.setMedia(this.updatedActivation.media);
    },
    setMedia(event) {
      this.updatedActivation.media = event; // for some reason the deep watch doesn't react on array changes???
      this.emitChanges('media', this.updatedActivation.media);
    }
  },
  computed: {
    tags() {
      if (this.transl && this.transl.tags) {
        return this.transl.tags;
      } else {
        return {};
      }
    },
  }
}
</script>

<style lang="scss" scoped>

@import "./ActivationStep";

.banner-container {
  display: flex;
  flex-direction: column;
  padding: 8px;

  gap: 16px;

  border: 1px solid $gray-100;
}
</style>

