<template>
  <div class="activation-config-container">

    <div class="tab-content-container">

      <div class="form">
        <div class="input-container">
          <p class="label">Amount</p>
          <input v-model="updatedActivation.cashback_amount"/>
        </div>

        <div class="input-container">
          <p class="label">Currency</p>

          <select class="select" :value="updatedActivation.cashback_currency" @change="setEdit({ currency: $event.target.value })">
            <option v-for="currency in currencies" :key="currency" :value="currency">
              {{ currency }}
            </option>
          </select>

        </div>

        <!--<div class="input-container">
          <p class="label">Cburl</p>
          <input v-model="updatedActivation.cashback_cburl"/>
        </div>-->

        <div class="input-container">
          <p class="label">Payout methods</p>
        </div>

        <div class="input-container">
          <p class="order-label">Order</p>

          <div class="payoutmethods-container">
            <select class="select" :value="payoutMethods['swish'].order" @change="payoutMethods['swish'].order = $event.target.value">
              <option v-for="order in [1,2,3]" :key="order" :value="order">
                {{ order }}
              </option>
            </select>
            <InputField inputType="checkbox" :label="'Swish'" v-model="payoutMethods['swish'].active"/>
          </div>
        </div>
        <div class="input-container">
          <div class="payoutmethods-container">
            <select class="select" :value="payoutMethods['card'].order" @change="payoutMethods['card'].order = $event.target.value">
              <option v-for="order in [1,2,3]" :key="order" :value="order">
                {{ order }}
              </option>
            </select>
            <InputField inputType="checkbox" :label="'Credit card'" v-model="payoutMethods['card'].active"/>
          </div>
        </div>
        <div class="input-container">
          <div class="payoutmethods-container">
            <select class="select" :value="payoutMethods['account'].order" @change="payoutMethods['account'].order = $event.target.value">
              <option v-for="order in [1,2,3]" :key="order" :value="order">
                {{ order }}
              </option>
            </select>
            <InputField inputType="checkbox" :label="'Bank account'" v-model="payoutMethods['account'].active"/>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import InputField from "@/stories/components/InputField/InputField.vue";

export default {
  components: {InputField},
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.cashback_amount'(value) {
      this.emitChanges('cashback_amount', value);
    },
    'updatedActivation.cashback_currency'(value) {
      this.emitChanges('cashback_currency', value);
    },
    /*'updatedActivation.cashback_cburl'(value) {
      this.emitChanges('cashback_cburl', value);
    },*/
    'payoutMethods': {
      deep: true,
      handler: function(payoutMethods) {

        const payoutMethodsString = Object.keys(payoutMethods).filter(payoutMethodName => this.payoutMethods[payoutMethodName].active)
          .sort((payoutMethodNameA, payoutMethodNameB) => this.payoutMethods[payoutMethodNameA].order - this.payoutMethods[payoutMethodNameB].order)
          .toString()

        this.emitChanges('cashback_payoutmethods', payoutMethodsString);
      }
    }
  },
  mounted() {

    if (Object.keys(this.updatedActivation.cashback_payoutmethods).length === 0)
      return

    const storedPayoutMethods = this.updatedActivation.cashback_payoutmethods.split(',');

    storedPayoutMethods.forEach((payoutMethodName, index) => {
      this.payoutMethods[payoutMethodName].active = true;
      this.payoutMethods[payoutMethodName].order = index + 1;
    })
  },
  data() {
    return {
      updatedActivation: this.activation,
      currencies: ['SEK', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN',
        'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD',
        'BTN', 'BWP', 'BYN', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC',
        'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR',
        'FJD', 'FKP', 'FOK', 'GBP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ',
        'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD',
        'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KID', 'KMF',
        'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD',
        'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR', 'MWK', 'MXN',
        'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN',
        'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR',
        'SBD', 'SCR', 'SDG', 'SGD', 'SHP', 'SLE', 'SLL', 'SOS', 'SRD', 'SSP', 'STN',
        'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TVD', 'TWD',
        'TZS', 'UAH', 'UGX', 'USD', 'UYU', 'UZS', 'VES', 'VND', 'VUV', 'WST', 'XAF',
        'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW', 'ZWL'],
      payoutMethods: {
        'swish': { active: false, order: null },
        'card': { active: false, order: null },
        'account': { active: false, order: null }
      },
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
    setEdit(values) {
      this.updatedActivation = {
        ...this.updatedActivation,
        ...values,
      }
    },
  },
}


</script>

<style lang="scss" scoped>

@import "./ActivationStep";

.form {
  margin-top: 16px;
}


.payoutmethods-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  gap: 8px;

  .select {
    padding: 8px 12px !important;
    border-radius: 4px !important;
    height: 40px !important;
    width: 70px;

    border-color: $gray-200 !important;
  }
}

.order-label {
  color: $gray-500;
}





</style>

