<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">
    <div class="company-company-header">
      <div class="admin-entity--title">{{ company.name }}</div>
      <div class="company-company-actions">
        <div
          class="fullscreen-icon"
          @click="showPreviewCompanyModal = true">
          <iconComponent symbol="fullscreen"/>
          Preview
        </div>
        <!--<div
          class="fullscreen-icon"
          @click="showPreviewCompanyModal = true">
          <iconComponent symbol="cloud"/>
          Publish
        </div>
        <div
          class="fullscreen-icon"
          @click="showPreviewCompanyModal = true">
          <iconComponent symbol="circledCross"/>
          Cancel
        </div>
        <div
          class="fullscreen-icon"
          @click="showPreviewCompanyModal = true">
          <iconComponent symbol="floppyDisk"/>
          Save
        </div>-->
      </div>
    </div>
    <!--<md-field md-clearable class="md-toolbar-section-end">
      <md-input
        placeholder="Search by collection name..."
        v-model="search"
        @input="findCompanyGoodiebags(search)" />
    </md-field>-->
    <div class="admin-entity--title">Collections</div>
    <div class="admin-goodiebags-wrapper">
      <div
        v-for="childGoodiebag in parentGoodiebags"
        :key="childGoodiebag._id"
        class="goodiebag-box"
        :style="`background-image: url(${childGoodiebag.media ? childGoodiebag.media.url : ''})`">
        <div
          class="goodiebag-live-status"
          :class="{
            'goodiebag-live': childGoodiebag.public,
            'goodiebag-offline': !childGoodiebag.public,
          }"></div>
        <div class="goodiebag-box-text">
          <div class="goodiebag-box-name">{{ childGoodiebag.name }}</div>
          <div
            v-if="childGoodiebag.childGoodiebags && childGoodiebag.childGoodiebags.length"
            class="goodiebag-box-info">
            {{ childGoodiebag.childGoodiebags.length }} {{ childGoodiebag.childGoodiebags.length === 1 ? 'collection' : 'collections' }}
          </div>
          <div
            v-if="childGoodiebag.offers && childGoodiebag.offers.length"
            class="goodiebag-box-info">
            {{ childGoodiebag.offers.length }} {{ childGoodiebag.offers.length === 1 ? 'activation' : 'activations' }}
          </div>
          <div
            class="goodiebag-box-fullscreen fullscreen-icon"
            @click="getGoodiebag(childGoodiebag._id)">
            <iconComponent symbol="fullscreen"/>
          </div>
          <div
            class="goodiebag-box-edit"
            @click="$router.push({ name: 'CompanyGoodiebag', params: { goodiebagId: childGoodiebag._id } })">
            <iconComponent symbol="pencil"/>
          </div>
        </div>
      </div>
      <div
        class="goodiebag-box goodiebag-box-add"
        @click="showGoodiebagDialog = true">
        <iconComponent symbol="circledPlus"/>
      </div>
    </div>

    <PreviewGoodiebag
      v-if="goodiebag && goodiebag._id && showPreviewGoodiebagModal"
      :goodiebag="goodiebag"
      @close="showPreviewGoodiebagModal = false"/>
    <PreviewCompany
      v-else-if="company && company._id && showPreviewCompanyModal"
      :company="company"
      @close="showPreviewCompanyModal = false"/>

    <modal
      v-if="showGoodiebagDialog"
      size="xlarge"
      @close="showGoodiebagDialog = false;" modalType="fullscreen">
      <slot>
        <h2>Create Category</h2>
        <EntityEditor
          v-if="loadedPage"
          entity-type="Goodiebag"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import PreviewCompany from "../components/PreviewCompany";
import PreviewGoodiebag from "../components/PreviewGoodiebag";
// import aUpload from '../components/AUpload';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "CompanyCompany",
  components: {
    PreviewCompany,
    PreviewGoodiebag,
    EntityEditor,
    // aUpload,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findCompanyGoodiebags('');
  },
  computed: {
    ...mapGetters(["company", "goodiebag", "goodiebags", "userStatus"]),
    parentGoodiebags() {
      if (this.company && this.company.goodiebags && this.company.goodiebags.length) {
        let parentGoodiebags = [];
        for (let i = 0; i < this.company.goodiebags.length; i++) {
          const goodiebag = this.company.goodiebags[i];
          if (!goodiebag.parentGoodiebag) {
            parentGoodiebags.push(goodiebag);
          }
        }
        return parentGoodiebags;
      }
      return [];
    },
  },
  data() {
    return {
      loadedPage: false,
      goodiebagId: '',
      search: '',
      showGoodiebagDialog: false,
      showPreviewGoodiebagModal: false,
      showPreviewCompanyModal: false,
      previewWidth: 'computer',
      attach: [],
    };
  },
  methods: {
    getGoodiebag(entityId) {
      this.$store.dispatch('getCompanyGoodiebag', { entityId }).then(() => {
        this.showPreviewGoodiebagModal = true;
      });
    },
    findCompanyGoodiebags(query) {
      this.$store.dispatch('getPickedCompany').then(() => {
        this.$store.dispatch('findCompanyGoodiebags', { query }).then(() => {
          this.setFields();
        });
      });
    },
    gotoGoodiebag(goodiebagId) {
      location.assign(`${this.windowOrigin}/c-goodiebag/${goodiebagId}`);
    },
    setFields() {
      this.loadedPage = true;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/CompanyCompany";
</style>
