<template>
  <div class="image-slider-container">
    <div class="header">
      <div class="headline-container">
        <h3 class="heading">{{ heading }}</h3>
      </div>
    </div>

    <div class="image-select">

      <ImageSelect :smallPreview=true
                   :addImage=true
                   :showInfo=false
                   :showAllImageRestrictions=false
                   :selected=false
                   :width=125
                   :height=140
                   @imageSelected="addImage($event)"/>

      <div v-for="(image, i) in images" :key="i + '_' + currentTime()">
        <ImageSelect
                   :heading="i + 1 + '.'"
                   :selected-image-file="image"
                   :aspect-ratio="'16:9'"
                   :showInfo=false
                   :smallPreview=true
                   :showAllImageRestrictions=false
                   :selected=false
                   :width=125
                   :height=140
                   @imageSelected="updateImage(i, $event)"
                   @imageDeleted="deleteImage(i, $event)"
                   class="slider-image" />


          <svg v-if="i >= 1 && i < images.length" style="cursor: pointer" @click="movePrevious(i)" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M7.60319 7.87152C7.50651 7.87738 7.41569 7.91986 7.34977 7.99164L3.5 12.0011L7.34977 16.0105C7.44205 16.1087 7.57976 16.1497 7.71013 16.1175C7.84051 16.0852 7.94452 15.9856 7.98114 15.8567C8.01923 15.7263 7.98407 15.5872 7.88885 15.4919L4.89898 12.3761H21.1199C21.2546 12.3776 21.3806 12.3072 21.4495 12.1901C21.5168 12.0729 21.5168 11.9293 21.4495 11.8121C21.3806 11.6949 21.2546 11.6246 21.1199 11.6261H4.89898L7.88885 8.51022C7.99872 8.40035 8.02948 8.23481 7.96503 8.09418C7.90203 7.95209 7.75847 7.86419 7.60319 7.87152Z" fill="black"/>
          </svg>

          <svg v-if="i < images.length - 1" style="cursor: pointer" @click="moveNext(i)" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M17.3839 7.87116C17.2315 7.86823 17.0924 7.95905 17.0309 8.09821C16.9708 8.23883 17.0016 8.40143 17.1085 8.50982L20.0996 11.6255H3.87975C3.74499 11.624 3.61902 11.6943 3.55163 11.8115C3.48279 11.9287 3.48279 12.0722 3.55163 12.1894C3.61902 12.3066 3.74499 12.3769 3.87975 12.3755H20.0996L17.1085 15.4911C16.9649 15.6405 16.9708 15.8778 17.1202 16.0214C17.2696 16.1649 17.5069 16.1605 17.6505 16.0097L21.5 12.0005L17.6505 7.99128C17.5816 7.91657 17.4849 7.87409 17.3839 7.87116Z" fill="black"/>
          </svg>

      </div>
    </div>

    <ul class="image-restrictions">
      <li>Supported formats (jpg, jpeg, png). </li>
      <li>File size: Less than 4MB</li>
      <li>Dimensions: 1920x1080px / Aspect ratio: 16:9</li>
    </ul>
  </div>
</template>

<script>
import svgMixin from "@/mixins/SvgMixin.vue";
import ImageSelect from "@/stories/components/ImageSelect/ImageSelect.vue";
import DateTimeConverterMixin from "@/mixins/DateTimeConverterMixin.vue";


export default {
  name: 'ImageSlider',
  components: { ImageSelect },
  mixins: [svgMixin, DateTimeConverterMixin],
  props: {
    heading: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      images: this.value,
    };
  },
  methods: {
    moveNext(currentIndex) {
        const currentImage = this.images[currentIndex];
        this.images.splice(currentIndex, 1);
        this.images.splice(currentIndex + 1, 0, currentImage);
    },
    movePrevious(currentIndex) {
        const currentImage = this.images[currentIndex];
        this.images.splice(currentIndex, 1);
        this.images.splice(currentIndex - 1, 0, currentImage);
    },
    handleInput () {
      this.$emit('input', this.images);
    },
    addImage(image) {
      if (this.images.length === 7)
          return

      this.$set(image.fileObject, 'add', image.fileObject.name);
      this.images.push(image);
      this.handleInput();
    },
    updateImage(index, image) {
      this.$set(image.fileObject, 'add', image.fileObject.name); // the image is really replaced, therefore add it
      this.images[index] = image;
      this.handleInput();
    },
    deleteImage(index) {
      this.images.splice(index, 1);
      this.handleInput();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/company";

$imageWidth: 130px;
$imageHeight: 145px;

.image-slider-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  width: 570px;
  // height: 310px;
  padding: 16px;

  border-radius: 4px;
  border: 1px dashed #CCC;
  background-color: #FFF;

  .image-select {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-items: flex-start;
    flex-wrap: wrap;

    .slider-image {
      width: $imageWidth;
      height: $imageHeight;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    margin-bottom: 16px;

    .headline-container {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .info {
        cursor: pointer;
      }

      .heading {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        letter-spacing: normal;
        color: #111;
      }
    }

    .name--selected {
      font-weight: 700;
    }

    .image-actions {
      display: flex;
      align-items: center;
    }

    .button-yellow {
      min-height: 30px;
      border: 2px solid $brand-yellow;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
    padding: 16px;
    background-color: #F2F2F2;

    button {
      background-color: #FFF;
      border: 2px solid $brand-yellow;
    }

    .preview-container-buttons {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      z-index: 1000;

      .button-cancel {
        background-color: transparent;
        color: #333;
        border: 1px solid #333;
        margin-right: 5px;
        border-radius: 3px;
      }

      .button-apply {
        background-color: $brand-yellow;
        color: white;
        margin-right: 5px;
        border-radius: 3px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-restrictions {
    width: 100%;
    margin: 8px 0 0 0;
    padding: 0;
    list-style-type: disc;
    list-style-position: inside;

      li {
        padding: 0 0 0 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.014px;
        text-align: left;
        color: #666;
      }
  }

  p {
    align-self: start;
    margin-top: 8px;
  }
}

.image--selected {
  border-color: #111;
}

  .preview-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 64px 128px;

    .preview-modal-image-container {
      img {
        width: 100%;
      }
    }
  }

  .preview-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 24px 40px;
    height: 88px;
    background-color: #F2F2F2;
  }
</style>
