<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage && parentCompany._id"
      :title="parentCompany.name"
      entity-type="ParentCompany"
      :entity="parentCompany"
      :admin="true"/>

    <div class="admin-entity--subentity">
    <div class="admin-entity--title">Brands</div>
    <div v-if="parentCompany._id" class="admin-goodiebags-wrapper subentity">
      <div
        v-for="company in parentCompany.companies"
        :key="company._id"
        class="goodiebag-box"
        :style="`background-image: url(${company.media ? company.media.url : ''})`">
        <div class="goodiebag-box-text">
          <div class="goodiebag-box-name">{{ company.name }}</div>
          <div
            v-if="company.goodiebags.length"
            class="goodiebag-box-info">
            {{ company.goodiebags.length }} {{ company.goodiebags.length === 1 ? 'collection' : 'collections' }}
          </div>
          <div
            class="goodiebag-box-fullscreen fullscreen-icon preview-action"
            @click="getGoodiebag(company._id)">
            <iconComponent symbol="fullscreen"/>
          </div>
          <div
            class="goodiebag-box-edit preview-action"
            @click="$router.push({ name: 'AdminCompany', params: { companyId: company._id } })">
            <iconComponent symbol="pencil"/>
          </div>
        </div>
      </div>
      <div
        class="goodiebag-box goodiebag-box-add"
        @click="showCompanyDialog = true">
        <iconComponent symbol="circledPlus"/>
      </div>
    </div>

    <!--<PreviewGoodiebag
      v-if="previewGoodiebag && previewGoodiebag._id && showPreviewGoodiebagModal"
      :goodiebag="previewGoodiebag"
      @close="showPreviewGoodiebagModal = false"/>
    <PreviewOffer
      v-if="previewOffer && previewOffer._id && showPreviewOfferModal"
      :offer="previewOffer"
      :goodiebag="goodiebag"
      @close="showPreviewOfferModal = false"/>-->

    <modal v-if="showCompanyDialog" size="xlarge"
          @close="showCompanyDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Company"
          :admin="true"
          :attach="attachCompany"/>
      </slot>
    </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: 'AdminParentCompany',
  components: {
    EntityEditor
  },
  props: ['parentCompanyId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
    this.setPageTitle('Admin', 'Client');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('parentCompanies', [
      "parentCompany",
      "status"
    ])
  },
  watch: {
    parentCompanyId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      search: '',
      showCompanyDialog: false,
      attachCompany: [],
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('parentCompanies/getAdminParentCompany', { entityId: this.parentCompanyId }).then(() => {
        this.setAttachments();
        this.loadedPage = true;
      });
    },
    setAttachments() {
      this.attachCompany = [{
        key: 'parentCompany',
        value: {
          text: this.parentCompany.name,
          value: this.parentCompany._id,
        }
      }];
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminParentCompany";
</style>
