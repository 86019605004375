/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import goodiebagApi from '../../api/goodiebag.api';
import offerApi from '../../api/offer.api';
import narApi from '../../api/nar.api';
import moment from 'moment-timezone';

// initial state
const state = {
  goodiebag: {},
  offer: {},
  goodiebagQuery: '',

  goodiebags: [],
  modalGoodiebags: [],
  goodiebagStatus: '',
};

// getters
const getters = {
  goodiebag: state => state.goodiebag,
  offer: state => state.offer,
  goodiebagQuery: state => state.goodiebagQuery,

  goodiebags: state => state.goodiebags,
  modalGoodiebags: state => state.modalGoodiebags,
  goodiebagStatus: state => state.goodiebagStatus,
};

// actions
const actions = {
  // ADMIN
  findAdminGoodiebags({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.findAdminGoodiebags(payload)
      .then((response) => {
        if (payload.type === 'modal') {
          commit('MODAL_GOODIEBAGS', response.goodiebags);
        } else {
          commit('GOODIEBAGS', response.goodiebags);
        }
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  getAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.getAdminGoodiebag(payload)
      .then((response) => {
        commit('GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.addAdminGoodiebag(payload)
      .then((response) => {
        if (payload.parentGoodiebagId) {
          commit('ADD_CHILD_GOODIEBAG', {
            goodiebag: response.goodiebag, parentGoodiebagId: payload.parentGoodiebagId
          });
        } else {
          commit('ADD_GOODIEBAG', response.goodiebag);
        }
        commit('ADD_GOODIEBAG_TO_COMPANY', response.goodiebag, { root: true })
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addAdminGoodiebagMedia({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.addAdminGoodiebagMedia(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  finishAdminGoodiebagLinks({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.finishAdminGoodiebagLinks(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  saveAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
/*     payload.companyId = payload.localCompany.value; */
    return goodiebagApi.saveAdminGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  publiciseAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.publiciseAdminGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  softPubliciseAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.softPubliciseAdminGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  publiciseLandingAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.publiciseLandingAdminGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  archiveAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.archiveAdminGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteAdminGoodiebagPictures({ commit, state }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.deleteAdminGoodiebagPictures(payload)
      .then((response) => {
        if (state.goodiebags.length) {
          commit('SAVE_GOODIEBAG', response.goodiebag);
        } else {
          commit('GOODIEBAG', response.goodiebag);
        }
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  deleteAdminGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.deleteAdminGoodiebag(payload.entityId)
      .then(() => {
        commit('DELETE_GOODIEBAG', payload.entityId);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  getAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.getAdminOffer(payload)
      .then((response) => {
        commit('OFFER', response.offer);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  addAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
/*     payload.companyId = payload.localCompany.value; */
    return offerApi.addAdminOffer(payload)
      .then((response) => {
        commit('ADD_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addAdminOfferMedia({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.addAdminOfferMedia(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  cloneAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.cloneAdminOffer(payload)
    .then((response) => {
      commit('GOODIEBAG_STATUS', 'FINISHED');
      return response.offers.length;
    });
  },

  finishAdminOfferLinks({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.finishAdminOfferLinks(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  publiciseAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.publiciseAdminOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  softPubliciseAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.softPubliciseAdminOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteAdminOfferPictures({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.deleteAdminOfferPictures(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addAdminOfferLink({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.addAdminOfferLink(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  saveAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.saveAdminOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  closeAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.closeAdminOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  archiveAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.archiveAdminOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteAdminOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.deleteAdminOffer(payload.entityId)
      .then(() => {
        commit('DELETE_OFFER', payload.entityId);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },


  // Company
  getCompanyGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.getCompanyGoodiebag(payload)
      .then((response) => {
        commit('GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return response.goodiebag;
      });
  },

  findCompanyGoodiebags({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.findCompanyGoodiebags(payload)
      .then((response) => {
        commit('GOODIEBAGS', response.goodiebags);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return true;
      });
  },

  addCompanyGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.addCompanyGoodiebag(payload)
      .then((response) => {
        if (payload.parentGoodiebagId) {
          commit('ADD_CHILD_GOODIEBAG', {
            goodiebag: response.goodiebag, parentGoodiebagId: payload.parentGoodiebagId
          });
        } else {
          commit('ADD_GOODIEBAG', response.goodiebag);
        }
        commit('ADD_GOODIEBAG_TO_COMPANY', response.goodiebag, { root: true })
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addCompanyGoodiebagMedia({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.addCompanyGoodiebagMedia(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  saveCompanyGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.saveCompanyGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  publiciseCompanyGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.publiciseCompanyGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  archiveCompanyGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.archiveCompanyGoodiebag(payload)
      .then((response) => {
        commit('SAVE_GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteCompanyGoodiebagPictures({ commit, state }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.deleteCompanyGoodiebagPictures(payload)
      .then((response) => {
        if (state.goodiebags.length) {
          commit('SAVE_GOODIEBAG', response.goodiebag);
        } else {
          commit('GOODIEBAG', response.goodiebag);
        }
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  deleteCompanyGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return goodiebagApi.deleteCompanyGoodiebag(payload.entityId)
      .then(() => {
        commit('DELETE_GOODIEBAG', payload.entityId);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  getCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.getCompanyOffer(payload)
      .then((response) => {
        commit('OFFER', response.offer);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return response.offer;
      });
  },

  addCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.addCompanyOffer(payload)
      .then((response) => {
        commit('ADD_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addCompanyOfferMedia({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.addCompanyOfferMedia(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteCompanyOfferPictures({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.deleteCompanyOfferPictures(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addCompanyOfferLink({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.addCompanyOfferLink(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  saveCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    if (isNaN(payload.localStartsAt) || isNaN(payload.localExpiresAt)) {
      payload.startsAt = 0;
      payload.expiresAt = 0;
    } else {
      if (typeof payload.localStartsAt === 'object') {
        payload.localStartsAt = moment(payload.localStartsAt).valueOf();
      }
      if (typeof payload.localExpiresAt === 'object') {
        payload.localExpiresAt = moment(payload.localExpiresAt).valueOf();
      }
      payload.startsAt = payload.localStartsAt/1000;
      // Add 23h, so that the counter ends at the end of the day
      payload.expiresAt = payload.localExpiresAt/1000 + (23*60*60 + 59*60 + 59);
      if (payload.expiresAt <= payload.startsAt) {
        console.error('"Gäller till" måste vara senare än "Gäller från"');
        return;
      }
    }

    if (!payload.useRecurring) {
      payload.recurringStartsAt = 0;
      payload.recurringExpiresAt = 0;
    } else {
      if (parseInt(payload.recurringExpiresAt) <= parseInt(payload.recurringStartsAt)) {
        console.error('Recurring "Gäller till" måste vara senare än "Gäller från"');
        return;
      }
    }

    if (payload.internalLink) {
      payload.externalLink = '';
    }
    if (payload.iframeMediaId) {
      payload.iframeMedia = [{
        id: payload.iframeMediaId,
        host: payload.iframeMediaHost,
      }];
    }
    return offerApi.saveCompanyOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  publiciseCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.publiciseCompanyOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  closeCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.closeCompanyOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  archiveCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.archiveCompanyOffer(payload)
      .then((response) => {
        commit('SAVE_OFFER', {
          offer: response.offer,
          goodiebagId: payload.goodiebagId
        });
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteCompanyOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.deleteCompanyOffer(payload.entityId)
      .then(() => {
        commit('DELETE_OFFER', payload.entityId);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  // Consumer
  getGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    commit('GOODIEBAG', {});
    return goodiebagApi.getGoodiebag(payload)
      .then((response) => {
        commit('GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  // TODO: Separate offer from goodiebag?
  getOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    commit('OFFER', {});
    return offerApi.getOffer(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('OFFER', response.offer);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return;
      });
  },

  getActiveOffers({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return offerApi.getActiveOffers(payload)
      .then((response) => {
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return response.offers;
      });
  },

  // No Auth Required
  findNarGoodiebags({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    commit('GOODIEBAG_QUERY', payload.query);
    return narApi.findNarGoodiebags(payload.query, state.number)
      .then((response) => {
        commit('GOODIEBAGS', response.goodiebags);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  getNarGoodiebag({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    commit('GOODIEBAG', {});
    return narApi.getNarGoodiebag(payload)
      .then((response) => {
        commit('GOODIEBAG', response.goodiebag);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  getNarOffer({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    commit('OFFER', {});
    return narApi.getNarOffer(payload)
      .then((response) => {
        commit('OFFER', response.offer);
        commit('GOODIEBAG_STATUS', 'FINISHED');
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  resetGoodiebag({ commit }) {
    commit('GOODIEBAG', {});
  },

  resetOffer({ commit }) {
    commit('OFFER', {});
  },
};

// mutations
const mutations = {
  GOODIEBAG(state, goodiebag) {
    state.goodiebag = goodiebag || {};
  },
  OFFER(state, offer) {
    state.offer = offer || {};
  },

  GOODIEBAG_QUERY(state, goodiebagQuery) {
    state.goodiebagQuery = goodiebagQuery;
  },
  GOODIEBAGS(state, goodiebags) {
    state.goodiebags = goodiebags;
  },
  MODAL_GOODIEBAGS(state, goodiebags) {
    state.modalGoodiebags = [];
    for (let i = 0; i < goodiebags.length; i += 1) {
      state.modalGoodiebags.push({
        value: goodiebags[i]._id,
        text: goodiebags[i].name,
      });
    }
  },
  ADD_GOODIEBAG(state, goodiebag) {
    state.goodiebags.unshift(goodiebag);
  },
  ADD_CHILD_GOODIEBAG(state, { goodiebag, parentGoodiebagId }) {
    if (state.goodiebag._id === parentGoodiebagId) {
      state.goodiebag.childGoodiebags.push(goodiebag);
    }
  },
  SAVE_GOODIEBAG(state, goodiebag) {
    for (let i = 0; i < state.goodiebags.length; i += 1) {
      if (state.goodiebags[i]._id === goodiebag._id) {
        Vue.set(state.goodiebags, i, goodiebag);
      }
    }
    if (state.goodiebag._id === goodiebag._id) {
      state.goodiebag = goodiebag;
    }
  },
  SAVE_GOODIEBAG_LINKS(state, links) {
    state.goodiebag.links_in = state.goodiebag.links_in.concat(links);
  },
  DELETE_GOODIEBAG(state, goodiebagId) {
    for (let i = 0; i < state.goodiebags.length; i += 1) {
      if (state.goodiebags[i]._id === goodiebagId) {
        state.goodiebags.splice(i, 1);
      }
    }
    if (state.goodiebag._id === goodiebagId) {
      state.goodiebag = {};
    }
  },
  ADD_OFFER(state, data) {
    if (state.goodiebag._id === data.goodiebagId) {
      state.goodiebag.offers.push(data.offer);
    } else {
      for (let i = 0; i < state.goodiebags.length; i += 1) {
        if (state.goodiebags[i]._id === data.goodiebagId) {
          state.goodiebags[i].offers.push(data.offer);
        }
      }
    }
  },
  SAVE_OFFER(state, data) {
    for (let i = 0; i < state.goodiebags.length; i += 1) {
      if (state.goodiebags[i]._id === data.goodiebagId) {
        for (let j = 0; j < state.goodiebags[i].offers.length; j += 1) {
          if (state.goodiebags[i].offers[j]._id === data.offer._id) {
            Vue.set(state.goodiebags[i].offers, j, data.offer);
          }
        }
      }
    }
    if (state.offer._id === data.offer._id) {
      state.offer = data.offer;
    }
  },
  SAVE_OFFER_LINKS(state, links) {
    state.offer.links_in = state.offer.links_in.concat(links);
  },
  DELETE_OFFER(state, offerId) {
    for (let i = 0; i < state.goodiebags.length; i += 1) {
      for (let j = 0; j < state.goodiebags[i].offers.length; j += 1) {
        if (state.goodiebags[i].offers[j]._id === offerId) {
          state.goodiebags[i].offers.splice(j, 1);
        }
      }
    }
    if (state.offer._id === offerId) {
      state.offer = {};
    }
  },
  GOODIEBAG_STATUS(state, status) {
    state.goodiebagStatus = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
