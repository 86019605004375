import axios from 'axios';

export default {
  // Admin
  findAdminPVCompany: (data) => {
    return axios.put(`/api/pv/admin/companies/${data.companyId}/activations`, data)
      .then(response => {
        return {
          company: response.data.company != null
            ? response.data.company : {},
        }});
  },
  findAdminPVCompanyByReceiptProperties: (data) => {
    return axios.put('/api/pv/admin/activationsbyreceipt', data)
      .then(response => {
        return {
          company: response.data.company != null
            ? response.data.company : {},
        }});
  },
  findAdminPVActivation: (data) => {
    return axios.put(`/api/pv/admin/activations`, data)
      .then(response => {
        return {
          activations: response.data.activations != null
            ? response.data.activations : [],
        }});
  },


  addAdminPVActivation: (payload) => {
    return axios.post('/api/pv/admin/activations', payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationMedia: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/picture`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationThumbnail: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/thumbnail`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationDonation: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/donation`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationIcon: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/icon`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminPVActivationPdf: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/terms`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addAdminActivationEmailAttachment: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/emailActionAttachment`, payload.data, payload.config)
      .then(response => ({
        fileKey: response.data.fileKey != null
          ? response.data.fileKey : {},
      }));
  },

  saveAdminPVActivation: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  publiciseAdminPVActivation: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/publicise`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  cloneAdminPVActivation: (payload) => {
    return axios.post(`/api/pv/admin/activations/${payload.entityId}/clone`, payload)
      .then(response => ({
        activations: response.data.activations != null
          ? response.data.activations : [],
      }));
  },

  changeCTAs: (payload) => {
    return axios.put(`/api/pv/admin/activations/${payload.entityId}/ctas`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  sendProducts: (payload) => {
    return axios.post(`/api/pv/admin/activations/${payload.entityId}/products/delivery`, payload)
      .then(response => ({
        numberSent: response.data.numberSent != null
          ? response.data.numberSent : 0,
      }));
  },

  getTracking: (payload) => {
    return axios.get(`/api/pv/admin/activations/products/tracking`, payload)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  deleteAdminPVActivationPicture: (entityId) => {
    return axios.delete(`/api/pv/admin/activations/${entityId}/picture`)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  deleteAdminPVActivation: (payload) => {
    return axios.delete(`/api/pv/admin/activations/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Company
  findCompanyPVActivation: (data) => {
    return axios.put('/api/pv/partner/activations', data)
      .then(response => {
        return { activations: response.data.activations != null
          ? response.data.activations : []}
        });
  },

  getCompanyPVActivation: (payload) => {
    return axios.get(`/api/pv/partner/activations/${payload.activationId}`)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addCompanyPVActivation: (payload) => {
    return axios.post('/api/pv/partner/activations', payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addCompanyPVActivationPicture: (payload) => {
    return axios.put(`/api/pv/partner/activations/${payload.entityId}/picture`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  addCompanyPVActivationIcon: (payload) => {
    return axios.put(`/api/pv/partner/activations/${payload.entityId}/icon`, payload.data, payload.config)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  saveCompanyPVActivation: (payload) => {
    return axios.put(`/api/pv/partner/activations/${payload.entityId}`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  publiciseCompanyPVActivation: (payload) => {
    return axios.put(`/api/pv/partner/activations/${payload.entityId}/publicise`, payload)
      .then(response => ({
        activation: response.data.activation != null
          ? response.data.activation : {},
      }));
  },

  deleteCompanyPVActivation: (entityId) => {
    return axios.delete(`/api/pv/partner/activations/${entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
