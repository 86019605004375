<template>
  <div class="admin-content--container">
    <div class="admin-search-container">
      <div class="admin-spacer"/>
      
      <div class="add-entity-button" @click="showParentCompanyDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>
    
    <aList
      entityType="Clients"
      columnsWidth="400"
      :entities="parentCompanies"
      :keys="parentCompanyKeys"
      routerName="AdminParentCompany"
      routerId="parentCompanyId"/>

    <modal v-if="showParentCompanyDialog" size="xlarge"
          @close="showParentCompanyDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
                      entity-type="ParentCompany"
                      :admin="true" />
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '../resources/eventBus';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from '../components/AList';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: 'AdminClients',
  components: {
    aList,
    EntityEditor,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminParentCompanies();
    this.setPageTitle('Admin', 'Clients');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('parentCompanies', ['parentCompanies', 'status']),
  },
  data() {
    return {
      showParentCompanyDialog: false,
      parentCompanyKeys: [
        {
          title: 'Id',
          key: '_id',
        },
        {
          title: 'Name',
          key: 'name',
        },
        {
          title: 'Unique Name',
          key: 'unique_name',
        },
      ],
    };
  },
  methods: {
    findAdminParentCompanies(query) {
      this.$store.dispatch('parentCompanies/findAdminParentCompanies', {
        query,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminParentCompanies";
</style>