<template>
  <div class="dashboard-users-container">
    <PartnerTable
      :data="users"
      :fields="fields"
      :minimumRows="minimumRows"
      :pagination="pagination"
      @clickItem="$emit('clickItem', $event)"
      @paginate="$emit('paginate', $event)"
    >
      <template v-slot:column-status="{ item }">
        <UserProgress :steps="item.steps || []" />
      </template>
    </PartnerTable>
  </div>
</template>

<script>
import moment from 'moment';
import UserProgress from './UserProgress.vue'
import PartnerTable from '../PartnerTable/PartnerTable.vue';

export default {
  name: 'DashboardUsers',
  components: { UserProgress, PartnerTable },
  props: {
    users: {
      type: Array,
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    minimumRows: {
      type: Number,
      default: 10,
    },
    pagination: {
      type: Object,
    },
  },
  data() {
    return {
      filterStatus: undefined,
      filterAction: undefined,
      searchValue: '',
    }
  },
  methods: {
    getRegistrationDate(user) {
      return moment.unix(user.registeredAt).format('DD/MM/YYYY');
    },
    getUserProgress({ steps = [] }) {
      return { steps }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'phoneNr',
          label: 'Phone',
          copyable: true,
        },
        {
          key: 'email',
          label: 'Email',
          copyable: true,
        },
        {
          key: 'created_at',
          label: 'Registered',
          getter: this.getRegistrationDate,
        },
        {
          key: 'status',
          label: 'Status',
        }
      ];
    }
  }
}
</script>

<style lang='scss' scoped>
.dashboard-users-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .dashboard-users-table-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
