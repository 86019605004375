var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper",class:{
    '--edit': _vm.edit,
  },style:(_vm.cssProps)},[(_vm.edit)?_c('draggable',{staticClass:"content",style:({
      borderRadius: _vm.localContent.borderRadius,
      gridGap: _vm.localContent.gridGap,
      maxWidth: _vm.localContent.maxWidth,
      margin: _vm.localContent.maxWidth ? '0 auto' : '',
    }),attrs:{"group":"rows"},on:{"end":_vm.verticalDragEnd},model:{value:(_vm.localContent.rows),callback:function ($$v) {_vm.$set(_vm.localContent, "rows", $$v)},expression:"localContent.rows"}},_vm._l((_vm.localContent.rows),function(contentRow,rowIndex){return _c('div',{key:`content-row${rowIndex}`,staticClass:"content-row-wrapper"},[_c('draggable',{staticClass:"content-row",style:({
          padding: contentRow.padding,
          borderRadius: contentRow.borderRadius || _vm.rowBorderRadius[rowIndex],
          backgroundColor: contentRow.backgroundColor,
          display: contentRow.display,
          gridTemplateColumns: contentRow.gridTemplateColumns,
          gridGap: contentRow.gridGap,
        }),attrs:{"list":contentRow.cells,"group":"cells"}},_vm._l((contentRow.cells),function(cell,cellIndex){return _c('div',{key:`content-cell${cell.id || cellIndex}`,staticClass:"content-cell",style:({
            padding: cell.padding,
            margin: cell.type != 'button' ? cell.margin : '',
            borderRadius: cell.borderRadius,
            backgroundColor: cell.type != 'button' ? cell.backgroundColor : '',
          }),on:{"dblclick":function($event){return _vm.$emit('editCell', { row: rowIndex, cell: cellIndex })}}},[(cell.type === 'text')?_c('ContentText',{attrs:{"text":cell.text}}):(cell.type === 'table')?_c('ContentTable',{attrs:{"table":cell.table}}):(cell.type == 'button')?_c('button',{staticClass:"btn btn-activate",style:({
              margin: cell.margin,
              borderRadius: cell.borderRadius,
              backgroundColor: cell.backgroundColor,
            }),attrs:{"role":"button","aria-roledescription":"custom button"}},[_vm._v(_vm._s(cell.text)+" ")]):(cell.type === 'image')?_c('ContentImage',{attrs:{"src":cell.media.url,"alt":cell.alt,"borderRadius":cell.borderRadius}}):(cell.type === 'video')?_c('ContentVideo',{attrs:{"src":cell.media.url,"alt":cell.alt}}):(cell.type === 'iframe')?_c('ContentIframe',{attrs:{"src":cell.url,"alt":cell.alt}}):_vm._e()],1)}),0),(_vm.edit)?_c('div',{staticClass:"content-edit"},[_c('iconComponent',{attrs:{"symbol":"pencil","width":"15px"},on:{"click":function($event){return _vm.$emit('editRow', rowIndex)}}})],1):_vm._e()],1)}),0):_c('table',{staticClass:"content",style:({
      borderRadius: _vm.localContent.borderRadius,
      gridGap: _vm.localContent.gridGap,
      maxWidth: _vm.localContent.maxWidth,
      margin: _vm.localContent.maxWidth ? '0 auto' : '',
    }),attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',_vm._l((_vm.localContent.rows),function(contentRow,rowIndex){return _c('tr',{key:`content-row${rowIndex}`,staticClass:"content-row-wrapper"},[_c('td',{staticClass:"content-row",style:({
            margin: _vm.rowMarginGap[rowIndex],
            padding: contentRow.padding,
            borderRadius: contentRow.borderRadius || _vm.rowBorderRadius[rowIndex],
            backgroundColor: contentRow.backgroundColor,
          })},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tbody',{style:({
                width: '100%',
                display: contentRow.display,
                gridTemplateColumns: contentRow.gridTemplateColumns,
                gridGap: contentRow.gridGap,
              })},_vm._l((contentRow.cells),function(cell,cellIndex){return _c('tr',{key:`content-cell${cell.id || cellIndex}`},[_c('td',{staticClass:"content-cell",style:({
                    padding: cell.padding,
                    margin: cell.type != 'button' ? cell.margin : '',
                    borderRadius: cell.borderRadius,
                    backgroundColor: cell.type != 'button' ? cell.backgroundColor : '',
                  })},[(cell.type === 'text')?_c('ContentText',{attrs:{"text":cell.text}}):(cell.type === 'table')?_c('ContentTable',{attrs:{"table":cell.table}}):(cell.type == 'button')?_c('button',{staticClass:"btn btn-activate",style:({
                      margin: cell.margin,
                      borderRadius: cell.borderRadius,
                      backgroundColor: cell.backgroundColor,
                    }),attrs:{"role":"button","aria-roledescription":"custom button"}},[_vm._v(_vm._s(cell.text)+" ")]):(cell.type === 'image')?_c('ContentImage',{attrs:{"src":cell.media.url,"alt":cell.alt,"borderRadius":cell.borderRadius}}):(cell.type === 'video')?_c('ContentVideo',{attrs:{"src":cell.media.url,"alt":cell.alt}}):(cell.type === 'iframe')?_c('ContentIframe',{attrs:{"src":cell.url,"alt":cell.alt}}):_vm._e()],1)])}),0)])])])}),0)]),(!_vm.localContent.rows)?_c('div',[_c('p',[_vm._v("No content available.")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }