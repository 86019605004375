<template>
  <div v-if="user" class="user-shared-data">
    <div class="-header">
      <h3>{{ transl.UserProfile.mysharedinfo }}</h3>
      <div @click="$router.push({ name: 'UserProfile'}); $emit('back')" class="back-button--container">
        <iconComponent class="settings--back-button" symbol="arrowLeft" />
      </div>
    </div>

    <div class="--user-data --mobile">
      <div class="pre-title-small">{{ transl.UserSettings.myaccount }}</div>
      <div class="--container">
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    leadingIcon='checkmark'
                    leadingIconStroke="#EA7801"
                    leadingIconFill="#EA7801"
                    :label="transl.UserSettings.telnr"
                    v-model="user.phone_nr" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :label="transl.UserSettings.firstname"
                    v-model="user.name" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :label="transl.UserSettings.lastname"
                    v-model="user.last_name" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :leadingIcon="user.verified_email ? 'checkmark' : ''"
                    leadingIconStroke="#EA7801"
                    leadingIconFill="#EA7801"
                    :label="transl.UserSettings.emailadress"
                    v-model="user.email" />
      </div>

      <div class="--container">
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :label="transl.UserSettings.adress"
                    v-model="user.adress" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :label="transl.UserSettings.postalcode"
                    v-model="user.postal_code" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :label="transl.UserSettings.city"
                    v-model="user.city" />
      </div>
    </div>

    <div class="--user-data --desktop">
      <div class="pre-title-small">{{ transl.UserSettings.myaccount }}</div>
      <div class="--container">
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :color="['#F2F2F2','#CCCCCC']"
                    leadingIcon='checkmark'
                    leadingIconStroke="#EA7801"
                    leadingIconFill="#EA7801"
                    :label="transl.UserSettings.telnr"
                    v-model="user.phone_nr" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :color="['#F2F2F2','#CCCCCC']"
                    :label="transl.UserSettings.firstname"
                    v-model="user.name" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :color="['#F2F2F2','#CCCCCC']"
                    :label="transl.UserSettings.lastname"
                    v-model="user.last_name" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :leadingIcon="user.verified_email ? 'checkmark' : ''"
                    leadingIconStroke="#EA7801"
                    leadingIconFill="#EA7801"
                    :color="['#F2F2F2','#CCCCCC']"
                    :label="transl.UserSettings.emailadress"
                    v-model="user.email" />
      </div>

      <div class="--container">
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :color="['#F2F2F2','#CCCCCC']"
                    :label="transl.UserSettings.adress"
                    v-model="user.adress" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :color="['#F2F2F2','#CCCCCC']"
                    :label="transl.UserSettings.postalcode"
                    v-model="user.postal_code" />
        <InputField :readonly="true"
                    :filled="false"
                    :static="true"
                    :color="['#F2F2F2','#CCCCCC']"
                    :label="transl.UserSettings.city"
                    v-model="user.city" />
      </div>
    </div>

    <div class="--settings-link">
      <div class="small-text">{{ transl.UserProfile.chageuserdata }}</div>
      <div class="--icon-container" @click="$router.push({ name: 'UserSettings'})">
        <iconComponent class="--icon" width="14px" stroke="#111111" fill="#111111" symbol="gear" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import IconComponent from '../stories/components/IconComponent/IconComponent.vue';

export default {
  name: 'UserSharedData',
  components: {
    IconComponent,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted () {
    this.setPageTitle(this.transl.UserProfile.mysharedinfo);
  },
  watch: {},
  computed: {
    ...mapGetters(['user', 'userStatus']),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserSharedData";
</style>
