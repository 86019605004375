<template>
  <span
    v-if="loaded"
    class="a-breadcrumb">
    <aBreadcrumb
      v-if="goodiebag.parentGoodiebag"
      :goodiebag-id="goodiebag.parentGoodiebag._id || goodiebag.parentGoodiebag"
      :admin="admin"/>
    <span
      v-else
      class="a-breadcrumb-collection"
      @click="$router.push({ name: admin ? 'AdminCompany' : 'CompanyCompany', params: { companyId: getCompanyId(goodiebag) } })">
      <iconComponent width="20px" symbol="home"/>
    </span>
    <span
      class="a-breadcrumb-collection"
      @click="$router.push({ name: admin ? 'AdminGoodiebag' : 'CompanyGoodiebag', params: { goodiebagId } })">
      / {{ collectionName }}
    </span>
  </span>
</template>

<script>
import goodiebagApi from '../api/goodiebag.api';

export default {
  name: 'ABreadcrumb',
  props: {
    goodiebagId: {
      type: String,
      required: true,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  mounted() {
    goodiebagApi[`get${ this.admin ? 'Admin' : 'Company'}Goodiebag`]({ entityId: this.goodiebagId }).then((data) => {
      this.goodiebag = data.goodiebag;
      this.loaded = true;
    });
  },
  computed: {
    collectionName() {
      let collectionName = this.goodiebag.name;
      if (!collectionName || collectionName == ' ') {
        collectionName = this.goodiebag.unique_name;
      }
      if (!collectionName) {
        collectionName = this.goodiebag._id;
      }
      return collectionName;
    }
  },
  data() {
    return {
      loaded: false,
      goodiebag: {},
    };
  },
  methods: {
    getCompanyId(goodiebag) {
      return goodiebag.company && goodiebag.company._id ? goodiebag.company._id : goodiebag.company;
    }
  }
}
</script>

<style lang="scss" scoped>
.a-breadcrumb{
  background: #FFFFFF;
  display: flex;
  text-align: left;
  .a-breadcrumb-collection{
    cursor: pointer;
    margin: .3rem .5rem;
  }
}
</style>