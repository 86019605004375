<template>
  <div>
    <div v-if="!singleImage" class="flicking-container">
      <flicking
        class="flicking flicking0"
        :class="{ 'flicking-one-slide': mediaList.length == 1 }"
        ref="flick"
        :options="flickingOptions"
        :plugins="flickingPlugins">
        <div
          class="panel"
          v-for="(media, index) in mediaList"
          :key="index">
          <!--This should be implemented outside with a slot here-->
          <div
            class="offer-collection hvr-grow"
            @click="carouselClick(index)">
            <div v-if="media && media.url" class="offer-collection-bgimage">
              <div class="offer-collection-bgimage-overlay"></div>
              <img :src="media.url" alt="" />
            </div>

            <!--<div v-else-if="media.thumbnail && media.thumbnail.url" class="offer-collection-thumbnail">
              <img :src="media.thumbnail.url" alt="" />
            </div>-->

            <div v-if="media.icon.url" class="offer-collection-icon">
              <span>{{ media.second_header }}</span>
              <img :src="media.icon.url" alt="" />
            </div>

            <div class="offer-collection-content">
              <div class="offer-collection-name">{{ media.name }}</div>

              <div v-if="media.quick_text" class="offer-collection-text">
                <span style="padding: 0">{{ media.quick_text }}</span>
              </div>
            </div>
          </div>
        </div>
      </flicking>
      <div v-if="mediaList.length > scrollMore" class="flicking-controls__prev" @click="prev">
        <iconComponent symbol="arrowLeft" width="46" stroke="#666666" fill="#666666" />
      </div>
      <div v-if="mediaList.length > scrollMore" class="flicking-controls__next" @click="next">
        <iconComponent symbol="arrowRight" width="46" stroke="#666666" fill="#666666" />
      </div>
    </div>

    <vueper-slides
      v-else
      class="carousel-vueper-slides"
      autoplay infinite
      :arrows="singleArrows"
      :dragging-distance="90"
      :slide-ratio="singleRatio"
      :pause-on-hover="true"
      :bullets-outside="singleBullets">
      <vueper-slide
        v-for="(media, index) in mediaList"
        class="carousel-vueper-slide"
        :key="media.url"
        :id="index"
        :image="media.url">
        <template #content>
          <!--This should be implemented outside with a slot here-->
          <div class="carousel-vueper-slide-content">
            <div class="carousel-vueper-slide-name">{{ media.name }}</div>
            <div class="carousel-vueper-slide-header">{{ media.quick_text }}</div>
            <button
              v-if="media.button_text"
              class="carousel-vueper-slide-button btn btn-activate"
              @click="carouselClick(index)">
              {{ media.button_text }}
            </button>
          </div>
        </template>
      </vueper-slide>
      <template v-slot:pause>
        <i class="material-icons md-36">pause_circle_outline</i>
      </template>
    </vueper-slides>
  </div>

</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import { Flicking } from '@egjs/vue-flicking';
import { AutoPlay, Fade } from '@egjs/flicking-plugins';
import 'vueperslides/dist/vueperslides.css';
import {
  isMobile,
  isBrowser,
  isIOS,
  isAndroid,
  isTablet,
} from 'mobile-device-detect';

export default {
  name: 'aCarousel',
  components: {
    VueperSlides,
    VueperSlide,
    Flicking,
  },
  props: {
    mediaList: {
      type: Array,
      required: true,
    },
    singleImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleRatio: {
      type: Number,
      required: false,
      default: 1,
    },
    singleBullets: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleArrows: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollMore: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  watch: {
    scrollMore: {
      handler: function(value) {
        if (value >= this.mediaList.length) {
          this.flickingOptions.circular = false;
          this.flickingOptions.anchor = 500;
          this.flickingOptions.bound = true;
          // this.flickingOptions.align = -1
        }  else {
          this.flickingOptions.circular = true;
          this.flickingOptions.anchor = 0;
          // this.flickingOptions.bound = false;
          // this.flickingOptions.align = -1
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
      isIOS,
      isAndroid,
      windowWidth: window.innerWidth,
      isTablet,
      hanger: '',
      pauseOnHover: true,
      autoPlaying: true,
      internalAutoPlaying: true,
      flickingPlugins: [new Fade('', 0.1), new AutoPlay(2000, 'NEXT', 'true')],
      flickingOptions: {
        tag: 'div',
        gap: 10,
        hanger: this.hanger,
        duration: 500,
        autoResize: true,
        adaptive: true,

        circular: true,
        bound: false,
        anchor: 0,
      },
      breakpoints: {
        9999: {
          visibleSlides: 3,
          fixedHeight: '650px',
          bullets: 'true',
        },
        1900: {
          visibleSlides: 3,
          fixedHeight: '600px',
          bullets: 'true',
        },
        1400: {
          visibleSlides: 3,
          fixedHeight: '500px',
          bullets: 'true',
        },
        1025: {
          visibleSlides: 3,
          fixedHeight: '350px',
          bullets: 'true',
          slideMultiple: true,
        },
        900: {
          visibleSlides: 2,
          fixedHeight: '450px',
          slideMultiple: true,
          infinite: true,
        },
        600: {
          fixedHeight: '700px',
          bulletsOutside: true,
          infinite: true,
        },
        450: {
          fixedHeight: '480px',
          bulletsOutside: true,
          infinite: true,
        },
        375: {
          arrows: true,
          bulletsOutside: true,
          arrowsOutside: false,
          fixedHeight: '410px',
          infinite: true,
        },
      },
    };
  },
  computed: {
    anchorSize() {
      return this.calculateAnchor();
    },
  },
  methods: {
    carouselClick(index) {
      this.$emit('slideClick', this.mediaList[index]);
    },
    prev() {
      this.$refs.flick.prev();
    },
    next() {
      this.$refs.flick.next();
    },
    calculateAnchor() {
      if (this.windowWidth >= 760) {
        this.hanger = '0%';
      } else {
        this.hanger = '2%';
      }
    },
  },
  created() {
    this.calculateAnchor();
  },
};
</script>

<style lang="scss">
.carousel-vueper-slides {
  position: sticky;
  .vueperslides__arrow {
    color: rgba(white, .8);
    filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));
    &:hover {
      color: white;
    }
  }
  .vueperslides__bullets{
    overflow: hidden;
    height: 40px;
    /*.vueperslides__bullet {
      .default {
        width: 10px;
        height: 10px;
        border-width: 2px; an animation sets this, so it's a bit complex
      }
    }*/
  }

  .vueperslide {
    text-align: left;
    --slider-padding-up: 35px;
    --slider-padding-left: 55px;
    padding: var(--slider-padding-up) var(--slider-padding-left) !important;
    color: white;
    @media (max-width: $desktop-small-maxwidth) {
      --slider-padding-up: 30px;
      --slider-padding-left: 16px;
    }
    .carousel-vueper-slide-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - var(--slider-padding-left));

      .carousel-vueper-slide-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 350;
        line-height: 130%;
        margin-bottom: 5px;
        @media (max-width: $mobile-maxwidth) {
          font-size: 12px;
        }
      }
      .carousel-vueper-slide-header {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 24px;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 19px;
        @media (max-width: $mobile-maxwidth) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      .carousel-vueper-slide-button {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: auto;
        max-width: 150px;
        overflow: hidden;
        margin: 0;
        padding: 12px 24px;
        border-radius: 6px;
        font-size: 1rem;
        @media (max-width: $mobile-maxwidth) {
          font-size: 0.8rem;
          padding: 8px 22px;
        }
      }
      #text {
        // display: none;
      }
    }
  }
}

.flicking-container {
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 1fr;

  .flicking-one-slide {
    .eg-flick-camera {
      transform: none !important;
    }
  }

  .flicking {
    width: 100%;
    height: 200px;
    margin: 0px auto;
    grid-column: 1 / 4;
    grid-row: 1;

    .panel {
      position: relative;
      display: block;
    }

    @media screen and (min-device-width: 760px) and (max-device-width: 1279px) {
      height: 290px;
    }

    @media screen and (min-device-width: 1280px) and (max-device-width: 1281px) {
      height: 260px;
    }

    @media screen and (min-device-width: 1282px) {
      height: 290px;
    }
  }

  .flicking-controls__prev {
    grid-column: 1;
    grid-row: 1;
    place-self: center;
    z-index: 9999;
    background-color: white;
    border-radius: 50%;
    display: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 50px;
    cursor: pointer;

    span {
      padding: 5px;
      color: #666666;
    }
  }

  .flicking-controls__next {
    grid-column: 3;
    grid-row: 1;
    place-self: center;
    z-index: 9999;
    background-color: white;
    border-radius: 50%;
    display: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 50px;
    cursor: pointer;

    span {
      padding: 5px;
      color: #666666;
    }
  }

  @media screen and (min-device-width: 1020px) {
    .flicking-controls__prev,
    .flicking-controls__next {
      display: block;
    }
  }

  .offer-collection {
    display: grid;
    grid-template-columns: 144px;
    grid-template-rows: 20% 60% 20%;
    cursor: pointer;
    font-family: var(--medium-font-family);

    .offer-collection-bgimage-overlay {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 12%, rgba(250, 250, 250, 0) 35%, rgba(250, 250, 250, 0) 58%, rgba(250, 250, 250, 0) 100%);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 5%;
    }

    .offer-collection-bgimage {
      grid-column: 1;
      grid-row: 1 / -1;

      img {
        border-radius: 5%;
      }
    }

    .offer-collection-icon {
      grid-column: 1;
      grid-row: 1;
      justify-self: left;
      padding: 10px;

      img {
        width: 35px;
        background-color: white;
        border-radius: 50%;
      }
    }

    .offer-collection-content {
      z-index: 1;
      grid-column: 1;
      grid-row: 3;
      height: 60px;
      justify-self: left;
      padding-left: 10px;
      color: white;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      max-width: 95%;

      .offer-collection-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
        line-height: normal;
        word-break: break-all;
      }

      .offer-collection-text{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
        line-height: normal;
        word-break: break-all;
        font-size: 11px;
        line-height: 14px;
      }
    }

    @media screen and (min-device-width: 760px) {
      grid-template-columns: 230px;
      grid-template-rows: 50% 30% 20%;

      .offer-collection-icon {
        padding: 20px;

        img {
          width: 60px;
          background-color: white;
          border-radius: 50%;
        }
      }

      .offer-collection-content {
        z-index: 1;
        grid-column: 1;
        grid-row: 3;
        height: 60px;
        justify-self: left;
        padding-left: 20px;
        font-weight: 500;
        max-width: 95%;

        .offer-collection-name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 20px;
          line-height: 28px;
          text-align: left;
          line-height: normal;
          word-break: break-all;
        }
        .offer-collection-text{
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
      grid-template-columns: 205px;
      grid-template-rows: 50% 30% 20%;
    }
  }
}

// Animations
/*.hvr-grow {
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;

  &:hover,
  &:focus,
  &:active {
      transform: scale(1.02);
  }
} */
</style>
