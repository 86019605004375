<template>
  <div class="language-picker-wrapper">
    <div class="language-picker-container">
      <div class="language-picker-intro">
        Are you here to participate in a competition, participate in a Cash Back campaign or other activation? Choose your home country below
      </div>
      <AutoComplete
        :value="pickedCountry.text"
        :options="countries"
        accentColor="black"
        title="Select country"
        :limit="100"
        :image="pickedCountry.image"
        :country="pickedCountry.text"
        placeholder="Search country"
        @selected="pickedCountry = $event"/>
      <a
        v-if="!pickedCountry.text"
        :href="`${windowOrigin}/${pickedCountry.route}`"
        target="_blank"
        class="btn btn-disabled">
        Continue to Enginio
      </a>
      <a
        v-else
        :href="`${windowOrigin}/${pickedCountry.route}`"
        target="_blank"
        class="btn btn-activate">
        Continue to Enginio
      </a>
    </div>
  </div>
</template>

<script>
import LanguagePickerDataMixin from './LanguagePickerDataMixin.vue';
import AutoComplete from './AutoComplete.vue'

export default {
  name: 'LanguagePicker',
  components: {
    AutoComplete,
  },
  computed: {
    
  },
  mixins: [LanguagePickerDataMixin],
  data() {
    return {
      loadedPage: false,
      bounce: false,
      countries: [],
      pickedCountry: {},
      isDevelopment: process.env.NODE_ENV !== 'production',
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./LanguagePicker"
</style>
