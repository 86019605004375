<template>
  <svg v-if="type === 'valid'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.7739 15.3641L16.2781 8.72622C16.2782 8.72613 16.2782 8.72603 16.2783 8.72594C16.3248 8.65923 16.3519 8.58098 16.3567 8.49982C16.3616 8.41854 16.3438 8.33751 16.3055 8.2657C16.2671 8.19388 16.2096 8.13407 16.1394 8.0929C16.0691 8.05172 15.9889 8.03078 15.9075 8.03238C15.8358 8.03379 15.7655 8.05266 15.7027 8.08736C15.64 8.12198 15.5867 8.17133 15.5474 8.23115C15.5473 8.23126 15.5473 8.23137 15.5472 8.23148L11.5886 14.0667L8.77889 11.4596C8.77878 11.4594 8.77867 11.4593 8.77856 11.4592C8.73636 11.4187 8.68653 11.387 8.63197 11.3659C8.57726 11.3448 8.51888 11.3347 8.46025 11.3364C8.40162 11.3381 8.34391 11.3514 8.2905 11.3757C8.23709 11.3999 8.18904 11.4346 8.14917 11.4776C8.1093 11.5206 8.07841 11.5711 8.0583 11.6262C8.03818 11.6813 8.02925 11.7399 8.03203 11.7985C8.03481 11.8571 8.04924 11.9145 8.07447 11.9675C8.09964 12.0203 8.13504 12.0675 8.17863 12.1065C8.17876 12.1066 8.17888 12.1067 8.179 12.1069L11.6985 15.3727L11.7412 15.4123L11.7739 15.3641ZM12 2.95C7.00655 2.95 2.95 7.00655 2.95 12C2.95 16.9934 7.00655 21.05 12 21.05C16.9934 21.05 21.05 16.9934 21.05 12C21.05 7.00655 16.9934 2.95 12 2.95ZM12 3.83261C16.5152 3.83261 20.1674 7.48475 20.1674 12C20.1674 16.5152 16.5152 20.1674 12 20.1674C7.48475 20.1674 3.83261 16.5152 3.83261 12C3.83261 7.48475 7.48475 3.83261 12 3.83261Z" fill="#11C054" stroke="#11C054" stroke-width="0.1"/>
  </svg>
  <svg v-else-if="type === 'invalid'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 2.95C7.00646 2.95 2.95 7.00646 2.95 12C2.95 16.9935 7.00646 21.05 12 21.05C16.9935 21.05 21.05 16.9935 21.05 12C21.05 7.00646 16.9935 2.95 12 2.95ZM12 3.83261C16.5154 3.83261 20.1674 7.48464 20.1674 12C20.1674 16.5154 16.5154 20.1674 12 20.1674C7.48464 20.1674 3.83261 16.5154 3.83261 12C3.83261 7.48464 7.48464 3.83261 12 3.83261ZM11.5307 7.76335C11.4602 7.76335 11.4058 7.78726 11.3701 7.82982C11.3357 7.87083 11.3241 7.92265 11.3241 7.96926V13.2519C11.3241 13.3226 11.3483 13.377 11.391 13.4126C11.4322 13.4469 11.4841 13.4585 11.5307 13.4585H12.4303C12.501 13.4585 12.5554 13.4343 12.591 13.3916C12.6254 13.3504 12.637 13.2985 12.637 13.2519V7.96926C12.637 7.8985 12.6127 7.84423 12.5698 7.80875C12.5286 7.77466 12.4767 7.76335 12.4303 7.76335H11.5307ZM11.5307 14.8068C11.4602 14.8068 11.4058 14.8307 11.3701 14.8733C11.3357 14.9143 11.3241 14.9661 11.3241 15.0127V16.0307C11.3241 16.1015 11.3483 16.1558 11.3912 16.1912C11.4324 16.2253 11.4843 16.2367 11.5307 16.2367H12.4693C12.5398 16.2367 12.5942 16.2127 12.6299 16.1702C12.6643 16.1292 12.6759 16.0773 12.6759 16.0307V15.0127C12.6759 14.942 12.6517 14.8877 12.6088 14.8522C12.5676 14.8181 12.5157 14.8068 12.4693 14.8068H11.5307Z" fill="#E6007E" stroke="#E6007E" stroke-width="0.1"/>
  </svg>
  <svg v-else-if="type === 'info'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10.4348 10.3848H10.3848V10.4348V11.2174V11.2674H10.4348H10.8261H11.1674V16.2543H10.8261H10.4348H10.3848V16.3043V17.087V17.137H10.4348H10.8261H11.2174H12.7826H13.1739H13.5652H13.6152V17.087V16.3043V16.2543H13.5652H13.1739H12.8326V10.4348V10.3848H12.7826H12.3913H10.8261H10.4348ZM12 2.95C7.00646 2.95 2.95 7.00646 2.95 12C2.95 16.9935 7.00646 21.05 12 21.05C16.9935 21.05 21.05 16.9935 21.05 12C21.05 7.00646 16.9935 2.95 12 2.95ZM12 3.83261C16.5154 3.83261 20.1674 7.48464 20.1674 12C20.1674 16.5154 16.5154 20.1674 12 20.1674C7.48464 20.1674 3.83261 16.5154 3.83261 12C3.83261 7.48464 7.48464 3.83261 12 3.83261ZM12 6.47174C11.6754 6.47174 11.3641 6.60069 11.1346 6.83021C10.905 7.05974 10.7761 7.37105 10.7761 7.69565C10.7761 8.02025 10.905 8.33156 11.1346 8.56109C11.3641 8.79062 11.6754 8.91956 12 8.91956C12.3246 8.91956 12.6359 8.79062 12.8654 8.56109C13.095 8.33156 13.2239 8.02025 13.2239 7.69565C13.2239 7.37105 13.095 7.05974 12.8654 6.83021C12.6359 6.60069 12.3246 6.47174 12 6.47174Z" fill="#333333" stroke="#333333" stroke-width="0.1"/>
  </svg>
</template>

<script>
export default {
  name: 'UserActivationIcon',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>