<template>
  <div v-if="type === 'Integrity_badge_Medium'" class="hero-wrapper" @click="$router.push({ name: 'HomeData' })">
    <img
      v-if="language === 'SE'"
      src="../../assets/Integrity_badge_Medium_SE.png" alt="">
    <img
      v-else-if="language === 'DK'"
      src="../../assets/Integrity_badge_Medium_DK.png" alt="">
    <img
      v-else-if="language === 'NO'"
      src="../../assets/Integrity_badge_Medium_NO.png" alt="">
    <img
      v-else-if="language === 'FI'"
      src="../../assets/Integrity_badge_Medium_FI.png" alt="">
    <img
      v-else
      src="../../assets/Integrity_badge_Medium_EN.png" alt="">
  </div>
  <div v-else-if="type === 'Integrity_badge_Light'" class="hero-wrapper" @click="$router.push({ name: 'HomeData' })">
    <img
      v-if="language === 'SE'"
      src="../../assets/Integrity_badge_Light_SE.png" alt="">
    <img
      v-else-if="language === 'DK'"
      src="../../assets/Integrity_badge_Light_DK.png" alt="">
    <img
      v-else-if="language === 'NO'"
      src="../../assets/Integrity_badge_Light_NO.png" alt="">
    <img
      v-else-if="language === 'FI'"
      src="../../assets/Integrity_badge_Light_FI.png" alt="">
    <img
      v-else
      src="../../assets/Integrity_badge_Light_EN.png" alt="">
  </div>
</template>

<script>

export default {
  name: 'HeroComponent',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected: '',
    };
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "./Hero.scss";
.hero-wrapper{
  cursor: pointer;
}
</style>