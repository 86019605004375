<template>
  <div class="activation-config-container">

    <div class="tab-content-container">
      <div class="form">
        <div class="input-container-row">
          <span v-html="infoIcon" />
          <InputField inputType="checkbox" :label="'Show country'" v-model="updatedActivation.address_country"/>
        </div>
        <div class="input-container-row">
          <span v-html="infoIcon" class="info"/>
          <InputField inputType="checkbox" :label="'Show state'" v-model="updatedActivation.address_state"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import InputField from "@/stories/components/InputField/InputField.vue";
import svgMixin from "@/mixins/SvgMixin.vue";

export default {
  components: {
    InputField,
  },
  mixins: [svgMixin],
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.address_country'(value) {
      this.emitChanges('address_country', value);
    },
    'updatedActivation.address_state'(value) {
      this.emitChanges('address_state', value);
    },
  },
  data() {
    return {
      updatedActivation: this.activation,
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./ActivationStep";


.input-container-row {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;


  .info {
    cursor: pointer;
  }
}
</style>

