<template>
  <div class="home-info">
    <div
      class="gradient-header"
      :style="`background-image: url(${require('../assets/images/HomeInfo_header.jpg')}); background-position: 0 17%;`">
      <h2 class="show-desktop">{{ transl.HomeInfo.about }}</h2>
    </div>

    <div class="home-info-body">
      <h2 class="show-tablet">{{ transl.HomeInfo.about }}</h2>

      <h3 class="info-subheader">
        {{ transl.HomeInfo.allbrands }}
      </h3>

      <div class="info-body">
        <h4>
          {{ transl.HomeInfo.welcometoaworld }}
        </h4>
        <div>
          <b>{{ transl.HomeInfo.enginioplatform }}</b>
        </div>
        <div>
          {{ transl.HomeInfo.followbrands }}
        </div>
      </div>

      <div class="info-body-two-grid">
        <div class="info-body-two">
          <iconComponent class="icon-component" symbol="collectibles" :width="40"/>

          <h4>
            {{ transl.HomeInfo.allinone }}
          </h4>
          <div>
            {{ transl.HomeInfo.findnewbrands }}
          </div>
        </div>

        <div class="info-body-two">
          <iconComponent class="icon-component" symbol="easy" :width="40"/>

          <h4>
            {{ transl.HomeInfo.easypeasy }}
          </h4>
          <div>
            {{ transl.HomeInfo.createaccountlogin }}
          </div>
        </div>

        <div class="info-body-two">
          <iconComponent class="icon-component" symbol="safetyPin" :width="40"/>

          <h4>
            {{ transl.HomeInfo.safetyfirst }}
          </h4>
          <div>
            {{ transl.HomeInfo.correctinfo }}
          </div>
          <div>
            {{ transl.HomeInfo.goodverification }}
          </div>
        </div>
      </div>

      <div class="info-body">
        <div>
          {{ transl.HomeInfo.ownedby }}
        </div>
        <div>
          {{ transl.HomeInfo.visitnrg }}
        </div>
      </div>

      <div class="login-integrity">
        <Hero
          class="login-integrity-stamp"
          type="Integrity_badge_Medium"/>
        <span>
          <div>{{ transl.HomeInfo.safeintegrity }}</div>
          <div>{{ transl.HomeInfo.readcare }}</div>

          <div class="--links">
            <span
              class="clickable"
              @click="$router.push({ name: 'HomeData' })">{{ transl.HomeInfo.integrity }}</span>
            <span
              class="clickable"
              @click="openUrl(faqUrl)">FAQ.</span>
          </div>
        </span>
      </div>

      <p class="body-1-bold">{{ transl.HomeInfo.furtherquestions }}</p>

      <div
        class="btn btn-activate"
        style="width: 200px"
        @click="openUrl(faqUrl)">
        {{ transl.HomeInfo.faq }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Hero from '../stories/components/Hero/Hero.vue';

export default {
  name: 'HomeInfo',
  components: { Hero },
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle(this.transl.HomeInfo.about);
    this.setCompany({});
  },
  computed: {
  },
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  methods: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeInfo";
</style>
