import axios from "axios";

export default {
  // Admin
  findAdminFlagged: data => {
    return axios.get("/api/pv/admin/flagged", data).then(response => {
      return {
        flagged: response.data != null ? response.data : []
      };
    });
  },

  resetFlagForReceipt: receiptId => {
    return axios.post(`/api/pv/admin/receipts/${receiptId}/reset-flag/`, {}).then(response => {
      return {
        receipt: response.data != null ? response.data : []
      };
    });
  },
};
