import axios from 'axios';

export default {
  // Admin
  findAdminPVPartnerAPI: (data) => {
    return axios.put(`/api/pv/admin/partner-api`, data)
      .then(response => {
        return {
          partnerAPIs: response.data.partnerAPIs != null
            ? response.data.partnerAPIs : [],
      }});
  },

  addAdminPVPartnerAPI: (payload) => {
    return axios.post('/api/pv/admin/partner-api', payload)
      .then(response => ({
        partnerAPI: response.data.partnerAPI != null
          ? response.data.partnerAPI : {},
      }));
  },

  saveAdminPVPartnerAPI: (payload) => {
    return axios.put(`/api/pv/admin/partner-api/${payload.entityId}`, payload)
      .then(response => ({
        partnerAPI: response.data.partnerAPI != null
          ? response.data.partnerAPI : {},
      }));
  },

  getAdminPVPartnerAPI: (payload) => {
    return axios.get(`/api/pv/admin/partner-api/${payload.entityId}`, payload)
      .then(response => ({
        partnerAPI: response.data.partnerAPI != null
          ? response.data.partnerAPI : {},
      }));
  },

  deleteAdminPVPartnerAPI: (payload) => {
    return axios.delete(`/api/pv/admin/partner-api/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
