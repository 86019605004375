<template>
  <div
    class="autocomplete"
    :class="{
      'is-in-focus': isFocus
    }"
    :style="cssProps"
    @focus="isFocus = true"
    @blur="closeSuggestions()">
    <div class="autocomplete-header">
      {{ title }}
    </div>
    <!-- make custom -->

    <div 
        class="autocomplete-country-selector"
        @blur="closeSuggestions()"
        @click="isFocus = !isFocus">
      <div v-if="!image || !country" style="height: 32px; opacity: 50%;">
        No country selected
      </div>
      <div v-else>
        <img :src="image" alt="" style="width: 32px; height: 32px; margin-right: 5px;"/>
        {{ country }}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none">
        <path d="M3.88227 7.04082L3.84691 7.00546L3.81155 7.04082L2.96464 7.88773L2.92929 7.92308L2.96464 7.95844L11.9646 16.9584L12 16.9938L12.0354 16.9584L21.0354 7.95844L21.0707 7.92308L21.0354 7.88773L20.1884 7.04082L20.1531 7.00546L20.1177 7.04082L12 15.1586L3.88227 7.04082Z" fill="#333333" stroke="#333333" stroke-width="0.1"/>
      </svg>
      <!-- <iconComponent symbol="collapseArrow" :width="32"/> -->
    </div>

    <!-- <input
      type="button"
      class="autocomplete-value"
      :class="computedClasses"
      v-model="search"
      @focus="isFocus = true"
      @input="onInput($event)"/> -->
    <ul
      :id="`autocomplete-results${uniqueId}`"
      v-show="isFocus"
      class="autocomplete-results nonselectable-text"
      @blur="closeSuggestions()">
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 18 18" fill="none">
          <path d="M14.6967 15.7854L14.7321 15.8207L14.7674 15.7854L15.6322 14.9206L15.6675 14.8853L15.6322 14.8499L11.6676 10.8758C12.4426 9.95668 12.9084 8.77117 12.9084 7.47705C12.9084 4.56567 10.5427 2.2 7.63134 2.2C4.71997 2.2 2.3543 4.56567 2.3543 7.47705C2.3543 10.3884 4.71997 12.7541 7.63134 12.7541C8.76802 12.7541 9.81978 12.3928 10.6816 11.7798L14.6967 15.7854ZM7.63134 2.91495C10.1548 2.91495 12.1934 4.95359 12.1934 7.47705C12.1934 10.0005 10.1548 12.0391 7.63134 12.0391C5.10789 12.0391 3.06924 10.0005 3.06924 7.47705C3.06924 4.95359 5.10789 2.91495 7.63134 2.91495Z" fill="#111111" stroke="#111111" stroke-width="0.1"/>
        </svg>
        <input
          type="text"
          class="autocomplete-search"
          :class="computedClasses"
          :placeholder="placeholder"
          :max="maxlength"
          v-model="search"
          @focus="isFocus = true"
          @input="onInput($event)"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter" />
      </div>
      <li
        class="loading"
        v-if="isLoading">
        <div class="autocomplete-result autocomplete-result-disabled">
          {{ loadingResults }}
        </div>
      </li>
      <li
        v-else-if="noResults && !enableAdding"
        class="no-results">
        <div class="autocomplete-result autocomplete-result-disabled">
          {{ noResult }}
        </div>
      </li>
      <li
        v-else-if="noResults && enableAdding"
        class="autocomplete-result add-option"
        @click.prevent="add()"
        @keyup.enter="add()">
        <div class="autocomplete-result">
          + {{ search }}
        </div>
      </li>
      <li v-else>
        <div
          v-for="(result, i) in results"
          :key="i"
          @click.prevent="setResult(result);"
          @focus="isFocus = true"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }">
          <img :src="result.image" style="width: 32px; height: 32px;"/>{{ result.text }}
        </div>
        <div
          v-if="!isAsync && results.length < options.length"
          class="autocomplete-result autocomplete-result-disabled">
          {{ results.length }} {{ outOf }} {{ options.length }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'AutoComplete',
  props: {
    options: {
      required: true,
      type: Array,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
    country: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search..',
    },
    noResult: {
      type: String,
      required: false,
      default: 'No results..',
    },
    loadingResults: {
      type: String,
      required: false,
      default: 'Loading results..',
    },
    outOf: {
      type: String,
      required: false,
      default: 'out of',
    },
    maxlength: {
      type: Number,
      required: false,
      default: null,
    },
    enableAdding: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    uniqueId: {
      type: String,
      required: false,
      default: '',
    },
    limit: {
      type: Number,
      required: false,
      default: 10,
    },
    scroll: {
      type: Number,
      required: false,
      default: 10,
    },
    accentColor: {
      type: String,
      required: false,
      default: '#CCCCCC',
    },
    textColor: {
      type: String,
      required: false,
      default: 'black',
    },
    resultPosition: {
      type: String,
      required: false,
      default: 'absolute',
    },
  },
  data() {
    return {
      isFocus: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: -1,
      addNew: false,
    };
  },
  computed: {
    noResults() {
      return !this.results.length;
    },
    cssProps() {
      return {
        '--autocomplete-accent-color': this.accentColor,
        '--autocomplete-text-color': this.textColor,
        '--autocomplete-result-position': this.resultPosition,
      }
    },
    computedClasses() {
      return {
        '--focus': this.isFocus,
      };
    },
  },
  mounted() {
    this.search = this.value;
    this.filterResults();
  },
  /*
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },*/
  watch: {
    value(newVal, oldVal) {
      // console.log(newVal);
      if (newVal.length !== oldVal.length) {
        this.search = this.value;
        // if (!this.options.find(el => el.text === newVal).length) {
        //   this.options.push({ value: newVal, text: newVal });
        // }
      }
    },
    options(newVal, oldVal) {
      if (this.isAsync || newVal.length !== oldVal.length) {
        this.results = newVal;
        this.isLoading = false;
        // this.filterResults();
      }
    },
  },
  methods: {
    onInput(data) {
      this.search = data.target.value;
      this.isFocus = true;
      this.arrowCounter = -1;

      // Let's warn the parent that a change was made
      this.$emit('input', this.search);
      if (this.search === '') {
        this.$emit('selected', {});
      }

      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        // Data is sync, we can search our flat array
        this.filterResults();
      }
    },
    filterResults() {
      this.results = [];
      for (let i = 0; i < this.options.length; i += 1) {
        if (this.options[i].text
          && this.options[i].text.toLowerCase().includes((this.search || '').toLowerCase())) {
          if (this.results.length < this.limit) {
            this.results.push(this.options[i]);
          }
        }
      }
    },
    setResult(result) {
      this.search = result.text;
      this.$emit('selected', result);
      this.closeSuggestions();
      this.filterResults();
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter -1;
      }
    },
    onEnter() {
      if (this.results.length && this.arrowCounter !== -1) {
        const result = this.results[this.arrowCounter];
        this.search = result.text;
        this.$emit('selected', result);
        this.closeSuggestions();
      }
    },
    add() {
      this.$emit('addOption', this.search);
      this.closeSuggestions();
    },
    closeSuggestions() {
      console.log('clicked somewhere else');
      this.isFocus = false;
      this.arrowCounter = -1;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./AutoComplete";
</style>
