<template>
  <div class="integrity-box" :class="computedClasses" @click="$emit('click')">
    <div class="--container">
      <Hero
            class="--badge"
            :type="badgeType" />

      <div class="--text">
        <p v-for="(data, index) in isArray(textData)" :key="index">{{ data }}</p>
      </div>

      <div v-if="linksList" class="--links">
        <div v-for="(link, index) in isArray(linksList)" :key="index + ' links'"
             @click="$emit('linkClick' + index)">
          {{ link }}
        </div>
      </div>

      <div class="--expandable" v-if="expandable">
        <div v-show="expanded" class="--content" :class="expandableClasses">
          <iconComponent v-for="(icon, index) in isArray(iconList)" :key="index + ' icon'" width="16px"
                         class="-icon"
                         :symbol="icon" />
          <p class="-label" v-for="(label, index) in isArray(labelList)" :key="index + ' label'">
            {{ label }}
          </p>
        </div>

        <div class="--expander" @click="expanded = !expanded">
          <iconComponent width="16px" class="-icon" :class="expanded ? 'up' : 'down'" symbol="forward" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Hero from '../Hero/Hero.vue';

export default {
  name: 'IntegrityBoxComponent',
  components: { Hero },
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    badgeType: {
      type: String,
      required: false,
      default: undefined,
    },
    textData: {
      type: [String, Array],
      required: false,
      default: '',
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconList: {
      type: [String, Array],
      required: false,
      default: '',
    },
    labelList: {
      type: [String, Array],
      required: false,
      default: '',
    },
    linksList: {
      type: [String, Array],
      required: false,
      default: '',
    },
    floaty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return {
        '--small': this.textData.length <= 1,
        '--large': this.textData.length >= 1,
        '--expandable': this.expandable,
        '--floaty': this.floaty,
      };
    },
    expandableClasses() {
      return {
        [`--rows-${this.labelList.length}`]: this.labelList.length,
      };
    },
  },
  methods: {
    isArray(element) {
      const filteredTextData = [];
      if (Array.isArray(element)) {
        return element;
      } else {
        filteredTextData.push(element);
        return filteredTextData;
      }
    },
  },
};
</script >
<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/breakpoints";
.integrity-box {
  padding: 12px 16px;
  background-color: #fcefe1;
  border-radius: 16px;
  max-width: 100%;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  .--container {
    display: flex;
    text-align: left;
    width: 100%;
    border-radius: 6px;
    flex-wrap: wrap;

    .--badge {
      width: 100%;
      order: 1;
      flex: 0 1 20%;
    }

    .--text {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.002em;
      order: 1;
      flex: 0 1 75%;
      margin-left: 5%;

      p:last-of-type {
        margin-top: 10px;
      }
    }

    .--links {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex: 0 1 100%;
      order: 3;
      text-decoration: underline;
      margin-left: 15%;
      margin-top: 10px;
    }

    .--expandable {
      order: 1;
      flex: 0 1 100%;
      display: flex;
      flex-direction: column;

      .--content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
        max-width: 70%;
        margin-left: 70px;
        align-content: flex-start;

        svg {
          margin-right: 10px;
        }

        .-label {
          word-break: break-word;
          display: flex;
          align-items: center;
        }
      }

      .--rows-2 {
        svg,
        .-label {
          flex: 0 1 35%;
        }
      }

      .--rows-3 {
        svg,
        .-label {
          flex: 0 1 26%;
        }
      }

      .--rows-4 {
        svg,
        .-label {
          flex: 0 1 25%;
        }
      }

      .--expander {
        align-self: center;

        .up {
          transform: rotate(270deg);
        }

        .down {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.--floaty {
  overflow: hidden;
  border-radius: 8px !important;
  position: fixed;
  left: 50%;
  z-index: 10;
  width: 95%;
  transform: translate(-50%, calc(0%));

  @media (max-width: $mobile-maxwidth) {
    top: calc(80%);
  }

  @media (min-width: $tablet-minwidth) {
    top: calc(82%);
  }
}
</style>