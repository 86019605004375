import axios from 'axios';

export default {
  findAdminParentCompanies: (data) => {
    return axios.put('/api/admin/parent-companies', data)
      .then(response => ({
        parentCompanies: response.data.parentCompanies != null
          ? response.data.parentCompanies : [],
      }));
  },

  addAdminParentCompany: (data) => {
    return axios.post('/api/admin/parent-companies', data)
      .then(response => ({
        parentCompany: response.data.parentCompany != null
          ? response.data.parentCompany : {},
      }));
  },

  getAdminParentCompany: (data) => {
    return axios.get(`/api/admin/parent-companies/${data.entityId}`)
      .then(response => ({
        parentCompany: response.data.parentCompany != null
          ? response.data.parentCompany : {},
      }));
  },

  saveAdminParentCompany: (payload) => {
    return axios.put(`/api/admin/parent-companies/${payload.entityId}`, payload)
      .then(response => ({
        parentCompany: response.data.parentCompany != null
          ? response.data.parentCompany : {},
      }));
  },

  addAdminParentCompanyMedia: (payload) => {
    return axios.put(`/api/admin/parent-companies/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        parentCompany: response.data.parentCompany != null
          ? response.data.parentCompany : {},
      }));
  },

  deleteAdminParentCompany: (parentCompanyId) => {
    return axios.delete(`/api/admin/parent-companies/${parentCompanyId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // No auth
  getNarParentCompany: (data) => {
    return axios.get(`/api/nar/parent-companies/${data.parentCompanyId}`)
      .then(response => ({
        parentCompany: response.data.parentCompany != null
          ? response.data.parentCompany : {},
      }));
  },
};
