<template>
  <span/>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EntityMixin',
  computed: {
    ...mapGetters('parentCompanies', ['parentCompany']),
    ...mapGetters([
      'authenticated', 'offer', 'company', 'goodiebag',
      'game', 'activation', 'receipts',
    ]),
    localGoodiebag() {
      let goodiebag = false;
      if (this.goodiebag && this.goodiebag._id) {
        goodiebag = this.goodiebag;
      } else if (this.offer && this.offer.goodiebag) {
        goodiebag = this.offer.goodiebag;
      }

      if (goodiebag && goodiebag._id) {
        return goodiebag;
      } else if (goodiebag) {
        return { _id: goodiebag };
      } else {
        return false;
      }
    },
    localGoodiebagId() {
      return this.localGoodiebag ? this.localGoodiebag._id : '';
    },
    localCompany() {
      let company = false;
      if (this.company && this.company._id) {
        company = this.company;
      } else if (this.goodiebag && this.goodiebag.company) {
        company = this.goodiebag.company;
      } else if (this.offer && this.offer.company) {
        company = this.offer.company;
      }

      if (company && company._id) {
        return company;
      } else if (company) {
        return { _id: company };
      } else {
        return false;
      }
    },
    localCompanyId() {
      return this.localCompany ? this.localCompany._id : '';
    },
    localParentCompany() {
      let parentCompany = false;
      if (this.company && this.company.parentCompany) {
        parentCompany = this.company.parentCompany;
      } else if (this.goodiebag && this.goodiebag.company && this.goodiebag.company.parentCompany) {
        parentCompany = this.goodiebag.company.parentCompany;
      } else if (this.offer && this.offer.company && this.offer.company.parentCompany) {
        parentCompany = this.offer.company.parentCompany;
      }

      if (parentCompany && parentCompany._id) {
        return parentCompany;
      } else if (parentCompany) {
        return { _id: parentCompany };
      } else {
        return false;
      }
    },
    localParentCompanyId() {
      return this.localParentCompany ? this.localParentCompany._id : '';
    },
    thirdPartyCrmName() {
      let thirdPartyCrmName = '';
      if (this.offer && this.offer._id && this.offer.third_party_crm_name) {
        thirdPartyCrmName = this.offer.third_party_crm_name;
      } else if (this.localGoodiebag && this.localGoodiebag._id && this.localGoodiebag.third_party_crm_name) {
        thirdPartyCrmName = this.localGoodiebag.third_party_crm_name;
      } else if (this.localCompany && this.localCompany._id && this.localCompany.third_party_crm_name) {
        thirdPartyCrmName = this.localCompany.third_party_crm_name;
      } else if (this.localParentCompany && this.localParentCompany._id && this.localParentCompany.third_party_crm_name) {
        thirdPartyCrmName = this.localParentCompany.third_party_crm_name;
      } else if (this.localCompany && this.localCompany._id) {
        thirdPartyCrmName = this.localCompany.name;
      }

      return thirdPartyCrmName;
    },
    userType() {
      return !this.authenticated ? 'Home' : 'User';
    },
    companyRoute() {
      return `${this.userType}Company`;
    },
    goodiebagRoute() {
      return `${this.userType}Goodiebag`;
    },
    offerRoute() {
      return `${this.userType}Offer`;
    },
  },
  methods: {
    gotoCurrentEntity(entity) {
      if (entity.direct_link) {
        const url = entity.direct_link;
        this.pushDataLayer({
          parentCompany: entity.company ? entity.company.parentCompany : 'Unknown',
          company: entity.company,
          goodiebag: entity.goodiebag,
          offer: entity,
          action: `Activate link ${url}`
        });
        this.openUrl(url);
      } else if (entity.entityType == 'company') {
        this.$router.push({ name: this.companyRoute, params: { companyId: this.getId(entity) } });
      } else if (entity.entityType == 'goodiebag') {
        this.$router.push({ name: this.goodiebagRoute, params: { goodiebagId: this.getId(entity) } });
      } else {
        this.$router.push({ name: this.offerRoute, params: { offerId: this.getId(entity) } });
      }
    },
    gotoParent(goodiebag) {
      if (goodiebag.parentGoodiebag && goodiebag.parentGoodiebag._id) {
        this.$router.replace({ name: this.goodiebagRoute, params: { goodiebagId: goodiebag.parentGoodiebag._id }});
      } else {
        this.$router.replace({ name: this.companyRoute, params: { companyId: goodiebag.company._id }});
      }
    },
  }
};
</script>
