const devMarketRegex = /dev[a-z0-9-]*|test[a-z0-9-]*|master|local[a-z0-9-]*|pr[0-9]+/

export function detectMarketFromLocation(location:Location) {
  const segments = location.pathname.split('/');

  return segments?.length >= 1 ? segments[1] : null;
}

export function countryCodeForCurrentMarket(location: Location) {
  const market = detectMarketFromLocation(location);

  // dev markets
  const defaultCountryCode = 'SE'
  if (market === null) return defaultCountryCode;
  if (market == 'localhost') return defaultCountryCode;
  if (market.match(devMarketRegex)) return defaultCountryCode;

  // production markets
  const country = market.split('-')[0];
  if (!country) return defaultCountryCode;
  if (country.toLowerCase() == 'uk') return 'GB';
  return country.toUpperCase();
}
