/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import companyApi from '../../api/company.api';
import narApi from '../../api/nar.api';

// initial state
const state = {
  number: 20,
  company: {},
  companyQuery: '',

  companies: [],
  modalCompanies: [],
  companiesStatus: '',

  assets: [],
};

// getters
const getters = {
  number: state => state.number,
  company: state => state.company,
  companyQuery: state => state.companyQuery,

  companies: state => state.companies,
  modalCompanies: state => state.modalCompanies,
  companiesStatus: state => state.companiesStatus,
  assets: state => state.assets,
};

// actions
const actions = {
  findAdminCompanies({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.findAdminCompanies(payload)
      .then((response) => {
        commit('COMPANIES', response.companies);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  publiciseAdminCompany({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return companyApi.publiciseAdminCompany(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  findUserCompanies({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    commit('COMPANY_QUERY', payload.query);
    return companyApi.findUserCompanies(payload.query, state.number)
      .then((response) => {
        commit('COMPANIES', response.companies);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.addAdminCompany(payload)
      .then((response) => {
        commit('ADD_COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  getAdminCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.getAdminCompany(payload)
      .then((response) => {
        commit('COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  getAdminCompanyAssets({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.getAdminCompanyAssets(payload)
      .then((response) => {
        commit('ASSETS', response.assets);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminCompanyMedia({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return companyApi.addAdminCompanyMedia(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  addAdminCompanyArbitraryMedia({ commit }, payload) {
    return companyApi.addAdminCompanyArbitraryMedia(payload)
      .then((response) => {
        console.log('addAdminCompanyArbitraryMedia response', response);
        return response.mediaRef;
      });
  },

  finishAdminCompanyLinks({ commit }, payload) {
    commit('GOODIEBAG_STATUS', 'LOADING');
    return companyApi.finishAdminCompanyLinks(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  saveAdminCompany({ commit }, payload) {
    if (payload.parentCompany) {
      payload.parentCompanyId = payload.parentCompany.value;
    }
    commit('GOODIEBAG_STATUS', 'LOADING');
    return companyApi.saveAdminCompany(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('GOODIEBAG_STATUS', 'FINISHED');
      });
  },

  deleteAdminCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.deleteAdminCompany(payload.entityId)
      .then(() => {
        commit('DELETE_COMPANY', payload.entityId);
        commit('COMPANY_STATUS', 'FINISHED');
        return;
      });
  },

  // Consumer
  getCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    commit('COMPANY', {});
    return companyApi.getCompany(payload)
      .then((response) => {
        const company = response.company;
        commit('COMPANY', company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  // No Auth Required
  findNarCompanies({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    commit('COMPANY_QUERY', payload.query);
    return narApi.findNarCompanies(payload.query, state.number)
      .then((response) => {
        commit('COMPANIES', response.companies);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  getNarCompany({ commit }, payload) {
    if (payload.companyId !== state.company._id) {
      commit('COMPANY_STATUS', 'LOADING');
      commit('COMPANY', {});
      return narApi.getNarCompany(payload)
        .then((response) => {
          const company = response.company;
          commit('COMPANY', company);
          commit('COMPANY_STATUS', 'FINISHED');
        });
    } else {
      return false;
    }
  },

  getUserCompanies({ commit }) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.getUserCompanies()
      .then((response) => {
        commit('COMPANIES', response.companies);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  resetCompany({ commit }) {
    commit('COMPANY', {});
  },

  // session required
  pickCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.pickCompany(payload)
      .then((response) => {
        // localStorage.setItem('dashboard', response.company.dashboard);
        commit('COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
        return response.company;
      });
  },

  linkCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.linkCompany(payload)
      .then((response) => {
        // localStorage.setItem('dashboard', response.company.dashboard);
        commit('COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
        return response.company;
      });
  },

  // Company
  getNarPickedCompany({ commit }) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.getNarPickedCompany()
      .then((success) => {
        commit('COMPANY_STATUS', 'FINISHED');
        return success;
      });
  },

  getPickedCompany({ commit }) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.getPickedCompany()
      .then((response) => {
        // localStorage.setItem('dashboard', response.company.dashboard);
        commit('COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
        return response.company;
      });
  },
};

// mutations
const mutations = {
  SET_COMPANY_NUMBER(state, number) {
    state.number = number;
  },
  COMPANY_QUERY(state, companyQuery) {
    state.companyQuery = companyQuery;
  },
  COMPANIES(state, companies) {
    state.companies = companies;
    state.modalCompanies = [];
    for (let i = 0; i < state.companies.length; i += 1) {
      state.modalCompanies.push({
        value: state.companies[i]._id,
        text: state.companies[i].name,
      });
    }
  },
  COMPANY(state, company) {
    state.company = company || {};
  },
  ADD_COMPANY(state, company) {
    state.companies.unshift(company);
  },
  ADD_GOODIEBAG_TO_COMPANY(state, goodiebag) {
    if (state.company && state.company.goodiebags) {
      state.company.goodiebags.push(goodiebag);
    }
  },
  SAVE_COMPANY(state, company) {
    for (let i = 0; i < state.companies.length; i += 1) {
      if (state.companies[i]._id === company._id) {
        Vue.set(state.companies, i, company);
      }
    }
    if (state.company._id === company._id) {
      state.company = company;
    }
  },
  SAVE_COMPANY_LINKS(state, links) {
    state.company.links_in = state.company.links_in.concat(links);
  },
  DELETE_COMPANY(state, companyId) {
    for (let i = 0; i < state.companies.length; i += 1) {
      if (state.companies[i]._id === companyId) {
        state.companies.splice(i, 1);
      }
    }
  },
  COMPANY_STATUS(state, status) {
    state.companiesStatus = status;
  },
  ASSETS(state, assets) {
    state.assets = assets;
  },
  ADD_ASSET(state, asset) {
    state.assets.push(asset);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
