<template>
  <div class="activation-config-container">

    <div class="tab-content-container">
      <div class="form">

        <div class="input-container">
          <p class="label">Header</p>
          <input v-model="updatedActivation.cta_done_header"/>
        </div>

        <div class="input-container">
          <p class="label">Text message</p>
          <input v-model="updatedActivation.cta_done_custom_message"/>
        </div>

        <div class="input-container">
          <p class="label">Icon</p>
          <select class="select" v-model="updatedActivation.cta_done_custom_icon">
            <option v-for="input in customIcons" :key="input" :value="input">
              {{ input }}
            </option>
          </select>
        </div>

        <div class="input-container">
          <p class="label">Primary button text</p>
          <input v-model="updatedActivation.cta_done_button"/>
        </div>

        <div class="input-container">
          <p class="label">Primary button description</p>
          <input v-model="updatedActivation.cta_done_button_description"/>
        </div>

        <div class="input-container">
          <p class="label">Primary button url</p>
          <input v-model="updatedActivation.cta_done_url"/>
        </div>

        <div class="input-container">
          <p class="label">Add new button text</p>
          <input v-model="updatedActivation.cta_done_add_new_button"/>
        </div>

        <InputField inputType="checkbox" :label="'Cta code visible'" v-model="updatedActivation.cta_code_visible"/>

      </div>
    </div>

  </div>
</template>

<script>

import InputField from "@/stories/components/InputField/InputField.vue";

export default {
  components: {InputField},
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.cta_done_header'(value) {
      this.emitChanges('cta_done_header', value);
    },
    'updatedActivation.cta_done_custom_message'(value) {
      this.emitChanges('cta_done_custom_message', value);
    },
    'updatedActivation.cta_done_custom_icon'(value) {
      this.emitChanges('cta_done_custom_icon', value);
    },
    'updatedActivation.cta_done_button'(value) {
      this.emitChanges('cta_done_button', value);
    },
    'updatedActivation.cta_done_button_description'(value) {
      this.emitChanges('cta_done_button_description', value);
    },
    'updatedActivation.cta_done_url'(value) {
      this.emitChanges('cta_done_url', value);
    },
    'updatedActivation.cta_done_add_new_button'(value) {
      this.emitChanges('cta_done_add_new_button', value);
    },
    'updatedActivation.cta_code_visible'(value) {
      this.emitChanges('cta_code_visible', value);
    },
  },
  data() {
    return {
      updatedActivation: this.activation,
      selectedCustomIcon: 'person',
      customIcons: ['person', 'heart', 'festival', 'confetti', 'gift', 'info', 'shop', 'question'],
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
  },
}

</script>

<style lang="scss" scoped>

@import "./ActivationStep";

.form {
  margin-top: 16px;
}

</style>

