<template>
  <div class="activation-config-container">
    <div class="tabs">
      <button class="button-tab with-margin-left" :class="{'selected' : activeTab === 1}" @click="activeTab = 1;">Content</button>
      <button class="button-tab" :class="{'selected' : activeTab === 2}" @click="activeTab = 2;">Advanced settings</button>
    </div>

    <div class="tab-content-container">
      <div class="form" v-if="activeTab === 1">

        <div class="receipt-date-pickers-container">
          <div class="receipt-date-picker-container">
            <span class="label">Accept receipt from</span>
            <div class="receipt-date">
              <div class="date-picker-container">
                <md-datepicker
                  :value="toMilliSeconds(updatedActivation.receipt_date_range_from)"
                  @input="updatedActivation.receipt_date_range_from = toSeconds($event)"
                  :md-model-type=Number
                  :md-clearable="true">
                </md-datepicker>
              </div>
            </div>
          </div>

          <div class="receipt-date-picker-container">
            <span class="label">Accept receipt to</span>
            <div class="receipt-date">
              <div class="date-picker-container">
                <md-datepicker
                  :value="toMilliSeconds(updatedActivation.receipt_date_range_to)"
                  @input="updatedActivation.receipt_date_range_to = toSeconds($event)"
                  :md-model-type=Number
                  :md-clearable="true">
                </md-datepicker>
              </div>
            </div>
          </div>

        </div>

        <chip-area :key="1" :data-field="updatedActivation.receipt_terms" :label="'Receipt terms'" :with-search-input="true"
                   :placeholder="'Add term...'" @change="emitChanges('receipt_terms', $event)"/>

        <div class="shop-list-container">
          <span class="label">Receipt shop</span>
          <div class="shop-list-selection">
            <InputField inputType="radio" :label="'Include list'" :value="activeShopList === 'include'" @input="activeShopList = 'include'; setSelectedList('include')" />
            <InputField inputType="radio" :label="'Exclude list'" :value="activeShopList === 'exclude'" @input="activeShopList = 'exclude'; setSelectedList('exclude')" />
          </div>

          <chip-area v-if="activeShopList === 'include'" :key="2" :data-field="updatedActivation.receipt_shop_list" :with-search-input="true"
                     :placeholder="'Add shop...'" @change="emitChanges('receipt_shop_list', $event)"/>

          <chip-area v-else :key="3" :data-field="updatedActivation.receipt_shop_exclude_list" :with-search-input="true"
                     :placeholder="'Add shop...'" @change="emitChanges('receipt_shop_exclude_list', $event)"/>
        </div>
      </div>

      <div class="form" v-if="activeTab === 2">

        <div class="input-container">
          <p class="label">Receipt term minimum amount</p>
          <input v-model="updatedActivation.receipt_term_minimum_amount"/>
        </div>

        <div class="input-container">
          <p class="label">Receipt similarity</p>
          <input v-model="updatedActivation.receipt_similarity"/>
        </div>

        <InputField inputType="checkbox" :label="'Advanced term search'" v-model="updatedActivation.receipt_terms_search_advanced"/>

        <InputField inputType="checkbox" :label="'Can change item'" v-model="updatedActivation.receipt_can_change_item"/>

        <InputField inputType="checkbox" :label="'Can change item cost'" v-model="updatedActivation.receipt_can_change_item_cost"/>

        <InputField inputType="checkbox" :label="'Can change shop'" v-model="updatedActivation.receipt_can_change_store"/>

        <InputField inputType="checkbox" :label="'Can change date'" v-model="updatedActivation.receipt_can_change_date"/>

        <div class="input-container">
          <p class="label">Max days since receipt date</p>
          <input v-model="updatedActivation.receipt_date_max_days"/>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import InputField from "@/stories/components/InputField/InputField.vue";
import ChipArea from "@/stories/components/ChipArea/ChipArea.vue";
import DateTimeConverterMixin from "@/mixins/DateTimeConverterMixin.vue";

export default {
  name: 'ReceiptStepConfig',
  mixins: [DateTimeConverterMixin],
  components: {ChipArea, InputField},
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.receipt_term_minimum_amount'(value) {
      this.emitChanges('receipt_term_minimum_amount', value);
    },
    'updatedActivation.receipt_similarity'(value) {
      this.emitChanges('receipt_similarity', value);
    },
    'updatedActivation.receipt_shop_exclude_list'(value) {
      this.emitChanges('receipt_shop_exclude_list', value);
    },
    'updatedActivation.receipt_terms_search_advanced'(value) {
      this.emitChanges('receipt_terms_search_advanced', value);
    },
    'updatedActivation.receipt_can_change_item'(value) {
      this.emitChanges('receipt_can_change_item', value);
    },
    'updatedActivation.receipt_can_change_item_cost'(value) {
      this.emitChanges('receipt_can_change_item_cost', value);
    },
    'updatedActivation.receipt_can_change_store'(value) {
      this.emitChanges('receipt_can_change_store', value);
    },
    'updatedActivation.receipt_can_change_date'(value) {
      this.emitChanges('receipt_can_change_date', value);
    },
    'updatedActivation.receipt_date_max_days'(value) {
      this.emitChanges('receipt_date_max_days', value);
    },
    'updatedActivation.receipt_date_range_from'(value) {
      this.emitChanges('receipt_date_range_from', value);
    },
    'updatedActivation.receipt_date_range_to'(value) {
      this.emitChanges('receipt_date_range_to', value);
    },
  },
  created() {
    this.activeShopList = this.updatedActivation.receipt_shop_exclude_list_active ? 'exclude' : 'include';
  },
  data() {
    return {
      updatedActivation: {
        ...this.activation,
        receipt_date_range_from: this.activation.receipt_date_range_from ?? undefined,
        receipt_date_range_to: this.activation.receipt_date_range_to ?? undefined
      },
      activeTab: 1,
      activeShopList: 'include',
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
    setSelectedList(listType) {
      if (listType === 'exclude')
        this.emitChanges('receipt_shop_exclude_list_active', true);
      else
        this.emitChanges('receipt_shop_exclude_list_active', false);
    },
  },
  computed: {
    useDateRange() {
      return this.updatedActivation.receipt_date_range_from;
    },
  },
}

</script>

<style lang="scss">
@import "./ActivationStep";

.shop-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  padding: 32px 16px;
  border: 1px dashed #E0E0E0;
  border-radius: 4px;

  .label {
    text-align: start;
    font-size: 16px;
    font-weight: 800;
  }

  .shop-list-selection {
    display: flex;
    flex-direction: row;
    gap: 16px;

    margin-top: 8px;
  }
}

.tab-content-container {

  .receipt-date-pickers-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .receipt-date-picker-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .receipt-date {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 12px;
        margin-top: 4px;

        height: 40px;

        background: #F2F2F2;
        border-radius: 4px;

        .date {
          margin-right: 8px;
        }

        &.no-picker {
          width: 230px;
          font-size: 16px;
        }
      }

      .date-picker-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        gap: 8px;

        span {
          cursor: default;
          color: var(--enginio-fire-orange, #EA7801);
        }
      }

      .md-icon {
        color: #00B412;

        &:after {
          height: 0;
          content: "";
        }
      }

      .md-field {

        &:after {
          height: 0;
          content: "";
        }

        &.md-theme-default:before {
          background-color: transparent;
        }
      }

    }
  }
}
</style>
