<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <h1>Enginio - a privacy friendly platform</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <h3 style="margin: 17px 25px;">Enginio - a privacy friendly platform</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div
             class="data-image"
             :style="`background-image: url(${require('../assets/images/Integrity_header.jpg')}); background-position: 0 49%;`">
          <Hero
                class="absolute-stamp"
                type="Integrity_badge_Light" />
        </div>

        <div class="data-text">
          <div>
            <b>We care about your privacy. Therefore, we at NRG Nordic Retail Group AB and <u> companies
                with
                sites at Enginio</u> want to inform you about how we process your personal data and how we
              use
              cookies when you use Enginio. </b>
          </div>

          <div>
            On the links below, you will find detailed information on how your personal data will be processed
            in each specific situation.
          </div>

          <div>
            <b>If you visit the Engino platform,</b> we process your personal data to:
          </div>

          <div class="indent-left">
            <span>• Improve Enginio and the activities on the platform by the use of Google Analytics,
              and</span>
            <span>• Show you relevant marketing on other sites, e.g. on Facebook. </span>
          </div>

          <div>
            <b>If you become a member of Enginio,</b> we process your personal data to:
          </div>

          <div class="indent-left">
            <span>• Provide your membership, </span>
            <span>• Send surveys, and</span>
            <span>• If you have consented, we send you newsletters which we adapt to suit you. We analyse how
              our newsletters are used.</span>
          </div>

          <div>
            <b>If you participate in different activities on Enginio,</b> we process your personal data to:
          </div>

          <div class="indent-left">
            <span>• Enable you to participate in competitions, campaigns and other activities, </span>
            <span>• Let you collect “Enginio point” from your interactions with us, and</span>
            <span>• If you have consented, we send you newsletters which we adapt to suit you. We analyse how
              our newsletters are used.</span>
          </div>

          <div>
            You decide what personal data you state in your membership profile! Go to <u>your membership
              page</u> to
            see what personal data you are sharing with us. You are of course free to change and delete the
            information at any time.
          </div>

          <div>
            Your personal data may be transferred outside of the EU/EEA if the suppliers we use are based or
            process your personal data outside of the EU/EEA. For example, when we use Google Analytics your
            personal data will be transferred to the United States since Google processes your personal data
            there.
          </div>

          <div>
            <h4>Cookies</h4>
          </div>

          <div>
            We use cookies to make Enginio work, to improve Engino by the use of Google analytics, to show
            relevant marketing and to analyse newsletters. You find our cookie policy here.
          </div>

          <div>
            <b>Feel free to contact us if you have any questions or want to exercise your rights</b>
          </div>

          <div>
            In our privacy policies above, you will find information about your rights. For example, you can
            request access to your personal data at any time, withdraw consent or object to marketing.
          </div>

          <div>
            If you have any questions regarding the processing of your personal data, or if you wish to
            exercise any of your rights, please feel free to contact NRG Nordic Retail Group AB contact or the
            <u>company</u> you have interacted with on Enginio. You can reach NRG Nordic Retail Group AB at
            <u>info@nordicretailgroup.com</u> or Linnégatan 9-11 114 47, Stockholm.
          </div>
        </div>
      </div>

      <div class="right-card">
        <div class="--container">
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.cookies' })">
            <div class="button-text">Cookies policy</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.visitors' })">
            <div class="button-text">Privacy Policy - Visitors</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.members' })">
            <div class="button-text">Privacy Policy - Members</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.participants' })">
            <div class="button-text">Privacy Policy - Participants</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.terms' })">
            <div class="button-text">Terms and conditions</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <!--<div
             class="data-link"
             @click="$router.push({ name: 'HomeData.companies' })">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Hero from '../stories/components/Hero/Hero.vue';

export default {
  name: 'HomeData',
  components: { Hero },
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setCompany({});
    this.setPageTitle('A privacy friendly platform');
  },
  computed: {
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeData";
</style>
