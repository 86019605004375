<template>
  <span/>
</template>

<script>
import axios from 'axios';

export default {
  name: 'BeforeEnterMixin',
  data() {
    return {
      fromRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('setFromRoute', from);

      const serverVersion = localStorage.getItem('serverVersion');
      axios.get('/api/nar/version').then(response => {
        const { version } = response.data;
        localStorage.setItem('serverVersion', version);
        if (serverVersion !== version) {
          location.reload();
        }
      });

      // const acceptedCookies = localStorage.getItem(this.cookieModalVersion);
      const sixmonths = 1000*60*60*24*30*6;
      const lastclear = localStorage.getItem('lastclear') || sixmonths;
      const time_now = (new Date()).getTime();
      if ((time_now - lastclear) > sixmonths) {
        localStorage.clear();
        localStorage.setItem('lastclear', time_now);
        // localStorage.setItem(this.cookieModalVersion, acceptedCookies);
      }
    });
  },
};
</script>
