<template>
  <div v-if="loadedPage">

    <CollectionView
      :collection="theCompany"
      :showIntegrity="true"/>
  </div>
</template>

<script>
import EntityMixin from '../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import CollectionView from '../stories/components/CollectionView/CollectionView.vue';

export default {
  name: 'HomeCompany',
  components: {
    CollectionView,
  },
  mixins: [BeforeEnterMixin, EntityMixin],
  props: ['companyId'],
  mounted() {
    if (!this.companyId) {
      this.$router.replace({ name: 'Home' });
    } else {
      this.$store
        .dispatch('getNarCompany', { companyId: this.companyId })
        .then(() => {
          if (this.company.replace_company) {
            return this.$router.replace({ name: 'HomeGoodiebag', params: { goodiebagId: this.getId(this.company.replace_company) } })
          }

          this.setPageTitle(this.company.name);
          this.loadedPage = true;
          this.setLoadedPageData(true);
          // this.$dpAnalytics({ view: 'HomeCompany', subject: this.company._id, action: 'enter' });
          this.pushDataLayer({
            parentCompany: this.company.parentCompany,
            company: this.company,
            goodiebag: 'None',
            offer: 'None',
            action: 'Enter',
          });
          if (this.company.parentCompany) {
            this.$store.dispatch('parentCompanies/getNarParentCompany', {
              parentCompanyId:
                this.company.parentCompany._id || this.company.parentCompany,
            });
          }
        })
        .catch(() => {
          this.$router.replace({ name: 'Home' });
        });
    }
  },
  watch: {
    companyId(newVal, oldVal) {
      if (newVal !== oldVal) {
        location.reload();
      }
    },
  },
  computed: {
    theCompany () {
      return {
        ...this.company,
        company: this.company,
      };
    }
  },
  destroyed() {
    this.$store.dispatch('resetCompany');
  },
  data() {
    return {
      loadedPage: false,
    };
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
