<template>
  <div
    v-if="goodiebag && goodiebag._id"
    class="goodiebag-preview entity-preview">
    <div class="preview-width-picker">
      <h3>Preview of Goodiebag "{{ goodiebag.name }}"</h3>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'computer'">
        <iconComponent symbol="computer"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'tablet'">
        <iconComponent symbol="tablet"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'smartphone'">
        <iconComponent symbol="smartphone"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="$emit('close')">
        <iconComponent symbol="cross"/>
      </md-button>
    </div>
    <div
      class="preview-default home-goodiebag"
      :class="{
        'timeless-theme-goodiebag': true,
        'radiant-theme-goodiebag': false,
        'preview-tablet': previewWidth === 'tablet',
        'preview-smartphone': previewWidth === 'smartphone',
        'iris': goodiebag.theme ? goodiebag.theme : 'iris',
      }">
      <div
           class="goodiebag-page"
           :style="goodiebag.theme === 'nemo' && goodiebag.media ? `background-image: url(${goodiebag.media.url});`: ''">
        <div
          class="header-image"
          :style="`background-image: url(${goodiebag.theme !== 'nemo' && goodiebag.media ? goodiebag.media.url : ''});`
          +`margin-bottom: ${goodiebag.icon?.url ? '2rem;' : '0;'}`">
        </div>
        <div class="goodiebag-content-wrapper content-page">
          <img
            v-if="goodiebag.icon?.url"
            class="header-logo"
            :src="`${goodiebag.icon?.url}`"
            @click="gotoParent(goodiebag)"/>
          <h2>{{ goodiebag.name }}</h2>
          <div class="header-info body-1">{{ goodiebag.info }}</div>
          <cardWrapper :data="currentOffers" :theme="goodiebag.theme" :secondary-data="closedOffers" @cardClick="gotoCurrentOffer($event)" :start-countdown="startCountdown" v-if="currentOffers && currentOffers.length"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardWrapper from '../stories/components/CardWrapper/CardWrapper.vue'

export default {
  name: 'PreviewGoodiebag',
  components: {CardWrapper},
  props: {
    goodiebag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentOffers() {
      let offers = [];
      if (this.goodiebag && this.goodiebag._id) {
        if (this.goodiebag.offers && this.goodiebag.offers.length) {
          for (let i = 0; i < this.goodiebag.offers.length; i += 1) {
            const offer = this.goodiebag.offers[i];
            if (!offer.closed) {
              offers.push(offer);
            }
          }
        }
        if (this.goodiebag.donations && this.goodiebag.donation_media && this.goodiebag.donation_media.url) {
          const style = 'position: absolute; top: 50%; left: 20px; transform: translateY(-50%); font-weight: 600; text-align: left; font-size: 18px;';
          const donations = this.goodiebag.donation_amount ? this.goodiebag.donation_amount + 1000 : 1000;
          offers.unshift({
            media: [this.goodiebag.donation_media],
            clickable: false,
            columns: 2,
            donation: true,
            htmlText: `<div style="${style}"><div>${this.transl.Goodiebag.donationstatus}</div><div style="font-size: 30px; line-height: 30px; padding: 1rem 0; color: white; text-shadow: 2px 2px 4px black;">${donations}</div><div>${this.goodiebag.company.name} ${this.transl.Goodiebag.products}</div></div>`,
          });
        }
        if (this.goodiebag.childGoodiebags && this.goodiebag.childGoodiebags.length) {
          for (let index = 0; index < this.goodiebag.childGoodiebags.length; index++) {
            const childGoodiebag = this.goodiebag.childGoodiebags[index];
            offers.push({
              unique_name: childGoodiebag.unique_name,
              media: [childGoodiebag.media || {}],
              columns: 2,
              clickable: true,
              first_header: childGoodiebag.name,
              inlineCollection: true,
              quick_text: `${childGoodiebag.offers.length} ${this.transl.Goodiebag.activations}`,
              order: childGoodiebag.order,
              _id: childGoodiebag._id
            });
          }
          offers.sort( function (a, b) {
            if (!a.order) {
              a.order = 0;
            } else if (!b.order) {
              b.order = 0;
            }
            return b.order - a.order;
          });
        }
      }
      return offers;
    },
    closedOffers() {
      let offers = [];
      if (this.goodiebag && this.goodiebag.offers && this.goodiebag.offers.length) {
        for (let i = 0; i < this.goodiebag.offers.length; i += 1) {
          const offer = this.goodiebag.offers[i];
          if (offer.closed) {
            offers.push(offer);
          }
        }
      }
      return offers;
    },
  },
  data() {
    return {
      previewWidth: 'computer',
      startCountdown: true,
    };
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/HomeGoodiebag";
</style>
