/* eslint-disable no-shadow, no-param-reassign */
import narApi from '../../api/nar.api';
import offerApi from '../../api/offer.api';
import goodiebagApi from '../../api/goodiebag.api';

// initial state
const state = {
  query: '',
  number: 8,
  status: '',

  goodiebags: [],
  offers: []
};

// getters
const getters = {
  query: state => state.query,
  number: state => state.number,
  status: state => state.status,

  goodiebags: state => state.goodiebags,
  offers: state => state.offers
};

// actions
const actions = {
  // No Auth Required
  findNarSuggestions({ commit }, payload) {
    commit('SEARCH_STATUS', 'LOADING');
    commit('QUERY', payload.query);
    payload.number = state.number;
    return narApi.findNarSuggestions(payload).then((response) => {
        commit('GOODIEBAGS', response.goodiebags);
        commit('OFFERS', response.offers);
        commit('SEARCH_STATUS', 'FINISHED');
      });
  },

  // Admin
  findAdminOffers({ commit }, payload) {
    commit('SEARCH_STATUS', 'LOADING');
    return offerApi.findAdminOffers(payload).then((response) => {
        commit('OFFERS', response.offers);
        commit('SEARCH_STATUS', 'FINISHED');
      });
  },

  findAdminGoodiebags({ commit }, payload) {
    commit('SEARCH_STATUS', 'LOADING');
    return goodiebagApi.findAdminGoodiebags(payload).then((response) => {
        commit('GOODIEBAGS', response.goodiebags);
        commit('SEARCH_STATUS', 'FINISHED');
      });
  },

  // COMPANY
  findCompanyOffers({ commit }, payload) {
    commit('SEARCH_STATUS', 'LOADING');
    return offerApi.findCompanyOffers(payload).then((response) => {
        commit('OFFERS', response.offers);
        commit('SEARCH_STATUS', 'FINISHED');
      });
  },

  findCompanyGoodiebags({ commit }, payload) {
    commit('SEARCH_STATUS', 'LOADING');
    return goodiebagApi.findCompanyGoodiebags(payload).then((response) => {
        commit('GOODIEBAGS', response.goodiebags);
        commit('SEARCH_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  QUERY(state, status) {
    state.status = status;
  },
  SET_NUMBER(state, number) {
    state.number = number;
  },
  SEARCH_STATUS(state, status) {
    state.status = status;
  },

  GOODIEBAGS(state, status) {
    state.goodiebags = status;
  },
  OFFERS(state, status) {
    state.offers = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
