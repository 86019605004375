<template>
  <div v-if="loadedPage">
    <CollectionView
      :preview="false"
      :collection="goodiebag"
      :authenticated="true"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import EntityMixin from '../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';
import { isMobile, isBrowser, isIOS, isAndroid } from 'mobile-device-detect';
import CollectionView from '../stories/components/CollectionView/CollectionView.vue';

export default {
  name: 'UserGoodiebag',
  components: {CollectionView},
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin, EntityMixin],
  props: ['goodiebagId'],
  mounted() {
    if (!this.goodiebagId) {
      this.$router.replace({ name: 'UserStart' });
    } else {
      this.$store.dispatch("getGoodiebag", { goodiebagId: this.goodiebagId })
        .then(() => {
          if (this.goodiebag.banner) {
            this.gotoParent(this.goodiebag);
            return;
          }
          this.loadedPage = true;
          this.setLoadedPageData(true);
          this.setPageTitle(this.goodiebag.company.name, this.goodiebag.name);
          this.$store.dispatch('getNarCompany', { companyId: this.goodiebag.company._id }).then(() => {
            // this.$dpAnalytics({ view: 'UserGoodiebag', subject: this.goodiebag._id, action: 'enter' });
            this.pushDataLayer({
              parentCompany: this.goodiebag.company ? this.goodiebag.company.parentCompany : 'Unknown',
              company: this.goodiebag.company,
              goodiebag: this.goodiebag,
              offer: 'None',
              action: 'Enter'
            });
          });
          if (this.goodiebag.company.parentCompany) {
            this.$store.dispatch('parentCompanies/getNarParentCompany', { parentCompanyId: this.goodiebag.company.parentCompany._id || this.goodiebag.company.parentCompany });
          }
          /* if (this.goodiebag.offers.length === 1) {
            this.$router.replace({
              name: 'UserOffer',
              params: { offerId: this.goodiebag.offers[0]._id }
            });
          } else { */
          this.startCountdown = true;
          setTimeout(() => {
            let elements = document.getElementsByClassName("autoplayMedia");
            for (let i = 0; i < elements.length; i += 1) {
              this.autoplay(elements[i]);
            }
          }, 200);
          // }
        })
        .catch((error) => {
          this.findEntityIssue(error);
        });
    }
  },
  watch: {
    goodiebagId(newVal, oldVal) {
      if (newVal !== oldVal) {
        location.reload();
      }
    },
  },
  computed: {
    ...mapGetters(["user", "userStatus"]),
  },
  destroyed() {
    this.$store.dispatch('resetGoodiebag');
  },
  data() {
    return {
      loadedPage: false,
      startCountdown: false,
      isMobile,
      isBrowser,
      isIOS,
      isAndroid,
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/UserGoodiebag";
</style>