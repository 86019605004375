<template>
  <div class="drop-down">
    <span @click="visible = !visible;" v-html="moreIcon"/>
    <div class="drop-down-container" ref="dropDown" tabindex="-1" @focusout="handleFocusOut">
      <div v-if="visible" class="drop-down-menu">
        <div v-for="(item) in listItems" :key="item.label" class="drop-down-menu-item" @click="$emit('selected', item.action); visible = false;">
          <iconComponent v-if="item.icon !== '' && item.icon !== undefined" :symbol="item.icon" :width="20"/>
          <div v-else v-html="item.svgIcon"/>
          <div class="label"> {{ item.label }} </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SvgMixin from "@/mixins/SvgMixin.vue";

export default {
  name: 'DropDown',
  mixins: [SvgMixin],
  props: {
    listItems: {
      type: Array,
      required: true,
    },
  },
  watch: {
    visible(value) {
      if (value)
        this.$refs.dropDown.focus();
    }
  },
  data() {
    return {
      visible: false
    }
  },
  mounted() {

  },
  methods: {
    handleFocusOut() {
      this.visible = false;
    }
  }
}


</script>
<style lang="scss" scoped>
@import './DropDown.scss';
</style>
