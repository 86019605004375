<template>
  <div>
    <div class="preview-container" :style="containerStyle" ref="container">
      <div class="preview-canvas" :style="canvasStyle" ref="canvasContainer">
        <slot></slot>
      </div>
    </div>
    <VueSlider
      v-model="scrollOffset"
      class="preview-scrollbar"
      :class="scrollbarClasses"
      :direction="'ttb'"
      :dotSize="16"
      :width="8"
      :height="height"
      :min="0"
      :max="maxScrollOffset"
      :tooltip="'none'"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'ComponentPreview',
  components: { VueSlider },
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    zoom: {
      type: Number,
      default: 1,
    },
    borderWidth: {
      type: Number,
      default: 8,
    },
    showScrollbar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resizeObserver: null,
      contentWidth: 0,
      contentHeight: 0,
      scrollOffset: 0,
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    if (this.$refs.canvasContainer) {
      this.resizeObserver.observe(this.$refs.canvasContainer);
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  },
  computed: {
    borderSize() {
      return this.borderWidth * 2
    },
    containerStyle() {
      return {
        width: `${this.width + this.borderSize}px`,
        height: `${this.height + this.borderSize}px`,
        border: `${this.borderWidth}px solid #BAC0CA`,
        overflowY: this.showScrollbar ? 'scroll' : 'hidden'
      }
    },
    canvasStyle() {
      const scrollBarWidth = this.showScrollbar ? 8 : 0;
      const scale = (this.width - (this.borderSize + scrollBarWidth)) / ((this.width - this.borderSize) * this.zoom);

      return {
        width: `${this.width * this.zoom}px`,
        height: 'auto',
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      }
    },
    maxScrollOffset() {
      return this.contentHeight - this.height;
    },
    scrollbarClasses() {
      return {
        'preview-scrollbar--hidden': (this.contentHeight - Math.floor(this.height * this.zoom)) < 5
      }
    }
  },
  methods: {
    getContentSize(element) {
      this.contentWidth = element.clientWidth;
      this.contentHeight = element.clientHeight;
    },
    handleResize(entries) {
      for (const entry of entries) {
        this.getContentSize(entry.target);
      }
    },
  },
  watch: {
    scrollOffset(value) {
      this.$refs.container.scrollTop = value;
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-container {
  position: relative;
  border-radius: 8px;
  background-color: #f9f5f5;
  overflow-x: hidden;
}

.preview-canvas {
  position: absolute;
}

.preview-scrollbar {
  position: absolute;
  top: 8px;
  right: -32px;
}

.preview-scrollbar--hidden {
  display: none;
}
</style>