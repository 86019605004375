/* eslint-disable no-shadow, no-param-reassign */
import chainApi from '../../api/pvChain.api';

// initial state
const state = {
  chain: {},

  chainQuery: '',
  chains: [],

  modalPvChains: [],
};

// getters
const getters = {
  chain: state => state.chain,

  chainQuery: state => state.chainQuery,
  chains: state => state.chains,

  modalPvChains: state => state.modalPvChains,
};

// actions
const actions = {
  // ADMIN
  findAdminPVChain({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return chainApi.findAdminPVChain(payload)
      .then((response) => {
        commit('CHAINS', response.chains);
        commit('CHAIN_STATUS', 'FINISHED');
      });
  },

  findAdminModalPVChains({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return chainApi.findAdminPVChain(payload)
      .then((response) => {
        commit('MODAL_CHAINS', response.chains);
        commit('CHAIN_STATUS', 'FINISHED');
      });
  },

  addAdminPVChain({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return chainApi.addAdminPVChain(payload)
      .then((response) => {
        commit('ADD_CHAIN', response.chain);
        commit('CHAIN_STATUS', 'FINISHED');
      });
  },

  saveAdminPVChain({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return chainApi.saveAdminPVChain(payload)
      .then((response) => {
        commit('SAVE_CHAIN', response.chain);
        commit('CHAIN_STATUS', 'FINISHED');
      });
  },

  getAdminPVChain({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return chainApi.getAdminPVChain(payload)
      .then((response) => {
        commit('CHAIN', response.chain);
        commit('CHAIN_STATUS', 'FINISHED');
      });
  },

  deleteAdminPVChain({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return chainApi.deleteAdminPVChain(payload)
      .then(() => {
        commit('DELETE_CHAIN', payload.entityId);
        commit('CHAIN_STATUS', 'FINISHED');
        return;
      });
  },
};

// mutations
const mutations = {
  CHAIN(state, chain) {
    state.chain = chain;
  },
  CHAINS(state, chains) {
    state.chains = chains;
  },
  ADD_CHAIN(state, chain) {
    state.chains.unshift(chain);
  },

  CHAIN_QUERY(state, chainQuery) {
    state.chainQuery = chainQuery;
  },
  SAVE_CHAIN(state, chain) {
    state.chain = chain;
  },
  DELETE_CHAIN(state) {
    state.chain = {};
  },
  CHAIN_STATUS(state, status) {
    state.chainStatus = status;
  },
  MODAL_CHAINS(state, chains) {
    state.modalPvChains = [];
    for (let i = 0; i < chains.length; i += 1) {
      state.modalPvChains.push({
        value: chains[i]._id,
        text: chains[i].name,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
