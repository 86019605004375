<template>
  <portal to="modals">
    <div v-if="this.show || this.loaded" class="partner-modal">
      <transition name="overlay-fade" appear>
        <div v-if="this.showContent && this.showOverlay" class="partner-modal-overlay"></div>
      </transition>
      <div class="partner-modal-clickable" @click="handleOverlayClick"></div>
      <transition name="content-fade">
        <div class="partner-modal-content" v-if="this.showContent" ref="contentContainer" :class="contentClasses">
          <slot></slot>
          <div v-if="this.showCloseButton">
            <button @click="handleClose" class="close-button">
              <iconComponent symbol="cross" :width="24"/>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>

export default {
  name: 'PartnerModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      loaded: false,
      showContent: false,
      showOverlay: false,
      timeout: null,
      visible: false,

      windowWidth: 0,
      windowHeight: 0,
      contentWidth: 0,
      contentHeight: 0,
      resizeObserver: null,
    }
  },
  mounted() {
    this.getWindowSize();
    window.addEventListener('resize', this.getWindowSize);
    this.resizeObserver = new ResizeObserver(this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowSize);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  },
  destroyed() {
    clearTimeout(this.timeout);
    this.$nextTick(this.unlockScrolling);
  },
  watch: {
    show(isTrue) {
      if (isTrue) {
        this.loaded = true;
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.loaded = false;
        }, 300)
      }

      this.$nextTick(() => {
        this.showContent = isTrue;
      });

      this.showOverlay = document.getElementsByClassName('partner-modal-overlay').length < 1;
    },
    showContent(isTrue) {
      if (isTrue) {
        this.$nextTick(() => {
          this.$emit('appeared');
          this.$nextTick(this.startObserving);
        });
      } else {
        this.stopObserving();
      }
    },
    loaded(isTrue) {
      if (isTrue) {
        document.body.style.overflow = 'hidden';
      } else {
        this.$emit('disappeared');
        setTimeout(this.unlockScrolling, 5);
      }
    }
  },
  computed: {
    contentClasses() {
      return {
        'partner-modal-content--borderless': this.contentWidth >= this.windowWidth && this.contentHeight >= this.windowHeight,
      }
    }
  },
  methods: {
    unlockScrolling() {
      if (document.getElementsByClassName('partner-modal').length < 1) {
        document.body.style.overflow = '';
      }
    },
    handleClose() {
      this.$emit('close');
    },
    handleOverlayClick(event) {
      event.stopPropagation();
      event.preventDefault();
      this.handleClose();
    },
    handleResize(entries) {
      for (const entry of entries) {
        this.getContentSize(entry.target);
      }
    },
    getContentSize(container) {
      this.contentWidth = container.clientWidth;
      this.contentHeight = container.clientHeight;
    },
    getWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    startObserving() {
      if (this.$refs.contentContainer) {
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(this.handleResize);
          this.resizeObserver.observe(this.$refs.contentContainer);
        }

        this.resizeObserver.observe(this.$refs.contentContainer);
      } else {
        // console.log('No ref');
      }

    },
    stopObserving() {
      if (this.resizeObserver) {
        if (this.$refs.contentContainer) {
          this.resizeObserver.unobserve(this.$refs.contentContainer);
        } else {
          // console.log('No ref');
        }
        this.resizeObserver = null;
      }
    },

  }
}
</script>

<style lang='scss' scoped>
@import "../../../assets/scss/components/company";

$partner-modal-border-radius: 12px;
$partner-modal-z-index: 10;

.partner-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $partner-modal-z-index;
  // background-color: rgba(255,0,0,0.25);

  .partner-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .partner-modal-clickable {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .partner-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.0);
    transition: opacity 1s;
    opacity: 1;

    display: flex;
    flex-direction: column;

    overflow: hidden;
    border-radius: $partner-modal-border-radius;
    background-color: #fff;
    z-index: $partner-modal-z-index + 1;

    max-width: 100%;
    max-height: 100%;

    .close-button {
      position: absolute;
      top: 16px;
      right: 40px;
      padding: 0;
      border: 0;
      background-color: transparent;
    }
  }

  .partner-modal-content--borderless {
    border-radius: 0;
  }

  .overlay-background {
    background-color: rgba(0,0,0,0.5);
  }

  .overlay-fade-enter-active,
  .overlay-fade-leave-active {
    transition: opacity 0.3s;
  }

  .overlay-fade-enter,
  .overlay-fade-leave-to {
    opacity: 0;
  }

  .content-fade-enter-active,
  .content-fade-leave-active {
    transition: opacity 0.2s 0.1s, transform 0.2s 0.1s;
  }

  .content-fade-enter,
  .content-fade-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.96);
  }
}

</style>
