<template>
  <div class="home-faq">
    <div
      id="top"
      class="gradient-header"
      :style="`background-image: url(${require('../assets/images/FAQ_header.jpg')}); background-position: 0 49%;`">
      <h2 class="show-desktop">{{ transl.HomeFAQ.faq }}</h2>
    </div>

    <h2 class="show-tablet" style="padding-top: 2rem">{{ transl.HomeFAQ.faq }}</h2>
    <div class="faq-wrapper">
      <div class="subject-picker_wrapper show-desktop">
        <div class="subject-picker_header">
          <p>{{ transl.HomeFAQ.knowmore }}</p>
        </div>
        <div class="subject-picker_container-desktop">
          <ul>
            <li
              v-for="(subject, index) in subjectPicker"
              :key="index"
              @click="scrollDown(subject.title), active = subject.title"
              class="subject-picker_item btn"
              :class="subject.title == active ? 'btn-activate' : 'btn-inactive'">{{ subject.title }}</li>
          </ul>
        </div>
      </div>
      <div class="subject-picker_wrapper show-mobile-and-tablet">
        <div class="subject-picker_header">
          <p>{{ transl.HomeFAQ.knowmore }}</p>
        </div>
        <div class="subject-picker_container-mobile-tablet">
          <InputField
            :label="transl.HomeFAQ.subject"
            inputType="select"
            :selectOptions="subjectPicker"
            :selectSettings="{ optionTitle: 'title', optionValue: 'value', optionKey: 'value' }"
            @input="subjects = [$event]"
          />
        </div>
      </div>

      <FAQ class="show-desktop"/>
      <FAQ :subjects="subjects" class="show-mobile-and-tablet"/>

      <h4 class="support-text">{{ transl.HomeFAQ.noanswer }}</h4>
      <div
        class="btn btn-activate"
        style="max-width: 200px"
        @click="$emit('openSupportModal', {})">
        {{ transl.HomeFAQ.supportbtn }}
      </div>
      <div class="top-btn">
        <a @click="smoothScroll('top')" ><iconComponent symbol="circledArrowUp" fill="#EA7801"/></a>
        <h4>{{ transl.HomeFAQ.top }}</h4>
      </div>
    </div>
    <!--<iframe
      :src="company.faq_url || faqDefault"
      frameborder="0" height="100%" width="100%"></iframe>-->
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityMixin from '../mixins/EntityMixin.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import FAQ from '../stories/components/FAQ/FAQ.vue'

// NOT USED ANYMORE, now we use intercom FAQ, or other company.faq_url setting

export default {
  name: 'HomeFAQ',
  components: {
    InputField,
    FAQ
  },
  props: ['companyId'],
  mixins: [BeforeEnterMixin, EntityMixin],
  mounted() {
    this.setPageTitle(this.transl.HomeFAQ.faq);
    /*if (this.companyId) {
      this.$store
      .dispatch('getCompany', { companyId: this.companyId })
      .then(() => {
        this.setLoadedPageData(true);
      });
    } else {
      localStorage.removeItem('dashboard');
    }*/

    this.subjectPicker = [
      {
        title: this.transl.HomeFAQ.receiptscan,
        value: 'receiptscan',
      },
      {
        title: 'Enginio',
        value: 'enginio',
      },
      {
        title: this.transl.HomeFAQ.account,
        value: 'account',
      },
      {
        title: this.transl.HomeFAQ.delivery,
        value: 'delivery',
      },
    ]
  },
  data() {
    return {
      subjectPicker: [],
      scrolled: false,
      active: '',
      subjects: [],
      // We cannot iframe this url..
      faqDefault: 'https://intercom-help.eu/enginio/',
    };
  },
  methods: {
    scrollDown(subject) {
      const element = document.getElementById(subject);
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    },
    smoothScroll(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    },
  }
};
</script>

<style lang="scss">
.subject-picker_wrapper{

  .btn-activate{
    border-radius: 8px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    width: auto;
  }

  .btn-inactive{
    border-radius: 8px;
    width: auto;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeFAQ";
</style>
