/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import hyperlinksApi from '../../api/hyperlink.api';

// initial state
const state = {
  hyperlink: {},
  hyperlinks: [],
  hyperlinksStatus: '',
};

// getters
const getters = {
  hyperlink: state => state.hyperlink,
  hyperlinks: state => state.hyperlinks,
  hyperlinksStatus: state => state.hyperlinksStatus,
};

// actions
const actions = {
  findAdminHyperlinks({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.findAdminHyperlinks(payload)
      .then((response) => {
        commit('HYPERLINKS', response.hyperlinks);
        commit('HYPERLINK_STATUS', 'FINISHED');
      });
  },

  getAdminHyperlink({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.getAdminHyperlink(payload)
      .then((response) => {
        commit('HYPERLINK', response.hyperlink);
        commit('HYPERLINK_STATUS', 'FINISHED');
      });
  },

  addAdminHyperlink({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.addAdminHyperlink(payload)
      .then((response) => {
        commit('ADD_HYPERLINK', response.hyperlink);
        commit('HYPERLINK_STATUS', 'FINISHED');
      });
  },

  addAdminHyperlinkMedia({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.addAdminHyperlinkMedia(payload)
      .then((response) => {
        commit('HYPERLINK', response.hyperlink);
        commit('HYPERLINK_STATUS', 'FINISHED');
      });
  },

  saveAdminHyperlink({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.saveAdminHyperlink(payload)
      .then((response) => {
        commit('HYPERLINK', response.hyperlink);
        commit('HYPERLINK_STATUS', 'FINISHED');
      });
  },

  deleteAdminHyperlink({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.deleteAdminHyperlink(payload)
      .then(() => {
        commit('HYPERLINK', {});
        commit('HYPERLINK_STATUS', 'FINISHED');
        return;
      });
  },

  // Company
  findCompanyHyperlinks({ commit }, payload) {
    commit('HYPERLINK_STATUS', 'LOADING');
    return hyperlinksApi.findCompanyHyperlinks(payload)
      .then((response) => {
        commit('HYPERLINKS', response.hyperlinks);
        commit('HYPERLINK_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  HYPERLINK(state, hyperlink) {
    state.hyperlink = hyperlink;
  },
  HYPERLINKS(state, hyperlinks) {
    state.hyperlinks = hyperlinks;
  },
  ADD_HYPERLINK(state, hyperlink) {
    state.hyperlinks.unshift(hyperlink);
  },
  SAVE_HYPERLINK(state, hyperlink) {
    for (let i = 0; i < state.hyperlinks.length; i += 1) {
      if (state.hyperlinks[i]._id === hyperlink._id) {
        Vue.set(state.hyperlinks, i, hyperlink);
      }
    }
  },
  DELETE_HYPERLINK(state, hyperlinkId) {
    for (let i = 0; i < state.hyperlinks.length; i += 1) {
      if (state.hyperlinks[i]._id === hyperlinkId) {
        state.hyperlinks.splice(i, 1);
      }
    }
  },
  HYPERLINK_STATUS(state, status) {
    state.hyperlinksStatus = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
