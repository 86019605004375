<template>
  <div class="activation-config-container">
    <div class="tab-content-container">
      <div class="form">
      <div class="terms-container">
        <InputField inputType="toggle"
                    :label="'Terms must accept'"
                    :value="updatedActivation.terms_must_accept"
                    @inputClick="updatedActivation.terms_must_accept = !updatedActivation.terms_must_accept"/>

        <span class="terms-label">Terms text</span>
        <CKEditor v-model="updatedActivation.terms_text" :config="textEditorConfig"></CKEditor>
      </div>

      <div class="terms-container">
        <InputField inputType="toggle"
                    :label="'Merchandise checkbox default'"
                    :value="updatedActivation.terms_merchandise_default"
                    @inputClick="updatedActivation.terms_merchandise_default = !updatedActivation.terms_merchandise_default" />

        <span class="terms-label">Terms merchandise</span>
        <CKEditor v-model="updatedActivation.terms_merchandise" :config="merchandiseEditorConfig"></CKEditor>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/stories/components/InputField/InputField.vue";
import CKEditor from '@/stories/components/CKEditor/CKEditor.vue';

export default {
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  components: {
    InputField,
    CKEditor,
  },
  watch: {
    'updatedActivation.terms_must_accept'(value) {
      this.emitChanges('terms_must_accept', value);
    },
    'updatedActivation.terms_merchandise_default'(value) {
      this.emitChanges('terms_merchandise_default', value);
    },
    'updatedActivation.terms_text'(value) {
      this.emitChanges('terms_text', value);
    },
    'updatedActivation.terms_merchandise'(value) {
      this.emitChanges('terms_merchandise', value);
    },
  },
  data() {
    const commonEditorConfig = {
      toolbar: [ [ 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', '-',
      'Outdent', 'Indent', '-', 'Blockquote', '-', 'Styles', '-', 'Format', '-',  ] ],
    };

    return {
      // The inputs here require valid values to behave correctly, but sometimes the db-documents don't have them.
      updatedActivation: {
        ...this.activation,
        terms_text: this.activation.terms_text || '',
        terms_merchandise: this.activation.terms_merchandise || '',
        terms_must_accept: this.activation.terms_merchandise_default ?? false,
        terms_merchandise_default: this.activation.terms_merchandise_default ?? false,
      },
      // The CK editors seems to require its own config object, so we can't just pass the same object to both.
      textEditorConfig: { ...commonEditorConfig },
      merchandiseEditorConfig: { ...commonEditorConfig },
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    }
  }
};

</script>

<style lang="scss">

@import "./ActivationStep";

.form {
  margin-top: 16px;
}

  .terms-container {
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    border-radius: 4px;

    border: 1px solid #E5E5E5;

  .terms-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 4px;
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
}
</style>
