import axios from 'axios';

export default {
  // Admin
  findAdminPVStore: (data) => {
    return axios.put(`/api/pv/admin/stores`, data)
      .then(response => {
        return {
          stores: response.data.stores != null
            ? response.data.stores : [],
      }});
  },

  addAdminPVStore: (payload) => {
    return axios.post('/api/pv/admin/stores', payload)
      .then(response => ({
        store: response.data.store != null
          ? response.data.store : {},
      }));
  },

  saveAdminPVStore: (payload) => {
    return axios.put(`/api/pv/admin/stores/${payload.entityId}`, payload)
      .then(response => ({
        store: response.data.store != null
          ? response.data.store : {},
      }));
  },

  getAdminPVStore: (payload) => {
    return axios.get(`/api/pv/admin/stores/${payload.entityId}`, payload)
      .then(response => ({
        store: response.data.store != null
          ? response.data.store : {},
      }));
  },

  deleteAdminPVStore: (payload) => {
    return axios.delete(`/api/pv/admin/stores/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Company
  findCompanyStores: (data) => {
    return axios.put('/api/pv/c/stores', data)
      .then(response => {
        return { stores: response.data.stores != null
          ? response.data.stores : []}
      });
  },

  getCompanyStore: (payload) => {
    return axios.get(`/api/pv/c/stores/${payload.storeId}`)
      .then(response => ({
        store: response.data.store != null
          ? response.data.store : {},
      }));
  },

  addCompanyStore: (payload) => {
    return axios.post('/api/pv/c/stores', payload)
      .then(response => ({
        store: response.data.store != null
          ? response.data.store : {},
      }));
  },

  saveCompanyStore: (payload) => {
    return axios.put(`/api/pv/c/stores/${payload.entityId}`, payload)
      .then(response => ({
        store: response.data.store != null
          ? response.data.store : {},
      }));
  },

  deleteCompanyStore: (entityId) => {
    return axios.delete(`/api/pv/c/stores/${entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
