<template>
  <span/>
</template>

<script>
import { isIOS, isSafari } from 'mobile-device-detect';

export default {
  name: 'AnnoyingClientMixin',
  data() {
    return {
      isIOS,
      isSafari,
    };
  },
  computed: {
    isAnnoyingClient() {
      return this.isIOS || this.isSafari;
    },
  },
  methods: {
    autoplay(element) {
      // console.log('play!');
      element.play().catch((error) => {
        console.log('error', error);
      });

      // almost 1h, wich is the expiration time of an signed Url from amazon
      setTimeout(() => {
        element.addEventListener("timeupdate", function () {
          console.log('checking time...');
          if (element.currentTime > element.duration - 0.5) {
            // 0.5 seconds before end.
            element.pause();
          }
        });
      }, 3550*1000);

      /*element.addEventListener('ended', () => {
        element.currentTime = 0;
        console.log('ended: play again!');
        element.play().catch((error) => {
          console.log('error', error);
        });
      });*/
      /*element.ontimeupdate = () => {
        console.log('ontimeupdate: timeupdate!', element.currentTime, element.duration);
        if (element.currentTime >= element.duration - 1) {
          console.log('ontimeupdate: now!');
          element.currentTime = 0;
        }
      };*/

      element.onended = () => {
        // element.currentTime = 1;
        // console.log('onended: play again!');
        element.play().catch((error) => {
          console.log('error', error);
        });
      };
    },
  },
};
</script>
