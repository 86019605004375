<template>
  <div class='user-progress-container'>
    <div class='user-progress'>
      <div class='user-progress-line'></div>
      <div v-for='(step, index) in steps' :key='index' class='user-progress-step' :class="getStepClass(step)">
        <span v-if="step.status === 'pending' || step.status === 'in-progress'">{{ index + 1 }}</span>
        <svg v-if="step.status === 'completed'" xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
          <path d="M14.4421 1.9284L14.4542 1.92691C14.6734 1.9001 14.9187 1.97769 15.0644 2.17098M14.4421 1.9284L15.0643 2.17098L14.825 2.35135L15.0644 2.17098M14.4421 1.9284L14.4301 1.93086M14.4421 1.9284L14.4301 1.93086M15.0644 2.17098C15.2192 2.37637 15.2098 2.64835 15.0473 2.84375L8.00287 11.8336M15.0644 2.17098L8.00287 11.8336M14.4301 1.93086C14.4115 1.93468 14.3942 1.93955 14.3826 1.94301C14.2412 1.97591 14.1035 2.0548 14.0106 2.18196L7.36482 10.6676L2.91711 7.36292C2.75394 7.20826 2.51789 7.15718 2.30935 7.20229C2.0881 7.25014 1.87903 7.4151 1.83342 7.66802C1.788 7.91992 1.92597 8.13735 2.12031 8.24853L7.08466 11.9511L7.08464 11.9512M14.4301 1.93086L7.08464 11.9512M7.08464 11.9512L7.08864 11.954M7.08464 11.9512L7.08864 11.954M7.08864 11.954C7.228 12.0545 7.4029 12.0932 7.56808 12.0729M7.08864 11.954L7.56808 12.0729M7.56808 12.0729C7.73145 12.0529 7.89359 11.9746 8.00198 11.8348M7.56808 12.0729L8.00198 11.8348M8.00198 11.8348C8.00228 11.8344 8.00257 11.834 8.00287 11.8336M8.00198 11.8348L8.00287 11.8336" fill="white" stroke="white" stroke-width="0.6"/>
        </svg>
        <svg v-if ="step.status === 'error'" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path d="M2.93486 2.48798L2.8995 2.45283L2.86425 2.48808L2.16386 3.18847L2.12846 3.22388L2.16392 3.25923L7.42023 8.50009L2.16392 13.7409L2.12846 13.7763L2.16386 13.8117L2.86425 14.5121L2.8995 14.5474L2.93486 14.5122L8.20695 9.27103L13.4635 14.5122L13.4989 14.5474L13.5342 14.5121L14.2346 13.8117L14.27 13.7763L14.2345 13.7409L8.9782 8.50009L14.2345 3.25923L14.27 3.22388L14.2346 3.18847L13.5342 2.48808L13.4989 2.45278L13.4635 2.48803L8.20695 7.72915L2.93486 2.48798Z" fill="white" stroke="white" stroke-width="0.1"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserProgress',
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getStepClass(step) {
      return {
        'user-progress-step--active': step.status === 'in-progress',
        'user-progress-step--completed': step.status === 'completed',
        'user-progress-step--error': step.status === 'error',
      };
    }
  }
}
</script>

<style lang='scss' scoped>

.user-progress-container {
  display: flex;
}
.user-progress {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  .user-progress-line {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #333333;
  }

  .user-progress-step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    overflow: hidden;
    border-radius: 24px;
    border: 1px solid #CCCCCC;
    background-color: #ECEDEE;
    z-index: 1;

    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    color: #999999;
  }

  .user-progress-step--active {
    color: #FFFFFF;
    background-color: #223263;
    border-color: #223263;
  }

  .user-progress-step--completed {
    color: #FFFFFF;
    background-color: $brand-yellow;
    border-color: $brand-yellow;
  }

  .user-progress-step--error {
    color: #FFFFFF;
    background-color: #FF0000;
    border-color: #FF0000;
  }
}
</style>
