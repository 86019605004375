<template>
  <div v-if="loadedPage"
       class="entity-editor__actions-manager-container">
    <div v-for="(action, index) in actions" :key="action._id" style="border: 1px dotted gray; border-radius: 8px; padding: 10px; margin-bottom: 10px;">
      <div class="entity-editor__actions-manager-row">
        <div class="entity-editor__actions-manager-action-label">
          <div class="entity-editor__actions-manager-action-name">
            {{index+1}}. {{actionTypes.find(x => x.key === action.type)?.label}}
          </div>
        </div>
        <div class="entity-editor__actions-manager-delay-input">
          <InputField  
            label="Days to delay action"
            :value="action.delayed_duration"
            @input="setDuration(index, $event)"/>
        </div>
        <div v-if="['UNLOCK-OFFER', 'LOCK-OFFER', 'ADD-USER-POINTS'].includes(action.type)" class="entity-editor__actions-manager-input">
          <InputField
            label="Enginio Offer Id"
            :value="action.offer_id"
            @input="setOfferId(index, $event)"/>
        </div>
        <div v-if="action.type === 'UNLOCK-COLLECTION'" class="entity-editor__actions-manager-input">
          <InputField
            label="Enginio Collection Id"
            :value="action.collection_id"
            @input="setCollectionId(index, $event)"/>
        </div>
        <div v-if="['UNLOCK-ACTIVATION', 'LOCK-ACTIVATION'].includes(action.type)" class="entity-editor__actions-manager-input">
          <AutoComplete
            :value="action?.activation?.text || action?.activation?.name"
            :options="modalPvActivations"
            :required="true"
            :is-async="true"
            unique-id="change"
            title="Activation"
            placeholder="Find Activation"
            resultPosition="relative"
            @input="findAdminModalPvActivations($event)"
            @selected="action.activation = $event"/>
        </div>
        <div v-if="action.type === 'SEND-PAYLOAD'" class="entity-editor__actions-manager-input">
          <InputField
            label="URL to send to"
            :value="action.endpoint"
            @input="setEndpoint(index, $event)"/>
        </div>
        <div v-if="action.type.includes('REMINDER-EMAIL')" class="entity-editor__actions-manager-action-label" style="flex-grow: 1;">
          <div class="entity-editor__actions-manager-action-description">
            <p>Will send a reminder to all users who haven't completed the step.<br/>
              <b>Delay will be calculated from receipt created at date.</b>
            </p>
          </div>
        </div>
        <div v-if="['LOYALTYSHOP', 'GAME'].some(term => action.type.includes(term))" class="entity-editor__actions-manager-input">
          <InputField
            :label="action.type.includes('LOYALTYSHOP') ? 'Loyaltyshop id' : 'Game id'"
            :value="action.game_id"
            @input="setGameId(index, $event)"/>
        </div>
        <div v-if="action.type.includes('-POINTS')" class="entity-editor__actions-manager-delay-input">
          <InputField
            label="Points per user"
            :value="action.points"
            @input="setPoints(index, $event)"/>
        </div>
        <div v-if="action.type.includes('-EMAIL')" class="entity-editor__actions-manager-email-subject">
          <InputField
            label="Subject"
            :value="action.email_subject"
            @input="setEmailSubject(index, $event)"/>
        </div>
        <div v-if="!action.type.includes('REMINDER-EMAIL')" class="entity-editor__actions-manager-delay-input">
          <InputField
            label="Start delay from"
            inputType="select"
            :value="action.trigger"
            :selectOptions="triggerTypes"
            :selectSettings="{ optionTitle: 'label', optionKey: 'key', optionValue: 'key' }"
            @input="setTrigger(index, $event)" />
        </div>
        <div v-if="action.type == 'SEND-ADMIN-NOTIFICATION-EMAIL'" class="entity-editor__actions-manager-delay-input">
          <InputField
            label="Send to"
            inputType="textarea"
            :value="action.email_to"
            @input="setEmailTo(index, $event)"/>
        </div>
        <div
          class="btn entity-editor__actions-manager-icon"
          @click="removeAction(index)">
          <iconComponent symbol="trashcan" />
        </div>
        <div>action-{{ index }}</div>
    </div>
    <div v-if="action.type === 'SEND-EMAIL' || action.type.includes('REMINDER-EMAIL')" class="entity-editor__actions-manager-row">
      <div class="spacer"></div>
      <div class="entity-editor__actions-manager-big-field">
        <InputField
          label="Email template"
          :value="action.email_template"
          inputType="textarea"
          @input="setEmailTemplate(index, $event)"/>
        <span class="modal-button" @click="emailTemplateModal = !emailTemplateModal">i</span>
      </div>
      <div class="entity-editor__actions-manager-icon"></div>
    </div>
    <div v-if="action.type === 'SEND-EMAIL'" class="entity-editor__actions-manager-row">
      <div class="spacer" style="width: 23% !important;"></div>
      <div id="uploadContainer" style="float:left">
        <input :id="`fileUpload_${index}`" name="file" class="inputfile" type="file" @change="fileInputOnChange(index, $event)">
        <label :for="`fileUpload_${index}`" class="text-wrap">
          <iconComponent symbol="file" fill="white" stroke="white" />
          {{action.file_attachment?.filename || "Attach a file"}}
        </label>
      </div>
      <div v-if="action.file_attachment?.fileType === 'application/pdf'" @click="startPDFEditor(index, action.file_attachment?.fileKey)" class="btn btn-medium btn-transparent btn-activate btn-width" style="float:right; width: 200px;">
        <iconComponent symbol="paper" fill="white" stroke="black" /> Edit PDF file
      </div>
    </div>

    <div v-if="action.type === 'SEND-PAYLOAD'" class="entity-editor__actions-manager-row">
      <div class="spacer"></div>
      <div class="entity-editor__actions-manager-big-field">
        <InputField
          label="JSON object as payload"
          :value="action.payload"
          inputType="textarea"
          @input="setPayload(index, $event)"/>
      </div>
      <div class="entity-editor__actions-manager-icon"></div>
    </div>
    <!-- End of container-->
    </div>


    <div v-if="addNew" class="entity-editor__actions-manager-add-new">
      <InputField
        label="Choose action"
        inputType="select"
        :selectOptions="actionTypes"
        :selectSettings="{ optionTitle: 'label', optionValue: 'key', optionKey: 'key' }"
        @input="pickAction($event)" />

    </div>
    <div class="btn btn-activate btn-width"
          style="float: left;"
         @click="addNew = !addNew">
      <iconComponent symbol="plusMath" fill="white" stroke="white" /> Add scheduled action
    </div>

    <div v-if="emailTemplateModal" class="actions-modal">
      <div class="modal-content">
        <span class="modal-button" @click="emailTemplateModal = false">&times;</span>
        <p v-html="'Usable values:'"></p>
        <p v-html="'{{receiptName}} => Elliott'"></p>
        <p v-html="'{{receiptLastName}} => Jönsson'"></p>
        <p v-html="'{{receiptPhoneNr}} => +4611313333'"></p>
        <p v-html="'{{receiptEmail}} => elliot@jonsson.se'"></p>
        <p v-html="'{{receiptDate}} => 2024-03-01'"></p>
        <p v-html="'{{receiptCode}} => DIN-UNIKA-KOD'"></p>
        <p v-html="'{{enginioUrl}} => https://www.enginio.io/xx'"></p>
        <p v-html="'{{activationId}} => asd123asd123'"></p>
        <p v-html="'{{activationUrl}} => https://www.enginio.io/xx/pv/asd123asd123/offer-one-24'"></p>
        <p v-html="'{{activationName}} => Buy and get 2024'"></p>
        <p v-html="'{{activationMedia}} => PV Activation background image'"></p>
        <p v-html="'{{offerId}} => qwe321qwe321'"></p>
        <p v-html="'{{offerUniqueName}} => offer-one-24'"></p>
        <p v-html="'{{offerUrl}} => https://www.enginio.io/xx/a/offer-one-24'"></p>
        <p v-html="'{{offerName}} => Buy and get, landing'"></p>
        <p v-html="'{{offerMedia}} => Enginio Offer media (first image if a carousel)'"></p>
        <p v-html="'{{offerCollectionName}} => Enginio collection name'"></p>
        <p v-html="'{{offerCompanyName}} => Enginio company name'"></p>
        <p v-html="'{{offerCompanyIcon}} => Enginio company icon'"></p>
        <p v-html="'{{offerCompany11Icon}} => Enginio company 1:1 icon'"></p>
        <p v-html="'{{offerCompanyFaqUrl}} => intercom url to FAQ'"></p>
        <p v-html="'{{fullYear}} => current year => 2024'"></p>

        <p v-html="'{{action-n-date}} => Date when action will be run, n is the index of the action'"></p>

        <p v-html="'{{relayState}} => userId (gammal)'"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce as _debounce } from 'lodash-es';
import InputField from '../InputField/InputField.vue';
import AutoComplete from '../AutoComplete/AutoComplete.vue';
import reminderEmailTemplate from './emailTemplates/reminderEmail.js';

export default {
  name: 'ActionsManagerComponent',
  components: {
    InputField,
    AutoComplete,
  },
  props: {
    activation: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    userType: {
      type: String,
      required: false,
      default: 'Admin',
    },
  },
  beforeUnmount() {
    this.findAdminPVActivation.cancel();
  },
  mounted() {
  },
  computed: {
    ...mapGetters('pvActivations', ['modalPvActivations']),
  },
  data() {
    return {
      selected: '',
      loadedPage: true,
      addNew: false,
      actionTypes: [
        {label: 'Unlock offer', key: 'UNLOCK-OFFER'}, 
        {label: 'Lock offer', key: 'LOCK-OFFER'},
        {label: 'Unlock collection', key: 'UNLOCK-COLLECTION'},
        {label: 'Unlock activation', key: 'UNLOCK-ACTIVATION'},
        {label: 'Lock activation', key: 'LOCK-ACTIVATION'},
        /*{label: 'Add user points', key: 'ADD-USER-POINTS'},*/
        {label: 'Send email', key: 'SEND-EMAIL'},
        {label: 'Send loyalty shop points', key: 'ADD-LOYALTYSHOP-POINTS'},
        {label: 'Unlock game', key: 'UNLOCK-GAME'},
        {label: 'Send custom payload', key: 'SEND-PAYLOAD'},
        {label: 'Send Receipt reminder', key: 'SEND-REMINDER-EMAIL'},
        {label: 'Send PR reminder', key: 'SEND-PR-REMINDER-EMAIL'},
        {label: 'Send Survey reminder', key: 'SEND-SURVEY-REMINDER-EMAIL'},
        {label: 'Send Address reminder', key: 'SEND-ADDRESS-REMINDER-EMAIL'},
        {label: 'Send Admin notification', key: 'SEND-ADMIN-NOTIFICATION-EMAIL'},
      ],
      triggerTypes: [
        {label: 'CTA done date', key: 'CTA-DONE'},
        {label: 'CTA done and receipt date', key: 'CTA-DONE-AND-RECEIPT-DATE'},
        {label: 'CTA done and created date', key: 'CTA-DONE-AND-CREATED-DATE'},
        {label: 'Receipt date', key: 'RECEIPT-DATE'},
        {label: 'Created date', key: 'CREATED-DATE'},
      ],
      emailTemplateModal: false,
    };
  },
  methods: {
    pickAction(actionType) {
      const action = {
        type: actionType,
        trigger: 'CTA-DONE',
        offer_id: "",
        collection_id: "",
        delayed_duration: 0
      }
      if (actionType === 'SEND-PAYLOAD') {
        action.endpoint = 'https://www.enginio.io/se/launch-rockets/62fffwef343144';
        action.payload = '{ "code": "{{jwtObj({ "custom_field": 50, "phoneNr": "", "email": "", "receiptId": "" })}}" }';
      }
      if (actionType === 'ADD-USER-POINTS') {
        action.points = 1;
      }
      if (actionType === 'ADD-LOYALTYSHOP-POINTS') {
        action.points = 1;
      }
      if (actionType === 'SEND-EMAIL') {
        action.email_subject = "🥳 Your thing is done, and you can now do stuff!";
        action.email_template = "A template here, look at <example template> as an example";
      }
      if (actionType.includes('REMINDER-EMAIL')) {
        // Reminder emails always trigger on created date,
        // since they're sent only to users who haven't completed the whole process yet
        action.email_subject = 'You have not forgotten us, right?! 🤔';
        action.email_template = reminderEmailTemplate;
        action.trigger = 'CREATED-DATE';
      }
      if (actionType === 'SEND-ADMIN-NOTIFICATION-EMAIL') {
        action.email_subject = 'Receipt incoming! 🚀';
        action.email_to = '["test@test.com", "another@test.com"]';
      }

      this.$emit('pickedAction', action);
      this.addNew = false;
    },
    setOfferId(index, value) {
      this.$emit('setOfferId', { index, value });
    },
    setCollectionId(index, value) {
      this.$emit('setCollectionId', { index, value });
    },
    setActivationId(index, value) {
      this.$emit('setActivationId', { index, value });
    },
    setDuration(index, value) {
      this.$emit('setDuration', { index, value });
    },
    setTrigger(index, value) {
      this.$emit('setTrigger', { index, value });
    },
    setGameId(index, value) {
      this.$emit('setGameId', { index, value });
    },
    setPoints(index, value) {
      this.$emit('setPoints', { index, value });
    },
    setPayload(index, value) {
      this.$emit('setPayload', { index, value });
    },
    setEndpoint(index, value) {
      this.$emit('setEndpoint', { index, value });
    },
    setEmailSubject(index, value) {
      this.$emit('setEmailSubject', { index, value });
    },
    setEmailTemplate(index, value) {
      this.$emit('setEmailTemplate', { index, value });
    },
    setEmailTo(index, value) {
      this.$emit('setEmailTo', { index, value });
    },
    startPDFEditor(index, fileKey) {
      this.$emit('openPDFEditor', {index, pdfSource: fileKey});
    },
    removeAction(index) {
      this.$emit('removedAction', { index });
    },
    fileInputOnChange(index, event) {
      let files = event.target.files;
      if (files.length) {
        const file = files[0];
        let reader = new FileReader();

        reader.onload = async (e) => {
          this.src = e.target.result;
          const uploadedFileKey = await this.savePDFToStorage(index, file);
          const fileObj = {
            filename: file.name,
            fileType: file.type,
            fileKey: uploadedFileKey,
          }
          this.currentFileObj = fileObj;
          this.$emit('setEmailAttachment', { index, fileObj });
          this.$forceUpdate();
        }

        reader.readAsArrayBuffer(file);
      }
    },
    findAdminModalPvActivations(query) {
      this.$store.dispatch(`pvActivations/find${this.userType}ModalPVActivations`, {
        query, type: 'modal', exclude: this.activation._id
      });
    },
    async savePDFToStorage(index, file) {
      try {
        if (file.size >= this.fileSize) {
          const mb = 1/1000000;
          this.alert(`Too large file (${(file.size*mb).toFixed(1)}Mb)`
            + `, maximum is (${this.fileSize*mb}Mb), try cropping it more, or use another file`,
            6, 'danger');
        } else {
          const data = new FormData();
          data.append('file', file);

          const config = {
            headers: {
              size: file.size,
            }
          };

          this.uploadStatus = 'UPLOADING';

          const payload = { data, config };
          payload.entityId = this.activation._id.toString();

          return await this.$store.dispatch(`pvActivations/addAdminActivationEmailAttachment`, payload);
        }
      } catch (error) {
        //TODO: show some message
        console.error("Couldn't upload file, error: " + error);
      }
      // .then((response) => {
      //   console.log("I think we saved a PDF? Yeah, deffo saved an PDF...");
      //   this.$emit("update:activation", response.activation);
      // }).catch(() => {
      //   this.uploadStatus = 'FAILED';
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ActionsManager.scss';

.btn-width {
  width: 300px;
  float: center;
}
.modal-button {
  margin: 1px;
  padding: 5px 12px;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;
  text-decoration: none;
  float: right;
  background-color: $brand-yellow;
}
.actions-modal {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
</style>