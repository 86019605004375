<template>
  <div
    class="qr-error-wrapper"
    :style="cssProps">
    <div class="game-desktop-background"></div>
    <div class="game-page-absolute">
      <h3 v-html="reason"></h3>

      <div class="btn btn-activate"
        @click="gotoOnboarding()">
        {{ transl.Games.back }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../../mixins/BeforeEnterMixin';

export default {
  name: 'QrHuntError',
  components: {
  },
  props: ['gameId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    if (!(this.game && this.game._id) || !this.reason) {
      console.log('Redirected to onboarding');
      console.log('Redirected to onboarding', this.game, this.reason);
      this.gotoOnboarding();
    }
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.gotoOnboarding();
      }
    },
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'game',
      'reason',
    ]),
    cssProps() {
      return {
        '--game-desktop-bg-color': this.game.desktop_bg_color,
        '--game-bg-color': this.game.bg_color,
        '--game-accent-color': this.game.accent_color,
        '--game-text-color': this.game.text_color,
        '--game-bg-text-color': this.game.bg_text_color,
      }
    },
  },
  methods: {
    gotoOnboarding() {
      console.log('gotoOnboarding Error');
      this.$router.push({ name: 'QrHuntOnboarding' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/games/QrHunt/Base";
@import "../../assets/scss/games/QrHunt/Error";
</style>
