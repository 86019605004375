<template>
  <div class="company-pv-receipt">
    <Receipt :receiptId="receiptId" :admin="false"/>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Receipt from '../stories/components/Receipt/Receipt.vue';

export default {
  name: 'CompanyPVReceipt',
  components: {
    Receipt,
  },
  mixins: [BeforeEnterMixin],
  props: ['receiptId'],
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.company-pv-receipt {
  height: calc(100vh - $nav-height);
}
</style>
