import axios from 'axios';

export default {
  // Admin
  getAdminTranslations: (data) => {
    return axios.get('/api/admin/translations', data)
      .then(response => ({
        translations: response.data.translations != null
          ? response.data.translations : [],
      }));
  },

  updateAdminTranslationsByKey: (payload) => {
    return axios.put(`/api/admin/translations/bykey`, payload)
      .then(response => ({
        translations: response.data.translations != null
          ? response.data.translations : {},
      }));
  },
};
