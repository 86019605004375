/* eslint-disable no-shadow, no-param-reassign */
import partnerAPIApi from '../../api/pvPartnerAPI.api';

// initial state
const state = {
  partnerAPI: {},
  partnerAPIs: [],

  modalPvPartnerAPIs: [],
};

// getters
const getters = {
  partnerAPI: state => state.partnerAPI,
  partnerAPIs: state => state.partnerAPIs,

  modalPvPartnerAPIs: state => state.modalPvPartnerAPIs,
};

// actions
const actions = {
  // ADMIN
  findAdminPVPartnerAPI({ commit }, payload) {
    commit('PARTNER_API_STATUS', 'LOADING');
    return partnerAPIApi.findAdminPVPartnerAPI(payload)
      .then((response) => {
        commit('PARTNER_APIS', response.partnerAPIs);
        commit('PARTNER_API_STATUS', 'FINISHED');
      });
  },

  findAdminModalPVPartnerAPIs({ commit }, payload) {
    commit('PARTNER_API_STATUS', 'LOADING');
    return partnerAPIApi.findAdminPVPartnerAPI(payload)
      .then((response) => {
        commit('MODAL_PARTNER_APIS', response.partnerAPIs);
        commit('PARTNER_API_STATUS', 'FINISHED');
      });
  },

  addAdminPVPartnerAPI({ commit }, payload) {
    commit('PARTNER_API_STATUS', 'LOADING');
    return partnerAPIApi.addAdminPVPartnerAPI(payload)
      .then((response) => {
        commit('ADD_PARTNER_API', response.partnerAPI);
        commit('PARTNER_API_STATUS', 'FINISHED');
      });
  },

  saveAdminPVPartnerAPI({ commit }, payload) {
    commit('PARTNER_API_STATUS', 'LOADING');
    return partnerAPIApi.saveAdminPVPartnerAPI(payload)
      .then((response) => {
        commit('SAVE_PARTNER_API', response.partnerAPI);
        commit('PARTNER_API_STATUS', 'FINISHED');
      });
  },

  getAdminPVPartnerAPI({ commit }, payload) {
    commit('PARTNER_API_STATUS', 'LOADING');
    return partnerAPIApi.getAdminPVPartnerAPI(payload)
      .then((response) => {
        commit('PARTNER_API', response.partnerAPI);
        commit('PARTNER_API_STATUS', 'FINISHED');
      });
  },

  deleteAdminPVPartnerAPI({ commit }, payload) {
    commit('PARTNER_API_STATUS', 'LOADING');
    return partnerAPIApi.deleteAdminPVPartnerAPI(payload)
      .then(() => {
        commit('DELETE_PARTNER_API', payload.entityId);
        commit('PARTNER_API_STATUS', 'FINISHED');
        return;
      });
  },
};

// mutations
const mutations = {
  PARTNER_API(state, partnerAPI) {
    state.partnerAPI = partnerAPI;
  },
  PARTNER_APIS(state, partnerAPIs) {
    state.partnerAPIs = partnerAPIs;
  },
  ADD_PARTNER_API(state, partnerAPI) {
    state.partnerAPIs.unshift(partnerAPI);
  },

  PARTNER_API_QUERY(state, partnerAPIQuery) {
    state.partnerAPIQuery = partnerAPIQuery;
  },
  SAVE_PARTNER_API(state, partnerAPI) {
    state.partnerAPI = partnerAPI;
  },
  DELETE_PARTNER_API(state) {
    state.partnerAPI = {};
  },
  PARTNER_API_STATUS(state, status) {
    state.partnerAPIStatus = status;
  },
  MODAL_PARTNER_APIS(state, partnerAPIs) {
    state.modalPvPartnerAPIs = [];
    for (let i = 0; i < partnerAPIs.length; i += 1) {
      state.modalPvPartnerAPIs.push({
        value: partnerAPIs[i]._id,
        text: partnerAPIs[i].name,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
