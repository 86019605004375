<template>
  <div class="tmr-page">
    <div>
      <div class="tmr-header">
        {{ transl['429'].ops }}
      </div>
      <div class="tmr-header-sub">
        {{ transl['429'].ops2 }}
      </div>
      <img class="svg-icon" alt='' src='../assets/images/429.svg'/>
      <div class="tmr-header-sub">
        {{ transl['429'].ops3 }}
      </div>
    </div>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeInfo',
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    // this.$dpAnalytics({ subject: `429`, action: `too many actions` });
    this.pushDataLayer({
      parentCompany: 'None',
      company: 'None',
      goodiebag: 'None',
      offer: 'None',
      action: 'Too many actions'
    });
    this.setPageTitle();
  },
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/Home429";
</style>
