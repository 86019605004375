<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h1>Privacy Policy – Participants</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h3>Privacy Policy – Participants</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div class="data-text">
          <div>
            <h3>Privacy policy – when you take part in activities at Enginio</h3>
          </div>
            When you participate in contests, campaigns or other activities (“<b>activities</b>”) at Enginio, the company that you interact with (the “<b>Company</b>“, “<b>we</b>”, “<b>us</b>”) will process your personal data.
          <div>
            We care about your privacy and want you to feel safe when you interact with us and we process your personal data. Enginio is provided by NRG Nordic Retail Group AB (“<b>NRG</b>”) and you are free to contact either the Company or NRG if you have any questions. 
          </div>
          <div>
            <b>In short: Your personal data – for what purposes do we use it?</b>
          </div>
          <div>
            • Enable you to participate in activities,<br>
            • Let you collect “Enginio point” from your interactions with us, and<br>
            • If you have consented, we send you newsletters. We adapt the newsletters to suit you and analyse how our newsletters are used in order to improve and develop them.
          </div>
          <div>
            You have several rights that you can read more about below. For example, you can choose to unsubscribe from our newsletters at any time. In that case we will continue to store your e-mail address in our unsubscribe-list.
          </div>
          <div>
            Don’t hesitate to contact the Company or NRG with any questions you have regarding this policy!
          </div>
          <div>
            <b>Below you can read more about:</b>
          </div>
          <div>
            Who is responsible for the processing of your personal data?<br>
            Detailed description on how we process your personal data<br>
            Who can gain access to your personal data and why?<br>
            Where are your personal data processed?<br>
            What are your rights when we process your personal data?
          </div>
          <div>
            <b>Who is responsible for the processing of your personal data?</b>
          </div>
          <div>
            The Company is responsible for the processing of your personal data when interacting with the Company at Enginio. Here you can find a list of companies that you may interact with at Enginio.
          </div>
          <div>
            Should you have any questions regarding our processing of your personal data, or if you wish to exercise any of your rights under data protection legislation, please feel free to contact the Company or NRG. You can reach NRG at support@enginio.io or Linnégatan 9-11 114 47, Stockholm.
          </div>
          <div>
            <b>Detailed description on how we process your personal data</b>
          </div>
          <div>
            Of course, you are not required to provide your personal data to us. But if you want to participate in any of our activities at Enginio, we will need certain personal data about you in order to administrate your participation. In some cases, we need to process your personal data to comply with mandatory legislation (i.e. when the so called lawful basis in the charts below is stated to be “Legal obligation”). 
          </div>
          <div>
            We process the personal data you have provided when creating your membership at Enginio and personal data that we collect from your performance of the activity.
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To enable you to participate in our activities and take part of our campaigns
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              • Enable you to take part in our activities<br>
              • Make sure that any contest entries comply with the contest rules
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • Information from your membership account on Enginio<br>
              • Information that is required in order for you to take part in the activity, e.g. your entry submission within the contest<br>
              • Information relevant to qualify as a participant in the activity, e.g. information about your age<br>
              <b>If you participate in a cash-back campaign:</b><br>
              • Picture of a receipt related to the specific product or service we have a cash-back campaign for<br>
              • Bank details or similar information we need in order to make your payment
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Performance of contract<br>
              The processing is necessary for you to be able to take part in activities, such as participating in one of our contests or use one our cash-back campaigns. <br>
              You need to provide the personal data to us, otherwise you will not be able to take part in any of our activities.
            </div>
          </div>
          <div>
            <b>Storage period</b>: Your personal data is stored as long as necessary to perform your activity. If you receive a warranty for a product or service we continue to store necessary personal data during the warranty period.
            If you remove your Enginio membership we will continue to store your phone number and e-mail address for as long as the contest, campaign or similar activity is ongoing. We do this in order to prevent fraud.
            Any accounting material will be stored according to accounting legislation, see below.
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              Reward your activities
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              • Enable you to collect “Enginio points” when you interact with us <br>
              • Enable you to use the Enginio points you have collected
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • Information from your membership account on Enginio<br>
              • Information that is required in order for you to collect Enginio points, e.g. information about which activities you have completed
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Legitimate interest<br>
              Our legitimate interest to process your personal data to enable you to collect and take advantage of your Enginio points on Enginio that is related to us.<br>
              This means that we have carried out a balancing of interests assessment before we process your personal data and concluded that our legitimate interest outweighs your interests or right of not having your personal data processed.
            </div>
          </div>
          <div>
            <b>Storage period:</b> We will store your personal data for as long as you continue to use Enginio.
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To send newsletters
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              • Send information about news, marketing, offers and invitations (“newsletters”) to you when you have chosen to subscribe to our newsletters<br>
              • Adapt newsletters to suit you based on information we have about your previous use and activities on Enginio (so called profiling*)<br>
              • Improve and develop our newsletters by analysing how you open them and what you click on in the newsletters <br>
              Do you want to read more about this type of analyse? You find a more detailed description of this in our information about cookies. 
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • E-mail address <br>
              • Information from your previous use and activities on Enginio<br>
              • Information about how you open our newsletters and what you click on<br>
              • IP-address <br>
              • E-mail address
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Consent<br>
              The personal data will be processed based on your consent. You can withdraw such consent at any time.
            </div>
          </div>
          <div>
            <b>Storage period:</b> You can object to receiving newsletters and marketing at any time. If you object to receiving marketing from us, we keep track of this in our “unsubscribe-list” to avoid sending you any further marketing material.
          </div>
          <div>
            <b>* Profiling:</b> We use so called profiling to be able to show you offers that are relevant to you and to provide you with customized marketing. We use profiling because without it, you would see offers and information which you probably not are interested in. You have the right to object to profiling as described below under the section explaining your rights. 
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To comply with marketing legislation
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              If you have stated that you do not wish to receive marketing from us, we will store such information in a “unsubscribe-list” to make sure we do not send any marketing to you.
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • E-mail address
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Legal obligation<br>
              The processing is necessary to comply with legal obligations to which we are subject, i.e. marketing law which require us to not send marketing material to individuals who have objected to receiving such marketing.<br>
              We cannot make sure you will not receive marketing from us without processing your personal data for this purpose and you are therefore required to provide your e-mail address to us.
            </div>
          </div>
          <div>
            <b>Storage period:</b> You will be listed in our “unsubscribe-list” until further notice.
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To comply with accounting legislation
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              Store information in accounting material.
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • Name<br>
              • History regarding payments made <br>
              • Other information that constitutes accounting records 
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Legal obligation<br>
              The processing is necessary to comply with legal obligations to which we are subject, i.e. accounting and accounting legislation. 
            </div>
          </div>
          <div>
            <b>Storage period:</b> We will store any document constituting accounting material and personal data included therein for seven to eight years according to accounting and accounting legislation. The regulation means that we store accounting material until and including the seventh year after the end of the calendar year for the fiscal year to which the personal data relates. 
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To handle any complaints or claims
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              We do everything we can to please you and rarely receive complaints. Nevertheless, if you want to make a complaint or discuss anything when you have interacted with us on Enginio, we will process your personal data to handle this complaint or claim. We will collect your personal data from you yourself or provide the information ourselves.<br>
              Note that the ongoing complaint or claim may mean that we cannot delete all your personal data after your request.
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • Information from your membership account on Enginio<br>
              • Information from our communication with you in relation to the claim, e.g. information about the relevant offer or contest
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Legal obligation<br>
              In some situations, the processing is necessary to act according to legal obligations to which we are subject. <br>
              In these cases, you need to provide your personal data to us since we otherwise will not be able to comply with your consumer rights.<br>
              Legitimate interest<br>
              We also have a legitimate interest to process your personal data to defend ourselves against or initiate a possible complaint or claim.<br>
              This means that we have carried out a balancing of interests assessment before we process your personal data and concluded that our legitimate interest outweighs your interests or right of not having your personal data processed.
            </div>
          </div>
          <div>
            <b>Storage period:</b> We will store your personal data from the time the complaint or claim was initiated and for the duration of such complaint or claim.
          </div>
          <div>
            <b>Who can gain access to your personal data and why? </b>
          </div>
          <div>
            Your personal data is initially collected and processed by us and we do not sell your personal data. This means that your personal data will be handled by our employees, but only personnel who need such access to conduct their work. 
          </div>
          <div>
            To conduct our business, we need to work with suppliers and partners which therefore will process your personal data. We are responsible for any sharing of your personal data to such suppliers or partners and to make sure your personal data is safe when shared with third parties as set out below. 
          </div>
          <div>
            We will share your personal data with the following recipients: <br>
            • In order for us to use Enginio in our business we will share your personal data with <b>NRG</b>, the supplier of Enginio, and <b>the IT</b> suppliers necessary to enable you to use Enginio. These <b>suppliers</b> will process your personal data on our behalf and on our instructions. <br>
            • If you we deliver a winning or similar to you, the <b>company that transport your purchase</b> will process your personal data to provide the transport.<br>
            • If you take part in an activity involving payment such as a cash back campaign, we will share your personal data with our <b>payment service provider</b> who will help us to make the payment.  <br>
            • If you receive our newsletters, we will share your personal data with the company which helps us <b>send newsletters</b>.
          </div>
          <div>
            If you have any questions regarding how we share your personal data or want to know more about who we share your personal data with, please feel free to contact the Company or NRG.
          </div>
          <div>
            <b>Where are your personal data processed?</b>
          </div>
          <div>
            Your personal data will be processed outside of the EU/EEA if the suppliers we use are based outside of the EU/EEA.
          </div>
          <div>
            We and our suppliers rely on Standard Contractual Clauses for the transfer of personal data outside of the EU/EEA. The use of Standard Contractual Clauses is an effort to provide a safe transfer of your personal data.
          </div>
          <div>
            If you want to know more about where your personal data will be processed, please feel free to contact the Company or NRG. 
          </div>
          <div>
            <b>What are your rights when we process your personal data?</b>
          </div>
          <div>
            You have certain rights that you can exercise to affect how we process your personal data. You can read about what those rights are below. Contact the Company or NRG if you want to know more about your rights or exercise any of your rights. 
          </div>
          <div>
            <b>• Right to lodge a complaint with a supervisory authority</b><br>
            You always have the right to lodge a complaint with a supervisory authority. You may do this in the EU/EEA member state where you live, work or where an infringement of applicable data protection laws is alleged to have occurred. <br>
            • The supervisory authority in Sweden is the <a href="https://www.imy.se/en/" target="_blank">Swedish Data Protection Authority</a> (Integritetsskyddsmyndigheten, “IMY”). <br>
            • The supervisory authority in Denmark is the <a href="https://www.datatilsynet.dk/english" target="_blank">Danish Data Protection Agency</a> (Datatilsynet). <br>
            • The supervisory authority in Norway is the <a href="https://www.datatilsynet.no/en/" target="_blank">Norwegian Data Protection Authority</a> (Datatilsynet).<br>
            • The supervisory authority in Finland is the <a href="https://tietosuoja.fi/en/home" target="_blank">Office of the Data Protection Ombudsman</a> (Tietosuojavaltuutetun toimisto).<br>
            <b>• Right to withdraw your consent and object to processing</b><br>
            You have a right to withdraw any consent you have given us, partly or completely. <br>
            You always have a right to object to our processing of your personal data when the processing is performed for marketing and profiling purposes, such as sending newsletters and when the processing is based on the lawful basis “legitimate interest”. <br>
            <b>• Right to information and access</b><br>
            You have the right to obtain confirmation as to whether we are processing personal data about you or not. If we do process your personal data, you also have a right to obtain a copy of the personal data processed by us as well as information about how we process your personal data. <br>
            <b>• Right to rectification</b><br>
            You have a right to correct any inaccurate personal data concerning you that we may be processing and to ask us to have incomplete personal data completed. <br>
            <b>• Right to erasure (“the right to be forgotten”) and restriction of processing</b><br>
            You have a right to request that we delete your personal data. We will delete the personal data for example if you have withdrawn your consent and there is no other lawful basis for processing. <br>
            You also have a right to request that we restrict our processing of your personal data. We will do so for example when the accuracy of the personal data is contested by you, or the processing is unlawful, and you do not want us to delete your personal data but instead you request that we restrict our use of them.<br>
            <b>• Right to data portability</b><br>
            Under certain circumstances, you have a right to receive your personal data from us in a structured, commonly used and machine-readable format and, where technically feasible, have your personal data transferred to another company (“data portability”). This applies to personal data that you have provided to us in a structured, commonly used and machine-readable format, if our processing of your personal data is carried out by automated means and the lawful basis for our processing is based on the performance of a contract or your consent. 
          </div>
          <div class="a-line"></div>
          <div>
            This privacy policy was adopted by NRG Nordic Retail Group AB on 2022-03-01.
          </div>
        </div>
      </div>

      <div class="right-card">
        <div class="--container">
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.cookies' })">
            <div class="button-text">Cookies policy</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.visitors' })">
            <div class="button-text">Privacy Policy - Visitors</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.members' })">
            <div class="button-text">Privacy Policy - Members</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link unclickable">
            <div class="button-text">Privacy Policy - Participants</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.terms' })">
            <div class="button-text">Terms and conditions</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <!--<div
             class="data-link"
             @click="$router.push({ name: 'HomeData.companies' })">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeDataParticipants',
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle('Privacy Policy – Participants');
    this.setCompany({});
  },
  computed: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeDataParticipants";
</style>