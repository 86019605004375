/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import companyApi from '../../api/pvCompany.api';
import activationApi from '../../api/pvActivation.api';

// initial state
const state = {
  number: 8,
  company: {},
  companyQuery: '',

  companies: [],
  modalCompanies: [],
  companiesStatus: '',
};

// getters
const getters = {
  number: state => state.number,
  company: state => state.company,
  companyQuery: state => state.companyQuery,

  companies: state => state.companies,
  modalCompanies: state => state.modalCompanies,
  companiesStatus: state => state.companiesStatus,
};

// actions
const actions = {
  findAdminPVCompanies({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.findAdminPVCompanies(payload)
      .then((response) => {
        commit('COMPANIES', response.companies);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  getAdminPVCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.getAdminPVCompany(payload)
      .then((response) => {
        commit('COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  publiciseAdminPVCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.publiciseAdminPVCompany(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminPVCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.addAdminPVCompany(payload)
      .then((response) => {
        commit('ADD_COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminPVCompanyIcon({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.addAdminPVCompanyIcon(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminPVCompanyPicture({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.addAdminPVCompanyPicture(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminPVCompanyThumbnail({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.addAdminPVCompanyThumbnail(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  saveAdminPVCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.saveAdminPVCompany(payload)
      .then((response) => {
        commit('SAVE_COMPANY', response.company);
        commit('COMPANY', response.company);
        commit('COMPANY_STATUS', 'FINISHED');
      });
  },

  deleteAdminPVCompany({ commit }, payload) {
    commit('COMPANY_STATUS', 'LOADING');
    return companyApi.deleteAdminPVCompany(payload)
      .then(() => {
        commit('DELETE_COMPANY', payload.companyId);
        commit('COMPANY_STATUS', 'FINISHED');
        return;
      });
  },

  findAdminPVCompany({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.findAdminPVCompany(payload)
      .then((response) => {
        commit('COMPANY', response.company);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },
  findAdminPVCompanyByReceiptProperties({ commit }, payload) {
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.findAdminPVCompanyByReceiptProperties(payload)
      .then((response) => {
        commit('COMPANY', response.company);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },

  addAdminPVActivation({ commit }, payload) {
    /*payload.issueAt = getCorrectUnix(payload.issueAt);
    payload.archiveAt = getCorrectUnix(payload.archiveAt);*/
    commit('ACTIVATION_STATUS', 'LOADING');
    return activationApi.addAdminPVActivation(payload)
      .then((response) => {
        commit('ADD_ACTIVATION', response.activation);
        commit('ACTIVATION_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  SET_COMPANY_NUMBER(state, number) {
    state.number = number;
  },
  COMPANY_QUERY(state, companyQuery) {
    state.companyQuery = companyQuery;
  },
  COMPANIES(state, companies) {
    state.companies = companies;
    state.modalCompanies = [];
    for (let i = 0; i < state.companies.length; i += 1) {
      state.modalCompanies.push({
        value: state.companies[i]._id,
        text: state.companies[i].display_name ? state.companies[i].display_name : state.companies[i].name,
      });
    }
  },
  COMPANY(state, company) {
    state.company = company;
  },
  ADD_COMPANY(state, company) {
    state.companies.unshift(company);
  },
  SAVE_COMPANY(state, company) {
    for (let i = 0; i < state.companies.length; i += 1) {
      if (state.companies[i]._id === company._id) {
        Vue.set(state.companies, i, company);
      }
    }
  },
  DELETE_COMPANY(state, companyId) {
    for (let i = 0; i < state.companies.length; i += 1) {
      if (state.companies[i]._id === companyId) {
        state.companies.splice(i, 1);
      }
    }
  },
  COMPANY_STATUS(state, status) {
    state.companiesStatus = status;
  },
  ADD_ACTIVATION(state, activation) {
    state.company.activations.push(activation);
  },
  ACTIVATION_STATUS(state, status) {
    state.activationStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
