<template>
  <div class="activation-step-icon" @click="$emit('click')">
    <img :src="src" />
  </div>
</template>

<script>
export default {
  name: 'ActivationStepIcon',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.activation-step-icon {
  width: 77px;
  height: 79px;
  padding: 8px;
  border-radius: 12px;
  background-color: #F2F2F2;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
</style>