<template>
  <div>
    <modal
      v-if="show"
      @close="closeModal"
      :size="{ width: 540, height: 240 }"
      :padding="'0px'"
      :modalType="'fullscreen'">
      <div class="unsaved-changes-container">
        <span class="headline">There are unsaved changes</span>
        <span class="body">Do you want to save your unpublished changes</span>
        <div class="actions">
          <button class="button-solid margin" @click="save">
            <iconComponent symbol="floppyDisk" fill="#fff" stroke="#fff" :width="18"/>
            Save
          </button>
          <button class="button-yellow-outlined" @click="closeModal"> Don´t save </button>
        </div>
      </div>

    </modal>
  </div>
</template>
<script>
export default {
  name: 'UnsavedChanges',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
methods: {
    closeModal() {
      this.$router.push({name: 'CompanyCollections'});
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
  },
}
</script>
<style>

.unsaved-changes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 50px;

  .headline {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .body {
    font-size: 16px;
    font-weight: 400;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;

    .margin {
      margin-right: 16px;
    }
  }
}
</style>

