import axios from 'axios';

export default {
  // Admin
  findAdminCommunications: (data) => {
    return axios.put('/api/admin/communications', data)
      .then(response => ({
        communications: response.data.communications != null
          ? response.data.communications : [],
      }));
  },

  addAdminCommunication: (data) => {
    return axios.post('/api/admin/communications', data)
      .then(response => ({
        communication: response.data.communication != null
          ? response.data.communication : {},
      }));
  },

  getAdminCommunication: (data) => {
    return axios.get(`/api/admin/communications/${data.entityId}`)
      .then(response => ({
        communication: response.data.communication != null
          ? response.data.communication : {},
      }));
  },

  saveAdminCommunication: (payload) => {
    return axios.put(`/api/admin/communications/${payload.entityId}`, payload)
      .then(response => ({
        communication: response.data.communication != null
          ? response.data.communication : {},
      }));
  },

  importAdminContacts: (payload) => {
    return axios.post(`/api/admin/communications/${payload.entityId}/import-contacts`, payload)
      .then(response => ({
        communication: response.data.communication != null
          ? response.data.communication : {},
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  sendAdminCommunicationEmail: (payload) => {
    return axios.post(`/api/admin/communications/${payload.entityId}/send-email`, payload)
      .then(response => ({
        communication: response.data.communication != null
          ? response.data.communication : {},
      }));
  },
};
