<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find company..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminCompanies()"
        @enterKey="findAdminCompanies()"/>

      <div class="add-entity-button" @click="showCompanyModal = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>

    <aList
      entityType="PV Companies"
      columnsWidth="400"
      :entities="companies"
      :keys="companyKeys"
      routerName="AdminPVCompany"
      routerId="companyId"/>

    <modal v-if="showCompanyModal"
      :header="{text: 'Add pv company', closeButton: true, textPosition: 'center', bgColor: '#F2F2F2'}"
      @close="showCompanyModal = false"
      class="modal-view"
      modalType="fullscreen">
      <slot>
        <EntityEditor
          title="Create company"
          :admin="true"
          entity-type="PVCompany"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from "../resources/eventBus";
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminPvCompanies",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminCompanies();
    this.setPageTitle('Admin', 'PV Companies');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('pvCompanies', ["companies", "companiesStatus"])
  },
   data() {
    return {
      showCompanyModal: false,
      loadedPage: false,
      query: '',
      companyKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Base Url',
          key: 'url'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminCompanies() {
      this.$store.dispatch('pvCompanies/findAdminPVCompanies', { query: this.query }).then(() => {
        this.loadedPage = true;
      });
    },
    previewMedia(company) {
      this.companyPreview = company;
    },
    openOfferDialog(data) {
      this.companyId = data.companyId;
      this.showOfferDialog = true;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.company-list {
}
.single-list{
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .company-item-image{
      width: fit-content;
      height: fit-content;
    }
    .company-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.company-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-company-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
