<template>
  <div v-if="user" class="user-email">
    <div class="--gradient-header"
         :style="`background-image: url(${require('../assets/images/enginio_header_desktop.png')});`"></div>
    <div class="user-email--container">

      <h3 v-if="verifiedNew">{{ transl.UserSettings.youredone }}</h3>
      <h3 v-else-if="emailSent" class="--email-sent-header">{{  transl.UserSettings.updateemail }}</h3>
      <h3 v-else-if="updateEmail">{{  transl.UserSettings.updateemail }}</h3>
      <h3 v-else>{{  transl.UserSettings.emailadress }}</h3>

      <div
           v-if="!redirect"
           @click="$router.push({ name: 'UserSettings' })"
           class="back-button--container">
        <iconComponent class="settings--back-button" symbol="arrowLeft" />
      </div>

      <div v-if="!updateEmail && !emailSent" class="--email">
        <InputField
          v-if="user.verified_email"
          :single="true"
          :readonly="true"
          :filled="false"
          :static="true"
          trailingIcon="checkmark"
          trailingIconStroke="#EA7801"
          trailingIconFill="#EA7801"
          :label="transl.UserSettings.emailadress"
          v-model="user.email" />
        <div class="body-2" v-if="!user.verified_email">{{ transl.UserSettings.youhavenotverified }}</div>
      </div>
      <div v-else-if="updateEmail && !emailSent" class="--update-email">
        <div class="--container">
          <div class="--text body-2">{{ transl.UserSettings.verifyemail }}</div>
          <InputField
            id="email"
            :label="transl.UserSettings.emailadress"
            v-model="newEmail" />
        </div>
      </div>

      <div v-if="!updateEmail && !emailSent && !verifiedNew" class="--buttons">
        <div
             v-if="!user.verified_email"
             @click="newEmail = user.email; changeEmail();" class="btn btn-activate">
          {{ transl.UserSettings.sendnewemail }}</div>
        <div @click="updateEmail = true" class="btn btn-transparent">{{ transl.UserSettings.updateemail }}
        </div>
      </div>
      <div v-else-if="verifiedNew" class="--buttons">
        <div @click="reRoute()" class="btn btn-activate btn">{{ transl.UserSettings.continue }}</div>
      </div>
      <div v-else-if="updateEmail && !emailSent" class="--verify">
        <div class="--text">{{ transl.UserSettings.sendemail }}</div>
        <div @click="changeEmail()" class="btn btn-activate btn">{{ transl.UserSettings.saveandverify }}</div>
      </div>

      <div v-if="emailSent" class="--email-sent">
        <div class="--container">
          <div class="--icon-container">
            <iconComponent class="--icon" symbol="timeOut" width="50px" fill="#999999" stroke="#999999" />
          </div>
          <div class="--text body-1">{{ transl.Login.sentmail.interpolate({ useremail: newEmail }) }}</div>
          <aPinInput
            key="email-code"
            :auto-focus="true"
            :inputs="4"
            @incomplete="setValidationCode()"
            @complete="setValidationCode($event)"/>
        </div>
        <div class="login-signup-body">{{ transl.Login.nocode }}
          <span
            class="clickable-link"
            @click="sendAnotherEmailCode()">{{ transl.Login.sendnew }}</span>
        </div>
        <div
          class="btn btn-activate"
          :class="{ 'btn-disabled' : !validationCodeEmail || !validationCodeEmail.length }"
          @click="changeEmail()">
          {{ transl.Login.complete }}
        </div>
      </div>

      <div class="--integrity-seal">
        <div class="--text-1 small-text-bold">{{ transl.UserSettings.whyverify }}</div>
        <div class="--text-2 small-text-bold">{{ transl.UserSettings.safetyfirst }}</div>
        <Hero
              class="ok-stamp"
              type="Integrity_badge_Medium" />
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import IconComponent from '../stories/components/IconComponent/IconComponent.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import Hero from '../stories/components/Hero/Hero.vue';
import aPinInput from '../components/APinInput';
// import EventBus from '../resources/eventBus';

export default {
  name: 'UserEmail',
  components: {
    IconComponent,
    InputField,
    Hero,
    aPinInput,
  },
  mixins: [BeforeEnterMixin],
  computed: {
    ...mapGetters(['user', 'userStatus']),
  },
  mounted() {
    // this.wsUpdateState();
    this.redirect = JSON.parse(localStorage.getItem('UserEmailRedirect'));
    this.setPageTitle();
  },
  data() {
    return {
      updateEmail: false,
      emailSent: false,
      newEmail: '',
      verified: false,
      verifiedNew: false,
      redirect: {},
      incompleteValidationCode: true,
      validationCodeEmail: '',
    };
  },
  methods: {
    changeEmail() {
      if (this.validateEmail(this.newEmail) && this.validateEmail(this.newEmail) !== '') {
        this.$store.dispatch('changeEmail', {
          newEmail: this.newEmail,
        }).then(() => {
          // this.wsUpdateState();
          this.emailSent = true;
          this.updateEmail = false;
        });
      } else {
        this.setAlert(this.transl.UserSettings.noemail);
      }
    },
    validateEmail(email) {
      let exp = /\S+@\S+\.\S+/;
      return email.match(exp);
    },
    setValidationCode(code) {
      if (!code) {
        this.incompleteValidationCode = true;
        this.validationCodeEmail = '';
      } else {
        this.incompleteValidationCode = false;
        this.validationCodeEmail = code;
        this.verifyEmail();
      }
    },
    sendAnotherEmailCode() {
      axios.put('/api/nar/device/code/email', {
        phoneNr: this.user.phone_nr,
        email: this.newEmail,
        name: this.user.name,
      })
      .then((response) => {
        this.setAlert(response.data.message, 6);
      })
      .catch((err) => {
        this.setAlert(err.response.data.message, 6);
      });
    },
    verifyEmail() {
      this.$store.dispatch('signupLoginUser', {
        phoneNr: this.user.phone_nr,
        validationCode: this.validationCodeEmail,
      }).then((response) => {
        const { success, message, error, newUser } = response;
        if (!success) {
          this.setAlert(message, 6);
        } else {
          this.setValidationCode();
          this.updateEmail = false;
          this.emailSent = false;
          this.verifiedNew = true;
        }
      });
    },
    /*wsUpdateState() {
      if (this.wsReady) {
        this.$socket.client.on(`${this.user.phone_nr}_EMAIL`, () => {
          this.$store.dispatch('getUser').then(() => {
            this.updateEmail = false;
            this.emailSent = false;
            this.newEmail = '';
            this.verified = this.user.verified_email;
            this.verifiedNew = this.verified;
          });
        });
      }
    },*/
    reRoute() {
      localStorage.removeItem('UserEmailRedirect');
      if (this.redirect && this.redirect.name) {
        this.$router.replace(this.redirect);
      } else {
        this.$router.replace({ name: 'UserSettings' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserEmail";
</style>