<template>
  <div v-if="user && user._id" class="user-settings">
    <div class="settings-box">

      <div class="--gradient-header"
           :style="`background-image: url(${require('../assets/images/enginio_header_desktop.png')});`">
        <h1>{{ transl.UserSettings.settings }}</h1>
      </div>

      <div class="-header">
        <h3 class="--mobile">{{ transl.UserSettings.settings }}</h3>

        <div class="back-button--container" @click="$router.go(-1)">
          <iconComponent class="settings--back-button" symbol="arrowLeft" />
        </div>
      </div>

      <div class="settings-box--info-container">
        <div class="pre-title-small">{{ transl.UserSettings.myaccount }}</div>

        <div class="settings-box--info-container-container-1">
          <InputField :readonly="true" :filled="false" :static="true" leadingIcon='checkmark'
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" :label="transl.UserSettings.telnr"
                      v-model="user.phone_nr" />
          <InputField :readonly="true" :filled="false" :static="true" :label="transl.UserSettings.firstname"
                      trailingIconFill="#999999;" trailingIconStroke="#999999;" leadingIconStroke="#EA7801"
                      leadingIconFill="#EA7801" v-model="user.name" trailingIcon='pencil'
                      @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true" :label="transl.UserSettings.lastname"
                      trailingIconFill="#999999;" trailingIconStroke="#999999;" leadingIconStroke="#EA7801"
                      leadingIconFill="#EA7801" v-model="user.last_name" trailingIcon='pencil'
                      @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true"
                      :leadingIcon="user.verified_email ? 'checkmark' : ''" trailingIcon='pencil'
                      trailingIconFill="#999999;" trailingIconStroke="#999999;" leadingIconStroke="#EA7801"
                      leadingIconFill="#EA7801" @inputClick="$router.push({ name: 'UserEmail'})"
                      :label="transl.UserSettings.emailadress" v-model="user.email" />
        </div>

        <div v-show="!collapsed[0]" @click="toggleShowMore(0)" class="setting-box--show-more button-text">
          {{ transl.UserSettings.showmore }}
        </div>

        <div v-show="collapsed[0]" class="settings-box--info-container-container-2">
          <InputField :readonly="true" :filled="false" :static="true" trailingIcon='pencil'
                      trailingIconFill="#999999;" trailingIconStroke="#999999;"
                      :label="transl.UserSettings.adress" v-model="user.adress"
                      @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true" trailingIcon='pencil'
                      trailingIconFill="#999999;" trailingIconStroke="#999999;"
                      :label="transl.UserSettings.postalcode" v-model="user.postal_code"
                      @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true" trailingIcon='pencil'
                      trailingIconFill="#999999;" trailingIconStroke="#999999;"
                      :label="transl.UserSettings.city" v-model="user.city"
                      @inputClick="changeUserModal = true" />
        </div>

        <div v-show="collapsed[0]" @click="toggleShowMore(0)" class="setting-box--show-less button-text">
          {{ transl.UserSettings.showless }}</div>

        <div class="settings-box--info-container-container-3">
          <!--<div @click="$router.push({ name: 'UserDevices'})" class="--devices">
            <div class="pre-title-small">{{ transl.UserSettings.devices }}</div>
            <div class="--devices-container">
              <iconComponent class="" stroke="#DB4800" fill="#DB4800" symbol="smartphone" />
              <div class="button-text">{{ transl.UserSettings.mydevices }}</div>
            </div>
          </div>-->

          <div class="--account">
            <div class="pre-title-small" @click="toggleShowMore(1)">{{ transl.UserSettings.handleaccount }}
              <iconComponent :class="collapsed[1] ? 'up' : 'down' " symbol="forward" />
            </div>
            <div v-show="collapsed[1]" class="--account-subscribe">
              <div @click="subscribeEmail()" class="button-text">
                {{ user.unregister_email ? transl.UserSettings.subscribe : transl.UserSettings.unsubscribe }}
              </div>
            </div>
            <div v-show="collapsed[1]" class="--account-delete">
              <div @click="deleteAccModal = true" class="button-text">{{ transl.UserSettings.removebtn }}
              </div>
            </div>
          </div>

          <div class="--datapolicy">
            <div class="pre-title-small" @click="toggleShowMore(2)">{{ transl.UserSettings.data }}
              <iconComponent :class="collapsed[2] ? 'up' : 'down' " symbol="forward" />
            </div>
            <div v-show="collapsed[2]" class="--datapolicy-expand">
              <div class="--cookie">
                <div class="button-text">{{ transl.UserSettings.cookie }}</div>
                <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
              </div>

              <div class="--integrity">
                <div class="button-text">{{ transl.UserSettings.integrity }}</div>
                <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
              </div>

              <div class="--conditions">
                <div class="button-text">{{ transl.UserSettings.conditions }}</div>
                <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
              </div>

              <div class="--forgottenright">
                <div class="button-text">{{ transl.UserSettings.forgottenright }}</div>
                <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
              </div>
            </div>
          </div>

          <div class="--logout">
            <div class="btn btn-whitebg-border" @click="logout()">{{ transl.UserSettings.logout }}</div>
          </div>
        </div>
      </div>

      <div class="--desktop-info">
        <div class="pre-title-small">{{ transl.UserSettings.myaccount }}</div>

        <div class="settings-box--info-container-container-1">
          <InputField :readonly="true" :filled="false" :static="true" leadingIcon='checkmark'
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" trailingIconFill="#999999"
                      trailingIconStroke="#999999" :label="transl.UserSettings.telnr"
                      v-model="user.phone_nr" />
          <InputField :readonly="true" :filled="false" :static="true" :label="transl.UserSettings.firstname"
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" trailingIconFill="#999999"
                      trailingIconStroke="#999999" trailingIcon='pencil'
                      v-model="user.name" @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true" :label="transl.UserSettings.lastname"
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" trailingIconFill="#999999"
                      trailingIconStroke="#999999" trailingIcon='pencil'
                      v-model="user.last_name" @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true"
                      :leadingIcon="user.verified_email ? 'checkmark' : ''" trailingIcon='pencil'
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" trailingIconFill="#999999"
                      trailingIconStroke="#999999" @inputClick="$router.push({ name: 'UserEmail'})"
                      :label="transl.UserSettings.emailadress" v-model="user.email" />
        </div>


        <div class="settings-box--info-container-container-2">
          <InputField :readonly="true" :filled="false" :static="true" trailingIcon='pencil'
                      trailingIconFill="#999999" trailingIconStroke="#999999"
                      :label="transl.UserSettings.adress" v-model="user.adress"
                      @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true" trailingIcon='pencil'
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" trailingIconFill="#999999"
                      trailingIconStroke="#999999" :label="transl.UserSettings.postalcode"
                      v-model="user.postal_code" @inputClick="changeUserModal = true" />
          <InputField :readonly="true" :filled="false" :static="true" trailingIcon='pencil'
                      leadingIconStroke="#EA7801" leadingIconFill="#EA7801" trailingIconFill="#999999"
                      trailingIconStroke="#999999" :label="transl.UserSettings.city" v-model="user.city"
                      @inputClick="changeUserModal = true" />
        </div>
      </div>

      <!--<div class="--devices-desktop">
        <div class="pre-title-small">{{ transl.UserSettings.devices }}</div>
        <div class="--devices-container" @click="$router.push({ name: 'UserDevices'})">
          <iconComponent class="" stroke="#DB4800" fill="#DB4800" symbol="smartphone" />
          <div class="button-text">{{ transl.UserSettings.mydevices }}</div>
        </div>
      </div>-->

      <div class="--account-desktop">
        <div class="pre-title-small" @click="toggleShowMore(1)">{{ transl.UserSettings.handleaccount }}
          <iconComponent :class="collapsed[1] ? 'up' : 'down' " symbol="forward" />
        </div>
        <div v-show="collapsed[1]" class="--account-subscribe">
          <div @click="subscribeEmail()" class="button-text">
            {{ user.unregister_email ? transl.UserSettings.subscribe : transl.UserSettings.unsubscribe }}
          </div>
        </div>
        <div v-show="collapsed[1]" class="--account-delete">
          <div @click="deleteAccModal = true; changeUserModal = false" class="button-text">{{ transl.UserSettings.removebtn }}</div>
        </div>
      </div>

      <div class="--datapolicy-desktop">
        <div class="pre-title-small" @click="toggleShowMore(2)">{{ transl.UserSettings.data }}
          <iconComponent :class="collapsed[2] ? 'up' : 'down' " symbol="forward" />
        </div>
        <div v-show="collapsed[2]" class="--datapolicy-expand">
          <div class="--cookie" @click="$router.push({ name: 'HomeData.cookies' })">
            <div class="button-text">{{ transl.UserSettings.cookie }}</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>

          <div class="--integrity">
            <div class="button-text">{{ transl.UserSettings.integrity }}</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>

          <div class="--conditions">
            <div class="button-text">{{ transl.UserSettings.conditions }}</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>

          <div class="--forgottenright">
            <div class="button-text">{{ transl.UserSettings.forgottenright }}</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
        </div>
      </div>

      <div class="--logout-desktop">
        <div class="btn btn-medium btn-whitebg-border" @click="logout()">
          <iconComponent width="16px" symbol="exit" fill="#EA7801" stroke="#EA7801" />
          {{ transl.UserSettings.logout }}
        </div>
      </div>

      <div class="--dashboard-desktop" v-if="!changeUserModal">
        <h3>{{ transl.UserSettings.hey }}</h3>
        <div class="pre-title-small">{{ transl.UserSettings.whattodo }}</div>
      </div>

      <div class="change-adress-modal" v-if="changeUserModal">
        <div class="--container">
          <div class="-header">
            <div class="back-button--container" @click="changeUserModal = false">
              <iconComponent class="settings--back-button" symbol="arrowLeft" />
            </div>
            <h3>{{ transl.UserSettings.changeadress }}</h3>
          </div>

          <div class="--input-container">
            <div class="pre-title-small">{{ transl.UserSettings.adress }}</div>
            <InputField id="firstname" :outlined="true" :filled="false" :label="transl.UserSettings.firstname"
                        v-model="name" />
            <InputField id="lastname" :outlined="true" :filled="false" :label="transl.UserSettings.lastname"
                        v-model="lastName" />
            <InputField id="street-address" :outlined="true" :filled="false"
                        :label="transl.UserSettings.adress" v-model="adress" />
            <InputField id="postal-code" :outlined="true" :filled="false"
                        :label="transl.UserSettings.postalcode" v-model="postalCode" />
            <InputField id="city" :outlined="true" :filled="false" :label="transl.UserSettings.city"
                        v-model="city" />
          </div>

          <div class="--button-container">
            <div @click="changeUser()" class="btn btn-medium btn-activate">
              {{ transl.UserPV.save }}
            </div>
            <div @click="resetModals(true)" class="btn btn-medium btn-icon-text">
              {{ transl.UserSettings.removeinfo }}
            </div>
          </div>
        </div>
      </div>

      <modal
        v-if="deleteAccModal"
        @close="deleteAccModal = false"
        :header="{ closeButton: true}"
        size="medium">
        <div class="--delete-account-modal">
          <iconComponent width="50px" fill="#E3001F" stroke="#E3001F" class="--icon" symbol="user" />
          <div class="--content-1 body-1-bold">{{ transl.UserSettings.removeaccount }}</div>
          <div class="--content-2 body-1">{{ transl.UserSettings.removeaccountinfo }}</div>
          <div class="--content-3 body-1">{{ transl.UserSettings.writeremove }}</div>
          <InputField :label="transl.UserSettings.addwords" :required="true"
                      :validationType="'^'+ transl.UserSettings.removecaps +'$'"
                      :helperText="transl.App.mandatory"
                      v-model="deleteAcc" />
          <div class="--buttons">
            <div @click="deleteUser()" class="btn btn-whitebg-border">{{ transl.UserSettings.removebtn2 }}
            </div>
            <div @click="deleteAccModal = false" class="btn btn-activate">{{ transl.UserSettings.cancelbtn }}
            </div>
          </div>
        </div>
      </modal>

      <modal
        v-if="subscriptionModal"
        @close="subscriptionModal = false"
        :header="{ closeButton: true }"
        size="medium">
        <slot>
          <div class="subscription-modal">
            <div v-if="user.unregister_email">
              <div>
                <iconComponent class="--icon --content-0" symbol="unsubscribe" fill="#EA7801" stroke="none" width="60px" />
              </div>
              <div>
                <p class="--content-1 bold">
                  {{ transl.Newsletter.unsubscribednewsletter }}
                </p>
                <p class="--content-2 break-all bold">
                  {{ user.email }}
                </p>
                <p class="--content-3">
                  {{ transl.Newsletter.wellmissyou }}
                </p>
              </div>
              <div class="btn btn-activate" @click="subscriptionModal = false">{{ transl.Offer.close }}</div>
              <div
                @click="subscribeEmail()"
                class="btn"
                style="color: #EA7801;"
                v-html="transl.Newsletter.resubscribe">
              </div>
            </div>
            <div v-else>
              <iconComponent symbol="subscribe" width="60px" stroke="none" fill="#EA7801"/>
              <p class="--content-1 bold">{{ transl.routes.yprenumeration }}</p>
              <router-link :to="`${windowOrigin}/u-st`" class="btn btn-activate" style="margin-top: 35px;">{{ transl.Newsletter.backtolanding }}</router-link>
            </div>
          </div>
        </slot>
      </modal>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '../resources/eventBus';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import IconComponent from '../stories/components/IconComponent/IconComponent.vue';
import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: 'UserSettings',
  components: {
    IconComponent,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle(this.transl.UserSettings.settings);
    this.setLoadedPageData(true);
    if (this.user && this.user._id) {
      this.resetModals();
    }
    EventBus.$on('updateUser', () => {
      this.resetModals();
    });

    /*const Testing123 = document.getElementsByClassName('user-settings');
    Testing123.addEventListener('wheel', event => {
      const { ctrlKey } = event
      if (ctrlKey) {
        event.preventDefault();
        return;
      }
    }, { passive: false });*/
  },
  computed: {
    ...mapGetters(['user', 'userStatus']),
  },
  updated() {
    if (this.deleteAccModal === true || this.deleteAccModal === true || this.changeUserModal === true) {
      this.disabledScrolling(false);
    } else {
      this.disabledScrolling(true);
    }
  },
  destroyed() {
    this.$store.dispatch('getUser');
  },
  data() {
    return {
      showDeleteDialog: false,
      enableDeleteUser: false,
      changeUserModal: false,
      deleteAccModal: false,
      subscriptionModal: false,
      deleteAcc: '',
      name: '',
      lastName: '',
      adress: '',
      postalCode: '',
      city: '',
      newEmail: '',
      collapsed: [false, false, false],
    };
  },
  methods: {
    resetModals(deleteAdres) {
      this.showDeleteDialog = false;
      this.enableDeleteUser = false;
      this.changeUserModal = false;
      this.name = this.user.name;
      this.lastName = this.user.last_name;
      this.adress = this.user.adress;
      this.postalCode = this.user.postal_code;
      this.city = this.user.city;
      this.newEmail = '';
      /*if (!this.user.verified_email) {
        this.$router.push({ name: 'UserEmail'});
      }*/
      if (deleteAdres) {
        this.adress = '';
        this.postalCode = '';
        this.city = '';
        this.changeUser();
      }
    },
    deleteUser() {
      const keyword = this.transl.UserSettings.removecaps;
      if (this.deleteAcc.toUpperCase() === keyword) {
        this.$store.dispatch('deleteUser').then(() => {
          this.showDeleteDialog = false;
          this.setAlert(this.transl.UserSettings.removeduser);
          this.logout();
          // this.$router.replace({ name: 'Home' });
        });
      } else {
        document.getElementById('Fyll i ordet').focus();
      }
    },
    changeUser() {
      this.$store
        .dispatch('changeUser', {
          name: this.name,
          lastName: this.lastName,
          adress: this.adress,
          postalCode: this.postalCode,
          city: this.city,
        })
        .then(() => {
          this.changeUserModal = false;
          this.setAlert(this.transl.UserSettings.saveddata);
        });
    },
    subscribeEmail() {
      this.$store.dispatch('subscribeEmail').then(() => {
        if (this.changeUserModal) {
          this.changeUserModal = false;
        }
        this.subscriptionModal = true;
      });
    },
    toggleShowMore(i) {
      this.$set(this.collapsed, i, !this.collapsed[i]);
    },
    logout() {
      EventBus.$emit('logout');
    },
    disabledScrolling(reset) {
      if (reset) {
        document.body.style.overflow = '';
        document.body.style.height = '';
      } else {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/UserSettings";
</style>
