<template>
  <div :class="classes">

    <div
      v-if="(theme === 'iris' || !this.theme)
        && (data.length || (secondaryData && secondaryData.length))"
      class="header-activations body-1-bold">
      <iconComponent symbol="rocket"/>
      <p >{{ transl.Goodiebag.ouractivations }}</p>
    </div>

    <card
      v-for="(offer, index) in data"
      :key="offer._id"
      :tag="offer.offer_tag"
      :variations="offer.variations"
      :appstore="offer.appstore"
      :gplay="offer.g_play"
      :thumbnail="offer.thumbnail"
      :media="offer.media"
      :carousel="offer.carousel"
      :carousel-ratio="carouselRatio"
      :first_header="offer.first_header"
      :index="index"
      :expiration_date="expiresDate"
      :expiration_time="expiresTime"
      :quick_text="offer.quick_text"
      :save_text="offer.save_text"
      :clickable="offer.clickable"
      :columns="offer.columns"
      :jwt_lock="offer.jwt_lock"
      :unlocked="preview ? true : offer.unlocked"
      :theme="theme"
      :htmlText="offer.htmlText"
      :hide_text_box="offer.hide_text_box"
      :inlineCollection="offer.inlineCollection"
      :donation="offer.donation"
      :isVisited="offer.isVisited"
      :preview="preview"
      :brandColor="offer.brand_color"
      sliderHeightDesktop="250px"
      @cardClick="cardClick($event || offer, offer.inlineCollection ? 'goodiebag' : 'offer')"/>

    <div v-if="secondaryData">
      <card
        v-for="(offer, index) in secondaryData"
        :key="offer._id"
        :closed_offer="true"
        :tag="offer.offer_tag"
        :variations="offer.variations"
        :appstore="offer.appstore"
        :gplay="offer.g_play"
        :thumbnail="offer.thumbnail"
        :media="offer.media"
        :carousel="offer.carousel"
        :carousel-ratio="carouselRatio"
        :first_header="offer.first_header"
        :index="index"
        :expiration_date="expiresDate"
        :expiration_time="expiresTime"
        :quick_text="offer.quick_text"
        :save_text="offer.save_text"
        :clickable="offer.clickable"
        :columns="offer.columns"
        :jwt_lock="offer.jwt_lock"
        :unlocked="preview ? true : offer.unlocked"
        :theme="theme"
        :htmlText="offer.htmlText"
        :hide_text_box="offer.hide_text_box"
        :inlineCollection="offer.inlineCollection"
        :donation="offer.donation"
        :isVisited="offer.isVisited"
        :preview="preview"
        :brandColor="offer.brand_color"
        sliderHeightDesktop="250px"
        @cardClick="cardClick($event || offer, offer.inlineCollection ? 'goodiebag' : 'offer')"/>
    </div>
  </div>
</template>

<script>
import Card from "../Card/Card.vue";
import ExpiredMixin from "../../../mixins/ExpiredMixin";
import iconComponent from '../IconComponent/IconComponent';

export default {
  name: "CardWrapperComponent",
  mixins: [ExpiredMixin],
  components: { Card, iconComponent },
  props: {
    data: {
      type: Array,
      required: false,
    },
    secondaryData: {
      type: Array,
      required: false,
    },
    startCountdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: '',
    },
    preview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      expiresDate: [],
      expiresTime: [],
      clientWidth: document.documentElement.clientWidth,
    };
  },
  computed: {
    classes() {
      return {
        'iris': this.theme !== 'nemo',
        'nemo': this.theme === 'nemo',
        'offer-wrapper': !this.preview,
        'offer-wrapper-contained': this.preview,
        'mobile-less-than-2-entities': this.clientWidth < 480 && (this.data?.length+this.secondaryData?.length) < 3,
      };
    },
    carouselRatio() {
      let ratio = 1/4;
      if (this.clientWidth <= this.mobileMaxwidth) {
        ratio = 1/2;
      } else if (this.clientWidth <= this.desktopSmallWidth) {
        ratio = 1/3;
      }
      return ratio;
    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  watch: {
    startCountdown(newVal) {
      if (newVal == true) {
        this.startExpiresTime();
      }
    },
  },
  methods: {
    cardClick(entity, entityType) {
      entity.entityType = entityType;
      this.$emit('cardClick', entity);
    },
    startExpiresTime() {
      this.setExpires();
      this.timeInterval = setInterval(() => {
        this.setExpires();
      }, 1000);
    },
    setExpires() {
      this.expiresDate = [];
      this.expiresTime = [];
      for (let i = 0; i < this.data.length; i += 1) {
        const offer = this.data[i];
        this.expiresDate.push(this.getExpiresDate(offer));
        this.expiresTime.push(this.getExpiresTime(offer.expires_at, true));
      }
    },
    getDimensions() {
      this.clientWidth = document.documentElement.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./CardWrapper.scss";
@import "./CardWrapperNemoTheme.scss";
.mobile-less-than-2-entities {
  grid-template-columns: 1fr !important;
}
</style>
