<template>
  <div>
    <ReceiptList :admin="true"/>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import ReceiptList from '../stories/components/ReceiptList/ReceiptList.vue';

export default {
  name: "AdminPVReceipts",
  mixins: [BeforeEnterMixin],
  components: {
    ReceiptList,
  },
};
</script>

<style lang="scss" scoped>
</style>
