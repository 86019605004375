import axios from 'axios';

export default {
  // Admin
  findAdminContents: (data) => {
    return axios.put('/api/admin/contents', data)
      .then(response => ({
        contents: response.data.contents != null
          ? response.data.contents : [],
      }));
  },

  addAdminContent: (data) => {
    return axios.post('/api/admin/contents', data)
      .then(response => ({
        content: response.data.content != null
          ? response.data.content : {},
      }));
  },

  getAdminContent: (data) => {
    return axios.get(`/api/admin/contents/${data.entityId}`)
      .then(response => ({
        content: response.data.content != null
          ? response.data.content : {},
      }));
  },

  saveAdminContent: (payload) => {
    return axios.put(`/api/admin/contents/${payload.entityId}`, payload)
      .then(response => ({
        content: response.data.content != null
          ? response.data.content : {},
      }));
  },
};
