<template>
  <span/>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'ExpiredMixin',
  data() {
    return {
      months: [
        'jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec',
      ],
    };
  },
  methods: {
    getExpiresDate(offer) {
      if (offer.starts_at || offer.expires_at) {
        let startsAtMonth = null;
        let expiresAtMonth = null;
        let startsAtDate = null;
        let expiresAtDate = null;
        if (offer.starts_at) {
          const startsAt = moment.unix(offer.starts_at);
          startsAtMonth = this.months[startsAt.month()];
          startsAtDate = startsAt.date();
        }
        if (offer.expires_at) {
          const expiresAt = moment.unix(offer.expires_at);
          expiresAtMonth = this.months[expiresAt.month()];
          expiresAtDate = expiresAt.date();
        }
        if (offer.starts_at && offer.expires_at) {
          return `${startsAtDate} ${startsAtMonth}–${expiresAtDate} ${expiresAtMonth}`;
        } else if (!offer.starts_at && offer.expires_at) {
          return `Gäller till ${expiresAtDate} ${expiresAtMonth}`;
        } else if (offer.starts_at && !offer.expires_at) {
          return `Gäller från ${startsAtDate} ${startsAtMonth}`;
        }
      } else {
        return false;
      }
    },
    getExpiresTime(expiresAt, hours) {
      const nowMoment = (new moment()).valueOf();
      if (!expiresAt) {
        return false;
      }
      const duration = moment.duration(expiresAt*1000-nowMoment);

      if (duration.asSeconds() <= 0) {
        this.expiresAtState = 'expired';
        if (this.firstTime) {
          this.internalLinkModal = true;
          this.firstTime = false;
          clearTimeout(this.timeInterval);
        }
        this.activeOffer = false;
        this.expiredOffer = true;
        return false;
      } else if (duration.asHours() >= 24) {
        return `${duration._data.days} dagar`;
      }
      const minutes = duration._data.minutes < 10 ? `0${duration._data.minutes}` : duration._data.minutes;
      const seconds = duration._data.seconds < 10 ? `0${duration._data.seconds}` : duration._data.seconds;
      if (hours || duration.asHours() >= 1) {
        const hours = duration._data.hours < 10 ? `0${duration._data.hours}` : duration._data.hours;
        return `${hours}:${minutes}:${seconds}`;
      } else {
        return `${minutes}:${seconds}`;
      }
    },
    isLong(event) {
      const timeDiff = event.end - event.start;
      const stampedDiff = event.endStamp - event.startStamp;
      const muchDiff = stampedDiff - timeDiff;
      const tooMuch = muchDiff >= 60;
      if (tooMuch) {
        return muchDiff;
      }
      return tooMuch;
    },
    afterNow(time) {
      const nowMoment = moment().unix();
      const end = time;
      return nowMoment >= end;
    },
    getExpiredState(offer) {
      let state = 'none';
      const nowUnix = (new moment()).unix()
      if (offer.expires_at) {
        if (offer.expires_at - nowUnix > 3600*24) {
          state = 'date';
        } else if (offer.expires_at - nowUnix < 3600*24 && offer.expires_at - nowUnix > 0) {
          state = 'time';
        } else if (offer.expires_at - nowUnix < 0) {
          state = 'expired';
        }
      }
      return state;
    },
  },
};
</script>
