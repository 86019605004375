var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-explorer"},[_c('div',{staticClass:"table",style:(_vm.cssProps)},[(_vm.company)?_c('div',{staticClass:"table-links"},[_c('p',[_vm._v("Channels in")])]):_vm._e(),(_vm.company)?_c('div',{staticClass:"table-header highlight",class:{
        'links-done': _vm.company.links_done,
      }},[_c('div',[_c('a',{attrs:{"href":`${_vm.windowOrigin}/admin/links-manager/comp/${_vm.company._id}`}},[_c('iconComponent',{attrs:{"symbol":"home","fill":"black"},on:{"click":function($event){return _vm.$emit('openCompany')}}})],1),_c('iconComponent',{attrs:{"symbol":"forward"}}),_c('div',[_c('p',[_vm._v(_vm._s(_vm.company.name))])])],1)]):_vm._e(),_vm._l((_vm.goodiebags),function(goodiebag){return _c('div',{key:goodiebag._id,staticClass:"table-content"},[_c('div',{staticClass:"table-goodiebag",class:{
          'highlight': _vm.selected == goodiebag._id,
          'links-done': goodiebag.links_done
        }},[_c('div',{staticClass:"table-item-container"},[_c('a',{attrs:{"href":`${_vm.windowOrigin}/admin/links-manager/coll/${goodiebag._id}`}},[_c('iconComponent',{attrs:{"symbol":"folder","fill":"black"},on:{"click":function($event){return _vm.$emit('openFolder', goodiebag)}}})],1),_c('iconComponent',{attrs:{"symbol":"forward"},on:{"click":function($event){return _vm.getGoodiebags(goodiebag)}}}),_c('div',[_c('p',[_vm._v(_vm._s(goodiebag.name))])])],1),_c('div',{staticClass:"goodiebag-links"},[_c('p',[_vm._v(_vm._s(goodiebag.links_in && goodiebag.links_in.length ? goodiebag.links_in.length : '0 links'))])])]),(_vm.selected == goodiebag._id
          && (_vm.childGoodiebags.length || _vm.childOffers.length))?_c('ListExplorerComponent',{attrs:{"goodiebags":_vm.childGoodiebags,"offers":_vm.childOffers,"admin":_vm.admin,"tablePaddingIndex":_vm.tablePaddingIndex+1},on:{"openItem":function($event){return _vm.$emit('openItem', $event)},"openFolder":function($event){return _vm.$emit('openFolder', $event)}}}):_vm._e()],1)}),_vm._l((_vm.offers),function(offer){return _c('div',{key:offer._id,staticClass:"table-content"},[_c('div',{staticClass:"table-goodiebag",class:{
          'links-done': offer.links_done
        }},[_c('div',{staticClass:"table-item-container"},[_c('a',{attrs:{"href":`${_vm.windowOrigin}/admin/links-manager/acti/${offer._id}`}},[_c('iconComponent',{attrs:{"symbol":"file","fill":"black"},on:{"click":function($event){return _vm.$emit('openItem', offer)}}})],1),_c('div',[_c('p',[_vm._v(_vm._s(offer.first_header))])])]),_c('div',{staticClass:"goodiebag-links"},[_c('p',[_vm._v(_vm._s(offer.links_in && offer.links_in.length ? offer.links_in.length : '0 links'))])])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }