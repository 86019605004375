<template>
  <div>
  </div>
</template>

<script>


export default {
  name: 'FAQDataMixin',
  data() {
    return {
      faqs: [],
    }
  },
  mounted() {
    this.faqs = [
      {
        subject: this.transl.HomeFAQ.receiptscan,
        key: 'receiptscan',
        questions: [
          {
            header: this.transl.HomeFAQ.whynotreceipt,
            key: 'whynotreceipt',
            body: [
              {
                faqType: 'list',
                texts: [
                  this.transl.HomeFAQ.whatreceiptscantext1,
                  this.transl.HomeFAQ.notvalidreceipt
                ]
              },
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.acceptedreceipts
              },
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.includeinreceipt
              },
              {
                faqType: 'list',
                texts: [
                  this.transl.HomeFAQ.receiptscanliststore,
                  this.transl.HomeFAQ.receiptscanlistproduct,
                  this.transl.HomeFAQ.receiptscanlistdate,
                ],
              },
              {
                faqType: 'image', localUrl: `${this.windowOrigin}/api/nar/file/Enginio_Reciept_Small.jpg`, text: this.transl.HomeFAQ.cashierreceipt,
              },
            ],
          },
        ]
      },
      {
        subject: 'Enginio',
        key: 'enginio',
        questions: [
          {
            header: this.transl.HomeFAQ.whatis,
            key: 'whatis',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whattext1,
              },
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whattext2,
              },
            ],
          },
          {
            header: this.transl.HomeFAQ.activateheader,
            key: 'activateheader',
            body: [
              {
                faqType: 'text-info', texts: this.transl.HomeFAQ.activatetext1,
              },
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.activatetext2,
              },
            ]
          },
          {
            header: this.transl.HomeFAQ.emailcontribheader,
            key: 'emailcontribheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.emailcontribtext1
              },
            ]
          },
          {
            header: this.transl.HomeFAQ.desktopactivateheader,
            key: 'desktopactivateheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.desktopactivatetext1,
              },
            ]
          },
          {
            header: this.transl.HomeFAQ.whatactivationheader,
            key: 'whatactivationheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whatactivationtext1,
              },
            ]
          },
        ]
      },
      {
        subject: this.transl.HomeFAQ.account,
        key: 'account',
        questions: [
          {
            header: this.transl.HomeFAQ.pastaccountheader,
            key: 'pastaccountheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.pastaccounttext1
              },
            ],
          },
          {
            header: this.transl.HomeFAQ.whyverifybothheader,
            key: 'whyverifybothheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whyverifybothtext1,
              },
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whyverifybothtext2,
              },
            ],
          },
          {
            header: this.transl.HomeFAQ.severalaccountsdeviceheader,
            key: 'severalaccountsdeviceheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.severalaccountsdevicetext1
              },
            ],
          },
          {
            header: this.transl.HomeFAQ.severalnrheader,
            key: 'severalnrheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.severalnrtext1
              },
            ],
          },
        ]
      },
      {
        subject: this.transl.HomeFAQ.delivery,
        key: 'delivery',
        questions: [
          {
            header: this.transl.HomeFAQ.whendoigetdigitalheader,
            key: 'whendoigetdigitalheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whendoigetdigitaltext1
              },
            ],
          },
          {
            header: this.transl.HomeFAQ.whendoigetphysicalheader,
            key: 'whendoigetphysicalheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whendoigetphysicaltext1,
              },
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.whendoigetphysicaltext2,
              },
            ],
          },
          {
            header: this.transl.HomeFAQ.timelimitheader,
            key: 'timelimitheader',
            body: [
              {
                faqType: 'text-info', text: this.transl.HomeFAQ.timelimittext1
              },
            ],
          }
        ]
      },
    ]
  },
}
</script>