/* eslint-disable no-shadow, no-param-reassign */
import storeApi from '../../api/pvStore.api';

// initial state
const state = {
  store: {},

  storeQuery: '',
  stores: [],

  modalPvStores: [],
};

// getters
const getters = {
  store: state => state.store,

  storeQuery: state => state.storeQuery,
  stores: state => state.stores,

  modalPvStores: state => state.modalPvStores,
};

// actions
const actions = {
  // ADMIN
  findAdminPVStore({ commit }, payload) {
    commit('STORE_STATUS', 'LOADING');
    return storeApi.findAdminPVStore(payload)
      .then((response) => {
        commit('STORES', response.stores);
        commit('STORE_STATUS', 'FINISHED');
      });
  },

  findAdminModalPVStores({ commit }, payload) {
    commit('STORE_STATUS', 'LOADING');
    return storeApi.findAdminPVStore(payload)
      .then((response) => {
        commit('MODAL_STORES', response.stores);
        commit('STORE_STATUS', 'FINISHED');
      });
  },

  addAdminPVStore({ commit }, payload) {
    commit('STORE_STATUS', 'LOADING');
    return storeApi.addAdminPVStore(payload)
      .then((response) => {
        commit('ADD_STORE', response.store);
        commit('STORE_STATUS', 'FINISHED');
      });
  },

  saveAdminPVStore({ commit }, payload) {
    commit('STORE_STATUS', 'LOADING');
    return storeApi.saveAdminPVStore(payload)
      .then((response) => {
        commit('SAVE_STORE', response.store);
        commit('STORE_STATUS', 'FINISHED');
      });
  },

  getAdminPVStore({ commit }, payload) {
    commit('STORE_STATUS', 'LOADING');
    return storeApi.getAdminPVStore(payload)
      .then((response) => {
        commit('STORE', response.store);
        commit('STORE_STATUS', 'FINISHED');
      });
  },

  deleteAdminPVStore({ commit }, payload) {
    commit('STORE_STATUS', 'LOADING');
    return storeApi.deleteAdminPVStore(payload)
      .then(() => {
        commit('DELETE_STORE', payload.entityId);
        commit('STORE_STATUS', 'FINISHED');
        return;
      });
  },
};

// mutations
const mutations = {
  STORE(state, store) {
    state.store = store;
  },
  STORES(state, stores) {
    state.stores = stores;
  },
  ADD_STORE(state, store) {
    state.stores.unshift(store);
  },

  STORE_QUERY(state, storeQuery) {
    state.storeQuery = storeQuery;
  },
  SAVE_STORE(state, store) {
    state.store = store;
  },
  DELETE_STORE(state) {
    state.store = {};
  },
  STORE_STATUS(state, status) {
    state.storeStatus = status;
  },
  MODAL_STORES(state, stores) {
    state.modalPvStores = [];
    for (let i = 0; i < stores.length; i += 1) {
      state.modalPvStores.push({
        value: stores[i]._id,
        text: stores[i].name,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
