<template>
  <div
    class="activation-step-label"
    :class="{ 'activation-step-label-selected': selected }"
  >
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'ActivationStepLabel',
  props: {
    text: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.activation-step-label {
  padding: 8px 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: #000000;
}
.activation-step-label-selected {
  font-weight: 700;
}
</style>