<template>
  <div class="user-activation-editor-container">
    <h2>{{ activation.name }}</h2>
    <UserActivationSteps
      :steps="activation.steps"
      :selectedIndex="selectedStepIndex"
      @selectStep="$emit('selectStep', $event)"
    />
    <div class="user-receipt" v-if="receipt.receipt_analyze_attempts > 0">
      <div class="user-receipt-details">
        <!-- Inputs generated from computed fields -->
        <div v-for="field in receiptFields" :key="field.key" class="input-container">
          <label :for="field.key">
            <UserActivationIcon :type="'info'" />
            {{ field.label }}
          </label>
          <div class="input-row">
            <md-datepicker v-if="field.type === 'date'"
              class='input'
              :class="{ 'valid-field': field.state.valid === true, 'invalid-field': field.state.valid === false }"
              :md-model-type=String
              :md-clearable="false"
              :value="receiptData[field.key]"
              @input="receiptData[field.key] = $event"
            />
            <input v-else
              v-model="receiptData[field.key]"
              type="text"
              :id="field.key"
              :placeholder="field.label"
              :class="{ 'valid-field': field.state.valid === true, 'invalid-field': field.state.valid === false }"
            />
            <UserActivationIcon v-if="field.state.valid === true" :type="'valid'" />
            <UserActivationIcon v-if="field.state.valid === false" :type="'invalid'" />
          </div>
          <div class="info-row">
            <span>{{ field.state.message }}</span>
            <button v-if="field.button" @click="field.button.action">{{ field.button.label }}</button>
          </div>
        </div>

        <!-- Receipt info -->
        <div class="user-receipt-info">
          <div class="row">
            <span>Attempts</span>
            <span class="user-receipt-info-value">
              {{ receipt.receipt_analyze_attempts ?? 0 }}
              <span class="user-receipt-info-placeholder"></span>
            </span>
          </div>
          <div class="row">
            <span>Similiarity</span>
            <span class="user-receipt-info-value">
              {{ receiptSimilarity }}%
              <UserActivationIcon :type="receiptSimilarity < 80 ? 'valid' : 'invalid'" />
            </span>
          </div>
          <div class="row">
            <span>Format</span>
            <span class="user-receipt-info-value">
              {{ parseMediaFormat()}}
              <UserActivationIcon :type="'valid'" />
            </span>
          </div>
        </div>

        <!-- <div class="input-container">
          <label for="comment">Comment</label>
          <textarea v-model="receiptData.comment" id="comment" rows="5" placeholder="Comment"></textarea>
        </div> -->
      </div>

      <!-- Receipt preview -->
      <div class="user-receipt-preview-container">
        <div class="user-receipt-preview">
          <vuePdf
            v-if="previewType === 'pdf'"
            class="user-receipt-pdf"
            :src="previewSrc"
          />
          <img v-else-if="previewType" :src="previewSrc" />
        </div>
        <div class="receipt-actions">
          <button class="button-yellow" @click="$emit('declineReceipt')">Decline receipt</button>
          <button class="button-solid" :disabled="!shouldEnableConfirm" @click="$emit('confirmReceipt')">Confirm receipt</button>
        </div>
      </div>

      <!-- Terms editor modal -->
      <PartnerModal :show="showReceiptModal" :showCloseButton="false" @close="showReceiptModal = false">
        <div class="receipt-modal-header">
          <h2>Receipt terms</h2>
          <button @click="showReceiptModal = false">
            <iconComponent symbol="cross" :width="24"/>
          </button>
        </div>

        <div class="receipt-modal-content">
          <div class="receipt-modal-tabs">

            <div class="receipt-modal-tab" :class="{ selected: receiptModalTab === 'terms' }" @click="receiptModalTab = 'terms'">
              <span class="label">Receipt terms</span>
            </div>
            <div class="receipt-modal-tab" :class="{ selected: receiptModalTab === 'shops' }" @click="receiptModalTab = 'shops'">
              <span class="label">Receipt shop list</span>
            </div>
          </div>

          <div class="divider"></div>

          <div v-if="receiptModalTab === 'terms'" class="receipt-terms-content">
            <chip-area
              :key="'terms'"
              :data-field="activationData.receipt_terms"
              :label="'Receipt terms'"
              :with-search-input="true"
              :placeholder="'Add term...'"
              @change="emitActivationChange('receipt_terms', $event)"
            />
          </div>
          <div v-else-if="receiptModalTab === 'shops'" class="receipt-shops-content">
            <div class="shop-list-container">
              <span class="label">Receipt shop</span>
              <div class="shop-list-selection">
                <InputField inputType="radio" :label="'Include list'" :value="activeShopList === 'include'" @input="activeShopList = 'include'" />
                <InputField inputType="radio" :label="'Exclude list'" :value="activeShopList === 'exclude'" @input="activeShopList = 'exclude'" />
              </div>

              <chip-area v-if="activeShopList === 'include'"
                :key="'shop-list'"
                :data-field="activationData.receipt_shop_list"
                :with-search-input="true"
                :placeholder="'Add shop...'"
                @change="emitActivationChange('receipt_shop_list', $event)"
              />

              <chip-area v-else-if="activeShopList === 'exclude'"
                :key="'excluded-shop-list'"
                :data-field="activationData.receipt_shop_exclude_list"
                :with-search-input="true"
                :placeholder="'Add shop...'"
                @change="emitActivationChange('receipt_shop_exclude_list', $event)"
              />
            </div>
          </div>
        </div>

        <div class="receipt-modal-footer">
          <button class="button-solid" @click="$emit('saveActivation'); showReceiptModal = false;">
            <iconComponent symbol="floppyDisk" :width="16" stroke="#FFF" fill="#FFF"/>
            Save
          </button>
        </div>
      </PartnerModal>

    </div>
  </div>
</template>

<script>
import vuePdf from 'vue-pdf';
import DateTimeConverterMixin from '@/mixins/DateTimeConverterMixin.vue';
import UserActivationSteps from './UserActivationSteps.vue';
import UserActivationIcon from './UserActivationIcon.vue';
import PartnerModal from '../PartnerModal/PartnerModal.vue';
import ChipArea from '../ChipArea/ChipArea.vue';
import InputField from '../InputField/InputField.vue';
import moment from 'moment';


export default {
  name: 'UserActivationEditor',
  components: {
    vuePdf,
    UserActivationSteps,
    UserActivationIcon,
    PartnerModal,
    ChipArea,
    InputField,
},
  mixins: [DateTimeConverterMixin],
  props: {
    activation: {
      type: Object,
      required: true,
    },
    receipt: {
      type: Object,
    },
    selectedStep: {
      type: String,
    },
    mediaHost: {
      type: String,
    },
  },
  data() {
    const { receipt_shop_exclude_list } = this.activation || {};

    return {
      receiptData: {},
      activationData: {},
      showReceiptModal: false,
      receiptModalTab: 'terms',
      activeShopList: receipt_shop_exclude_list?.length > 0 ? 'exclude' : 'include'
    }
  },
  computed: {
    previewSrc() {
      if (!this.mediaHost || !this.receipt?.media?.file) return null;

      return `${this.mediaHost}/${this.receipt.media.file}`;
    },
    previewType() {
      if (!this.receipt?.media?.fileType) return null;

      return this.receipt.media.fileType.includes('pdf') ? 'pdf' : 'img';
    },

    receiptItemIsRequired() {
      const { receipt_terms } = this.activation || {};
      return receipt_terms?.length > 0;
    },
    receiptStoreIsRequired() {
      const { receipt_shop_list, receipt_shop_exclude_list } = this.activation || {};
      return receipt_shop_list?.length > 0 || receipt_shop_exclude_list?.length > 0;
    },
    receiptAmountIsRequired() {
      const { receipt_term_minimum_amount } = this.activation || {};
      return receipt_term_minimum_amount > 0;
    },
    receiptDateIsRequired() {
      const { receipt_date_max_days, receipt_date_range_from, receipt_date_range_to } = this.activation || {};
      return receipt_date_max_days || receipt_date_range_from || receipt_date_range_to;
    },

    receiptItemValidationError() {
      const { receipt_item } = this.receiptData || {};
      const { receipt_terms } = this.activation || {};

      if (!this.receiptItemIsRequired && !receipt_item)
        return null;

      return receipt_terms?.length > 0 && !receipt_terms?.find(term => term.toLowerCase() === receipt_item?.toLowerCase())
        ? 'Product not in terms'
        : null;
    },
    receiptStoreValidationError() {
      const { receipt_store } = this.receiptData || {};
      const { receipt_shop_list, receipt_shop_exclude_list } = this.activation || {};

      if (!receipt_store)
        return this.receiptStoreIsRequired ? 'No retailer' : null;

      if (receipt_shop_exclude_list?.length > 0) {
        if (receipt_shop_exclude_list?.find(shop => shop.toLowerCase() === receipt_store?.toLowerCase()))
          return 'Retailer excluded';
      } else if (receipt_shop_list?.length > 0) {
        if (!receipt_shop_list?.find(shop => shop.toLowerCase() === receipt_store?.toLowerCase()))
          return 'Retailer not in list';
      }

      return null;
    },
    receiptAmountValidationError() {
      const { receipt_item_cost } = this.receiptData || {};
      const { receipt_term_minimum_amount } = this.activation || {};

      if (!this.receiptAmountIsRequired)
        return null;

      if (!receipt_item_cost)
        return 'No amount';

      const receiptAmount = typeof receipt_item_cost === 'string' && receipt_item_cost.length > 0
        ? this.parseReceiptValue(receipt_item_cost)
        : null;

      return typeof receiptAmount !== 'number'
        ? 'Invalid amount'
        : typeof receiptAmount === 'number' && receiptAmount < receipt_term_minimum_amount
          ? 'Amount too low'
          : null;
    },
    receiptDateValidationError() {
      const { receipt_date_max_days, receipt_date_range_from, receipt_date_range_to, timezone } = this.activation
      const { receipt_date, receipt_date_unix } = this.receiptData || {};

      if (!this.receiptDateIsRequired)
        return null;

      if (!receipt_date)
        return 'No date';

      const unixReceiptDate = receipt_date_unix ? receipt_date_unix : new moment(receipt_date).tz(timezone).unix();

      if (receipt_date_max_days) {
        const now = new moment().tz(timezone).unix();
        const maxDaysInSeconds = receipt_date_max_days * 3600 * 24;

        if ((now - unixReceiptDate) > maxDaysInSeconds) {
          return 'Receipt is too old';
        }
      }

      if (receipt_date_range_from && receipt_date_range_to) {
        if (unixReceiptDate < receipt_date_range_from || unixReceiptDate > receipt_date_range_to) {
          return 'Receipt date out of range'; 
        }
      }

      return null;
    },

    receiptFields() {
      const productError = this.receiptItemValidationError;
      const storeError = this.receiptStoreValidationError;
      const amountError = this.receiptAmountValidationError;
      const dateError = this.receiptDateValidationError;

      return [
        {
          key: 'receipt_item',
          label: 'Product',
          state: {
            valid: this.receiptItemIsRequired ? !productError : undefined,
            message: this.receiptItemIsRequired ? (productError || 'Valid product') : 'Not required'
          },
          button: {
            label: 'Show terms',
            action: () => this.handleOpenReceiptModal('terms')
          }
        },
        {
          key: 'receipt_store',
          label: 'Retailer',
          state: {
            valid: this.receiptStoreIsRequired ? !storeError : undefined,
            message: this.receiptStoreIsRequired ? (storeError || 'Valid retailer') : 'Not required'
          },
          button: {
            label: 'Show shop list',
            action: () => this.handleOpenReceiptModal('shops')
          }
        },
        {
          key: 'receipt_item_cost',
          label: 'Amount',
          state: {
            valid: this.receiptAmountIsRequired ? !amountError : undefined,
            message: this.receiptAmountIsRequired ? (amountError || 'Valid amount') : 'Not required'
          },
        },
        {
          key: 'receipt_date',
          label: 'Date',
          type: 'date',
          state: {
            valid: this.receiptDateIsRequired ? !dateError : undefined,
            message: this.receiptDateIsRequired ? (dateError || 'Valid date') : 'Not required'
          },
        }
      ];
    },

    receiptSimilarity() {
      const maxSimilarity = this.receipt?.similar_receipts?.map(r => r.similarity) ?? [0]
      return Math.max(...maxSimilarity, 0);
    },
    shouldEnableConfirm() {
      return this.receiptFields.every(field => field.state.valid !== false);
    },
    selectedStepIndex() {
      if (!this.selectedStep) return null;

      return this.activation.steps.findIndex((step) => step.id === this.selectedStep);
    },
  },
  methods: {
    emitActivationChange(key, value) {
      this.$emit('changeActivation', { [key]: value });
    },
    emitReceiptChange(key, value) {
      this.$emit('changeReceipt', { [key]: value });
    },
    handleReceiptChanges() {
      const keys = ['receipt_item', 'receipt_store', 'receipt_item_cost', 'receipt_date'];
      const changes = keys.reduce((acc, key) => {
        if (this.receiptData[key] !== this.receipt[key] && !!this.receiptData[key] !== !!this.receipt[key]) {
          acc[key] = this.receiptData[key];
        }
        return acc;
      }, {});

      if (Object.keys(changes).length > 0) {
        this.$emit('changeReceipt', changes);
      }
    },
    handleOpenReceiptModal(mode) {
      this.activeShopList = this.activation?.receipt_shop_exclude_list?.length > 0 ? 'exclude' : 'include'
      this.receiptModalTab = mode === 'terms' ? 'terms' : 'shops';
      this.showReceiptModal = true;
    },
    parseMediaFormat() {
      if (!this.receipt?.media?.fileType) return null;

      const fileType = this.receipt.media.fileType;

      if (fileType.includes('pdf')) return 'PDF';
      if (fileType.includes('png')) return 'PNG';
      if (fileType.includes('jpeg')) return 'JPEG';

      return 'Unknown';
    },
    parseReceiptValue(str) {
      // Remove currency symbols and spaces
      let cleanedStr = str.replace(/[^\d,-.]/g, '');

      // Standardize decimal separator to period
      cleanedStr = cleanedStr.replace(',', '.');

      // Parse to number
      return parseFloat(cleanedStr);
    },
    setActivationData(activation) {
      this.activationData = {
        receipt_terms: [...activation?.receipt_terms || []],
        receipt_shop_list: [...activation?.receipt_shop_list || []],
        receipt_shop_exclude_list: [...activation?.receipt_shop_exclude_list || []]
      }
    },
    setReceiptData(receipt) {
      this.receiptData = {
        receipt_item: receipt?.receipt_item || '',
        receipt_store: receipt?.receipt_store || '',
        receipt_item_cost: receipt?.receipt_item_cost || '',
        receipt_date: receipt?.receipt_date || '',
        comment: '',
      }
    },
  },
  watch: {
    activation: {
      handler: function (activation) {
        this.setActivationData(activation);
      },
      immediate: true,
    },
    receipt: {
      handler: function (receipt) {
        this.setReceiptData(receipt);
      },
      immediate: true,
    },
    receiptData: {
      handler: 'handleReceiptChanges',
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.user-activation-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px 32px;

  border-radius: 8px;
  background-color: #FFFFFF;

  h2 {
    padding-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
    text-align: center;
  }

  .user-receipt {
    display: flex;
    align-self: stretch;
    gap: 64px;
    padding: 0 64px;
  }

  .user-receipt-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    .input-container {
      .input-row {
        position: relative;
        display: flex;

        svg {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
        }

        input {
          flex: 1;
          padding-right: calc(16px + 24px + 4px);
        }

        .valid-field {
          border-color: #11C054;
        }

        .invalid-field {
          border-color: #E6007E;
        }

        .md-field {
          padding: 0;
          margin: 0;
        }

        .md-field::v-deep .md-input {
          margin-left: 40px;
        }


        .md-field::v-deep .md-icon {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translateY(-50%);
          margin: 0;
        }

        .md-field::v-deep .md-icon:after {
          display: none;
        }

        .md-field.md-theme-default:before {
          display: none;
        }

        .md-field.md-theme-default:after {
          display: none;
        }
      }


      .info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 4px 0;

        span {
          color: #333;
        }

        button {
          padding: 0;
          border: 0;
          background-color: transparent;

          font-size: 14px;
          font-weight: 700;
          line-height: normal;
          color: $brand-yellow;
        }
      }
    }

    .user-receipt-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 24px;
      padding: 12px 16px;
      border-radius: 8px;
      background: #F2F2F2;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;

        font-size: 16px;
        font-weight: 700;
        line-height: 22.4px;
        color: #333;
      }
    }

    .user-receipt-info-value {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .user-receipt-info-placeholder {
      width: 24px;
      height: 24px;
    }
  }

  .user-receipt-preview-container {
    position: relative;
    width: 636px;
    max-height: 900px;
    overflow: hidden;
  }

  .user-receipt-preview {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 64px;
    overflow: hidden auto;
    border-radius: 8px;
    background-color: #CCCCCC;
  }

  .receipt-actions {
    position: absolute;
    width: 332px;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 16px;
    padding: 12px 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
  }

  .user-receipt-pdf {
    width: 100%;
    height: 100%;
  }
}

.receipt-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 40px;
  border-radius: $company-modal-border-radius $company-modal-border-radius 0 0;
  background-color: #F2F2F2;

  h2 {
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  button {
    outline: 0;
    border: 0;
    margin-right: -6px;
  }
}

.receipt-modal-content {
  display: flex;
  flex-direction: row;
  height: 449px;
  min-width: 994px;
  padding: 24px 40px 24px 0;

  .receipt-modal-tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
    gap: 8px;
    position: relative;

    overflow-y: auto;

    .button-yellow {
      font-size: 14px;
    }

    .receipt-modal-tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      min-height: 65px;
      width: 100%;
      padding: 12px 8px 12px 24px;

      border-radius: 0 8px 8px 0;
      border-top: 1px solid #CCC;
      border-right: 1px solid #CCC;
      border-bottom: 1px solid #CCC;
      background: #FFF;
      cursor: pointer;

      .label {
        font-size: 16px;
        font-weight: 700;
      }

      &.selected {
        border-top: 2px solid $brand-yellow;
        border-right: 2px solid $brand-yellow;
        border-bottom: 2px solid $brand-yellow;
        background-color: #FCEFE1;

        font-weight: 700;
      }
    }
  }

  .divider {
    display: flex;
    width: 1px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 24px 0 8px;

    background: var(--enginio-silver-grey, #CCC);
  }

  .receipt-terms-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;

    h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }

    .chip-input-container {
      flex: 1;
      align-self: stretch;
      overflow: hidden;
    }
    .chip-input-container::v-deep .chips-input-container-header {
      padding-bottom: 16px;
    }

    .chip-input-container::v-deep .chips-container {
      position: relative;
      flex: 1;
      height: auto;
      padding: 0;
    }

    .chip-input-container::v-deep .chip-search {
      width: auto;
      margin: 10px 10px 0 10px;
    }

    .chip-input-container::v-deep .chips {
      flex: 1;
      height: auto;
      padding: 10px;
    }

    .chip-input-container::v-deep .action {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .chip-input-container::v-deep .md-chips.md-field {
      column-gap: 8px;
      row-gap: 8px;
    }

    .chip-input-container::v-deep .md-chip {
      margin: 0;
    }


    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .label {
        font-size: 16px;
        font-weight: 700;
        color: $charcoal-black;
      }
    }

    .input-container-column {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      gap: 16px;
    }
  }

  .receipt-shops-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;

    h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }

    .shop-list-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;

      padding: 32px 16px;
      border: 1px dashed #E0E0E0;
      border-radius: 4px;

      .label {
        text-align: start;
        font-size: 16px;
        font-weight: 800;
      }

      .shop-list-selection {
        display: flex;
        flex-direction: row;
        gap: 16px;

        margin-top: 8px;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .label {
        font-size: 16px;
        font-weight: 700;
        color: $charcoal-black;
      }
    }

    .input-container-column {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      gap: 16px;
    }
  }
}

.receipt-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 87px;
  padding: 0 40px;
  border-radius: 0 0 $company-modal-border-radius $company-modal-border-radius;
  background-color: #F2F2F2;
}
</style>