/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import translationApi from '../../api/translation.api';

// initial state
const state = {
  translations: {},
  translationsStatus: '',
};

// getters
const getters = {
  translations: state => state.translations,
  translationsStatus: state => state.translationsStatus,
};

// actions
const actions = {
  getAdminTranslations({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return translationApi.getAdminTranslations(payload)
      .then((response) => {
        commit('TRANSLATIONS', response.translations);
        commit('LINK_STATUS', 'FINISHED');
      });
  },

  updateAdminTranslationsByKey({ commit }, payload) {
    commit('LINK_STATUS', 'LOADING');
    return translationApi.updateAdminTranslationsByKey(payload)
      .then((response) => {
        commit('TRANSLATIONS', response.translations);
        commit('LINK_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  TRANSLATIONS(state, translations) {
    state.translations = translations;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
