<template>
  <div class="list-explorer">
    <div
      class="table"
      :style="cssProps">
      <div
        v-if="company"  
        class="table-links" >
        <p>Channels in</p>
      </div>

      <div
        v-if="company"
        class="table-header highlight"
        :class="{
          'links-done': company.links_done,
        }">
        <div>
          <a :href="`${windowOrigin}/admin/links-manager/comp/${company._id}`">
            <iconComponent
              symbol="home" fill="black"
              @click="$emit('openCompany')"/>
          </a>
          <iconComponent symbol="forward"/>
          <div><p>{{ company.name }}</p></div> 
        </div>
      </div>

      <div
        v-for="goodiebag in goodiebags"
        :key="goodiebag._id"
        class="table-content">
        <div
          class="table-goodiebag"
          :class="{
            'highlight': selected == goodiebag._id,
            'links-done': goodiebag.links_done
          }">

          <div class="table-item-container">
            <a :href="`${windowOrigin}/admin/links-manager/coll/${goodiebag._id}`">
              <iconComponent
                symbol="folder" fill="black"
                @click="$emit('openFolder', goodiebag)"/>
            </a>
            <iconComponent
              symbol="forward"
              @click="getGoodiebags(goodiebag)"/>
            <div><p>{{ goodiebag.name }}</p></div> 
          </div>

          <div class="goodiebag-links">
            <p>{{ goodiebag.links_in && goodiebag.links_in.length ? goodiebag.links_in.length : '0 links' }}</p>
          </div>

        </div>
        <ListExplorerComponent
          v-if="selected == goodiebag._id
            && (childGoodiebags.length || childOffers.length)"
          :goodiebags="childGoodiebags"
          :offers="childOffers"
          :admin="admin"
          :tablePaddingIndex="tablePaddingIndex+1"
          @openItem="$emit('openItem', $event)"
          @openFolder="$emit('openFolder', $event)"/>
      </div>
      <div
        v-for="offer in offers"
        :key="offer._id"
        class="table-content">
        <div
          class="table-goodiebag"
          :class="{
            'links-done': offer.links_done
          }">

          <div class="table-item-container">
            <a :href="`${windowOrigin}/admin/links-manager/acti/${offer._id}`">
              <iconComponent
                symbol="file" fill="black"
                @click="$emit('openItem', offer)"/>
            </a>
            <div><p>{{ offer.first_header }}</p></div> 
          </div>

          <div class="goodiebag-links">
            <p>{{ offer.links_in && offer.links_in.length ? offer.links_in.length : '0 links' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodiebagApi from './../../../api/goodiebag.api';

export default {
  name: 'ListExplorerComponent',
  props: {
    company: {
      type: Object,
      required: false,
      default: () => {},
    },
    goodiebags: {
      type: [Array, Object],
      required: true,
    },
    offers: {
      type: Array,
      required: false,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
    tablePaddingIndex: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  data() {
    return {
      selected: '',
      childGoodiebags: [],
      childOffers: []
    };
  },
  computed: {
    cssProps() {
      return {
        '--table-padding-index': this.tablePaddingIndex,
      };
    }
  },
  methods: {
    getGoodiebags(goodiebag) {
      goodiebagApi[`get${ this.admin ? 'Admin' : 'Company'}Goodiebag`]({ entityId: goodiebag._id }).then((data) => {
        this.selected = goodiebag._id;
        this.setEntities(goodiebag.name, data);
      });
    },
    setEntities(name, data) {
      const { goodiebag } = data;
      this.childGoodiebags = goodiebag.childGoodiebags;
      this.childOffers = goodiebag.offers;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./ListExplorer.scss";
</style>