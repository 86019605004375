<template>
  <!-- <div> -->
    <div class="chip-input-container">
      <div class="chips-input-container-header">
        <p class="label">{{ label }}</p>
      </div>
      <div class="chips-container" :class="{ 'disabled' : disabled }">
        <div v-if="withSearchInput" class="chip-search">
          <iconComponent symbol="magnifyingGlass" class="chip-search-icon" :stroke="disabled ? '#e3e2e2' : '#CDCDCD'" :fill="disabled ? '#e3e2e2' : '#CDCDCD'"/>
          <input class="chip-search-input" :class="{'disabled' : disabled }" placeholder="find..." v-model="searchText" :disabled="disabled"/>
        </div>
        <div ref="mdChipsContainer" class="chips">
          <md-chips :value="filteredDataField" :md-placeholder="placeholder"
                  @md-insert="onInsert" @md-delete="onDelete" :md-static="disabled"></md-chips>
        </div>
        <div class="action">
          <button class="paste-from-clipboard-button" :class="{'disabled' : disabled }" @click="paste" :disabled="disabled">Paste</button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>
<script>
export default {
  name: 'ChipArea',
  components: {
  },
  props: {
    dataField: {
      type: Array,
      required: true,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    withSearchInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  mounted() {
    this.scrollToBottom();
    this.setChecked();
  },
  updated() {
    this.setChecked();
  },
  data() {
    return {
      updatedDataField: this.dataField,
      searchText: '',
      checked: false,
    };
  },
  methods: {
    onInsert(addedChip) {
      this.updatedDataField.push(addedChip);
      this.$emit('change', this.updatedDataField);
    },
    onDelete(removedChip) {
      this.updatedDataField = this.updatedDataField.filter(chip => chip !== removedChip);
      this.$emit('change', this.updatedDataField);
    },
    scrollToBottom() {
      const chipsContainer = this.$refs.mdChipsContainer;
      if (chipsContainer) {
        chipsContainer.scrollTop = chipsContainer.scrollHeight;
      }
    },
    sortByName(nameA, nameB) {
      if (nameA < nameB)
        return -1;
      if (nameA > nameB)
        return 1;
      return 0;
    },
    async paste() {
      const pastedText = await navigator.clipboard.readText();
      if (pastedText.length === 0) {
        this.setAlert('error', 'Clipboard is empty', 2);
        return;
      }

      let newChips = pastedText.split('\n').map(chip => chip.trim());
      newChips = this.removeDuplicates(this.updatedDataField, newChips);
      this.updatedDataField.push(...newChips);
      this.$emit('change', this.updatedDataField);
    },
    removeDuplicates(currentChips, newChips) {
      return newChips.filter(chip => !currentChips.includes(chip));
    },
    setSelection() {
      this.$emit('selected', this.selected);
    },
    setChecked() {
      if (this.disabled)
        this.checked = false;
      else
        this.checked = true;
    }
  },
  computed: {
    filteredDataField() {
      const filteredData = this.updatedDataField.filter(term => term.includes(this.searchText));
      return filteredData.sort(this.sortByName);
    },
  }
}
</script>
<style lang="scss">

@import '../../../assets/scss/abstracts/colors.scss';
@import '../../../assets/scss/abstracts/typeography.scss';

$font-default: 'CircularXXWeb-Regular';

.chip-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .chips-input-container-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    gap: 8px;
    padding-bottom: 8px;

    .label {
      color: $shadow-grey;

      font-family: $font-default;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-align: center;
    }
  }

  .chips-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 220px;
    overflow: hidden;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid $charcoal-black;
    width: 100%;

    .chip-search {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 25px;

      background: $brand-white;
      border-radius: 8px;

      margin-bottom: 10px;

      .chip-search-icon {
        margin-left:10px;
      }

      .chip-search-input {
        min-height: 35px;
        width: 100%;
        padding: 2px 0 2px 4px;
        background: transparent;
        border: 0;

        outline: none;

        &.disabled::placeholder {
          color: $gray-200;
          opacity: 1;
        }
      }
    }

    .chips {
      height: 120px;
      overflow-y: auto;
    }

    .action {
      display:flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 4px;

      .paste-from-clipboard-button {
        height: 25px;
        border-radius: 4px;
        border: none;
        color: #FFFFFF;
        background: $brand-yellow;

        &.disabled {
          color: #e3e2e2;
          background: $gray-200;
        }
      }
    }
  }

  .md-chips {
    margin-bottom: 0;
    color: pink;

    &.md-field {
      margin: 0;
      padding-top: 0;

      .md-chip {
        color: $gray-200;
        background: $brand-white;
        border: 1px solid $gray-100;
        border-radius: 4px;
        height: 25px;
        margin-bottom: 8px;
        margin-right: 4px;

        .md-ripple {
          text-align: center;
          line-height: 25px;
        }

        &.md-theme-default {
          line-height: 25px;
        }

        &.md-deletable {
          color: $charcoal-black;
          background: $brand-white;
          border: 1px solid $gray-200;
        }

        .md-button.md-input-action {
          background: $brand-white;

          .md-icon svg {
            fill: $charcoal-black;
          }
        }
      }
    }

  }

  .md-field {

    &:after {
      height: 0;
      content: "";
    }

    &.md-theme-default:before {
      background-color: transparent;
    }
  }
}
</style>
