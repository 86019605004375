<template>
  <div>
    <modal class="modal-view" slotTransName="slot" slotTransMode="out-in" :slotTransAppear="true" modalType="fullscreen">

      <div v-if="data.signupView" class="login-content --signup-container">

        <div class="--login-back">
          <div
            class="back-button--container">
            <iconComponent symbol="arrowLeft" :width="30" />
          </div>
          <div class="--signup-header-text">
            <h3>{{ transl.Login.signup1 }}</h3>
          </div>
          <div>
            <p class="--signup-step">1/3</p>
          </div>
        </div>

        <div class="--signup-content">
          <div class="--inputs-and-error">
            <div class="login-signup-body" v-html="company?.signup_subheader || transl.Login.funtojoin"></div>
            <div class="--inputs-container">
              <InputField id="given-name" :label="`${transl.Login.firstname} *`" />
              <InputField id="family-name" :label="`${transl.Login.lastname} *`"/>
              <InputField id="email" :label="`${transl.Login.emailadress} *`"/>
              <vuePhoneNumberInput id="tel"
                                   color="#EA7801"
                                   :default-country-code="countryCode"
                                   :no-validator-state="true"
                                   :border-radius="10"
                                   :translations="phoneInputTransl"
                                   @update="formattedNr = $event.formattedNumber"
                                   :preferred-countries="prefCountries" />
            </div>
          </div>

          <div class="--accept-terms">
            <div class="--confirm-terms">
              <inputField inputType="checkbox"/>

              <div class="--text">
                <div class="clickable">
                  <span>* </span>
                  <span v-if="company && company._id">
                      {{ transl.Login.readconfirm2.interpolate({ companyname: company.name }) }}
                    </span>
                  <span v-else>
                      {{ transl.Login.readconfirm }}
                    </span>
                </div>
                <div class="clickable-link">{{ transl.Login.userconditions }}</div>
              </div>
            </div>

            <div v-if="company && company._id" class="--newsletter">
              <inputField inputType="checkbox"/>

              <div class="--text">
                <div class="--expanded-text">
                  <div
                    v-if="company.mandatory_company_subscribe_description
                        && company.mandatory_company_subscribe_description.includes('.')
                        && company.mandatory_company_subscribe_description.includes('<p>')"
                    class="--regular-expanded-text">
                    <div class="clickable">
                      <p>
                        <span v-html="`${company.mandatory_company_subscribe_description.split('<p>')[0]}${company.mandatory_company_subscribe ? '* ' : ''}${company.mandatory_company_subscribe_description.split('<p>').slice(1).join('<p>').split('.')[0]}.`"></span>
                      </p>
                    </div>
                    <div v-if="company.mandatory_company_subscribe_description.split('.').length >= 2">
                      <div
                        v-show="collapsedNews[0]"
                        v-html="company.mandatory_company_subscribe_description.split('.').slice(1).join('.')"></div>

                      <div v-show="collapsedNews[0]" class="-show-more" @click="toggleShowMore(collapsedNews)">
                        {{ transl.UserSettings.showless }}
                        <iconComponent class="up" width="10" fill="#999999" stroke="#999999" symbol="forward" />
                      </div>

                      <div v-show="!collapsedNews[0]" class="-show-less" @click="toggleShowMore(collapsedNews)">
                        {{ transl.UserSettings.showmore }}
                        <iconComponent class="down" width="10" fill="#999999" stroke="#999999" symbol="forward" />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="clickable">
                      <p>
                          <span class="--regular-expanded-text">
                            <span v-if="company.mandatory_company_subscribe">*</span>
                            {{ transl.Login.sendemails2.interpolate({ companyname: company.name }) }}
                          </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mandatory-description">
              {{ transl.App.mandatory }}
            </div>

          </div>

          <div class="--buttons-container">
            <div class="btn btn-activate --continue-btn"
                 :style="`background: '' }`">
              {{ transl.Login.continue }}
            </div>
          </div>

          <integrityBox badgeType="Integrity_badge_Medium"
                        :textData="[transl.Login.whenmember, transl.Login.integritypolicyinfo]"/>
        </div>

        <!--div v-else-if="codeStep" class="--verify-email-phone">
          <div class="login-signup-body">{{ transl.Login.verifyboth }}</div>
          <div class="login-verification-step">
            <div class="back-button--container"
                 :style="`background-color: ${ validationCodePhone && validationCodePhone.length ? '#4ECB71' : ''}`">
              <iconComponent
                v-if="validationCodePhone && validationCodePhone.length"
                symbol="checkmark"
                stroke="white" fill="white"/>
              <iconComponent
                v-else
                symbol="smartphone"
                stroke="black" fill="black"
                width="30px" height="30px"/>
            </div>
            <div>{{ transl.Login.usethencode }}</div>
            <aPinInput
              key="phone-code"
              :auto-focus="true"
              @incomplete="setValidationCode()"
              @complete="setValidationCode($event)"/>

            <div class="login-issue-text">
              {{ loginCodeIssue }}
            </div>
          </div>

          <div class="login-signup-body">{{ transl.Login.nocode }}
            <span
              class="clickable-link"
              @click="sendAnotherPhoneCode()">{{ transl.Login.sendnew }}</span>
          </div>

          <div class="login-issue-text">
            {{ loginIssue }}
          </div>

          <div
            :class="{ 'btn-disabled': incompleteValidationCode }"
            class="btn btn-activate"
            @click="signupVerifyPhoneNr()">
            {{ transl.Login.goon }}
          </div>
        </div-->

        <!--div v-else-if="emailStep" class="--verify-email-phone">
          <div class="login-signup-body">{{ transl.Login.verifyboth }}</div>
          <div class="login-verification-step">
            <div class="back-button--container"
                 :style="`background-color: ${ validationCodeEmail && validationCodeEmail.length ? '#4ECB71' : ''}`">
              <iconComponent
                v-if="validationCodeEmail && validationCodeEmail.length"
                symbol="checkmark"
                stroke="white" fill="white"/>
              <iconComponent
                v-else
                symbol="email"
                stroke="black" fill="black"/>
            </div>
            <div>{{ transl.Login.usethenemailcode }}</div>
            <aPinInput
              key="email-code"
              :auto-focus="true"
              :inputs="4"
              @incomplete="setValidationCode()"
              @complete="setValidationCode($event)"/>

            <div class="login-issue-text">
              {{ loginEmailIssue }}
            </div>
          </div>

          <div class="login-signup-body">{{ transl.Login.nocode }}
            <span
              class="clickable-link"
              @click="sendAnotherEmailCode()">{{ transl.Login.sendnew }}</span>
          </div>

          <div class="login-issue-text">
            {{ loginIssue }}
          </div>

          <div
            :class="{ 'btn-disabled': incompleteValidationCode }"
            class="btn btn-activate"
            @click="signupVerifyPhoneNr()">
            {{ transl.Login.goon }}
          </div>
        </div-->
      </div>

      <!-- div v-else-if="data.loginView" class="login-content --login-container">
        <div class="--login-back">
          <div
            class="back-button--container">
            <iconComponent symbol="arrowLeft" :width="30" />
          </div>
          <h3>{{ transl.Login.login }}!</h3>
          <Hero
            class="login-absolute-stamp"
            type="Integrity_badge_Light" />
        </div>

        <div class="--content">
          <div class="body-1">
            {{ transl.Login.addphonenr }}
          </div>

          <div class="--input-container">
            <vuePhoneNumberInput id="tel"
                                 color="#EA7801"
                                 :default-country-code="countryCode"
                                 :no-validator-state="true"
                                 :border-radius="10"
                                 :translations="phoneInputTransl"
                                 :preferred-countries="prefCountries" />
          </div>

          <div class="--buttons-container">
            <div
              class="login-signup-body --tech-issues">
              {{ transl.Login.techissues }}
              <div class="clickable-link">{{ transl.Login.contactsupport }}</div>
            </div>

            <div class="--continue-btn">
              <div class="login-description">
                {{ transl.Login.usecode }}
              </div>
              <div class="btn btn-activate">
                {{ transl.Login.continue }}
              </div>
            </div>

            <div class="--or-btns">
              <div class="login-issue-or-text">
                {{ transl.Login.or }}
              </div>
              <div>
                <div class="btn btn-transparent">
                  {{ transl.Login.changednrq }}
                </div>
                <div
                  class="btn btn-transparent"
                  @click="setLoginView('signupView')">
                  {{ transl.Login.signup2 }}
                </div>
              </div>
            </div>
          </div>

          <integrityBox badgeType="Integrity_badge_Medium"
                        :textData="[transl.Login.yourintegrity, transl.Login.nrgpolicy, transl.Login.contactpolicy]"/>
        </div>

      </div-->

      <div v-else-if="data.startView" class="login-content --login-container">
        <div class="login-header --logo-container">
          <img
            v-if="company.fe_icon_1x1 || company.fe_icon"
            class="login-header-logo"
            :src="company.fe_icon_1x1 || company.fe_icon"
            :alt="company.name" />
          <h3 class="modal-view-header-text">
            {{ transl.Login.loginfirst }}
          </h3>
          <div
            class="modal-view-subheader-text"
            v-html="company?.login_landing_subheader || transl.Login.companywhy2.interpolate({ companyname: company.name })">
          </div>
        </div>
        <div
          class="btn btn-activate">
          {{ transl.Login.signup1 }}
        </div>
        <div
          v-if="company && company.login_design === 'TEXT_BUTTONS'"
          class="btn btn-icon-text"
          style="font-size: 15px; margin: 10px 0 0 0">
          {{ transl.Login.logintext }}
        </div>
        <div
          v-else
          class="btn btn-transparent">
          {{ transl.Login.login }}
        </div>
        <div
          class="btn btn-icon-text"
          style="font-size: 15px; margin: 10px 0 0 0">
          {{ transl.Login.changednr }}
        </div>
        <hr>
        <div
          class="btn btn-gray-no-border">
          {{ transl.Login.what }}
        </div>

        <integrityBox badgeType="Integrity_badge_Medium"
                      :textData="transl.HomeInfo.safeintegrity + ' ' + transl.HomeInfo.readcare"
                      :linksList="[transl.HomeInfo.integrity, 'FAQ']"/>
      </div>

    </modal>
  </div>
</template>
<script>

import InputField from "@/stories/components/InputField/InputField.vue";
import integrityBox from "@/stories/components/IntegrityBox/IntegrityBox.vue";

export default {
  name: 'SignupView',
  components: {InputField, integrityBox},
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    company() {
      return this.data.company;
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
}

</script>

<style lang="scss">

.preview-container {
  //padding: 0 16px 0 16px;
}
.modal-view {
  overflow: hidden;
  border-radius: 8px !important;
  position: absolute;
  margin-top: 100px;

  .close-login-modal-button {
    position: sticky;
    justify-content: flex-end;
    top: 0;
    z-index: 1;
    background: white;
    width: 100%;
    padding: 10px 0px 5px;
    display: flex;
    cursor: pointer;
  }

  .login-absolute-stamp {
    width: 50px;
  }

  .modal-view-steps {
    padding: 1rem 2rem;

    .step {
      display: grid;
      grid-template-columns: 1fr 6fr;
      grid-gap: 1rem;
      margin: 1.5rem 0;

      .step-number {
        position: relative;
        display: inline-block;
        border-radius: 50%;
        background: $brand-yellow;
        color: white;
        height: 30px;
        width: 30px;

        @media (max-width: 812px) {
          height: 25px;
          width: 25px;
        }

        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .modal-why-content {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 10fr;
    padding: 1rem 0.5rem;

    .modal-why-header {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .modal-view-join {
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }

  .modal-view-why {
    padding: 1rem 0;
    text-align: center;
    background: #e0e0e0;
    font-weight: 600;
    cursor: pointer;
  }

  .modal-view-why2 {
    color: white;
    background: $brand-yellow;
  }

  .login-content {
    touch-action: pan-y;

    .input-field--container {
      margin-bottom: 16px;
    }

    .vuephoneinput-container {
      margin-bottom: 16px;

      .flex {
        height: 57px;
      }

      .small-text-bold {
        margin: 10px 0;
      }
    }

    .login-header {
      display: flex;
      flex-direction: column;

      .login-header-logo {
        width: 100px;
        border-radius: 50%;
        margin: auto;
        background-color: black;
        z-index: 10000000;
        position: absolute;
        top: -50px !important;
        place-self: center;
      }

      .modal-view-subheader-text {
        margin: 10px 0;
      }
    }

    .--login-back {
      display: grid;
      grid-template-columns: 0.4fr 1.6fr 0.4fr;
      grid-gap: 8px;
      align-items: center;
      justify-content: flex-start;

      h3 {
        white-space: normal;
      }

      .back-button--container {
        align-self: center;
        width: 40px;
        height: 40px;
        margin: 17px 17px 17px 0;
        background: $brand-white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .--signup-header-text {
        text-align: left;
        word-break: break-word;
      }

      .--signup-step {
        color: gray;
        margin-left: 5px;
      }

      .login-absolute-stamp {
        width: 50px;
        margin-left: auto;
      }
    }

    .login-signup-step {
      color: $brand-grey;
    }

    .login-signup-body {
      text-align: center;
    }

    .login-verification-step {
      position: relative;
      border-radius: 8px;
      background-color: $brand-white;
      margin: 25px 0;
      padding: 40px 1rem 0 1rem;
      text-align: center;
      height: 150px;

      .back-button--container {
        width: 40px;
        height: 40px;
        margin: auto;
        margin-bottom: 5px;
        background: #f8f8f8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CCCCCC;
        padding: 0.2rem;
        position: absolute;
        left: 39%;
        top: 0;
        transform: translate(+50%, -50%);
        background-color: white;
        border-radius: 50%;
        border: 1px solid #ededed;
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
        -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
        cursor: pointer;
      }

      .pininput-wrapper {
        margin-top: 10px;
      }


      .validation-input {
        margin-top: 1rem;
        width: unset !important;
      }
    }

    .login-issue-text {
      color: red;
    }

    .login-issue-or-text {
      font-size: 1rem;
      padding: 1rem;
      font-weight: 600;
      text-align: center;
    }

    .login-description {
      text-align: center;
      color: $brand-grey;
    }

    .btn-icon-text {
      color: var(--brand-button-color) !important;
    }

    // buttons and input fields
    .input-field--container.--container-checkbox {
      input {
        border-color: var(--brand-button-color);
      }
    }
    .input-field--container.--container-checkbox.--container-focused-filled {
      input {
        background: var(--brand-button-color);
      }
    }
    .input-field--container.--container-filled.--container-focused-filled {
      input.--focused {
        border-color: var(--brand-button-color) !important;
      }
    }
  }

  .company-header-logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .--accept-terms {
    // TODO: change
    margin-top: 20px;
    margin-bottom: 10px;
    // font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;

    .md-checkbox {
      margin: 4px 16px 16px 0;
    }

    .--company-subscribe-description {
      text-align: left;
      // margin-top: 10px;
      margin-bottom: 30px;
    }

    .--confirm-terms,
    .--newsletter,
    .--company-subscribe {
      display: grid;
      grid-template-columns: 20px 1fr;
      grid-gap: 10px;
      align-items: flex-start;
      text-align: left;
      max-width: 95%;
      @media (min-width: 812px) {
        max-width: 85%;
      }

      input {
        margin-top: 4px;
      }
      .--text {
        margin-bottom: 20px;
        margin-left: 10px;

        .--bold-text {
          font-family: var(--medium-font-family);
          font-weight: bold;
        }

        .clickable-link {
          font-family: var(--bold-font-family);
          font-weight: 400;
        }

        .--expanded-text {
          div {
            margin-bottom: 10px;
          }

          .--bold-expanded-text {
            font-family: var(--medium-font-family);
            font-weight: bold;
            color: #333333;
          }

          .--grey-expanded-text {
            font-family: var(--regular-font-family);
            font-weight: 400;
            color: #999999;
          }

          .--regular-expanded-text {
            font-family: var(--regular-font-family);
            font-weight: 400;
          }

          span {
            font-family: var(--regular-font-family);
            font-weight: 400;
          }
        }

        .-show-less,
        .-show-more {
          cursor: pointer;
          font-family: var(--medium-font-family);
          color: #333333;
        }
      }
    }

    .mandatory-description {
      font-style: italic;
      color: #616161
    }
  }

  .--signup-container {
    .--signup-content {
      text-align: left;
      .--inputs-and-error {
        .login-signup-body {
          margin-bottom: 10px;
          text-align: left;
        }
        .--inputs-container {
          padding: 25px 12px;
          background: $brand-white;
          border-radius: 8px;

          .login-issue-text {
            margin-top: 30px;
          }
        }
      }
    }

    .--terms {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 12;
      background: white;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 60px;
      position: absolute;
      padding: 24px 16px;
      overflow: auto;
      border-radius: 10px;

      .-header-container {
        /*background: white;
        position: fixed;
        height: 60px;
        width: 90%;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cross {
          place-self: flex-start;
        }

        h3 {
          padding: 24px 0;
        }
      }

      /*.body-terms{
        padding: 17px;
        background: $brand-white;
        border-radius: 8px;
        margin: 50px 0 80px 0;
      }*/

      .body-1 {
        padding: 17px;
        background: $brand-white;
        border-radius: 8px;
        margin-bottom: 80px;
      }

      .btn {
        width: 90%;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }

      &::before {
        content: '';
        position: fixed;
        bottom: 0;
        transform: translateY(1px);
        left: 0;
        background: white;
        width: 100%;
        height: 55px;
        padding: 20px;
        border-radius: 0 0 10px 10px;
      }
    }

    .--buttons-container {
      .--tech-issues {
        padding: 17px 0;
      }

      .--continue-btn {
      }

      .--or-btns {
      }
    }
  }

  .--login-container {
    // margin-top: 60px;

    .--content {
      .body-1 {
        padding: 16px 0;
        text-align: center;
      }

      .--input-container {
        background: $brand-white;
        border-radius: 8px;
        padding: 24px 24px 34px;

        .input-field--container {
          margin-bottom: 16px !important;
        }

        .login-issue-text {
          margin-top: 30px;
        }
      }

      .--buttons-container {
        .--tech-issues {
          margin-top: 14px;
        }

        .--continue-btn {
          margin-top: 32px;
        }

        .--or-btns {
        }
      }
    }

    .--verify-and-login {
      text-align: center;

      .confetti {
        margin: 25px 0;
      }
    }

    @media (min-width: 1020px) {
      width: auto;
    }
  }

  .--new-number-container {
    text-align: left;
    .--login-back {
      h3 {
        width: auto;
      }
    }
    .--content {
      .small-text {
        margin: 16px 0 10px;
        color: #111111;
        font-weight: 700;
      }

      .login-issue-text {
        margin-top: 30px;
      }

      .--choose-verify {
        .--input-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: space-between;
          margin-bottom: 25px;

          .input-field--container {
            margin-bottom: 16px !important;
          }
        }
      }

      .--verify-number,
      .--verify-email {
        background: $brand-white;
        border-radius: 8px;

        .--old-num,
        .--fill-email {
          display: flex;
          flex-direction: column;
          padding: 10px 16px;
        }

        .--new-num {
          padding: 0 16px 25px;
        }
      }

      .--fill-email {
        .login-issue-text {
          margin-top: 0;
        }
      }

      .--buttons-container {
        .--tech-issues {
          margin-top: 14px;
        }

        .--continue-btn {
          margin-top: 32px;
        }

      }
    }
  }

  .--login-menu-container {
    .--logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        border: 0.5px solid #cccccc;
        background: white;
        border-radius: 50%;
        max-width: 100px;
        position: absolute;
        top: -50px;
        z-index: 1;
      }
    }
  }

  @media (min-width: 1020px) {
    overflow: unset;
  }
}
</style>
