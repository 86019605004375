<template>
  <div class="theme-container" :class="{ 'theme--selected': this.selected }" @click="handleSelect">
    <div class="header">
      <h3
        class="name"
        :class="{ 'name--selected': this.selected }"
      >
        <iconComponent class="checkmark" symbol="checkmark" v-if="!this.selected" />
        <!-- ToDo: Move to icon-component or  -->
        <svg class="checkmark--selected" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="this.selected">
          <path id="Vector" d="M12 3.59961C7.03748 3.59961 3 7.63709 3 12.5996C3 17.5625 7.03748 21.5996 12 21.5996C16.9629 21.5996 21 17.5625 21 12.5996C21 7.63709 16.9629 3.59961 12 3.59961ZM16.2367 9.29778L11.7323 15.9355L8.21178 12.6685C8.0533 12.5217 8.04391 12.274 8.19104 12.1156C8.33778 11.9567 8.58587 11.9481 8.74396 12.0948L11.5966 14.7416L15.589 8.85796C15.7107 8.67913 15.9541 8.63296 16.1326 8.75387C16.3118 8.87517 16.3583 9.11857 16.2367 9.29778Z" fill="#EA7801"/>
        </svg>
        {{ this.name }}
      </h3>
      <iconComponent class="fullscreen" symbol="fullscreen" @click="showFullscreen = true" />
    </div>
    <div class="preview-container" @click="handleSelect">
      <img :src="this.thumbnailImage" />
    </div>
    <PartnerModal :show="this.showFullscreen" @close="showFullscreen = false">
      <div class="image-fullscreen-container">
        <img :src="this.largeImage" />
      </div>
    </PartnerModal>
  </div>
</template>

<script>
import PartnerModal from '../PartnerModal/PartnerModal.vue';

export default {
  name: 'CampaignTheme',
  components: { PartnerModal },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    thumbnailImage: {
      type: String,
      default: '',
    },
    largeImage: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleSelect() {
      this.$emit('select', this.name);
    },
  },
  data() {
    return {
      showFullscreen: false,
    };
  },
}
</script>

<style lang="scss" scoped>
.theme-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 441px;
  padding: 24px;

  border-radius: 4px;
  border: 1px solid #CCC;
  background-color: #FFF;

  cursor: pointer;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 27px;
    margin: 0 0 16px 0;

    .name {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: normal;
      color: #111;

      .checkmark {
        margin: 3px;
        width: 18px;
        height: 18px;
      }

      .checkmark--selected {
        width: 24px;
        height: 24px;
      }
    }

    .name--selected {
      font-weight: 700;
    }

    .fullscreen {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .preview-container {
    width: 100%;
    height: 258px;
    border-radius: 4px;
    padding: 48px 24px;
    background-color: #F2F2F2;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.theme--selected {
  border: 2px solid $brand-yellow;
  padding: 23px;
}

.image-fullscreen-container {
  position: relative;
  width: 1488px;
  height: 859px;
  padding: 92px 55px;
  background-color: #FFF;
  max-width: 100%;
  max-height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 33px;
    right: 43px;
  }
}
</style>
