var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'admin-home-page': !_vm.authenticated }},[(!_vm.authenticated)?_c('div',{staticClass:"admin-welcome-title"},[_c('div',{staticClass:"a-title"},[_vm._v(" Welcome to the Admin of Enginio ")]),_c('div',{staticClass:"btn btn-activate",staticStyle:{"max-width":"150px"},on:{"click":_vm.setLoginView}},[_vm._v(" Login ")])]):(_vm.loadedPage)?_c('div',{staticClass:"admin-welcome-user"},[_c('div',{staticClass:"welcome-user-header"},[_vm._v("Welcome "+_vm._s(_vm.user.name)+"!")]),_c('div',{staticClass:"welcome-sub-header"},[_vm._v("What do you want to do today?")]),_c('div',{staticClass:"welcome-bookmarked-header"},[_vm._v("Bookmarked collections")]),_c('div',{staticClass:"admin-goodiebags-wrapper"},_vm._l((_vm.bookmarkedGoodiebags),function(bookmarkedGoodiebag){return _c('div',{key:bookmarkedGoodiebag._id,staticClass:"goodiebag-box clickable",style:(`background-image: url(${bookmarkedGoodiebag.media ? bookmarkedGoodiebag.media.url : ''})`),on:{"click":function($event){return _vm.$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: bookmarkedGoodiebag._id } })}}},[_c('div',{staticClass:"goodiebag-live-status",class:{
            'goodiebag-live': bookmarkedGoodiebag.public,
            'goodiebag-offline': !bookmarkedGoodiebag.public,
          }}),_c('div',{staticClass:"goodiebag-box-text"},[_c('div',{staticClass:"goodiebag-box-name"},[_vm._v(_vm._s(bookmarkedGoodiebag.name))]),(bookmarkedGoodiebag.childGoodiebags && bookmarkedGoodiebag.childGoodiebags.length)?_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(bookmarkedGoodiebag.childGoodiebags.length)+" "+_vm._s(bookmarkedGoodiebag.childGoodiebags.length === 1 ? 'collection' : 'collections')+" ")]):_vm._e(),(bookmarkedGoodiebag.offers && bookmarkedGoodiebag.offers.length)?_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(bookmarkedGoodiebag.offers.length)+" "+_vm._s(bookmarkedGoodiebag.offers.length === 1 ? 'activation' : 'activations')+" ")]):_vm._e(),_c('div',{staticClass:"goodiebag-box-edit preview-action",on:{"click":function($event){return _vm.$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: bookmarkedGoodiebag._id } })}}})])])}),0),_c('div',{staticClass:"welcome-bookmarked-header"},[_vm._v("Bookmarked activations")]),_c('div',{staticClass:"admin-goodiebags-wrapper"},_vm._l((_vm.bookmarkedOffers),function(bookmarkedOffer){return _c('div',{key:bookmarkedOffer._id,staticClass:"goodiebag-box clickable",style:(`background-image: url(${(bookmarkedOffer.thumbnail && bookmarkedOffer.thumbnail.url ? bookmarkedOffer.thumbnail.url : undefined)
          || (bookmarkedOffer.media && bookmarkedOffer.media.length ? bookmarkedOffer.media[0].url : '')})`),on:{"click":function($event){return _vm.$router.push({ name: 'AdminOffer', params: { offerId: bookmarkedOffer._id } })}}},[_c('div',{staticClass:"goodiebag-live-status",class:{
            'goodiebag-live': bookmarkedOffer.public,
            'goodiebag-offline': !bookmarkedOffer.public,
          }}),_c('div',{staticClass:"goodiebag-box-text"},[_c('div',{staticClass:"goodiebag-box-name"},[_vm._v(_vm._s(bookmarkedOffer.first_header))]),_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(bookmarkedOffer.quick_text)+" ")])])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }