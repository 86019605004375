<template>
  <div
    class="admin-entity-content--container">
    <!--<div
      class="gradient-header"
      :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      <h3>Vanliga frågor</h3>
    </div>-->

    <div class="faq-wrapper">
      <!--<div
        v-for="faq in faqs"
        :key="faq.text"
        class="faq-box">
        <div class="faq-letter">Q:</div>
        <h4 class="faq-text">{{ faq.header }}</h4>
        <div class="faq-letter">A:</div>
        <p class="faq-text" v-html="faq.text"></p>
      </div>

      <h4 style="padding-top: 1.5rem;">Har vi inte besvarat din fråga?</h4>
      <div style="padding-bottom: 1rem;">Hör av dig till oss så svarar vi så fort vi kan!</div>-->
      <a
        class="btn btn-activate"
        style="max-width: 200px"
        :href="`mailto:${'support@enginio.io'}`">
        CONTACT US
      </a>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'CompanySupport',
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
  },
  data() {
    return {
      faqs: [
        {
          header: 'Varför ska jag använda idfyed?',
          text: '<p>Vi använder Idfyed för att det är ett enkelt och säkert sätt för konsumenter att logga in, nyttja aktiveringar och samtidigt ha full kontroll över sin data. Idfyed använder dessutom krypterad teknik så du alltid kan känna dig säker. Läs mer om Idfyed <a href="https://getidfyed.com/">här.</a></p>',
        },
        {
          header: 'Kan jag skapa ett konto utan Idfyed?',
          text: 'Det enda sättet att skapa konto på Enginio är via Idfyed. Tack vare att vi använder Idfyed så slipper du allt krångel kring att skapa konton och komma ihåg lösenord. Eftersom alla aktiveringar är unika och personliga så säkerhetsställer Idfyed att bara du kan nyttja dem. På så sätt samlas allt på samma ställe och allt du behöver är din mobil.',
        },
      ],
    };
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/CompanySupport";
</style>
