<template>
  <div
    class="game-body"
    v-if="loadedPage"
    :style="cssProps">
    <div class="game-desktop-background"></div>
    <div v-if="step != 'finished'" class="game-content-background"></div>
    <span
      v-if="isDevelopment"
      class="dev-navigation">
      DEV
      <router-link class="btn" :to="{ name: 'SpinOnboarding' }">onboarding</router-link>
      <button @click="willWin = !willWin">{{ willWin ? 'Will win' : 'Normal chance' }}</button>
    </span>

    <div
      v-if="step == 'finished'"
      class="game-page-absolute">
      <div v-html="game.winner_text"></div>
      <div class="btn btn-activate"
        @click="ctaDoneRedirect()">
        {{ game.cta_done_button || transl.Games.back }}
      </div>
    </div>
    <div
      v-else
      class="game-page">
      <div
        v-if="step == 'tiebreaker'"
        class="tiebreaker-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;" >
            <iconComponent
              class="game-info-icon"
              symbol="info"
              width="18px"/>
          </div>
          <img class="tiebreaker-image" v-if="game.tiebreak_image" :src="game.tiebreak_image.url" alt="">
        </div>

        <div class="tiebreaker-question">
          <div class="tiebreaker-text" v-html="game.tiebreak_text"></div>
          <Form
            :questions="[game.tiebreak_question]"
            :accentColor="game.accent_color"
            :textColor="game.text_color"
            :submitButton="game.tiebreak_button_text"
            @answeredAll="tiebreakAnswer = $event.none"
            @missingRequired="tiebreakAnswer = ''"
            @submit="participate()"/>

          <div class="winner-info" v-html="game.winner_text"></div>
        </div>
      </div>

      <div
        v-else-if="step == 'spin'"
        class="spin-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;" >
            <iconComponent
              class="game-info-icon"
              symbol="info"
              width="18px"/>
          </div>
          <img class="tiebreaker-image" v-if="game.hunt_image" :src="game.hunt_image.url" alt="">
        </div>

        <div class="spin-content">
          <div class="hunt-header">
            <h2>{{ game.hunt_header }}</h2>
          </div>
  
          <!--WHEEL-->
          <div
            v-if="game.wheel_image"  
            class="spinner-background">
            <div class="spinner-wrapper">
              <md-icon class="md-size-3x">keyboard_arrow_down</md-icon>
              <div
                class="spinner"
                ref="spinner"
                unselectable="on"
                onselectstart="return false;"
                onmousedown="return false;"
                :style="{
                  transform: `rotate(${-currentAngle}deg)`,
                  transition: `transform ${this.spinTime}s ease`,
                }">
                <img :src="game.wheel_image.url" alt="">
              </div>
            </div>
          </div>
  
          <button
            class="btn btn-activate btn-fit"
            :disabled="!spinAgain"
            @click="spinTheWheel()">Random spin</button>
  
          <div
            class="bottom-content text-align-center"
            style="margin: 0 16px;">
            <h3>{{ game.hunt_funfacts_header }}</h3>
            <div v-html="game.hunt_funfacts[randomIndex]"></div>
          </div>
        </div>
      </div>

      <div
        v-else-if="step == 'survey'"
        class="survey-wrapper">

      <!-- Trigger snackbar when finished ? and send email to participation -->
        <img v-if="game.tiebreak_image" :src="game.tiebreak_image.url" alt="">
        <div class="survey-container">
          <div class="points-grid" v-if="participation && participation.points">
            <span class="scan-text">{{ game.hunt_header }}</span>
          </div>

          <div class="survey-header">{{ game.survey_header }}</div>
          <Form
            class="survey-all-questions"
            :questions="game.survey"
            :accentColor="game.accent_color"
            :textColor="game.text_color"
            :submitButton="transl.Games.submit"
            @answeredAll="surveyAnswers = $event"
            @missingRequired="surveyAnswers = {}"
            @submit="setSurvey()"/>
        </div>
      </div>
    </div>

    <modal v-if="tocModal" @close="tocModal = false" size="medium" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-html="game.terms_text" class="html-text"></div>
        </div>
        <div class="terms-buttons">
          <div
            v-if="authenticated && game.terms_merchandise && !participation.accepted_merchandise && !hasAcceptedGame"
            class="terms-merchandise-grid">
            <inputField
              v-model="acceptMerchandise"
              inputType="checkbox"/>
            <span
              class="terms-merchandise-text"
              v-html="game.terms_merchandise"></span>
          </div>

          <div v-if="authenticated && !hasAcceptedGame" class="btn btn-activate" @click="acceptGameTerms()">
            {{ transl.UserPV.accept }}
          </div>

          <div class="btn btn-secondary" @click="tocModal = false">
            {{ transl.UserPV.close }}
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import BeforeEnterMixin from '../../mixins/BeforeEnterMixin';
import EntityMixin from '../../mixins/EntityMixin';
import InputField from '../../stories/components/InputField/InputField';
// import Logo from "../Logo.vue";
import Form from '../../stories/components/Form/Form';
// import { usePermission } from '@vueuse/core';
// chrome://flags/#unsafely-treat-insecure-origin-as-secure set IP-address

export default {
  name: 'SpinHome',
  components: {
    // Logo,
    InputField,
    Form
  },
  props: ['gameId'],
  mixins: [BeforeEnterMixin, EntityMixin],
  data() {
    return {
      gameType: 'Spin',
      loadedPage: false,
      isDevelopment: process.env.NODE_ENV !== 'production',
      step: 'spin',
      angle: 0,
      spinTime: 1, // 3 is a good number for production
      spinAgain: true,
      acceptMerchandise: true,
      acceptTerms: false,
      tocModal: false,
      scanModal: false,
      tiebreakAnswer: '',
      surveyAnswers: [],
      firstTime: true,
      disableTryAgain: false,
      activateErrorMsg: null,
      currentUnix: (new moment).unix(),
      timeInterval: null,
      randomIndex: 0,
      enlargedMap: false,
      willWin: false,
      prize: null,
      spinMessage: '',
    };
  },
  mounted() {
    this.getGame();
    /*this.timeInterval = setInterval(() => {
      this.currentUnix = (new moment).unix();
      // TODO: if game starts during time interval (reload page? or set canAddParticipation = true)
    }, 1000);*/
  },
  destroyed() {
    // clearTimeout(this.timeInterval);
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getGame();
      }
    },
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'user',
      'participation',
      'canAddParticipation',
      'reason',
    ]),
    cssProps() {
      return {
        '--game-desktop-bg-color': this.game.desktop_bg_color,
        '--game-bg-color': this.game.bg_color,
        '--game-accent-color': this.game.accent_color,
        '--game-text-color': this.game.text_color,
      }
    },
    hasCurrentParticipation() {
      return this.participation && this.participation._id;
    },
    hasAcceptedGame() {
      if (!this.user || !this.user._id) {
        return false;
      }
      for (let i = 0; i < this.user.accepted_games.length; i++) {
        const acceptedGame = this.user.accepted_games[i];
        if (acceptedGame._id === this.gameId
          || acceptedGame.unique_name === this.gameId) {
          return true;
        }
      }
      return false;
    },
    currentAngle() {
      let currentAngle = 0;
      if (this.angle) {
        currentAngle = this.angle;
      } else if (this.participation?.attempts?.length) {
        const attempts = this.participation.attempts.length;
        currentAngle = this.participation.attempts[attempts-1].angle;
      }
      return currentAngle;
    },
  },
  methods: {
    getGame() {
      this.$store.dispatch('getGame', this.attach({ authenticated: this.authenticated }))
      .then((success) => {
        if (!success) {
          if (!this.game || !this.game._id) {
            this.setAlert(this.reason);
            this.$router.push({ name: 'UserStart' });
            return;
          }
          this.gotoError();
          return;
        }

        this.setSpinAgain();
        this.setPageTitle(this.game.name, this.game.company.name);
        this.acceptMerchandise = this.game.terms_merchandise_default;
        this.setRandomFact();
        this.pushDataLayer({
          parentCompany: this.game.company.parentCompany,
          company: this.game.company,
          game: this.game,
          action: 'Enter',
        });

        this.setStep();
      });
    },
    setStep() {
      const game = this.game;
      const participation = this.participation;
      if ((!this.authenticated && game.must_login)
        || (!this.hasCurrentParticipation && !game.tiebreak)) {
        this.gotoOnboarding();
      } else if (!this.hasCurrentParticipation && !this.canAddParticipation) {
        this.gotoError();
      } else if (!this.hasCurrentParticipation && game.tiebreak) {
        this.step = 'tiebreaker';
        if (game.terms_must_accept && !this.hasAcceptedGame) {
          this.tocModal = true;
        }
      } else if (game.wheel_spins <= participation.attempts.length &&
        (!participation.survey_answers || !participation.survey_answers.length)) {
        this.step = 'survey';
      } else if (game.wheel_spins <= participation.attempts.length &&
        participation.survey_answers && participation.survey_answers.length) {
        this.step = 'finished';
      } else if (game.wheel_spins > participation.attempts.length) {
        this.step = 'spin';
      }
      this.loadedPage = true;
    },
    setSpinAgain() {
      if (this.hasCurrentParticipation && this.participation?.attempts?.length >= this.game.wheel_spins) {
        this.spinAgain = false;
      } else {
        this.spinAgain = true;
      }
    },
    setRandomFact() {
      if (this.game.hunt_funfacts && this.game.hunt_funfacts.length) {
        this.randomIndex = this.getRandomInt(this.game.hunt_funfacts.length);
      }
    },
    acceptGameTerms() {
      this.tocModal = false;
      this.acceptTerms = true;
    },
    participate() {
      this.$store.dispatch('participate', this.attach({
        tiebreakAnswer: this.tiebreakAnswer,
        acceptTerms: this.acceptTerms || this.hasAcceptedGame,
        acceptMerchandise: this.acceptMerchandise,
        thirdPartyCrmName: this.thirdPartyCrmName,
      }))
      .then(() => {
        this.setStep();
      });
    },
    setSurvey() {
      this.$store.dispatch('answerGameSurvey', this.attach({ answers: this.surveyAnswers }))
      .then((success) => {
        if (!success) {
          this.setAlert(this.reason);
          return;
        }

        this.setStep();
      });
    },
    attach(obj) {
      obj.entityId = this.gameId;
      obj.entityType = this.gameType;
      return obj;
    },
    gotoError() {
      console.log('Home gotoError');
      this.$router.push({ name: 'SpinError' });
    },
    gotoOnboarding() {
      console.log('Home gotoOnboarding');
      const query = this.$route.query;
      this.$router.push({ name: 'SpinOnboarding', query });
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    async spinTheWheel() {
      this.spinAgain = false;
      const {
        success, prize, prizeIndex, finalAngle, message,
      } = await this.$store.dispatch('spinTheWheel', this.attach({ willWin: this.willWin }));
      if (!success) {
        // take me to error page?
        this.setAlert(message);
        return;
      }

      this.angle = finalAngle;
      this.prize = prize;
      this.spinMessage = this.game.wheel_areas[prizeIndex].message;

      setTimeout(() => {
        this.setSpinAgain();
      }, 1000 * this.spinTime);
    },
    ctaDoneRedirect() {
      if (this.game.cta_done_url) {
        this.openUrl(this.game.cta_done_url, true);
      } else {
        this.$router.push({ name: 'HomeCompany', params: { companyId: this.getId(this.game.company) } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/games/Spin/Base";
@import "../../assets/scss/games/Spin/Home";
</style>
