<template>
  <div class="dashboard-funnel-bar-container">
    <div class="dashboard-funnel-outline">
      <div
        v-if="this.dropoff > 0"
        class="dashboard-funnel-bar dashboard-funnel-dropoff"
        :class="{ 'dashboard-funnel-dropoff--selected': selection === 'dropoff' }"
        :style="dropoffStyle"
        @click="$emit('clickDropoff')"
        ></div>
      <div
        v-if="this.value > 0"
        class="dashboard-funnel-bar"
        :class="{ 'dashboard-funnel-bar--selected': selection === 'bar' }"
        :style="valueStyle"
        @click="$emit('click')"></div>
    </div>
    <p
      class="dashboard-funnel-bar-value"
      :class="{ 'dashboard-funnel-bar-value--zero': value === 0 && !selection }">
      {{ value }}
    </p>
    <p
      v-if="showDropoffValue"
      class="dashboard-funnel-bar-dropoff-value"
      :style="dropoffValueStyle"
      @click="$emit('clickDropoff')">
      {{ dropoff }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DashboardFunnelBar',
  props: {
    barWidth: {
      type: Number,
    },
    barHeight: {
      type: Number,
    },
    dropoff: {
      type: Number,
      default: 0,
    },
    selection: {
      type: String,
    },
    showDropoffValue: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dropoffStyle() {
      const percentage = this.total > 0 ? (this.dropoff / this.total) * 100 : 0;
      return {
        height: `${percentage}%`,
        borderRadius: '4px 4px 0 0',
      }
    },
    valueStyle() {
      const percentage = (this.value / this.total) * 100;
      return {
        height: `${percentage}%`,
        borderRadius: this.dropoff > 0 ? '0 0 4px 4px' : '4px',
      }
    },
    dropoffValueStyle() {
      const percentage = 100 - ((this.value / this.total) * 100);
      return {
        top: `${percentage}%`,
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard-funnel-bar-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;

  .dashboard-funnel-outline {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: stretch;
    gap: 0px;
    height: 100%;
    overflow: hidden;
  }

  .dashboard-funnel-bar {
    width: 100%;
    background-color: $brand-yellow;
  }

  .dashboard-funnel-dropoff {
    background-color: #F9CE81;
  }

  .dashboard-funnel-bar--selected {
    background-color: #008000;
  }

  .dashboard-funnel-dropoff--selected {
    background-color: #E6007E;
  }

  .dashboard-funnel-bar-value {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, 0);

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #FFF;
  }

  .dashboard-funnel-bar-value--zero {
    color: #666;
  }

  .dashboard-funnel-bar-dropoff-value {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #999;
    background-color: #FFFFFF;

    font-size: 14px;
    font-weight: 700;
    line-height: 21.6px;
    letter-spacing: 0.014px;
    text-align: center;
    color: #666;
  }
}
</style>
