<template>
  <span></span>
</template>

<script>
import ActivationStepConfig from "@/stories/components/ActivationStepConfig/ActivationStepConfig.vue";
import StyleDetailsStepConfig from "@/stories/components/ActivationStepConfig/StyleDetailsStepConfig.vue";
import TermsStepConfig from "@/stories/components/ActivationStepConfig/TermsStepConfig.vue";
import ReceiptStepConfig from "@/stories/components/ActivationStepConfig/ReceiptStepConfig.vue";
import ProductRegistrationStepConfig from "@/stories/components/ActivationStepConfig/ProductRegistrationStepConfig.vue";
import SurveyStepConfig from "@/stories/components/ActivationStepConfig/SurveyStepConfig.vue";
import AddressSurveyStepConfig from "@/stories/components/ActivationStepConfig/AddressSurveyStepConfig.vue";
import CashbackStepConfig from "@/stories/components/ActivationStepConfig/CashbackStepConfig.vue";
import ConfirmationStepConfig from "@/stories/components/ActivationStepConfig/ConfirmationStepConfig.vue";
import EmailConfirmationStepConfig from "@/stories/components/ActivationStepConfig/EmailConfirmationStepConfig.vue";
import ReturnSurveyStepConfig from "@/stories/components/ActivationStepConfig/ReturnSurveyStepConfig.vue";
import axios from "axios";
import DateTimeConverterMixin from "@/mixins/DateTimeConverterMixin.vue";


export default {
  name: 'PartnerMixin',
  mixins: [DateTimeConverterMixin],
  components: {
    ActivationStepConfig, // eslint-disable-line vue/no-unused-components
    StyleDetailsStepConfig, // eslint-disable-line vue/no-unused-components
    TermsStepConfig, // eslint-disable-line vue/no-unused-components
    ReceiptStepConfig, // eslint-disable-line vue/no-unused-components
    ProductRegistrationStepConfig, // eslint-disable-line vue/no-unused-components
    SurveyStepConfig, // eslint-disable-line vue/no-unused-components
    AddressSurveyStepConfig, // eslint-disable-line vue/no-unused-components
    CashbackStepConfig, // eslint-disable-line vue/no-unused-components
    ConfirmationStepConfig, // eslint-disable-line vue/no-unused-components
    EmailConfirmationStepConfig, // eslint-disable-line vue/no-unused-components
    ReturnSurveyStepConfig, // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      activationSteps: [],
    }
  },
  methods: {
    getActivationSteps(offer, activation, subOffer) {
      if (!offer?._id) {
        return [];
      }

      // Offer is always included
      const steps = ['ActivationStepConfig'];

      // No PV? Skip further checks
      if (!activation?._id) {
        return steps;
      }

      // Terms?
      if (activation.terms_must_accept || activation.terms_text?.length > 0)
        steps.push('TermsStepConfig');

      // Style details
      steps.push('StyleDetailsStepConfig');

      // Receipt registration?
      if (activation.receipt_step)
        steps.push('ReceiptStepConfig');

      // Product registration
      if (activation.registration_step)
        steps.push('ProductRegistrationStepConfig');

      // Survey?
      if (activation.survey_step)
        steps.push('SurveyStepConfig');

      if (activation.address_step)
        steps.push('AddressSurveyStepConfig');

      // Cashback?
      if (activation.cashback_step)
        steps.push('CashbackStepConfig');

      // Confirmation is always included in PV
      steps.push('ConfirmationStepConfig');

      if (activation.cta_email)
        steps.push('EmailConfirmationStepConfig');

      // Return survey?
      if (subOffer) {
        steps.push('ReturnSurveyStepConfig');
      }

      return steps;
    },
    isActivationSupported(offer, activation) {
      const modules = this.getActivationSteps(offer, activation);

      return (
        modules.includes('ActivationStepConfig')
        // && modules.includes('ActivationTerms')
        // && modules.includes('ActivationReceipt')
        // && modules.includes('ActivationRegistration')
        // && modules.includes('ActivationSurvey')
        // && modules.includes('ActivationCashback')
      );
    },
    chipStateClass(state) {

      if (state === undefined || state === null)
        return '';

      let chipStateClass = '';
      switch (state) {
        case 'Archived':
          chipStateClass = 'chip--archived';
          break;
        case 'Draft':
          chipStateClass =   'chip--draft';
          break;
        case 'Published':
        case 'PublishDraft':
          chipStateClass =  'chip--published';
          break;
        case 'Scheduled':
          chipStateClass =  'chip--scheduled';
          break;
      }

      return chipStateClass;
    },
    async publishCollection(collection) {
      await this.updateCollection(collection);
    },
    async unPublishCollection(collection) {
      collection.public = false;
      await this.updateCollection(collection);
    },
    async updateCollection(collection) {
      let formData = new FormData();
      formData.append('collection', JSON.stringify(collection));

      try {
        await axios.put(`/api/c/companies/collections`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch(error)  {
        console.log(error);
      }
    },
    async deleteCollection(collectionId) {
      try {
        await axios.delete(`/api/c/companies/collections/${collectionId}`);
      } catch(error)  {
        console.log(error);
      }
    },
    async publishOffer(activationId, scheduleDate, publishNow = false) {
      let properties = { scheduled_publish_at: scheduleDate }

      if (publishNow)
        properties.public = true;

      await this.updateOffer(activationId, properties)
    },
    async unPublishOffer(activationId) {
      let properties = { public: false, scheduled_publish_at: null  };
      await this.updateOffer(activationId, properties)
    },
    async publishActivation(activationId) {
      let properties = { public: true };
      await this.updateActivation(activationId, properties)
    },
    async unPublishActivation(activationId) {
      let properties = { public: false};
      await this.updateActivation(activationId, properties)
    },
    async updateOffer(activationId, properties, imageUploads) {
      try {
        const formData = new FormData();
        formData.append('offer', JSON.stringify(properties));

        if (imageUploads !== undefined) {
          Object.entries(imageUploads).forEach(([key, value]) => {

              if (value === null || value === undefined)
                  return;

              if (Array.isArray(value)) {
                value.forEach((file) => {
                  formData.append(key, file);
                });
              } else
                  formData.append(key, value);
          });
        }

        return await axios.put(`/api/c/companies/activations/${activationId}`, formData,
            { headers: { 'Content-Type': 'multipart/form-data' } });
      } catch (error) {
        this.setAlert('An error occurred, could not publish offer', 1);
      }
    },
    async updateActivation(activationId, properties, imageUploads) {
      try {
        const formData = new FormData();
        formData.append('activation', JSON.stringify(properties));

        if (imageUploads !== undefined) {
          Object.entries(imageUploads).forEach(([key, value]) => {
            if (value !== null && value !== undefined)
              formData.append(key, value);
          });
        }

        return await axios.put(`/api/c/companies/activations/${activationId}/pv`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Use-Original-Filename': 'true'
          }
        });
      } catch (error) {
        this.setAlert('An error occurred, could not publish activation', 1);
      }
    },
    stateInformation(model) {
      if (model.document_state === 'Published' || model.document_state === 'Scheduled') {
        return `Publish date <span style='font-weight: 600'>${this.toDDMMMMYYYYDate(model.scheduled_publish_at)}</span>`;
      } else if (model.document_state === 'Draft') {
        return `Created <span style='font-weight: 600'>${this.toDDMMMMYYYYDate(model.created_at)}</span>`;
      } else if (model.document_state === 'PublishDraft') {
        let numberOfDays = this.calculateDaysDifferenceFromNow(model.updated_at);
        numberOfDays = Math.abs(numberOfDays);

        let label = `Publish date <span style='font-weight: 600'>${this.toDDMMMMYYYYDate(model.scheduled_publish_at)}</span> &middot; `;
        if (numberOfDays === 0)
          return label + 'Unpublished changes today';
        else if (numberOfDays === 1)
          return label + 'Unpublished changes yesterday';

        return label + `Unpublished changes <span style='font-weight: 600'>${numberOfDays} days ago</span>`;
      }
      return '';
    },
    async createCampaign(data) {
      try {
        if (!data)
          throw new Error('No data');

        const collectionData = {
          company: this.companyId,
          name: data.name,
          uniqueName: data.name.toLowerCase().replace(/ /g, "-"),
          theme: data.theme,
          info: data.info,
          order: 0,
          filenames: "",
          thumbnailFilenames: "",
          thirdPartyCrmName: ""
        };

        let formData = new FormData();
        formData.append('collection', JSON.stringify(collectionData));

        if (data.background !== null)
          formData.append('backgroundImage', data.background.fileObject);

        if (data.logo !== null)
          formData.append('logoImage', data.logo.fileObject);

        if (data.startPage !== null)
          formData.append('startPageImage', data.startPage.fileObject);

        const { data: result } = await axios.post(`/api/c/companies/collections`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (error) {
        console.log('Error', error);
      }
    },
    async createActivation(data) {
      try {
        if (!data)
          throw new Error('No data');

        const { data: result } = await axios.post(`/api/c/companies/collections/${data.collectionId}/activations`, JSON.stringify({
          name: data.name,
          template: data.module
        }), {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.$emit('disappeared');
      } catch (error) {
        console.log('Error', error);
      }
    },
  },
  computed: {
    companyId() {
      return this.$store.getters.company?._id || null;
    },
  },
  filters: {
    state: function (value) {
      if (value === 'PublishDraft')
        return 'Published';
      return value;
    },
  }
}
</script>
