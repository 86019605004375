<template>
  <div class="admin-content--container">
    <div class="admin-search-container">
      <div class="admin-spacer"/>

      <div class="add-entity-button" @click="showCompanyDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>

    <aList
      entityType="Companies"
      columnsWidth="400"
      :entities="companies"
      :keys="companyKeys"
      routerName="AdminCompany"
      routerId="companyId"/>

    <modal v-if="showCompanyDialog" size="xlarge"
           @close="showCompanyDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
                      entity-type="Company"
                      :admin="true" />
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '../resources/eventBus';
import aList from '../components/AList';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: 'AdminCompanies',
  components: {
    aList,
    EntityEditor,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminCompanies();
    this.setPageTitle('Admin', 'Companies');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(['companies', 'status']),
  },
  data() {
    return {
      showCompanyDialog: false,
      companyKeys: [
        {
          title: 'Id',
          key: '_id',
        },
        {
          title: 'Name',
          key: 'name',
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Unique Name',
          key: 'unique_name',
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminCompanies(query) {
      this.$store.dispatch('findAdminCompanies', {
        query,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminCompanies";
</style>