<template>
  <div>

    <div class="child-container">
      <div class="empty">
        <button
          class="button-yellow"
          @click="$emit('addSubcollection')">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path fill="#EA7801" stroke="#EA7801" stroke-width=".3" d="M8.578 2.1h-.15v6.328H2.1v1.144h6.328V15.9h1.144V9.572H15.9V8.428H9.572V2.1h-.994Z"/>
          </svg>
          Create new sub-collection
        </button>
        <p class="subtitle">{{ collections.length }} sub-collections</p>
      </div>
    </div>

    <div v-for="collection in collections" :key="collection.id" class="child-container">
      <div class="header">
        <div class="item">
          <div class="icon-container">
            <!-- ToDo: The icon misbehaves in the browser compared to in Figma. Also, move to icon-component? -->
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M9.02251 5.18741L9.02256 5.18748L9.75732 6.16921L9.02251 5.18741ZM9.02251 5.18741C8.78144 4.86653 8.40156 4.67656 8.00077 4.67656H4.7272C4.0277 4.67656 3.45 5.25426 3.45 5.95377M9.02251 5.18741L3.45 5.95377M8.28749 5.73829L8.28744 5.73823C8.22035 5.64833 8.11414 5.59572 8.00077 5.59572H4.7272C4.52472 5.59572 4.36916 5.75128 4.36916 5.95377V19.403H7.13161V11.2722C7.13161 10.5711 7.70934 9.99495 8.40881 9.99495H10.8632C11.2654 9.99495 11.6438 10.1833 11.885 10.5058L8.28749 5.73829ZM8.28749 5.73829L9.02381 6.72159L9.02386 6.72165C9.26498 7.04262 9.64337 7.23097 10.0456 7.23097H20.2728C20.4752 7.23097 20.6308 7.3864 20.6308 7.59055V11.6317H12.9096C12.7953 11.6317 12.6903 11.5781 12.6212 11.4875C12.6212 11.4874 12.6211 11.4874 12.6211 11.4873L11.885 10.5059L8.28749 5.73829ZM3.45 5.95377V19.453M3.45 5.95377V19.453M3.45 19.453C3.45 19.9285 3.84361 20.3222 4.31916 20.3222M3.45 19.453L4.31916 20.3222M4.31916 20.3222H20.6808M4.31916 20.3222H20.6808M20.6808 20.3222C21.1564 20.3222 21.55 19.9285 21.55 19.453M20.6808 20.3222L21.55 19.453M21.55 19.453V7.59055M21.55 19.453V7.59055M21.55 7.59055C21.55 6.88947 20.9723 6.31334 20.2728 6.31334M21.55 7.59055L20.2728 6.31334M20.2728 6.31334H10.0456M20.2728 6.31334H10.0456M10.0456 6.31334C9.93239 6.31334 9.82608 6.26086 9.75735 6.16925L10.0456 6.31334ZM10.8632 10.9126H8.40881C8.20645 10.9126 8.05077 11.068 8.05077 11.2722V19.403H20.6308V12.5494H12.9096C12.5074 12.5494 12.1274 12.3595 11.8878 12.0385L10.8632 10.9126ZM10.8632 10.9126C10.976 10.9126 11.0826 10.9663 11.1516 11.0569C11.1516 11.0569 11.1517 11.057 11.1517 11.057L11.8877 12.0384L10.8632 10.9126Z" stroke="#333333" stroke-width="0.5"/>
            </svg>
          </div>
          <div class="details">
            <p class="heading" @click="$emit('edit', collection.id)">{{ collection.name }}</p>
            <p class="subtitle">
              {{ collection.collectionsCount }} sub-collections · {{ collection.activationsCount }} activations
            </p>
          </div>
        </div>

        <button class="expand-button" @click="$emit('selectSubcollection', collection)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17">
            <path fill="#333" d="M5.12 2.1v13.44l7.04-6.72L5.12 2.1Z"/>
          </svg>
        </button>
      </div>
    </div>

    <!-- ToDo: Check if this should be visible
      <button v-if="collections.length > 0" class="button-yellow">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
          <path fill="#EA7801" stroke="#EA7801" stroke-width=".3" d="M8.578 2.1h-.15v6.328H2.1v1.144h6.328V15.9h1.144V9.572H15.9V8.428H9.572V2.1h-.994Z"/>
        </svg>
        Create new sub-collection
      </button>
    -->

  </div>
</template>

<script>
export default {
  name: 'SubcollectionsList',
  props: {
    collections: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import './CampaignCard.scss';
.empty {
  > p {
    padding: 0 0 0 16px;
  }
}
</style>