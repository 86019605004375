import axios from 'axios';

export default {
  findAdminPVReviews: (data) => {
    return axios.put(`/api/pv/admin/reviews`, data)
      .then(response => {
        return {
          reviews: response.data.reviews != null
            ? response.data.reviews : [],
        }});
  },
};
