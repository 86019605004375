/* eslint-disable no-shadow, no-param-reassign */
// import Vue from 'vue';
import gameApi from '../../api/game.api';

// initial state
const state = {
  game: {},
  participation: {},
  canAddParticipation: false,
  reason: '',
  prizes: {},
  // participations: [],
  gameStatus: '',
};

// getters
const getters = {
  game: state => state.game,
  participation: state => state.participation,
  canAddParticipation: state => state.canAddParticipation,
  reason: state => state.reason,
  prizes: state => state.prizes,
  // participations: state => state.participations,
  gameStatus: state => state.gameStatus,
};

// actions
const actions = {
  // Consumer
  getGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    commit('REASON', '');
    const method = payload.authenticated ? 'getGame' : 'getNarGame';
    return gameApi[method](payload)
      .then(({ game, participation, canAddParticipation, prizes, reason }) => {
        commit('GAME', game);
        commit('PARTICIPATION', participation);
        commit('CAN_ADD_PARTICIPATION', canAddParticipation);
        commit('COMPANY', game.company);
        commit('GAME_STATUS', 'FINISHED');
        commit('REASON', reason);
        commit('LIMITED_OFFERS_BY_TYPE', prizes);
        return true;
      }).catch((error) => {
        commit('GAME_STATUS', 'FAILED');
        commit('REASON', error.response.data.message);
        return false;
      });
  },

  participate({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    commit('REASON', '');
    return gameApi.participate(payload)
      .then(({ user, participation }) => {
        commit('USER', user);
        commit('PARTICIPATION', participation);
        commit('CAN_ADD_PARTICIPATION', false);
        commit('GAME_STATUS', 'FINISHED');
        return true;
      }).catch((error) => {
        commit('GAME_STATUS', 'FAILED');
        commit('CAN_ADD_PARTICIPATION', false);
        commit('REASON', error.response.data.message);
        return false;
      });
  },

  addGamePoint({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    commit('REASON', '');
    return gameApi.addGamePoint(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
        return true;
      }).catch((error) => {
        commit('REASON', error.response.data.message);
        commit('GAME_STATUS', 'FAILED');
        return false;
      });
  },

  claimPrize({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.claimPrize(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
        return { success: true, prize: response.prize };
      })
      .catch((error) => {
        const { message } = error.response.data;
        commit('GAME_STATUS', 'FINISHED');
        return { success: false, message };
      });
  },

  spinTheWheel({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.spinTheWheel(payload)
      .then((response) => {
        const { participation, prize, prizeIndex, finalAngle, message } = response;
        commit('PARTICIPATION', participation);
        commit('GAME_STATUS', 'FINISHED');
        return { success: true, prize, prizeIndex, finalAngle, message };
      })
      .catch((error) => {
        const { message } = error.response.data;
        commit('GAME_STATUS', 'FINISHED');
        return { success: false, message };
      });
  },

  answerGameSurvey({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    commit('REASON', '');
    return gameApi.answerGameSurvey(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
        return true;
      }).catch((error) => {
        commit('GAME_STATUS', 'FAILED');
        commit('REASON', error.response.data.message);
        return false;
      });
  },

  resetGame({ commit }) {
    commit('PARTICIPATION', {});
    commit('GAME', {});
  },
};

// mutations
const mutations = {
  GAME(state, game) {
    state.game = game;
  },
  PARTICIPATION(state, participation) {
    state.participation = participation;
  },
  CAN_ADD_PARTICIPATION(state, canAddParticipation) {
    state.canAddParticipation = canAddParticipation;
  },
  REASON(state, reason) {
    state.reason = reason;
  },
  LIMITED_OFFERS_BY_TYPE(state, prizes) {
    state.prizes = prizes;
  },
  // PARTICIPATIONS(state, participations) {
  //   state.participations = participations;
  // },
  // ADD_PARTICIPATION(state, participation) {
  //   state.participations.push(participation);
  // },
  GAME_STATUS(state, status) {
    state.gameStatus = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
