import axios from 'axios';

export default {
  // Admin
  findAdminPVChain: (data) => {
    return axios.put(`/api/pv/admin/chains`, data)
      .then(response => {
        return {
          chains: response.data.chains != null
            ? response.data.chains : [],
      }});
  },

  addAdminPVChain: (payload) => {
    return axios.post('/api/pv/admin/chains', payload)
      .then(response => ({
        chain: response.data.chain != null
          ? response.data.chain : {},
      }));
  },

  saveAdminPVChain: (payload) => {
    return axios.put(`/api/pv/admin/chains/${payload.entityId}`, payload)
      .then(response => ({
        chain: response.data.chain != null
          ? response.data.chain : {},
      }));
  },

  getAdminPVChain: (payload) => {
    return axios.get(`/api/pv/admin/chains/${payload.entityId}`, payload)
      .then(response => ({
        chain: response.data.chain != null
          ? response.data.chain : {},
      }));
  },

  deleteAdminPVChain: (payload) => {
    return axios.delete(`/api/pv/admin/chains/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Company
  findCompanyChains: (data) => {
    return axios.put('/api/pv/c/chains', data)
      .then(response => {
        return { chains: response.data.chains != null
          ? response.data.chains : []}
      });
  },

  getCompanyChain: (payload) => {
    return axios.get(`/api/pv/c/chains/${payload.chainId}`)
      .then(response => ({
        chain: response.data.chain != null
          ? response.data.chain : {},
      }));
  },

  addCompanyChain: (payload) => {
    return axios.post('/api/pv/c/chains', payload)
      .then(response => ({
        chain: response.data.chain != null
          ? response.data.chain : {},
      }));
  },

  saveCompanyChain: (payload) => {
    return axios.put(`/api/pv/c/chains/${payload.entityId}`, payload)
      .then(response => ({
        chain: response.data.chain != null
          ? response.data.chain : {},
      }));
  },

  deleteCompanyChain: (entityId) => {
    return axios.delete(`/api/pv/c/chains/${entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },
};
