<script>
import moment from "moment-timezone";

export default {
  name: 'DateTimeConverterMixin',
  methods: {
    currentTime() {
      return moment().unix();
    },
    todayInSeconds() {
      return moment().startOf("day").unix();
    },

    todayInMilliSeconds() {
      return moment().startOf("day").valueOf();
    },

    todayDate() {
      return moment().startOf("day").format("YYYY-MM-DD");
    },

    toDate(dateTimeInSeconds) {
      return moment.unix(dateTimeInSeconds).format('YYYY-MM-DD');
    },

    toDDMMMMYYYYDate(dateTimeInSeconds) {
      return moment.unix(dateTimeInSeconds).format('D MMMM YYYY');
    },

    toSeconds(dateTimeInMilliseconds) {
      return moment(dateTimeInMilliseconds).unix();
    },

    toMilliSeconds(dateTimeInSeconds) {
      return dateTimeInSeconds * 1000;
    },

    isBeforeToday(dateTimeInMilliSeconds) {
      return moment(dateTimeInMilliSeconds).isBefore(this.todayInMilliSeconds());
    },

    isToday(dateTimeInSeconds) {
      return dateTimeInSeconds === this.todayInSeconds();
    },

    calculateDaysDifferenceFromNow(dateTimeInSeconds) {
      return moment.unix(dateTimeInSeconds).diff(moment(), 'days');
    },
  }
}
</script>
