var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.goodiebag && _vm.goodiebag._id)?_c('div',{staticClass:"goodiebag-preview entity-preview"},[_c('div',{staticClass:"preview-width-picker"},[_c('h3',[_vm._v("Preview of Goodiebag \""+_vm._s(_vm.goodiebag.name)+"\"")]),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){_vm.previewWidth = 'computer'}}},[_c('iconComponent',{attrs:{"symbol":"computer"}})],1),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){_vm.previewWidth = 'tablet'}}},[_c('iconComponent',{attrs:{"symbol":"tablet"}})],1),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){_vm.previewWidth = 'smartphone'}}},[_c('iconComponent',{attrs:{"symbol":"smartphone"}})],1),_c('md-button',{staticClass:"md-icon-button close-preview-button",on:{"click":function($event){return _vm.$emit('close')}}},[_c('iconComponent',{attrs:{"symbol":"cross"}})],1)],1),_c('div',{staticClass:"preview-default home-goodiebag",class:{
      'timeless-theme-goodiebag': true,
      'radiant-theme-goodiebag': false,
      'preview-tablet': _vm.previewWidth === 'tablet',
      'preview-smartphone': _vm.previewWidth === 'smartphone',
      'iris': _vm.goodiebag.theme ? _vm.goodiebag.theme : 'iris',
    }},[_c('div',{staticClass:"goodiebag-page",style:(_vm.goodiebag.theme === 'nemo' && _vm.goodiebag.media ? `background-image: url(${_vm.goodiebag.media.url});`: '')},[_c('div',{staticClass:"header-image",style:(`background-image: url(${_vm.goodiebag.theme !== 'nemo' && _vm.goodiebag.media ? _vm.goodiebag.media.url : ''});`
        +`margin-bottom: ${_vm.goodiebag.icon?.url ? '2rem;' : '0;'}`)}),_c('div',{staticClass:"goodiebag-content-wrapper content-page"},[(_vm.goodiebag.icon?.url)?_c('img',{staticClass:"header-logo",attrs:{"src":`${_vm.goodiebag.icon?.url}`},on:{"click":function($event){return _vm.gotoParent(_vm.goodiebag)}}}):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.goodiebag.name))]),_c('div',{staticClass:"header-info body-1"},[_vm._v(_vm._s(_vm.goodiebag.info))]),(_vm.currentOffers && _vm.currentOffers.length)?_c('cardWrapper',{attrs:{"data":_vm.currentOffers,"theme":_vm.goodiebag.theme,"secondary-data":_vm.closedOffers,"start-countdown":_vm.startCountdown},on:{"cardClick":function($event){return _vm.gotoCurrentOffer($event)}}}):_vm._e()],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }