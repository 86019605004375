<template>
  <div>
    <ckeditor
      :value="value"
      :config="config"
      @input="handleInput"></ckeditor>
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue';

export default {
  name: 'CKEditorWrapper',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      /*config: {
        toolbar: [
          { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
          { name: 'editing', items: [ 'Scayt' ] },
          { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
          { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'SpecialChar' ] },
          { name: 'tools', items: [ 'Maximize' ] },
          { name: 'document', items: [ 'Source' ] },
          { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          '/',
          { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat' ] },
          { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'JustifyCenter' ] },
          { name: 'styles', items: [ 'Styles', 'Format' ] },
        ],
        alignment: {
          options: [ 'left', 'right' ]
        },
        extraPlugins: [ 'colorbutton', 'alignment' ]
      },*/
      config: {
        // The toolbar groups arrangement, optimized for a single toolbar row.
        toolbarGroups: [
          { name: 'clipboard', groups: [ 'clipboard', 'undo', ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
          { name: 'links' },
          { name: 'insert' },
          { name: 'forms' },
          { name: 'tools' },
          { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
          { name: 'others' },
          '/',
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'styles' },
          { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
          { name: 'colors' },
          // { name: 'about' }
        ],
        extraPlugins: [ 'colorbutton', 'justify' ]
      }
    };
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>

