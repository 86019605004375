<template>
  <div class="dashboard-funnel-chart-container" ref="chartContainer">
    <svg v-if="total > 0"  width="100%" height="100%" class="dashboard-funnel-lines">
      <g v-for="(item, index) in data" :key="index">
        <line
          v-if="(index < (data.length - 1)) && item.value > 0"
          v-bind="getLineAttributes(index)"></line>
      </g>
    </svg>
    <div v-for="(item, index) in data" :key="index" class="dashboard-funnel-bar" :style="{ width: `${barWidth}px` }">
      <DashboardFunnelBar
        :dropoff="item.dropoff"
        :value="item.value"
        :total="total"
        :selection="selection === item.filterId ? 'bar' : selection === item.dropoffId ? 'dropoff' : ''"
        :showDropoffValue="index > 0"
        :barWidth="barWidth"
        :barHeight="barHeight"
        @click="$emit('clickBar', item)"
        @clickDropoff="$emit('clickDropoff', item)"
      />
    </div>
  </div>
</template>

<script>
import DashboardFunnelBar from './DashboardFunnelBar.vue';

export default {
  name: 'DashboardFunnelChart',
  components: { DashboardFunnelBar },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    barWidth: {
      type: Number,
      default: 77,
    },
    selection: {
      type: String,
    },
  },
  data() {
    return {
      resizeObserver: null,
      chartWidth: 0,
      chartHeight: 0,
    }
  },
  mounted() {
    const container = this.$refs.chartContainer;
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(container);
    this.getContainerSize(container);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  },
  computed: {
    barHeight() {
      return this.chartHeight;
    },
    chartSpacing() {
      // The chart spacing is the available space between the bars.
      return (this.chartWidth - (this.barWidth * this.data.length)) / (this.data.length - 1);
    },
    total() {
      // Note: The component assumes that the first item of the data property has the largest value.
      let total = 0;
      if (this.data[0] && this.data[0].value) {
        total = this.data[0].value;
      }
      return total;
    },
  },
  methods: {
    getLineAttributes(index) {
      const thisStep = this.data[index];
      const nextStep = this.data[index + 1];
      const strokeWidth = 6;
      const xOffset = strokeWidth / 2;
      const yOffset = strokeWidth / 2 + 0.5;
      const maxY = this.barHeight - yOffset;
      const topOffset = 6;

      return {
        x1: (this.barWidth + (this.barWidth + this.chartSpacing) * index) - xOffset,
        y1: this.barHeight - (thisStep.value / this.total * this.barHeight) + yOffset + topOffset,
        x2: ((this.barWidth + this.chartSpacing) * (index + 1)) + xOffset,
        y2: Math.min(maxY, (this.barHeight - (nextStep.value / this.total * this.barHeight)) + yOffset) + topOffset,
        'stroke': '#F2F2F2',
        'stroke-width': strokeWidth,
        'stroke-linecap': 'round'
      }
    },
    handleResize(entries) {
      for (const entry of entries) {
        this.getContainerSize(entry.target);
      }
    },
    getContainerSize(container) {
      this.chartWidth = container.clientWidth;
      this.chartHeight = container.clientHeight;
    }
  }
}
</script>

<style lang='scss' scoped>
.dashboard-funnel-chart-container {
  position: relative;
  display: flex;
  gap: 48px;
  min-height: 98px;
}

.dashboard-funnel-bar {
  display: flex;
  height: 100%;
}

.dashboard-funnel-lines {
  position: absolute;
  top: -6px;
}
</style>
