<template>
  <div
    v-if="company && company._id"
    class="company-preview entity-preview">
    <div class="preview-width-picker">
      <h3>Preview of Company "{{ company.name }}"</h3>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'computer'">
        <iconComponent symbol="computer"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'tablet'">
        <iconComponent symbol="tablet"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'smartphone'">
        <iconComponent symbol="smartphone"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="$emit('close')">
        <iconComponent symbol="cross"/>
      </md-button>
    </div>
    <div
      class="preview-default home-company iris"
      :class="{
        'timeless-theme-goodiebag': true,
        'radiant-theme-goodiebag': false,
        'preview-tablet': previewWidth === 'tablet',
        'preview-smartphone': previewWidth === 'smartphone',
      }">
      <div class="company-page">
        <div
          class="header-image"
          :style="`background-image: url(${company.media ? company.media.url : ''});`
          +`margin-bottom: ${company.icon?.url ? '2rem;' : '0;'}`">
        </div>
        <div class="company-content-wrapper content-page">
          <img
             v-if="company.icon?.url"
             class="header-logo"
             :src="`${company.icon?.url}`" />
          <h2>{{ company.name }}</h2>
          <div class="header-info body-1">{{ company.info }}</div>
          <div v-if="!company.goodiebags || !company.goodiebags.length">
            {{ transl.Company.soon }}
          </div>
          <cardWrapper v-if="currentOffers && currentOffers.length" :data="currentOffers" :theme="'iris'" />
          <integrityBox
                        v-if="company.legal_name"
                        badgeType="Integrity_badge_Medium"
                        textData="Har du frågor om GDPR till varumärket? Se kontaktinformation nedan."
                        :expandable="true"
                        :labelList="[company.legal_name, company.email, company.org_number, company.address + ', ' + company.postal_code + ', ' + company.city]"
                        :iconList="['building', 'email', 'paper', 'mapPin']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardWrapper from '../stories/components/CardWrapper/CardWrapper.vue';
import IntegrityBox from '../stories/components/IntegrityBox/IntegrityBox.vue';

export default {
  name: 'PreviewCompany',
  components: { CardWrapper, IntegrityBox },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentOffers() {
      let offers = [];
      if (
        this.company &&
        this.company.goodiebags &&
        this.company.goodiebags.length
      ) {
        if (this.company.goodiebags.length) {
          for (let index = 0; index < this.company.goodiebags.length; index++) {
            const companyGoodiebag = this.company.goodiebags[index];
            offers.push({
              unique_name: companyGoodiebag.unique_name,
              media: [companyGoodiebag.media || {}],
              thumbnail: companyGoodiebag.thumbnail || {},
              columns: 2,
              clickable: true,
              first_header: companyGoodiebag.name,
              inlineCollection: true,
              quick_text: `${companyGoodiebag.offers.length} ${this.transl.Company.activations}`,
              order: companyGoodiebag.order,
              _id: companyGoodiebag._id,
            });
          }
          offers.sort(function (a, b) {
            if (!a.order) {
              a.order = 0;
            } else if (!b.order) {
              b.order = 0;
            }
            return b.order - a.order;
          });
        }
      }
      return offers;
    },
  },
  data() {
    return {
      previewWidth: 'computer',
    };
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
