<template>
  <div class="--myproducts">
    <div>
      <div>
        <div class="--myproducts-title-row">
          <h2>
            {{  user.name }} {{ user.last_name }}
          </h2>
        </div>
        <div class="card-container">
          <a
            v-for="card in cards"
            :key="card.name"
            class="clickable a-card"
            @click="$router.push({ name: card.route })">
            <div class="card" :style="{ backgroundImage: 'url(' + card.image + ')' }">
            </div>
            <p class="subtitle">{{ card.name }}</p>
          </a>
        </div>
      </div>
    </div>

    <UserClubProfileFooter :serverVersion="serverVersion" @openSupportModal="$emit('openSupportModal')">
    </UserClubProfileFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';
import UserClubProfileFooter from './UserClubProfileFooter.vue';

export default {
  name: 'UserClubProfileStart',
  components: { UserClubProfileFooter },
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin],
  props: {
    serverVersion: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['user', 'company']),
  },
  watch: {},
  data() {
    return {
      cards: [
        { name: "My Products", route: "UserClubMyProducts", image: require("../assets/images/club_profile_my_products.jpeg") },
        { name: "My Favorites", route: "UserClubMyFavorites", image: require("../assets/images/club_profile_my_favorites.jpeg") },
        { name: "My History", route: "UserClubMyHistory", image: require("../assets/images/club_profile_my_history.jpeg") }
      ]
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserProfile";


.--myproducts-title-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 40px 0;
  
  h2{
    font-family: var(--regular-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0px;
  }

}
.--profile-cards {
  padding: 50px;

  @media (max-width: 812px) {
    padding: 0px !important;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: $tablet-maxwidth) {
    grid-template-columns: 1fr 1fr;
  }
  .a-card {
    margin: 0 auto 20px auto;
    .card {
      font-family: var(--bold-font-family);
      overflow: hidden;
      background-color: #fff;
      flex: 1;
      border-radius: 10px;
      background: #FFF;
      width: 250px;
      height: 250px;
      background-size: cover;
      background-position: center;
      box-shadow: 0px 2px 4px 0px rgba(31, 41, 55, 0.06), 0px 4px 6px 0px rgba(31, 41, 55, 0.10);

      @media (max-width: $mobile-maxwidth) {
        width: 140px;
        height: 140px;
      }
    }
    .subtitle {
      font-family: var(--regular-font-family);
      color: var(--enginio-charcoal-black, #111);
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-top: 8px;
    }
  }
}
</style>
