<template>
  <div>
    <div
      class="club-about"
      v-html="company.club_about">
    </div>
    <UserClubProfileFooter :serverVersion="serverVersion" @openSupportModal="$emit('openSupportModal')">
    </UserClubProfileFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserClubProfileFooter from './UserClubProfileFooter.vue';

export default {
  name: 'UserClubProfileAbout',
  components: { UserClubProfileFooter },
  props: {
    serverVersion: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['user', 'company', 'userStatus']),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.club-about {
  text-align: left;
  max-width: 500px;
  margin: auto;
}
</style>
