<template>
  <div class="pininput-wrapper" :class="wrapperClasses">
    <input
      id="otc-1" ref="inputVal" class="pininput" type="tel"
      v-model="inputVal"
      @focus="$event.target.select()"
      @paste="handlePaste($event, 0), updateCaretPosition('#otc-1')"
      @input="onChange()"
      @keyup="keyup($event, 0), updateCaretPosition('#otc-1')"
      :class="classes"
      :minlength="inputs"
      :maxlength="inputs"
      autocomplete="one-time-code"
      name="inputVal"
      inputtype="numeric"
      pattern="[0-9]*"
      data-index="0">
      <!--@keydown="keydown($event)"-->
  </div>
</template>
<!--
  id="token" => is used for copy paste access on phone etc
  ref="inputVal" => is used for focus
  @paste="handlePaste.." => adds paste behaviour
  @paste.prevent => remove default paste behaviour
  @focus="$event.target.select()" => adds select all text when clicking the input
-->

<script>
export default {
  name: 'aPinInput',
  components: {},
  props: {
    /*value: {
      type: Number,
      required: false,
      default: undefined,
    },*/
    autoFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputs: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  data() {
    return {
      inputVal: undefined,
      canInput: true,
      caretPosition: 0,
    };
  },
  methods: {
    onChange() {
      this.emitChange();
    },
    /*keydown(event) {
      console.log(event.target.value)
      if (event.target && event.target.value && event.target.value.length+1 > this.inputs) {
        event.preventDefault();
      }
    },*/
    keyup(event) {
      if (event && (event.key === 'Backspace' || event.key === 'Delete')) {
        this.$emit('incomplete');
        return;
      }
    },
    handlePaste(e) {
      if (e) {
        let clipboardData = e.clipboardData || (e.originalEvent ? e.originalEvent.clipboardData : null) || window.clipboardData;
        if (clipboardData) {
          let data = e.clipboardData.getData('text');

          this.inputVal = data;

          this.emitChange();
          this.$forceUpdate();
          setTimeout(() => {
            this.canInput = true;
          }, 200);
        }
      }
    },
    emitChange() {
      let complete = true;
      const value = this.inputVal.toString();

      if (value === undefined || value === 'undefined' || value.length < this.inputs) {
        complete = false;
      }

      if (complete) {
        this.$emit('complete', value);
      } else {
        this.$emit('incomplete');
      }
    },
    updateCaretPosition(el) {
      let element = document.querySelector(el);
      if (element && element.value && element.value.length < this.inputs) {
        this.caretPosition = element.selectionStart;
      } else {
        this.caretPosition = element.selectionStart - 1;
      }
    }
  },
  computed: {
    wrapperClasses() {
      return {
        'inputs-6': this.inputs == 6,
        'inputs-5': this.inputs == 5,
        'inputs-4': this.inputs == 4,
      };
    },
    classes() {
      return {
        // 'caret-show': this.caretPosition == this.inputs-1,
        'caret-hide': this.caretPosition >= this.inputs-1,
      };
    },
  },
  created() {
    if (this.autoFocus) {
      this.$nextTick(() => this.$refs[`inputVal`].focus());
    }
    document.addEventListener('paste', this.handlePaste(event));
  },
  destroyed() {
    document.removeEventListener('paste', this.handlePaste(event));
  },
};
</script>

<style lang="scss">
.inputs-6.pininput-wrapper {
  --inputs: 6;
  --offset: 2px;
}

.inputs-5.pininput-wrapper {
  --inputs: 5;
  --offset: 1px;
}

.inputs-4.pininput-wrapper {
  --inputs: 4;
  --offset: 0px;
}

.pininput-wrapper {
  --height: 45px;
  --spacing: 29px;
  --font-size: 32px;
  // Special styling for smaller phones
  @media (max-width: $mobile-small-maxwidth) {
    --height: 38px;
    --spacing: 25px;
    --font-size: 26px;
  }
  --width: calc((var(--height) * var(--inputs)) + var(--spacing)/2);
}

.pininput-wrapper {
  display: inline-block;
  position: relative;
  width: var(--width);
  height: var(--height);
  padding: 0;
  margin: 0;
  background-image: url('data:image/svg+xml,<svg viewBox="-1 -1 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="45" height="45" rx="6" fill="white" stroke="black"/></svg>');
  background-size: var(--height) var(--height), var(--height) var(--height);
  background-repeat: round;
  font-family: var(--medium-font-family);
  font-size: var(--font-size);
  line-height: var(--font-size);

  .pininput {
    height: var(--height);
    padding: 0;
    padding-left: 0px;
    padding-bottom: 4px;
    // inputs will change the total width, and also more/less border of each icon, ie offset
    margin-left: calc(var(--spacing) / 2 - var(--offset));
    letter-spacing: var(--spacing);
    background-color: transparent;
    border: 0;
    outline: none;
    width: calc(var(--width) + var(--spacing));
    font-feature-settings: 'tnum';
  }

  .caret-hide {
    caret-color: transparent;
  }

  .caret-show {
    caret-color: unset;
  }
}

/* Chrome, Safari, Edge, Opera */
.pininput::-webkit-outer-spin-button,
.pininput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pininput[type='number'] {
  -moz-appearance: textfield;
}

// Used to remove autofill automatic style
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}
</style>