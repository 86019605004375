<template>
  <div
    class="dashboard-activation-step-container"
    :class="{ 'dashboard-activation-step-container-clickable': clickable }"
  >
    <ActivationStepIcon class="dashboard-activation-step-icon" :src="icon" @click="handleClick" />
    <div class="dashboard-activation-step-info">
      <ActivationStepLabel class="dashboard-activation-step-title" :text="title" />
    </div>
  </div>
</template>

<script>
import ActivationStepIcon from '../ActivationStep/ActivationStepIcon.vue';
import ActivationStepLabel from '../ActivationStep/ActivationStepLabel.vue';

export default {
  name: 'DashboardActivationStep',
  components: { ActivationStepIcon, ActivationStepLabel },
  props: {
    icon: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      if (this.clickable) {
        this.$emit('click');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$icon-width: 77px;
$icon-height: 79px;

.dashboard-activation-step-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: $icon-width;

  .dashboard-activation-step-info {
    .dashboard-activation-step-title {
      padding: 0;
      font-size: 14px;
      line-height: 19.6px;
      color: #666666;
    }
  }
}

.dashboard-activation-step-container-clickable {
  cursor: pointer;
}
</style>
