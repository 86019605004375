<template>
  <div 
  v-if="loadedPage"
  class="admin-entity-content--container">
    <links-manager
      :entity="offer"
      entityType="Offer"
      :admin="true"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import LinksManager from '../stories/components/LinksManager/LinksManager.vue';

export default {
  name: 'AdminLinksmanagerOffer',
  components: { LinksManager },
  props: ['offerId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    // när komponenten precis startas
    // hämta företaget från backend (och databasen)
    this.loadPage();
    EventBus.$emit('newAdmin', true);
  },
  computed: {
      ...mapGetters([
      "offer",
    ])
    // uträknade variabler
  },
  data() {
    return {
      loadedPage: false,
    }
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminOffer', { entityId: this.offerId }).then(() => {
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Links manager activation');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/AdminLinksManagerOffer";
</style>