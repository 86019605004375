var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collection-view-container",class:{ 'collection-view-container-scroll': this.scrolling }},[(_vm.collection && _vm.collection._id && !_vm.collection.company.signup)?_c('div',{class:{ 'iris': _vm.collection.theme !== 'nemo', 'nemo': _vm.collection.theme === 'nemo'}},[_c('div',{class:{
        'collection-view': !_vm.preview,
        'collection-view-contained': _vm.preview,
        'company-page': _vm.isCompany,
      },style:(_vm.theBackgroundStyle)},[_c('div',{staticClass:"header-image",class:{'blurry': !_vm.collection.company.disable_blurry_header},style:(_vm.theHeaderStyle)}),_c('div',{staticClass:"collection-content-wrapper content-page"},[(!_vm.isCompany && !_vm.collection.replace_company)?_c('div',{staticClass:"collection-left-button",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('div',{staticClass:"collection-back-button"},[_c('iconComponent',{attrs:{"symbol":"arrowLeft","fill":"black","stroke":"black","strokeWidth":2}})],1)]):_vm._e(),(_vm.authenticated && _vm.collection.company.club)?_c('div',{staticClass:"collection-club-button",on:{"click":function($event){return _vm.gotoClubProfile()}}},[_c('div',{staticClass:"collection-club-icon"},[_c('iconComponent',{attrs:{"symbol":"person","fill":"white","stroke":"white"}})],1)]):_vm._e(),(_vm.theIcon)?_c('img',{staticClass:"header-logo",class:{
            'clickable': !_vm.isCompany
          },attrs:{"src":_vm.theIcon},on:{"click":function($event){return _vm.gotoCompany(_vm.collection)}}}):_vm._e(),(_vm.showHeader === false)?_vm._t("headerTitle"):_vm._e(),(_vm.showHeader)?_c('h2',{staticClass:"collection-header"},[_vm._v(_vm._s(_vm.collection.name))]):_vm._e(),(_vm.showHeader)?_c('div',{staticClass:"header-info body-1"},[_vm._v(_vm._s(_vm.collection.info))]):_vm._e(),(!_vm.currentOffers.length)?_c('div',{staticClass:"no-content-header"},[(_vm.collection.theme === 'iris')?_c('div',{staticClass:"no-content-header-our-activations"},[_c('iconComponent',{attrs:{"symbol":"rocket"}}),_vm._v(" "+_vm._s(_vm.transl.Goodiebag.ouractivations)+" ")],1):_vm._e(),_c('div',{staticClass:"no-content-header-soon"},[_vm._v(_vm._s(_vm.transl.Company.soon))])]):_vm._e(),(_vm.$slots.content)?_vm._t("content"):_vm._e(),(!_vm.$slots.content && _vm.currentOffers && _vm.currentOffers.length)?_c('CardWrapper',{attrs:{"data":_vm.currentOffers,"theme":_vm.collection.theme,"secondary-data":_vm.closedOffers,"start-countdown":_vm.startCountdown,"preview":_vm.preview},on:{"cardClick":function($event){return _vm.gotoCurrentEntity($event)}}}):_vm._e()],2)]),(_vm.collection.company.legal_name && _vm.showIntegrity)?_c('integrityBox',{attrs:{"badgeType":"Integrity_badge_Medium","textData":"Har du frågor om GDPR till varumärket? Se kontaktinformation nedan.","expandable":true,"labelList":[
        _vm.collection.company.legal_name,
        _vm.collection.company.email,
        _vm.collection.company.org_number,
        _vm.collection.company.address + ', ' + _vm.collection.company.postal_code + ', ' + _vm.collection.company.city
      ],"iconList":['building', 'email', 'paper', 'mapPin']}}):_vm._e()],1):_vm._e(),(_vm.collection.signupView || _vm.collection.startView)?_c('div',[_c('SignupView',{attrs:{"data":_vm.collection}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }