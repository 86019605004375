<template>
  <div class="modal-component" :class="containerClasses" :style="computedCss">
    <div class="modal-overlay" @click="overlay.clickable ? $emit('close') : ''"></div>

    <transition appear name="fade">
      <div class="modal-container">

        <div v-if="hasHeader" class="modal-header" id="modalHeader">
          <div class="-text">
            <h3 class="body-1">{{ header.text }}</h3>
          </div>

          <div class="-close-btn" @click="$emit('close')" v-if="header.closeButton">
            <iconComponent symbol="cross" />
          </div>
        </div>

        <div class="modal-content" id="modalContent" :style="computedContentStyle"
          :class="overflowClass"
          @scroll="checkScroll($event, ['modalContent','modalHeader'])">

          <transition v-if="slotTransName !== ''" :name="slotTransName" :appear="slotTransAppear" :mode="slotTransMode">
            <slot></slot>
          </transition>

          <slot v-if="slotTransName === ''"></slot>
        </div>

        <div v-if="hasFooter" class="modal-footer" :class="footerClasses">
          <div v-for="(btn, i, index) in footer.btnText" :key="index" class="btn"
            :class="footer.btnType ? footer.btnType[i] : ''" @click="footer.btnActions ? footer.btnActions[i] : ''">
            {{ btn }}
          </div>
        </div>
        <!--<div class="modal-footer">
          <slot name="footer"></slot>
        </div>-->
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, PropType } from 'vue'
import { FooterInterface, HeaderInterface, OverlayInterface } from './Interfaces';
import { isSafari, isMobile } from 'mobile-device-detect';
import EventBus from '@/resources/eventBus';

/**
 * 
 * 
 * <modal v-if="internalLinkModal" class="modal" @close="internalLinkModal = false"
               :header="{text: offer.first_header, textPosition: 'center'}"
               slotTransName="slot" slotTransMode="out-in"
               :slotTransAppear="true" modalType="bottom">
 * 
 * 
 * https://vuejs.org/guide/components/props.html#prop-validation
 * 
 * <!-- equivalent of passing :disabled="true" -->
<MyComponent disabled />

<!-- equivalent of passing :disabled="false" -->
<MyComponent />        
 */

export default defineComponent({
  name: 'ModalComponent',
  components: {},
  data() {
    return {
      show: false,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      hasOverflow: false,
    };
  },
  props: {
    modalType: {
      type: String,
      // type: String as PropType<string>,
      required: false,
      default: 'fullscreen',
    },
    logo: {
      // type: Boolean as PropType<boolean>,
      type: Boolean,
      required: false,
      default: false,
    },
    terms: {
      // type: Boolean as PropType<boolean>,
      type: Boolean,
      required: false,
      default: false,
    },
    header: {
      // type: Object as PropType<HeaderInterface>,
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    footer: {
      // type: Object as PropType<FooterInterface>,
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    overlay: {
      // type: Object as PropType<OverlayInterface>,
      type: Object,
      required: false,
      default() {
        return {
          show: true,
          clickable: true,
        }
      }
    },
    slotTransName: {
      // type: String as PropType<string>,
      type: String,
      required: false,
      default: '',
    },
    slotTransAppear: {
      // type: Boolean as PropType<boolean>,
      type: Boolean,
      required: false,
      default: false,
    },
    slotTransMode: {
      // type: String as PropType<string>,
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: [String, Object],
      // type: [String, Object] as PropType<string | { width?: number, height?: number }>,
      required: false,
      default: '',
    },
    padding: {
      // type: String as PropType<string>,
      type: String,
        required: false,
        default: ''
    }
  },
  computed: {
    // containerClasses(): Record<string, unknown> {
    containerClasses() {
      return {
        '--fullscreen': this.modalType === 'fullscreen',
        '--bottom': this.modalType === 'bottom',
        '--center': this.modalType === 'center',
        '--has-header': this.hasHeader || this.hasLogo,
        '--has-footer': this.hasFooter,
        '--has-footer-slot': this.hasFooter && this.footer.slot,
        '--has-cross': this.header.closeButton !== undefined,
        '--has-logo': this.hasLogo,
        '--small': this.size === 'small',
        '--medium': this.size === 'medium',
        '--large': this.size === 'large',
        '--xlarge': this.size === 'xlarge',
        '--safari': isSafari,
      };
    },
    // computedCss(): Record<string, unknown> {
    computedCss() {
      return {
        '--header-bg-color':
          typeof this.header.bgColor === 'undefined'
            ? 'white'
            : this.header.bgColor,
        '--text-align':
          typeof this.header.textPosition === 'undefined'
            ? 'left'
            : this.header.textPosition,
        '--footer-btn-text-color':
          typeof this.footer.btnTextColor === 'undefined'
            ? 'black'
            : this.footer.btnTextColor,
        '--footer-btn-bg-color':
          typeof this.footer.btnBgColor === 'undefined'
            ? ''
            : this.footer.btnBgColor,
        '--footer-bg':
          typeof this.footer.bgColor === 'undefined'
            ? 'white'
            : this.footer.bgColor,
        '--footer-btn-position':
          this.footer.btnPosition === 'left'
            ? 'flex-start'
            : this.footer.btnPosition === 'center'
              ? 'center'
              : this.footer.btnPosition === 'right'
                ? 'flex-end'
                : '',
        '--modal-max-height': this.height,
        '--modal-padding': !this.padding ? '0 20px 20px' : this.padding,
      };
    },
    // footerClasses(): Record<string, unknown> {
    footerClasses() {
      return {
        '--left': this.footer.btnPosition === 'left',
        '--center': this.footer.btnPosition === 'center',
        '--right': this.footer.btnPosition === 'right',
        '--btn-custom-bg': this.footer.btnBgColor,
        '--btn-custom-txt-color': this.footer.btnTextColor !== undefined,
      };
    },
    // modalSize(): Record<string, unknown> {
    modalSize() {
      if (typeof this.size?.height === 'number') {
        return {
          maxHeight: `${Math.min(this.height, this.size.height)}px !important`
        }
      } else if (this.logo) {
        return {
          maxHeight: `calc(${this.height}px - $nav-height - 200px) !important`
        }
      } else if (this.hasHeader) {
        return {
          maxHeight: `calc(${this.height}px - $nav-height - 140px) !important`
        }
      } else {
        return {
          maxHeight: `calc(${this.height}px - $nav-height - 60px) !important`
        }
      }
    },
    // computedContentStyle(): Record<string, unknown> {
    computedContentStyle() {
      return {
        'max-height': this.modalSize.maxHeight,
        ...(typeof this.size?.height === 'number' && {
          'height': `${this.size.height}px !important`
        }),
        ...(typeof this.size?.width === 'number' && {
          'width': `${this.size.width}px !important`,
          'max-width': `${this.size.width}px !important`
        })
      };
    },
    // hasLogo(): boolean {
    hasLogo() {
      return !!this.logo;
    },
    // hasHeader(): boolean {
    hasHeader() {
      if (this.hasLogo || Object.keys(this.header).length !== 0)  {
        return true;
      } else {
        return false;
      }
    },
    // hasFooter(): boolean {
    hasFooter() {
      return Object.keys(this.footer).length !== 0;
    },
    // hasTerms(): boolean {
    hasTerms() {
      return this.terms;
    },
    // overflowClass(): Record<string, unknown> {
    overflowClass() {
      return {
        'show-indicator': this.hasOverflow && !this.hasLogo && !this.hasTerms,
      }
    }
  },
  methods: {
    // checkScroll(e: { target: HTMLDivElement }, targ: Array<string>) {
    checkScroll(e, targ) {
      if(isMobile) {
        for (let index = 0; index < targ.length; index++) {
          const element = document.getElementById(targ[index]);
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          let maxScroll = scrollHeight - clientHeight;
          const scrollOffset = 25;
  
          if (scrollTop > 1 && (element?.className?.includes("header"))) {
            element.classList.add('--scrolled');
          } else if (element && element.className.includes("content") && scrollTop > (maxScroll - scrollOffset)) {
            element.classList.add('hide-indicator');
            element.classList.remove('show-indicator');
          } else if (element && element.className.includes("content") && scrollTop < (maxScroll - scrollOffset)) {
            element.classList.add('show-indicator');
            element.classList.remove('hide-indicator');
          } else if (element) {
            element.classList.remove('--scrolled');
          }
        }
      }
    },
    // async checkOverflow(targ: string) {
    async checkOverflow(targ) {
      await this.$nextTick(() => {
        const element = document.getElementById(targ);
        // const { scrollTop } = element!;
        const { scrollTop } = element || {};
        if (isMobile && element && scrollTop !== 100) {
          this.hasOverflow = element.scrollHeight > element.clientHeight;
        } else {
          this.hasOverflow = false;
        }
      });
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    /* This code breaks the build
    showLastModal() {
      const modals = <HTMLCollection>document.getElementsByClassName('modal-component');
      for (let i = 0; i < modals.length; i++) {
        const modal = modals[i];
        if (i == modals.length - 1) {
          (modal as HTMLElement).style.display = "block";
        } else {
          (modal as HTMLElement).style.display = "none";
        }
      }
    },*/
    showLastModal() {
      const modals = document.getElementsByClassName('modal-component');
      const modalsLength = modals.length;
      for (let i = 0; i < modalsLength; i++) {
        const modal = modals[i];
        if (i == modalsLength - 1) {
          // eslint-disable-next-line
          // @ts-ignore
          modal.style.display = "block";
        } else {
          // eslint-disable-next-line
          // @ts-ignore
          modal.style.display = "none";
        }
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);
    EventBus.$emit('mountedModal');

    EventBus.$on('mountedModal', () => {
      this.showLastModal();
    });

    EventBus.$on('destroyedModal', () => {
      this.showLastModal();
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  created() {
    this.checkOverflow('modalContent');
    document.body.style.overflow = "hidden";
    if (isSafari) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.position = "relative";
      document.documentElement.style.height = "100%";
    }
  },
  updated() {
    this.checkOverflow('modalContent');
  },
  destroyed() {
    document.body.style.overflow = "";
    if (isSafari) {
      document.documentElement.style.overflow = "";
      document.documentElement.style.position = "";
      document.documentElement.style.height = "";
    }
    EventBus.$emit('destroyedModal');
  }
});
</script>

<style lang="scss" scoped>
@import "./Modal.scss";
</style>