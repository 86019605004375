<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="offer.first_header"
      entity-type="Offer"
      :entity="offer"
      :attach="attach"
      :breadcrumb="goodiebag._id"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep as _cloneDeep } from 'lodash-es';
import EventBus from "../resources/eventBus";
// import PreviewOffer from "../components/PreviewOffer";
// import aUpload from '../components/AUpload';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "CompanyOffer",
  components: {
    EntityEditor
    // PreviewOffer,
    // aUpload,
  },
  props: ['offerId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
  computed: {
    ...mapGetters(["offer", "goodiebag", "userStatus"]),
  },
  watch: {
    offerId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      showPreviewModal: false,
      attach: [],
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getCompanyOffer', { entityId: this.offerId }).then(() => {
        this.$store.dispatch('getCompanyGoodiebag', { entityId: this.offer.goodiebag._id }).then(() => {
          this.setAttachments();
        });
      });
    },
    setAttachments() {
      this.attach = [
        {
          key: 'goodiebag',
          value: {
            text: this.goodiebag.name,
            value: this.goodiebag._id,
          },
        },
        {
          key: 'company',
          value: {
            text: this.goodiebag.company.name,
            value: this.goodiebag.company._id,
          },
        }
      ];
      this.loadedPage = true;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/CompanyOffer";
</style>
