<template>
  <div class="footer">
    <div class="flexbox-list">
      <div class="list-item">
        <div class="icon">
          <iconComponent symbol="headset" width="24"/>
        </div>
        <div class="text link-text"
          @click="$emit('openSupportModal')">
          {{ transl.App.support }}
        </div>
      </div>
      <div class="list-item">
        <div class="icon">
          <iconComponent symbol="info" width="24"/>
        </div>

        <div class="text link-text" @click="$router.push({ name: 'UserClubAbout' })">
          <a>{{ transl.Home.aboutonly }} {{ company.name }}</a>
        </div>
      </div>
      <div class="list-item">
        <div class="icon">
          <iconComponent symbol="gear" width="24"/>
        </div>

        <div class="text link-text"><a :href="`${windowOrigin}/u-set`">{{ transl.App.settings }}</a></div>
      </div>
      <div class="list-item center-mobile">
        <div class="icon">

          <img src="../assets/images/DP_Icon_Blk.png" width="24px">
        </div>
        <div class="text link-text"><a :href="`${windowOrigin}/u-p`">{{ transl.UserProfile.myenginio }}</a></div>
      </div>

    </div>
    <div class="flexbox-list enginio-information light">
      <div class="list-item">
        <div class="text">Powered by</div>
      </div>
      <div class="list-item">
        <div class="text">© | Enginio {{ currentYear }}</div>
      </div>
      <div class="list-item">
        <div class="text">version {{ serverVersion }}</div>
      </div>
    </div>
    <div class="flexbox-list enginio-information bold">
      <div class="list-item clickable"
        @click="$router.push({ name: 'HomeData' })">
        <div class="text bold">{{ transl.HomeInfo.integrity }}</div>
      </div>
      <div class="list-item clickable"
        @click="$router.push({ name: 'HomeInfo' })">
        <div class="text bold">{{ transl.App.about }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserClubProfileFooter',
  components: {},
  props: {
    serverVersion: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'company',
    ]),
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserProfile";


.center-mobile{
  @media (max-width: 812px) {
    justify-content: center;
  }
 
}

.flexbox-list {
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}


.enginio-information {
  color: #666;

  font-family: var(--regular-font-family);
  font-size: 12px;

  line-height: normal;


  .list-item {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
}

.icon {
  width: 30px;
  margin-right: 10px;
}

.icon i {
  font-size: 24px;
}

.text {
  font-size: 14px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.link-text {
  color: $brand-yellow;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
