<template>
  <div 
  v-if="loadedPage"
  class="admin-entity-content--container">
    <links-manager
      :entity="goodiebag"
      entityType="Goodiebag"
      :admin="true"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import LinksManager from '../stories/components/LinksManager/LinksManager.vue';

export default {
  name: 'AdminLinksmanagerGoodiebag',
  components: { LinksManager },
  props: ['goodiebagId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    // när komponenten precis startas
    // hämta företaget från backend (och databasen)
    this.loadPage();
    EventBus.$emit('newAdmin', true);
  },
  computed: {
    ...mapGetters([
      "goodiebag",
    ])
    // uträknade variabler
  },
  data() {
    return {
      loadedPage: false,
    }
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminGoodiebag', { entityId: this.goodiebagId }).then(() => {
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Links manager collection');
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/AdminLinksManagerGoodiebag";
</style>