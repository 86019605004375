<template>
  <div class="content-table">
    <table>
      <tr v-for="(row, index) in table" :key="index">
        <td v-for="(cell, index) in row" :key="index">
          {{ cell }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ContentTable',
  props: {
    table: {
      type: Array,
      required: true,
    },
  }
}
</script>

<style scoped>
@import './Content.css';
</style>