<template>
  <div
    v-if="this.activation && this.collection"
    :class="{ 'activation-view': !preview, 'activation-view-contained': preview }">
    <div v-if="loadedPage && activation && iframeWebsiteUrl" class="iframe-activation">
      <iframe :src="iframeWebsiteUrl" allow="camera;"></iframe>
    </div>

    <div
        v-else-if="activation && collection && loadedPage && (expiresAtState !== 'expired' || expiredOffer || activation.closed)"
        class="user-activation-wrapper">
      <div class="user-activation">
        <div class="main-activation">
          <div v-if="activation.media?.length === 1 && activation.media[0].fileType.includes('image')"
              class="activation-header-image activation-single-image">
            <img :src="activation.media[0].url" alt="">

            <div v-if="activation.jwt_lock" class="activation-jwt-lock">
              <iconComponent symbol="icons8Lock" width="337px" :stroke-width="0" fill="#FCEFE1" />
            </div>

            <div v-if="activation.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="activation.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />

            <img v-if="activation.icon || collection.icon"
                :src="`${!activation.unique_icon && collection.icon ? collection.icon : activation.icon}`"
                class="activation-header-logo"
                @click="clickCollection(collection)" />
          </div>

          <video v-else-if="activation.media && activation.media.length === 1 && activation.media[0].fileType.includes('video')"
                class="news-autoplay activation-header-image autoplayMedia" controls muted playsinline>
            <div v-if="activation.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="activation.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />

            <source :src="`${activation.media && activation.media[0] ? activation.media[0].url : '' }`"
                    :type="activation.media[0].fileType">
            {{ transl.Offer.novideo }}
          </video>

          <div v-else-if="activation.media && activation.media.length > 1" class="activation-header-image carousel">
            <aCarousel :media-list="activation.media" :single-image="true" />

            <img v-if="activation.icon || collection.icon"
                :src="`${!activation.unique_icon && collection?.icon ? collection.icon?.url : activation.icon?.url}`"
                class="activation-header-logo"
                @click="clickCollection(collection)" />

            <div v-if="activation.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                {{ transl.Offer.closed }}
              </md-chip>
            </div>

            <iconComponent
                          v-if="activation.terms_text"
                          symbol="info"
                          @click="tocModal = true"
                          style="cursor: pointer;" />
          </div>

          <iframe v-else-if="activation.iframe_media && activation.iframe_media.length"
                  class="activation-header-image activation-header-iframe"
                  :src="`https://player.vimeo.com/video/${activation.iframe_media[0].id}`" frameborder="0"
                  allow="autoplay; fullscreen" allowfullscreen>
          </iframe>

          <div class="activation-content-wrapper content-page">
            <div class="activation-fast-icons">
              <div v-if="activation.variations" class="activation-fast-text">
                <iconComponent symbol="style" />
                <span>{{ transl.Offer.variants }}</span>
              </div>

              <div v-else-if="activation.save_text" class="activation-fast-text">
                <iconComponent symbol="newReleases" />
                <span>{{ activation.save_text }}</span>
              </div>

              <div v-else class="activation-fast-text">
                <span style="padding: 0;">{{ activation.quick_text }}</span>
              </div>

              <div v-if="expiresDate" class="activation-fast-text-two">
                <iconComponent symbol="calendarToday" :width="20" />
                <span>{{ expiresDate }}</span>
              </div>

              <!--<div
                v-if="!activation.hide_text_box"
                class="activation-fast-text-two">
                <iconComponent symbol="calendarToday"/>
                <span>Kommer inom kort!</span>
              </div>-->

              <div class="activation-fast-icons-actions">
                <div class="btn-icon-text btn-description activation-fast-share" v-if="!activation.closed"
                    @click="setLoginView">
                  <iconComponent symbol="heart" />
                  <!--<div class="md-description">{{ transl.Offer.save }}</div>-->
                </div>

                <div class="btn-icon-text btn-description activation-fast-share" v-if="activation.share && !activation.closed"
                    v-clipboard:copy="currentUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
                  <iconComponent symbol="share" />
                  <!--<div class="md-description">{{ transl.Offer.share }}</div>-->
                </div>

                <!--<input
                  id="share-link"
                  :value="currentUrl"
                  type="hidden">-->
              </div>
            </div>

            <div class="activation-header">{{ activation.first_header }}</div>
            <div class="activation-header-two" style="margin-bottom: 1.5rem;">
              <span v-html="activation.second_header"></span>
            </div>

            <div class="activation-survey" v-if="answeredSurvey">
              <iconComponent symbol="festival" :width="150" :stroke-width="0" fill="#EA7801" />

              <div style="padding-top: 1rem;">
                {{ transl.Offer.thanks }}
                <br />
                {{ transl.Offer.thanks2 }}
              </div>
            </div>

            <!-- <div class="activation-survey"
                v-else-if="!activation.must_login && activation.survey && activation.survey.length && !afterNow(activation.starts_at)"> -->
            <div class="activation-survey"
                v-else-if="activation.survey && activation.survey.length">
              <div v-for="(question, index) in activation.survey" :key="question.label"
                  class="activation-survey-question">

                <label class="activation-survey-label subtitle-small"
                      :for="question.label">{{ index + 1 }}. {{ question.label }}</label>

                <div class="activation-survey-radio-wrapper"
                    v-if="question.options && question.options.length && question.radio">
                  <md-radio v-for="option in question.options" :key="option" :value="option" :name="question.model"
                            v-model="surveyAnswers[question.model]" class="md-primary">{{ option }}</md-radio>
                  <!--<md-radio v-if="question.other" key="other" :value="surveyOther[question.model]" :id="`${question.model}other`" :name="question.model"
                            @change="setRadio(question, 'other')" class="md-primary">
                    <InputField
                          :label="transl.Offer.other" :outlined="false" :filled="true"
                          @input="setRadio(question, 'other', true)"
                          v-model="surveyOther[question.model]"/>
                  </md-radio>-->
                </div>

                <div class="activation-survey-checkbox-wrapper"
                    v-else-if="question.options && question.options.length && question.checkbox">
                  <div v-for="option in question.options" :key="option">
                    <input type="checkbox" class="activation-survey-checkbox" :value="option" :id="question.model"
                          @change="setCheckbox(question, option)" />
                    <span style="margin: 0 16px;">{{ option }}</span>
                  </div>
                  <div v-if="question.other" class="activation-survey-checkbox-other">
                    <input type="checkbox" class="activation-survey-checkbox" :id="`${question.model}other`"
                          @change="setCheckbox(question, 'other', surveyOther[question.model])"/>
                    <InputField
                        :label="transl.Offer.other" :outlined="false" :filled="true"
                        @input="setCheckbox(question, 'other', $event, true)"
                        style="margin-left: 16px; width: 93%;"
                        v-model="surveyOther[question.model]"/>
                  </div>
                </div>

                <div v-else-if="question.options && question.options.length && question.slider"
                    style="padding: 1rem 0 3.5rem 0;">
                  <img v-if="question.slider_options.media && question.slider_options.media.length"
                      :src="question.slider_options.media[question.options.indexOf(surveyAnswers[question.model] || question.options[0])].file"
                      style="padding-bottom: 1rem" />
                  <VueSlider
                    v-model="surveyAnswers[question.model]"
                    v-bind="question.slider_options.options"
                    :data="question.options">
                  </VueSlider>
                </div>

                <md-field v-else-if="question.options && question.options.length">
                  <md-select v-model="surveyAnswers[question.model]" :name="question.label"
                            :id="question.label">
                    <md-option v-for="option in question.options" :key="option" :value="option">{{ option }}
                    </md-option>
                  </md-select>
                </md-field>

                <span v-else-if="question.checkbox" class="activation-survey-checkbox-wrapper">
                  <input type="checkbox" :id="question.label"
                        v-model="surveyAnswers[question.model]">{{ question.label }}
                </span>

                <md-datepicker v-else-if="question.date" v-model="surveyAnswers[question.model]">
                </md-datepicker>

                <!-- ToDo: Replace with better solution -->
                <Uploader v-else-if="question.upload && activation.must_login"
                          role="UserSurvey"
                          entity="Offer"
                          mediaType="Picture"
                          :entityId="activation._id"
                          :keepFeFile="true"
                          :aspectHeight="NaN"
                          :aspectWidth="NaN"
                          @pickedFile="surveyAnswers[question.model] = 'there is a file'">
                </Uploader>

                <div v-else :required="question.required">
                  <!--:class="{ 'incorrect-input' : surveyAnswers[question.model] !== '' && question.validator && !validator[question.validator](surveyAnswers[question.model]) }"-->
                  <InputField v-if="!question.checkbox || !question.radio || !question.slider || !question.date || !question.textarea"
                              label="" :outlined="true" :filled="false"
                              v-model="surveyAnswers[question.model]" />
                  <InputField v-if="question.textarea" inputType="textarea" placeholder="" label=""
                              v-model="surveyAnswers[question.model]" />
                  <span class="md-helper-text the-issue">{{ question.helper }}</span>
                </div>
              </div>
            </div>

            <!--<div
              class="activation-survey"
              v-if="activation.survey && activation.survey.length && !afterNow(activation.starts_at)">
              <div
                v-for="question in activation.survey"
                :key="question.label">
                <label
                  class="activation-survey-label"
                  style="margin-bottom: 1.5rem"
                  :for="question.label">{{ question.label }}</label>
              </div>
            </div>-->

            <div class="btn btn-activate btn-fixed"
                v-if="activation.external_link && !expiredOffer && !activation.jwt_lock && !activation.must_login && !preview"
                @click="clickExternalLink(activation.external_link)">
              {{ activation.link_button_title || transl.Offer.gotooffer }}
            </div>

            <div v-else-if="((activation.links_gallery && activation.links_gallery.links && activation.links_gallery.links.length) || activation.pdf)
                && !expiredOffer && !activation.jwt_lock && !activation.must_login && !preview" class="btn btn-activate btn-fixed"
                @click="internalLinkModal = true">
              {{ activation.link_button_title }}
            </div>

            <div v-else-if="!answeredSurvey && !activation.must_login && activation.survey && activation.survey.length && !preview"
                class="btn btn-activate btn-fixed" @click="activateInternalOffer()">
              {{ activation.link_button_title }}
            </div>

            <div class="btn btn-activate btn-fixed" v-else-if="(activation.closed || answeredSurvey) && !preview"
                @click="clickGoBack">
              {{ backToText }}
            </div>

            <div class="btn btn-activate btn-fixed" v-else-if="hasCTA && !preview" @click="setLoginView">
              <!--internalLinkModal = true-->
              {{ activation.link_button_title || transl.Offer.gotooffer }}
            </div>
          </div>

          <modal v-if="internalLinkModal" class="modal" @close="internalLinkModal = false"
                :header="{text: activation.first_header, textPosition: 'center', bgColor: '#F2F2F2'}"
                slotTransName="slot" slotTransMode="out-in"
                :slotTransAppear="true" modalType="bottom">
            <slot>
                <div v-if="activation.closed">
                  <div class="competition-info">
                    <div class="internal-link-title-2">
                      {{ transl.Offer.expired }}
                    </div>

                    <div class="internal-link-title-4" style="text-align: center;">
                      {{ transl.Offer.keepeye }}
                    </div>

                    <div class="btn btn-activate" @click="clickGoBack">
                      {{ backToText }}
                    </div>
                  </div>
                </div>

                <div v-else-if="activation.pdf">
                  <vuePdf :page="pageNr" :src="`${windowOrigin}/api/nar/file/${activation.pdf}`"
                          @num-pages="pageCount = $event">
                  </vuePdf>

                  <div class="pdf-viewer-buttons">
                    <div class="btn btn-activate" @click="changePage(pageNr-1)">
                      <iconComponent symbol="arrowLeft" :width="30" />
                    </div>

                    <div class="btn btn-activate" @click="changePage(pageNr+1)">
                      <iconComponent symbol="arrowRight" :width="30" />
                    </div>

                    <!-- ToDo: Refaktoreras? -->
                    <div class="btn btn-activate" @click="openUrl(`${windowOrigin}/api/nar/file/${activation.pdf}`, true)">
                      <iconComponent symbol="magnifyingGlass" :width="30" />
                    </div>
                  </div>
                </div>

                <div v-else-if="activation.links_gallery && activation.links_gallery.links && activation.links_gallery.links.length">
                  <div class="competition-info btn-fixed-bg">
                    <div class="internal-link-title-2 selectable-text align-left" v-html="activation.links_gallery.label">
                    </div>

                    <div class="modal-external-links">
                      <div class="modal-external-link"
                        v-for="(hyperlink, index) in activation.links_gallery.hyperlinks"
                        :key="hyperlink._id">
                        <img
                          :src="hyperlink.media"
                          @click="clickExternalLink(activation.links_gallery.links[index])" />
                      </div>
                    </div>

                    <div class="btn btn-activate btn-fixed" @click="internalLinkModal = false">
                      {{ transl.Offer.back }}
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div class="competition-info">
                    <iconComponent class="svg-icon" symbol="gift"/>


                    <div class="internal-link-title-2">
                      {{ transl.Offer.ops }}
                    </div>

                    <div class="internal-link-title-4" style="text-align: center;">
                      {{ transl.Offer.loginto }}
                      <div v-if="!isMobile && activation.clearon_id">
                        {{ transl.Offer.usedata }}
                      </div>
                    </div>

                    <div v-if="!activation.winner_adress && !activation.recurring_time" class="internal-link-title-1"
                        style="padding: 1rem;">
                      {{ transl.Offer.enterenginio }}
                    </div>

                    <div class="btn btn-activate btn-fixed" @click="setLoginView">
                      {{ transl.Offer.login }}
                    </div>
                  </div>
                </div>
            </slot>
          </modal>
        </div>

        <div v-if="sideOffers && sideOffers.length" class="sideoffers-header-mobile">
          {{ transl.Offer.moreoffers }}
          <br />
          {{ transl.Offer.from }} {{ collection.company.name }}
        </div>

        <div class="sideoffers-wrapper" v-if="sideOffers && sideOffers.length">
          <div class="sideoffers-header">
            {{ transl.Offer.moreoffers }}
            <br />
            {{ transl.Offer.from }} {{ collection.company.name }}
          </div>

          <div class="sideoffer-box" :class="{ 'sideoffer-box-no-text-box': sideOffer.hide_text_box }"
              v-for="sideOffer in sideOffers"
              :key="sideOffer._id" @click="clickCurrentActivation(sideOffer)">
            <div v-if="sideOffer.thumbnail && sideOffer.thumbnail.fileType && sideOffer.thumbnail.fileType.includes('image')"
                class="sideoffer-box-pic" :style="`background-image: url(${sideOffer.thumbnail.url});`">
            </div>

            <div v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('image')"
                class="sideoffer-box-pic"
                :style="`background-image: url(${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : '' });`">
            </div>

            <video v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('video')"
                  class="news-autoplay autoplayMedia"
                  :style="!sideOffer.hide_text_box ? 'border-radius: 6px 6px 0 0;' : 'border-radius: 6px;'"
                  :controls="false" muted playsinline>
              <source :src="`${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : '' }`"
                      :type="sideOffer.media[0].fileType">
              {{ transl.Offer.novideo }}
            </video>

            <!--<img TODO: enable
              v-if="sideOffer.icon && sideOffer.unique_icon"
              :src="`${sideOffer.icon}`"/>-->

            <div class="card-tag">
              <md-chip class="card-chip" v-if="sideOffer.offer_tag && sideOffer.offer_tag.length">
                {{ sideOffer.offer_tag }}
              </md-chip>
            </div>

            <div v-if="!sideOffer.hide_text_box" class="sideoffer-box-text">
              <div class="sideoffer-box-name body-1-bold">{{ sideOffer.first_header }}</div>
              <div v-if="sideOffer.variations" class="sideoffer-box-info body-2">
                <iconComponent symbol="style" />
                <span>{{ transl.Offer.variants }}</span>
              </div>

              <div v-else-if="sideOffer.save_text" class="sideoffer-box-info body-2">
                <iconComponent symbol="newReleases" />
                <span>{{ sideOffer.save_text }}</span>
              </div>

              <div v-else class="sideoffer-box-info body-2">
                <span style="padding: 0;">{{ sideOffer.quick_text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="expired-date-activation" v-else-if="expiresAtState === 'expired' && !expiredOffer && loadedPage">
      <div>
        <iconComponent symbol="timeOut" :width="150" :stroke-width="0" fill="#EA7801" />

        <div class="expired-date-header">{{ transl.Offer.expired }}</div>
        <div class="expired-date-text">{{ transl.Offer.watchout }}</div>

        <div class="btn btn-activate" @click="clickGoBack">
          {{ backToText }}
        </div>
      </div>
    </div>

    <modal class="fullscreen-dialog" v-if="tocModal" @close="tocModal = false" modalType="fullscreen" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-if="activation.terms_text" v-html="activation.terms_text" class="html-text"></div>

          <div class="terms-buttons">
            <div
              v-if="activation.terms_merchandise && !acceptTerms && !requiresLogin"
              class="terms-merchandise-grid">
              <inputField
                v-model="acceptMerchandise"
                inputType="checkbox"/>
              <span
                class="terms-merchandise-text"
                v-html="activation.terms_merchandise"></span>
            </div>

            <!-- Refaktoreras -->
            <div v-if="!acceptTerms && !requiresLogin" class="btn btn-activate" @click="setAcceptTerms">
              {{ transl.UserPV.accept }}
            </div>

            <div class="btn btn-secondary" @click="tocModal = false">
              {{ transl.UserPV.close }}
            </div>
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import VueSlider from 'vue-slider-component';
import vuePdf from 'vue-pdf';
// import videojs from 'video.js';
// import EventBus from '../resources/eventBus';
import aCarousel from '../../../components/ACarousel.vue';
import ExpiredMixin from '../../../mixins/ExpiredMixin.vue';
import EntityMixin from '../../../mixins/EntityMixin.vue';
import BeforeEnterMixin from '../../../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../../../mixins/AnnoyingClientMixin.vue';
import InputField from '../../../stories/components/InputField/InputField.vue';
import Uploader from '../Uploader/Uploader.vue';

export default {
  name: "ActivationView",
  components: {
    aCarousel,
    vuePdf,
    VueSlider,
    InputField,
    Uploader,
  },
  mixins: [
    ExpiredMixin,
    BeforeEnterMixin,
    AnnoyingClientMixin,
    // EntityMixin
  ],
  props: {
    activation: {
      type: Object,
    },
    collection: {
      type: Object,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    previewWidth: {
      type: Number,
    },
    previewHeight: {
      type: Number,
    },
    answeredSurvey: {
      type: Boolean,
      default: false,
    },
    authenticated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // currentUrl: location.href,
      currentUrl: "",
      internalLinkModal: false,
      firstTime: true,
      expiredOffer: false,
      // answeredSurvey: false,
      surveyAnswers: {},
      surveyOther: {},
      expiresAtState: '',
      isMobile,
      pageCount: 0,
      pageNr: 1,
      loadedPage: true,
      tocModal: false,
      acceptTerms: false,
      acceptMerchandise: true,
    }
  },
  computed: {
    expiresDate() {
      return this.getExpiresDate(this.activation);
    },
    hasCTA() {
      if (this.activation?._id) {
        if (this.activation.pdf) {
          return true;
        } else if (this.activation.survey && this.activation.survey.length) {
          return true;
        } else if (this.activation.recurring_time) {
          return true;
        } else if (this.activation.winner_adress) {
          return true;
        } else if (this.activation.internal_link) {
          return true;
        } else if (this.activation.external_link) {
          return true;
        } else if (this.activation.links_gallery && this.activation.links_gallery.label) {
          return true;
        } else if (this.activation.clearon_id || this.activation.clearon_ean_id) {
          return true;
        } else if (this.activation.cta_email) {
          return true;
        } else if (this.activation.iframe_website) {
          return true;
        }
      }
      return false;
    },
    sideOffers() {
      if (this.collection.offers?.length) {
        let el = this;
        const excludeCurrentOffer = this.collection.offers.filter(function (
          offer
        ) {
          return (
            offer._id &&
            offer._id !== el.activation._id &&
            !offer.closed &&
            offer.clickable &&
            !offer.jwt_lock &&
            (offer.media.length ||
                (offer.thumbnail && Object.keys(offer.thumbnail).length !== 0))
          );
        });
        let indexes = [0, 1, 2];
        if (excludeCurrentOffer.length > 3) {
          indexes = [];
          while (indexes.length < 3) {
            let r =
              Math.floor(Math.random() * excludeCurrentOffer.length - 1) + 1;
            if (indexes.indexOf(r) === -1) {
              indexes.push(r);
            }
          }
        }
        const sideOffers = excludeCurrentOffer.filter(function (offer, index) {
          return indexes.indexOf(index) !== -1;
        });
        return this.shuffle(sideOffers);
      } else {
        return [];
      }
    },
    backToText() {
      return `${this.transl.Offer.back} ${
        this.collection.offers && this.collection.offers.length === 1
          ? ` ${this.transl.Offer.tohome}`
          : ''
      }`;
    },
    iframeWebsiteUrl() {
      let iframeWebsiteUrl = '';
      if (this.activation && this.activation.iframe_website) {
        if (this.activation.iframe_website.includes('?')) {
          iframeWebsiteUrl = `${this.activation.iframe_website}&relayId=${this.$route.query.relayId}`;
        } else {
          iframeWebsiteUrl = `${this.activation.iframe_website}?relayId=${this.$route.query.relayId}`;
        }
      } else {
        iframeWebsiteUrl = false;
      }
      return iframeWebsiteUrl;
    },
    requiresLogin() {
      if (this.activation?._id) {
        return (this.activation.must_login ||
          this.activation.winner_adress ||
          this.activation.cta_email ||
          this.activation.recurring_time ||
          this.activation.clearon_id ||
          this.activation.clearon_ean_id) ? true : false;
      } else {
        return true;
      }
    },
    hasNotAcceptedRequiredTerms() {
      return !this.requiresLogin && !this.acceptTerms && this.activation.terms_must_accept;
    },
  },

  methods: {
    shuffle(a) {
      let j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    onCopy(e) {
      this.$emit('onCopy', e);
    },
    onError(e) {
      this.$emit('onError', e);
    },
    clickExternalLink(url) {
      this.$emit('clickExternalLink', url);
    },
    clickGoBack() {
      this.$emit('clickGoBack');
    },
    setLoginView() {
      this.$emit('setLoginView');
    },
    // setLoginView() {
    //   this.internalLinkModal = false;
    //   let el = this;
    //   setTimeout(function () {
    //     el.$emit('setLoginView', {});
    //   }, 10); // 500
    // },
    clickCurrentActivation(activation) {
      this.$emit('clickCurrentActivation', activation);
    },
    clickCollection(collection) {
      this.$emit('clickCollection', collection);
    },
    changePage(newPageNr) {
      if (newPageNr <= this.pageCount && newPageNr > 0) {
        this.pageNr = newPageNr;
      }
    },

    // getAlreadyActivated() {
    //   if (this.activation && this.activation._id) {
    //     const activated = localStorage.getItem(`activated-${this.activation._id}`);
    //     const alreadyActivated = activated ? true : false;
    //     if (this.activation.survey && this.activation.survey.length) {
    //       this.answeredSurvey = alreadyActivated;
    //     } else {
    //       console.log('ERROR: cta not supported yet');
    //     }
    //   }
    // },
    activateInternalOffer(getExit, eanCode, link) {
      if (this.hasNotAcceptedRequiredTerms) {
        this.tocModal = true;
        return;
      }

      if (this.surveyAnswers && Object.keys(this.surveyAnswers).length) {
        let { success, message } = this.checkRequiredQuestions();
        if (!success) {
          // ToDo: Refactor?
          this.setAlert(message, 3);
          return;
        }
      }

      this.$emit('activateInternalOffer', {
        activation: this.offer,
        collection: this.collection,
        acceptMerchandise: this.acceptMerchandise,
        answers: this.surveyAnswers,
      });

      // ToDo: Check how to handle internal offer activation
    },

    // _activateInternalOffer(getExit, eanCode, link) {
    //   if (this.hasNotAcceptedRequiredTerms) {
    //     this.tocModal = true;
    //     return;
    //   }
    //   if (this.surveyAnswers && Object.keys(this.surveyAnswers).length) {
    //     let { success, message } = this.checkRequiredQuestions();
    //     if (!success) {
    //       this.setAlert(message, 3);
    //       return;
    //     }
    //   }
    //   this.pushDataLayer({
    //     parentCompany: this.activation.company
    //       ? this.activation.company.parentCompany
    //       : 'Unkown',
    //     company: this.collection.company,
    //     goodiebag: this.activation.goodiebag,
    //     offer: this.activation,
    //     action: 'Activate',
    //   });
    //   this.$store
    //     .dispatch('activateHomeOffer', {
    //       offerId: this.activation._id,
    //       answers: this.surveyAnswers,
    //       acceptMerchandise: this.acceptMerchandise,
    //       eanCode,
    //       getExit,
    //     })
    //     .then(() => {
    //       this.pushDataLayer({
    //         parentCompany: this.activation.company
    //           ? this.activation.company.parentCompany
    //           : 'Unkown',
    //         company: this.collection.company,
    //         goodiebag: this.activation.goodiebag,
    //         offer: this.activation,
    //         action: 'Won',
    //       });
    //       this.activateErrorMsg = null;
    //       this.activateErrorButton = 'retry';
    //       if (this.activation.survey && this.activation.survey.length) {
    //         // this.answeredSurvey = true;
    //         this.$emit('answerSurvey');
    //         this.setAlert(this.transl.Offer.thanks);
    //         localStorage.setItem(`activated-${this.activation._id}`, true);
    //       } else {
    //         this.setAlert(this.transl.routes.serverissue);
    //       }
    //     })
    //     .catch((err) => {
    //       const { message } = err.response.data;
    //       this.pushDataLayer({
    //         parentCompany: this.activation.company
    //           ? this.activation.company.parentCompany
    //           : 'Unkown',
    //         company: this.collection.company,
    //         goodiebag: this.activation.goodiebag,
    //         offer: this.activation,
    //         action: 'Lose',
    //       });
    //       if (!this.activation.survey || !this.activation.survey.length || link) {
    //         this.setAlert('', 0); // removes alert from axios response-interceptor
    //       } else {
    //         this.setAlert(message, 3);
    //       }
    //     });
    // },

    checkRequiredQuestions() {
      let unfilled = false;
      let message = '';
      for (let i = 0; i < this.activation.survey.length; i++) {
        let question = this.activation.survey[i];
        if (question.required) {
          let answeredRequiredField = false;
          if (question.upload) {
            if (this.surveyUpload && this.surveyUpload.data) {
              answeredRequiredField = true;
            }
          } else {
            const objectKeys = Object.keys(this.surveyAnswers);
            for (let j = 0; j < objectKeys.length; j++) {
              const objectKey = objectKeys[j];
              if (objectKey === question.model && this.surveyAnswers[objectKey]) {
                answeredRequiredField = true;
                break;
              }
            }
          }
          if (!answeredRequiredField) {
            message += `${i+1}, `;
            unfilled = true;
          }
        }
      }
      if (unfilled) {
        message.slice(message.length - 2);
        message = `Question(s) ${message} isn't answered even though it's required`;
        return { success: false, message };
      } else {
        return { success: true };
      }
    },
    setCheckbox(question, option, value, notCheck) {
      if (question.checkbox && question.options && question.options.length) {
        if (
          !this.surveyAnswers[question.model] ||
          !this.surveyAnswers[question.model].length
        ) {
          this.surveyAnswers[question.model] = [];
        }
        const isOther = option == 'other';
        let isChecked = -1;
        for (let i = 0; i < this.surveyAnswers[question.model].length; i++) {
          const internalOption = this.surveyAnswers[question.model][i];
          if (option === internalOption) {
            isChecked = i;
          } else if (isOther && this.surveyOther[question.model] == internalOption) {
            isChecked = i;
          }
        }
        if (notCheck) {
          isChecked = -1;
          this.removeOldOther(question, value);
        }
        if (isChecked < 0) {
          if (isOther) {
            const otherOptionCheckbox = document.getElementById(`${question.model}other`);
            otherOptionCheckbox.checked = true;
            this.surveyAnswers[question.model].push(this.surveyOther[question.model]);
          } else {
            this.surveyAnswers[question.model].push(option);
          }
        } else {
          this.surveyAnswers[question.model].splice(isChecked, 1);
        }
      }
    },
    setRadio(question, option, notCheck) {
      const isOther = option == 'other';
      if (isOther) {
        const otherOptionRadio = document.getElementById(`${question.model}other`);
        if (notCheck) {
          otherOptionRadio.checked = true;
        } else {
          otherOptionRadio.checked = !otherOptionRadio.checked;
        }
        if (otherOptionRadio.checked) {
          this.surveyAnswers[question.model] = this.surveyOther[question.model];
        }
      }
    },
    removeOldOther(question, newValue) {
      for (let i = 0; i < this.surveyAnswers[question.model].length; i++) {
        const internalOption = this.surveyAnswers[question.model][i];
        let remove = true;
        for (let j = 0; j < question.options.length; j++) {
          const option = question.options[j];
          if (option == internalOption && internalOption != newValue) {
            remove = false;
          }
        }
        if (remove) {
          this.surveyAnswers[question.model].splice(i, 1);
        }
      }
    },
    getAcceptedTerms() {
      if (this.requiresLogin) {
        return false;
      } else {
        return localStorage.getItem(`accepted-terms-${this.activation._id}`) == 'true';
      }
    },
    getAcceptedMerchandise() {
      if (this.requiresLogin) {
        return false;
      } else {
        if (!this.acceptTerms) {
          return true;
        } else {
          return localStorage.getItem(`accepted-merchandise-${this.activation._id}`) == 'true';
        }
      }
    },
    handleTerms() {
      if (this.activation && this.activation._id) {
        this.acceptTerms = this.getAcceptedTerms();
        this.acceptMerchandise = this.getAcceptedMerchandise();
        if (this.hasNotAcceptedRequiredTerms) {
          this.tocModal = true;
        }
      } else {
        console.error('Did not handle terms!');
      }
    },
    setAcceptTerms() {
      this.acceptTerms = true;
      localStorage.setItem(`accepted-terms-${this.activation._id}`, true);
      localStorage.setItem(`accepted-merchandise-${this.activation._id}`, this.acceptMerchandise);
      this.tocModal = false;
    },
  }

}
</script>

<style lang="scss" scoped>
@import "./ActivationView.scss";
</style>
