<template>
  <div class='dashboard-activation-steps-container'>
    <div class='dashboard-activation-steps'>
      <div v-for="(step, index) in steps" :key="step.id" class='dashboard-activation-step'>
        <DashboardActivationStep
          :icon="getStepIcon(step)"
          :number="index + 1"
          :title="step.name"
          :clickable="false"
        />
      </div>
    </div>

    <div class="dashboard-activation-chart">
      <DashboardFunnelChart
        :data="chartData"
        :selection="selection"
        @clickBar="$emit('selectStep', $event.filterId)"
        @clickDropoff="$emit('selectStep', $event.dropoffId)"
      />
    </div>
  </div>
</template>

<script>
import DashboardActivationStep from './DashboardActivationStep.vue';
import DashboardFunnelChart from '../DashboardFunnelChart/DashboardFunnelChart.vue';

export default {
  name: 'DashboardActivationSteps',
  components: {
    DashboardActivationStep,
    DashboardFunnelChart,
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: String,
    },
  },
  computed: {
    chartData() {
      return this.steps.map(step => ({ ...step, value: step.total }));
    },
  },
  methods: {
    getStepIcon(step) {
      switch (step.id) {
        case 'PLACEHOLDER_CREATED':
          return require('../../assets/module-icon-activation.png');
        case 'TERMS_CREATED':
          return require('../../assets/module-icon-terms.png');
        case 'RECEIPT_CREATED':
          return require('../../assets/module-icon-style.png');
        case 'RECEIPT_CONFIRMED':
          return require('../../assets/module-icon-receipt.png');
        case 'REGISTRATION_CONFIRMED':
          return require('../../assets/module-icon-product.png');
        case 'SURVEY_CONFIRMED':
          return require('../../assets/module-icon-survey.png');
        case 'CASHBACK_CONFIRMED':
          return require('../../assets/module-icon-cashback.png');
        case 'CTA_DONE':
          return require('../../assets/module-icon-confirm.png');
        case 'email':
          return require('../../assets/module-icon-email.png');
        case 'return-product':
          return require('../../assets/module-icon-return.png');
        default: {
          return '';
        }
      }
    },
    handleResize(entries) {
      for (const entry of entries) {
        this.getContainerSize(entry.target);
      }
    },
    getContainerSize(container) {
      this.chartWidth = container.clientWidth;
      this.chartHeight = container.clientHeight;
    }
  }
}
</script>

<style lang='scss' scoped>
$step-width: 77px;
$step-height: 147px;
$step-gap: 48px;

.dashboard-activation-steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 40px 24px 40px;
  background-color: #FFFFFF;

  .dashboard-activation-steps {
    display: flex;
    gap: $step-gap;
    align-self: stretch;
  }

  .dashboard-activation-step {
    display: flex;
    justify-content: center;
    width: $step-width;
    height: $step-height;
  }

  .dashboard-activation-chart {
    display: flex;
    height: 166px;
    align-self: stretch;
  }
}
</style>