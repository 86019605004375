import axios from 'axios';

export default {
  // AdminPV
  findAdminPVCompanies: (data) => {
    return axios.put('/api/pv/admin/companies', data)
      .then(response => ({
        companies: response.data.companies != null
          ? response.data.companies : [],
      }));
  },

  getAdminPVCompany: (data) => {
    return axios.get(`/api/pv/admin/companies/${data.entityId}`)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  addAdminPVCompany: (data) => {
    return axios.post('/api/pv/admin/companies', data)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  saveAdminPVCompany: (payload) => {
    return axios.put(`/api/pv/admin/companies/${payload.entityId}`, payload)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  publiciseAdminPVCompany: (payload) => {
    return axios.put(`/api/pv/admin/companies/${payload.entityId}/publicise`, payload)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  addAdminPVCompanyPicture: (payload) => {
    return axios.put(`/api/pv/admin/companies/${payload.entityId}/picture`, payload.data, payload.config)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  addAdminPVCompanyThumbnail: (payload) => {
    return axios.put(`/api/pv/admin/companies/${payload.entityId}/thumbnail`, payload.data, payload.config)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  addAdminPVCompanyIcon: (payload) => {
    return axios.put(`/api/pv/admin/companies/${payload.entityId}/icon`, payload.data, payload.config)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  deleteAdminPVCompany: (payload) => {
    return axios.delete(`/api/pv/admin/companies/${payload.entityId}?deleteCode=${payload.deleteCode}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  // Consumer
  findUserCompanies: (query, number) => {
    const data = {
      query,
      number,
    }
    return axios.put('/api/pv/companies', data)
      .then(response => ({
        companies: response.data.companies != null
          ? response.data.companies : [],
      }));
  },
  
  getCompany: (data) => {
    return axios.get(`/api/pv/companies/${data.companyId}`)
      .then(response => ({
        company: response.data.company != null
          ? response.data.company : {},
      }));
  },

  // Session Required
  getUserCompanies: () => {
    return axios.get(`/api/pv/nar/users/companies`).then(response => ({
      companies: response.data.companies != null
        ? response.data.companies : {},
    }));
  },

  pickCompany: (data) => {
    return axios.put('/api/pv/nar/companies/pick', data).then(response => ({
      company: response.data.company != null
        ? response.data.company : {},
    }));
  },

  linkCompany: (data) => {
    return axios.put('/api/pv/nar/companies/link', { code: data.code }).then(response => ({
      company: response.data.company != null
        ? response.data.company : {},
    }));
  },

  // Company
  getPickedCompany: () => {
    return axios.get('/api/pv/c/company').then(response => ({
      company: response.data.company != null
        ? response.data.company : {},
    }));
  },
};
