<template>
  <div class="content-text">
    <div v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: 'ContentText',
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
  }
}
</script>

<style scoped>
@import './Content.css';
</style>