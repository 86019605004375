<template>
  <div class="admin-pv-participation">
    <Participation :participationId="participationId"/>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Participation from '../stories/components/Participation/Participation.vue';

export default {
  name: 'CompanyGameParticipation',
  components: {
    Participation,
  },
  mixins: [BeforeEnterMixin],
  props: ['participationId'],
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.admin-pv-participation {
  height: calc(100vh - $nav-height);
}
</style>
