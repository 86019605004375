<template>
  <div class="activation-config-container">
    <div class="tab-content-container">
      <div class="form">
      <ImageSelect  :heading="'Header image'"
                    :selected-image-file="updatedActivation.fe_media"
                    :aspect-ratio="'9:16'"
                    @imageSelected="updatedActivation.fe_media = $event"
                    @imageDeleted="updatedActivation.fe_media = {}"
                    class="image" />
      <ColorSelect  :title="'Accent color'"
                    :color="updatedActivation.accent_color"
                    @select="updatedActivation.accent_color = $event" />
      <ColorSelect  :title="'Text color'"
                    :color="updatedActivation.text_color"
                    @select="updatedActivation.text_color = $event" />
    </div>
    </div>
  </div>
</template>

<script>

import ImageSelect from "@/stories/components/ImageSelect/ImageSelect.vue";
import ColorSelect from "@/stories/components/ColorSelect/ColorSelect.vue";

export default {
  components: {ImageSelect, ColorSelect},
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.text_color'(value) {
      this.emitChanges('text_color', value);
    },
    'updatedActivation.accent_color'(value) {
      this.emitChanges('accent_color', value);
    },
    'updatedActivation.fe_media'(value) {
      this.emitChanges('fe_media', value);
    },
    'updatedActivation.fe_icon'(value) {
      this.emitChanges('fe_icon', value);
    },
  },
  data() {
    return {
      updatedActivation: {
        ...this.activation,
        fe_media: { url: this.activation.fe_media?.file || null },
        text_color: this.activation.text_color || '',
        accent_color: this.activation.accent_color || '',
      },
      activeTab: 1,
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
  },
}
</script>

<style lang="scss" scoped>

@import "./ActivationStep";

.activation-config-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 16px;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    gap: 24px;

    .image {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .colors {
      display: flex;
      gap: 16px;
    }
  }

}



</style>

