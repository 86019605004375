<template>
  <div id="pdf-editor">
    <div class="flex-container columns">
      <div class="box dragzone flex-item" ref="fieldListColumn" id="fieldListColumn">
        <h4>Available fields</h4>
        <div
          v-for="field in this.fields"
          :key="field.key">
          <button :id="field.key" class="button is-link draggable" :data-x="field.x" :data-y="field.y" :data-position="field.position" :style="`transform: translate(${field.x}px, ${field.y}px);`">
            {{ field.label }}
          </button>
        </div>
      </div>
      <div class="flex-item">
        <div v-show="pdfSource" id="pageContainer" >
          <canvas id="the-canvas" ref="canvas" class="dropzone"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/web/pdf_viewer.css";
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.worker.js";

export default {
  props: {
    action: {
      default: null
    },
    scale: {
      type: String,
      default: "1"
    },
    fileName: {
      type: String,
      default: Date.now() + ""
    },
    pdfSource: null,
  },
  data() {
    return {
      maxPDFx: 595,
      maxPDFy: 842,
      offsetY: 7,
      offsetX: 0,
      isEnabled: false,
      src: null,
      pdfContext: null,
      pdfPage: null,
      position: {
        x: 0,
        y: 0
      },
      fields: [],
    };
  },
  beforeMount() {
    // pre-load all available fields before placing those that are places
    this.getReceiptFields();
    // console.log("PDFEDITOR MOUNTED!");
    window.interact(".draggable").unset();
  },
  mounted() {
    // are we loading an existing form? in that case, load the pdf and position all the fields
    if (this.action.file_attachment && this.action.file_attachment.fieldPositions) {
      const filledForm = this.action.file_attachment;

      // load the PDF from storage and display it
      this.src = `${this.windowOrigin}/api/pv/nar/file/${filledForm.fileKey}`;
      this.loadPdf(this.src);
      
      //place all existing fields into their saved positions
      for (let i = 0; i < filledForm.fieldPositions.length; i++) {
        const field = filledForm.fieldPositions[i];

        // find our html element and position it according to saved position
        let htmlEl = document.querySelector(`#${field.key}`);
        htmlEl.style.transform = 'translate(' + field.x + 'px, ' + field.y + 'px)';
        htmlEl.classList.add("can-drop", "is-success");

        // update the position attributes
        htmlEl.setAttribute('data-x', field.x);
        htmlEl.setAttribute('data-y', field.y);

        //save changes to 'fields' prop as well
        let existingField = this.fields.find((globField) => globField.key === field.key);
        existingField.x = field.x;
        existingField.y = field.y;
        existingField.pdfPositionX = field.pdfPositionX;
        existingField.pdfPositionY = field.pdfPositionY;
      }
    } else {
      this.src = `${this.windowOrigin}/api/pv/nar/file/${this.pdfSource}`;
      this.loadPdf(this.src);
    }

    this.initDraggable();
    this.initDropzone();
  },
  methods: {
    getReceiptFields() {
      this.fields.push({label: "Receipt ID", key: "_id"});
      this.fields.push({label: "Model", key: "registration_modelnr"});
      this.fields.push({label: "Serial nr", key: "registration_serialnr"});
      this.fields.push({label: "Retailer", key: "receipt_store"});
      this.fields.push({label: "First name", key: "name"});
      this.fields.push({label: "Last name", key: "last_name"});
      this.fields.push({label: "E-mail", key: "email"});

      let yCounterOfGenerated = 0;
      let numOfMaxItem = 25;
      let notValidHeight = 30;
      let y = 0;
      let x = this.offsetX;

      for (let i = 0; i < this.fields.length; i++) {
        const element = this.fields[i];

        if (i > 0 && i % numOfMaxItem == 0) {
          yCounterOfGenerated = 0;
        }

        if (i > 0 && i % numOfMaxItem == 0) {
          yCounterOfGenerated = 0;
        }

        y = yCounterOfGenerated;
        yCounterOfGenerated += notValidHeight;
        element.x = x;
        element.y = y;
        element.position = i;
      }
    },
    async loadPdf(fileBuffer) {
      // let container = document.getElementById("pageContainer");
      // let pdfViewer = new PDFViewer({
      //   container: container,
      // });
      let loadingTask = pdfjsLib.getDocument(fileBuffer);
      let self = this;

      loadingTask.promise.then(function(pdf) {
        //
        // Fetch the first page
        //
        pdf.getPage(1).then(function(page) {
          let scale = 1.0;
          let viewport = page.getViewport(scale);
          //
          // Prepare canvas using PDF page dimensions
          //
          let canvas = document.getElementById('the-canvas');
          let context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          //
          // Render PDF page into canvas context
          //
          let renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          page.render(renderContext);
          self.pdfContext = renderContext;
          self.pdfPage = page;
          // page.render(renderContext).then(function() {
          //   self.pdfContext = renderContext;
          // }, function() {
          //   console.error("Error rendering PDF!");
          // });
          
        });
      });
    },
    updateForm() {
      // Collect all placed fields...
      const placedFields = this.fields.filter((obj) => {
        return obj.pdfPositionX && obj.pdfPositionY && obj.pdfPositionX > 1;
      });

      // then send it all to EntityEditor which will in turn save to db
      this.$emit('formUpdated', placedFields);

      // console.log("UPDATED PDF FORM!");
    },
    initDraggable() {
      // console.log("Interact enabled", window.interact(".draggable").draggable());
      window.interact(".draggable").draggable({
        snap: {
            targets: [
              window.interact.createSnapGrid({ x: 1, y: 1 })
            ],
            range: Infinity,
            relativePoints: [ { x: 0, y: 0 } ]
        },
        inertia: false,
        restrict: {
          restriction: "#pageContainer",
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
        },
        autoScroll: true,
        onmove: event => {
          // console.log("MOVE MOVE MOVE");
          let target = event.target;
          // keep the dragged position in the data-x/data-y attributes
          let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          // translate the element
          target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

          // update the posiion attributes
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);

          //this.updateFieldPosition(target.id, x, y);
        },
        onend: event => {
          let target = event.target;
          // keep the dragged position in the data-x/data-y attributes
          let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          //field has been dragged outside of document, reset it by removing x+y position
          if (!event.target.classList.contains("can-drop")) {
            x = this.offsetX;
            y = parseFloat(target.getAttribute('data-position')) * 30;
            // translate the element
            target.style.transform ='translate(' + x + 'px, ' + y + 'px)';

            // update the posiion attributes
            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
          }
          event.target.classList.remove("can-drop");

          this.updateFieldPosition(target.id, x, y);
        }
      });
    },
    updateFieldPosition(fieldId, x, y) {
      // Must wait for DOM render event loop before we can use $refs here :/
      this.$nextTick(function () {
        let fieldObj = this.fields.find(obj => obj["key"] === fieldId);

        const canvasEl = document.getElementById('the-canvas');
        const fieldListEl = document.getElementById('fieldListColumn');
        const maxHTMLx = canvasEl.clientWidth;
        const maxHTMLy = canvasEl.clientHeight;
        const fieldListWidth = fieldListEl.clientWidth;

        // We need to calculate and transform PDF coordinates, since PDF uses bottom-left as origin
        // and HTML uses top-left
        // We also need to account for anything outside the canvas (field list column)
        
        const paddingRight = parseInt(getComputedStyle(fieldListEl).paddingRight.replace('px',''));
        const adjustPdfX = fieldListWidth - paddingRight;

        // Magic number ¯\_(ツ)_/¯
        const adjustPdfY = 74;

        // Save X and Y coordinates relative to the PDF  		    
        const pdfY = y * this.maxPDFy / maxHTMLy;
        const pdfPositionY = this.maxPDFy - this.offsetY - pdfY - adjustPdfY;	  
        const pdfPositionX =  x * this.maxPDFx / maxHTMLx - adjustPdfX;

        fieldObj["x"] = x;
        fieldObj["y"] = y;
        fieldObj["pdfPositionY"] = pdfPositionY;
        fieldObj["pdfPositionX"] = pdfPositionX;

        // A field has been updated in PDF
        this.updateForm();
      }.bind(this)); //END nextTick()
    },
    initDropzone() {
      let self = this;
      window.interact(".dropzone").dropzone({
        accept: ".draggable",
        overlap: 1,
        ondropactivate: function(event) {
          const item = event.relatedTarget;
          item.classList.add("dragging");
        },
        ondropdeactivate: function(event) {
          const item = event.relatedTarget;
          item.classList.remove("dragging", "cannot-drop", "is-danger");
        },
        ondragenter: function(event) {
          const item = event.relatedTarget;
          item.classList.remove("cannot-drop", "is-danger");
          item.classList.add("can-drop", "is-success");
        },
        ondragleave: function(event) {
          const item = event.relatedTarget;
          item.classList.remove("can-drop", "is-success");
          item.classList.add("cannot-drop", "is-danger");
        },
        ondrop: function () {
          // A field has been dropped onto PDF, let's update
          self.updateForm();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./PdfFormEditor.scss";

#pdf-editor {
  min-height: 760px;
}

#the-canvas {
  // Canvas MUST not change size in any form or shape otherwise the positions of fields will be off!
  height: 842px !important;
  width: 595px !important;
  border:1px  solid black
}

#fieldListColumn {
  width: 240px !important;
  height: 842px !important;
  display: block;
}

#pageContainer {
  width: 100%;
}

.box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  
  padding: 1.25rem;
}

.columns {
  margin: auto !important;
}

.dragzone,
.dropzone {
  height: 100%;
  width: 100%;
}

.draggable {
  margin: auto;
  touch-action: none;
  user-select: none;
  display: inline-block;
  // position: absolute;
  z-index:999;
  min-width: 40px;
  padding: 0em 0.5em;
  padding-left:0;
  color: #fff;
  background-color: #29e;
  border: none;

  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);

  transition: background-color 0.3s;
  line-height: 10px;
  padding-right: 0 !important;
  padding-left: 5px !important;
}
</style>
