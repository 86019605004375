<template>
  <div
       v-if="loadedPage"
       class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find collection..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminGoodiebags()"
        @enterKey="findAdminGoodiebags()"/>
      
      <div class="add-entity-button" @click="showCollectionDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>
    </div>
    
    <aList
      entityType="Collections"
      columnsWidth="400"
      :entities="goodiebags"
      :keys="goodiebagKeys"
      routerName="AdminGoodiebag"
      routerId="goodiebagId"/>


    <modal v-if="showCollectionDialog" size="xlarge"
          @close="showCollectionDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Goodiebag"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminGoodiebags",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminGoodiebags();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["goodiebag", "goodiebags", "userStatus"])
  },
  data() {
    return {
      showCollectionDialog: false,
      loadedPage: false,
      query: '',
      goodiebagKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Unique Name',
          key: 'unique_name'
        },
        {
          title: 'Company',
          key: 'company.name'
        },
        /*{
          title: 'Live',
          key: 'public',
        },*/
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
    };
  },
  methods: {
    findAdminGoodiebags() {
      this.$store.dispatch('findAdminGoodiebags', {
        query: this.query,
        public: true
      }).then(() => {
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Collections');
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminGoodiebags";
</style>