<template>
  <div class="activation-config-container">
    <div class="tab-content-container">
      <SurveyEditor :survey="this.updatedActivation.survey" @change="updatedActivation.survey = $event"/>
    </div>
  </div>
</template>

<script>
import SurveyEditor from '@/stories/components/SurveyEditor/SurveyEditor.vue';

export default {
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  components: { SurveyEditor },
  watch: {
    'updatedActivation.survey': {
      handler(value) {
        this.emitChanges('survey', value);
      },
      deep: true,
    }
  },
  data() {
    return {
      selectedIndex: undefined,
      updatedActivation: {
        survey: Array.isArray(this.activation.survey) ? [...this.activation.survey] : [],
      },
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
  },
};

</script>

<style lang="scss" scoped>
@import "./ActivationStep";

.tab-content-container {
  margin-top: 16px;
  max-height: calc(100vh - 320px);
  overflow-y: scroll;
}
</style>
