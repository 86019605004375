<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">
    <links-manager
      :entity="company"
      entityType="Company"
      :admin="true"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from "../resources/eventBus";
import LinksManager from '../stories/components/LinksManager/LinksManager.vue';

export default {
  name: 'AdminLinksmanagerCompany',
  components: { LinksManager },
  props: ['companyId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    // när komponenten precis startas
    // hämta företaget från backend (och databasen)
    this.loadPage();
    EventBus.$emit('newAdmin', true);
  },
  computed: {
    ...mapGetters([
      "company",
    ])
    // uträknade variabler
  },
  data() {
    return {
      loadedPage: false,
    }
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminCompany', { entityId: this.companyId }).then(() => {
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Links manager company');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/AdminLinksManagerCompany";
</style>