<template>
  <div class="image-container" :class="imageContainerClasses" :style="containerStyle">
    <div v-if="!smallPreview" class="header">
      <div class="headline-container">
        <span v-if="showInfo" v-html="infoIcon" class="info"/>
        <h3 class="heading">{{ heading }}</h3>
      </div>

      <input :ref="`edit${heading}`" type="file" accept="image/*" @change="onImageFileSelected($event.target.files[0])" hidden>

      <div class="image-actions" v-if="imageFile.url && !smallPreview">
        <button class="button-image-delete" @click="deleteImage">
          <iconComponent symbol="trashcan" stroke="#111" fill="#111"/>
        </button>

        <button class="button-yellow" @click.prevent="$refs['edit' + heading].click()">
          <iconComponent symbol="pencil" stroke="#EA7801" fill="#EA7801"/>
          Edit
        </button>

      </div>
    </div>

    <div v-if="!smallPreview">
      <div v-if="!imageFile.url" class="preview-container" :style="previewSize">
        <input :ref="`fileUpload${heading}`" type="file" accept="image/*" @change="onImageFileSelected($event.target.files[0])" hidden>
        <button class="button-yellow" @click.prevent="$refs['fileUpload' + heading].click()">
          <iconComponent symbol="arrowUp" stroke="#EA7801" fill="#EA7801"/>
          Select
        </button>
      </div>
      <div v-else class="preview-container" :style="previewSize" >
        <img :src="imageFile.url"/>
      </div>
    </div>

    <div v-if="smallPreview" class="small-container" :class="{ 'borderless' : !addImage }">

      <div v-if="addImage" class="add-image">
        <input :ref="`fileUpload${heading}`" type="file" accept="image/*" @change="onImageFileSelected($event.target.files[0])" hidden>
        <iconComponent symbol="plusMath" size="24px" color="#CCC" @click="$refs['fileUpload' + heading].click()"/>
        <span>Add image</span>
      </div>

      <div v-if="imageFile.url && !addImage" class="small-preview">
        <img :src="imageFile.url"/>
      </div>

      <div v-if="!addImage" class="small-image-actions-container">
        <input :ref="`edit${heading}`" type="file" accept="image/*" @change="onImageFileSelected($event.target.files[0])" hidden>
        <span style="font-size: 14px; font-weight: 600;">{{ heading }}</span>
        <div class="actions">
          <iconComponent symbol="pencil" :stroke="color" :fill="color" :height="16" :width="16"
                         @click="$refs['edit' + heading].click()" style="cursor: pointer"/>
          <iconComponent symbol="trashcan" stroke="#111" fill="#111" :height="16" :width="16" @click="deleteImage"
                         style="cursor: pointer"/>
        </div>
      </div>
    </div>

    <ul v-if="showAllImageRestrictions && !imageFile.url" class="image-restrictions">
      <li>Supported formats ({{ formats.join(', ') }}).</li>
      <li>File size: Less than {{ size }}MB</li>
      <li>Dimensions: {{ imageDimensions.width }}x{{ imageDimensions.height }}px / Aspect ratio: {{ aspectRatio }}</li>
    </ul>

    <p v-else-if="!showAllImageRestrictions && !smallPreview && !imageFile.url">Supported formats ({{ formats.join(', ') }}).</p>

    <PartnerModal :show="inputState === 'preview'" @appeared="loadCropper" @disappeared="destroyCropper" @close="cancelCropping">
      <div class="preview-modal-content">
        <div class="preview-modal-image-container" :style="imageContainerStyle">
          <img :id="cropperId" :src="tmpImageFile.url"/>
        </div>
      </div>

      <div class="preview-modal-footer">
        <button class="button-yellow-outlined" @click="changeImage">
          <iconComponent symbol="arrowUp" stroke="#EA7801" fill="#EA7801" />
          Upload new
        </button>
        <button class="button-solid" @click="selectImage">
          <iconComponent symbol="floppyDisk" stroke="#FFFFFF" fill="#FFFFFF" />
          Crop and save
        </button>
      </div>
    </PartnerModal>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import PartnerModal from '../PartnerModal/PartnerModal.vue'
import svgMixin from "@/mixins/SvgMixin.vue";
import {integer} from "twilio/lib/base/deserialize";

export default {
  name: 'ImageSelect',
  components: { PartnerModal },
  mixins: [svgMixin],
  props: {
    heading: {
      type: String,
      default: '',
    },
    selectedImageFile: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: true,
    },
    showAllImageRestrictions: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: String,
      default: '16:9',
    },
    dimensions: {
      type: String,
    },
    formats: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png']
    },
    size: {
      type: Number,
      default: 4,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    smallPreview: {
      type: Boolean,
      default: false,
    },
    addImage: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 570,
    },
    height: {
      type: Number,
      default: 310,
    },
  },
  data() {
    return {
      imageFile: {},
      cropper: null,
      inputState: 'select',
      showModal: false,
      tmpImageFile: {},
      tmpImageProperties: {},
      cropperContainerHeight: 0
    };
  },
  mounted() {
    if (!this.selectedImageFile?.fileObject && !this.selectedImageFile?.url) {
      return
    }

    this.inputState = 'selected'

    if (this.selectedImageFile?.fileObject) {
      this.$set(this.imageFile, 'url', this.selectedImageFile.fileObject.url);
    } else {
      this.$set(this.imageFile, 'url', this.selectedImageFile.url);
    }
  },
  destroyed() {
    this.destroyCropper();
  },
  computed: {
    imageContainerClasses() {
      return {
        'small': this.smallPreview,
        'image--selected': this.selected,
      }
    },
    aspectWidth() {
      if (typeof this.aspectRatio === 'string' && this.aspectRatio.includes(':')) {
        return parseInt(this.aspectRatio.split(':')[0]);
      }
      return 1;
    },
    aspectHeight() {
      if (typeof this.aspectRatio === 'string' && this.aspectRatio.includes(':')) {
        return parseInt(this.aspectRatio.split(':')[1]);
      }
      return 1;
    },
    imageDimensions() {
      if (typeof this.dimensions === 'string' && this.dimensions.includes('x')) {
        const [width, height] = this.dimensions.split('x').map(dimension => parseInt(dimension));
        return { width, height };
      }

      const minDimension = 1080
      const [width, height] = this.aspectWidth < this.aspectHeight
        ? [minDimension, minDimension / this.aspectWidth * this.aspectHeight]
        : [minDimension / this.aspectHeight * this.aspectWidth, minDimension];

      return { width, height };
    },
    cropperId() {
      return `cropper-${this.heading.toLowerCase().replace(/ /g, "-")}`;
    },
    imageContainerStyle() {
      return {
        height: `${this.cropperContainerHeight}px`,
        aspectRatio: `${this.aspectWidth}/${this.aspectHeight}`
      }
    },
    color() {
      return this.smallPreview ? '#111111' : '#EA7801';
    },
    containerStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    previewSize() {
      let width = this.width - 32;
      let height = this.height * 0.7;

      if (this.imageFile.url === undefined) {
        height = this.height * 0.4;
      }

      return {
        width: `${width}px`,
        height: `${height}px`,
      }
    },
  },
  methods: {
    getImageProperties(imageFile) {
      return new Promise((resolve, reject) => {
        if (!imageFile) {
          reject('No image');
        }

        const URL = window.URL || window.webkitURL;
        if (!URL?.createObjectURL) {
          reject('No URL object')
        }

        const img = new Image();
        img.onload = () => {
          const fileSizeInBytes = imageFile.size;
          const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
          resolve({ width: img.width, height: img.height, size: fileSizeInMB });
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(imageFile);
      });
    },
    async onImageFileSelected(imageFile) {
      if (!imageFile)
        return;

      if (!(/\.(jpg|jpeg|png)$/i.test(imageFile.name))) {
        this.setAlert('The selected file is not in a supported file format', 3, 'danger');
        return this.resetEdit();
      }

      try {
        this.tmpImageProperties = await this.getImageProperties(imageFile);
      } catch (error) {
        // console.log('Failed to read image dimensions', error);
      }


      this.openImageCropper(imageFile);
    },
    openImageCropper(imageFile) {
      this.tmpImageFile = imageFile;

      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        this.tmpImageFile.url = URL.createObjectURL(imageFile);
      }

      this.inputState = 'preview';
    },
    loadCropper() {
      this.$nextTick(function () {
        const image = document.getElementById(this.cropperId);

        if (!image) {
          console.log('No image');
          return;
        }

        this.cropper = new Cropper(image, {
          aspectRatio: this.aspectWidth / this.aspectHeight,
          autoCrop: true,
          autoCropArea: 1,
          viewMode: 1,
          dragMode: 'crop',
        });
      });
    },
    cropImage() {
      if (this.cropper != null) {
        let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(this.tmpImageFile.type).split(',')[1]);
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        this.imageFile = new File([arr], this.generateFilename(), { type: this.tmpImageFile.type });
        return true;
      }

      this.setAlert('The file could not be cropped', 3, 'danger');
      return false;
    },
    selectImage() {
      if (!this.cropImage())
        return;

      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        this.imageFile.url = URL.createObjectURL(this.imageFile);
      }
      this.inputState = 'selected';
      this.$emit("imageSelected", { fileObject: this.imageFile });
    },
    changeImage() {
      this.inputState = 'select';
      setTimeout(() => {
        this.$refs['edit' + this.heading].click();
      }, 400);
    },
    cancelCropping() {
      this.inputState = 'select';
    },
    destroyCropper() {
      if (this.cropper !== null) {
        this.cropper.destroy();
        this.cropper = null;
      }

      if (this.tmpImageFile) {
        this.tmpImageFile = {};
        this.tmpImageProperties = {};
      }
    },
    getExtension() {
      if (!this.tmpImageFile?.name) return ''
      return this.tmpImageFile.name.split('.').pop();
    },
    generateFilename() {
      const extension = this.getExtension();
      const name = this.tmpImageFile.name.replace(`.${extension}`,'')
      return `${name}${Date.now()}.${extension}`
    },
    deleteImage() {
      this.imageFile = {};
      this.inputState = 'select';

      this.$emit("imageDeleted");
    }
  },
  watch: {
    inputState(state) {
      if (state === 'preview') {
        this.cropperContainerHeight = Math.min(706, window.innerHeight - 128 - 88);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/company";
@import "../../../assets/scss/abstracts/_colors.scss";

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 441px;
  padding: 16px;

  border: 1px dashed #CCC;

  &.small {
    justify-content: center;
    width: 130px;
    height: 145px;
    padding: 0;

    border: none;
    background-color: #FFF;
  }

  .small-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 145px;
    padding: 0;
    border: 1px dashed #CCC;
    border-radius: 8px;
    background-color: #FFF;

    &.borderless {
      border: none;
    }

    .add-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 4px;
      cursor: pointer;
    }

    .small-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      padding: 8px 16px;
      border-radius: 8px 8px 0 0;
      background-color: #F2F2F2;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 8px;
      }
    }

    .small-image-actions-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 36px;
      padding: 0 12px;
      background: #FFF;
      border: 1px solid #CCC;
      border-radius: 0 0 8px 8px;

      .actions {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

  }

  .dashed {
    border: 1px dashed #CCC;
  }

  .header {
    display: flex;
    align-items: center;

    justify-content: space-between;
    width: 100%;
    height: 32px;
    margin-bottom: 16px;

    .headline-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 4px;

      .info {
        cursor: pointer;
      }

      .heading {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        letter-spacing: normal;
        color: #111;
      }
    }

    .name--selected {
      font-weight: 700;
    }
    .button-yellow {
      min-height: 30px;
      border: 2px solid $brand-yellow;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 540px;
    height: 230px;
    border-radius: 4px;
    background-color: #F2F2F2;

    &.empty {
      height: 180px;
    }

    &.small-preview {
      height: 145px;
      padding: 8px 16px;
    }

    button {
      background-color: #FFF;
      border: 2px solid $brand-yellow;
    }

    .preview-container-buttons {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      z-index: 1000;

      .button-cancel {
        background-color: transparent;
        color: #333;
        border: 1px solid #333;
        margin-right: 5px;
        border-radius: 3px;
      }

      .button-apply {
        background-color: $brand-yellow;
        color: white;
        margin-right: 5px;
        border-radius: 3px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-restrictions {
    width: 100%;
    margin: 8px 0 0 0;
    padding: 0;
    list-style-type: disc;
    list-style-position: inside;

      li {
        padding: 0 0 0 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.014px;
        text-align: left;
        color: #666;
      }
  }

  p {
    align-self: start;
    margin-top: 8px;
  }
}

.image--selected {
  border-color: #111;
}

  .preview-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 64px 128px;

    .preview-modal-image-container {
      img {
        width: 100%;
      }
    }
  }

  .preview-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 24px 40px;
    height: 88px;
    background-color: #F2F2F2;
  }

.image-actions {
  display: flex;
  justify-items: flex-end;
  align-items: center;
}

.small-delete-preview {
  padding: 0 12px 0 8px;
}


</style>
