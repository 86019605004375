<template>
  <div>
    <div><h1>Communication Manager</h1></div>
    <div class="communication-picker-wrapper">
      <div class="communication-container" @click="$router.push({ name: 'AdminCommunications' })">
        <h2>Communications</h2>
        <iconComponent symbol="email" width="100px"/>
      </div>

      <!--<div class="communication-container" @click="$router.push({ name: 'AdminCompanyContentCreator' })">
        <h2>Content Creator</h2>
        <iconComponent symbol="user" width="100px"/>
      </div>-->
    </div>
  </div>
</template>

<script>
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'AdminCommunicationPicker',
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setPageTitle('Admin', 'Communication picker');
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/pages/AdminCommunicationPicker'
</style>
