<template>
  <div>
    <modal
      v-if="show"
      @close="closeModal"
      :size="{ width: 450, height: 280 }"
      :padding="'0px'"
      :modalType="'fullscreen'">

      <div class="publish-confirmation-container">

        <div class="body">
          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
            <path d="M71.802 36.7552L68.253 30.5437L68.697 23.2912C68.7285 22.7707 68.487 22.2697 68.0595 21.9712L62.1405 17.8267L59.4795 11.1697C59.2845 10.6837 58.8495 10.3327 58.332 10.2472L51.2295 9.06367L45.9 4.17967C45.51 3.82267 44.9625 3.69667 44.4585 3.84817L37.5 5.91967L30.5415 3.84817C30.0375 3.69667 29.49 3.82267 29.1 4.17967L23.7705 9.06517L16.668 10.2487C16.1505 10.3342 15.7155 10.6852 15.5205 11.1712L12.8595 17.8282L6.9405 21.9727C6.513 22.2727 6.2715 22.7722 6.303 23.2927L6.747 30.5452L3.198 36.7567C2.934 37.2172 2.934 37.7842 3.198 38.2447L6.747 44.4562L6.303 51.7087C6.2715 52.2292 6.513 52.7302 6.9405 53.0287L12.8595 57.1732L15.5205 63.8302C15.7155 64.3162 16.1505 64.6672 16.668 64.7527L23.7705 65.9362L29.1 70.8217C29.49 71.1787 30.0375 71.3032 30.5415 71.1532L37.5 69.0787L44.4585 71.1502C44.5995 71.1922 44.742 71.2132 44.886 71.2132C45.2565 71.2132 45.618 71.0767 45.9 70.8187L51.2295 65.9332L58.332 64.7497C58.8495 64.6642 59.2845 64.3132 59.4795 63.8272L62.1405 57.1702L68.0595 53.0257C68.487 52.7257 68.7285 52.2262 68.697 51.7057L68.253 44.4532L71.802 38.2417C72.066 37.7827 72.066 37.2157 71.802 36.7552ZM35.2545 51.7942L21.645 39.5122L23.655 37.2862L34.6455 47.2042L51.09 21.6862L53.613 23.3107L35.2545 51.7942Z" fill="#00B412"/>
          </svg>
          <span class="large-font">Publish Successfull</span>
          <span class="message-font">Your campaign has been published successfully</span>
        </div>

      </div>

    </modal>
  </div>
</template>

<script>

export default {
  name: 'CampaignPublishConfirmation',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    seconds: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {
      timer: null,
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.timer = setInterval(this.closeModal, this.seconds * 1000)
      }
    }
  },
  computed: {
    isCollectionEdit() {
      return (/^\/c-collections\/(?=.*[0-9])(?=.*[a-zA-Z]).*$/).test(this.$route.path);
    },
  },
  methods: {
    livePreview() {
      this.setAlert('Live preview is not implemented yet', 1);
    },
    openCollections() {
      if (this.isCollectionEdit) {
        this.$router.push({name: 'CompanyCollections'});
        return;
      }
      this.$router.go(); // refresh route, since we are on the campaign overview
    },
    closeModal() {
      clearInterval(this.timer);

      if (this.isCollectionEdit) {
        this.$router.push({ name: 'CompanyCollections' });
        return;
      }

      this.$router.go(); // refresh route, since we are on the campaign overview
    },
  }
}
</script>

<style lang="scss" scoped>

@import "../../../assets/scss/components/company";

.publish-confirmation-container {
  display: flex;
  flex-direction: column;

  background-color: #F2F2F2;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px 16px 40px;
    height: 64px;

    .heading {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      .text {
        padding-left: 10px;

        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        text-align: left;
        color: #000000;
      }

      button {
        right: 43px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    gap: 16px;

    padding: 54px 0 0 0;

    border-radius: 20px;
    background-color: #FFF;

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      padding-top: 20px;

    }
  }

  .large-font {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.64px;
  }

  .message-font {
    font-size: 16px;
  }
}

</style>
