<template>
  <div class="--myproducts">
    <div>
      <div>
        <div class="--myproducts-title-row ">
          <h2>
          {{ this.transl.Offer.favorites }}
          </h2>
        </div>
        <div class="card-container">
          <a
            v-for="favorite in currentClubCompanyFavorites"
            :key="favorite._id"
            :href="`${windowOrigin}/u-a/${getId(favorite)}`">
            <div class="card">
              <div
                v-if="favorite.thumbnail && favorite.thumbnail.url"
                class="card-image"
                :style="{ backgroundImage: 'url(' + favorite.thumbnail.url + ')' }">
              </div>

              <div
                v-else-if="favorite.media && favorite.media.length && favorite.media[0].fileType.includes('image')"
                class="card-image"
                :style="{ backgroundImage: 'url(' + favorite.media[0].url + ')' }">
              </div>

              <video
                v-else-if="favorite.media && favorite.media.length && favorite.media[0].fileType.includes('video')"
                class="card-image"
                :controls="false"
                muted
                playsinline
                autoplay>
                <source
                  :src="`${favorite.media[0].url}`"
                  :type="favorite.media[0].fileType"/>
                {{ transl.Goodiebag.novideo }}
              </video>

              <div class="card-header-wrapper">
                <div class="card-header">
                  <p class="title">{{ favorite.first_header }}</p>
                  <iconComponent symbol="heartFilled" :width="30" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <UserClubProfileFooter
      :serverVersion="serverVersion"
      @openSupportModal="$emit('openSupportModal')"></UserClubProfileFooter>
    <modal v-if="receiptModalVisible" @close="receiptModalVisible = false" :header="{ closeButton: true }"
      modalType="fullscreen" size="xlarge">
      <slot>
        <div class="game-map" @click="receiptModalVisible = !receiptModalVisible">
          <img :src="selectedImage" alt="" class="game-map-image">
        </div>

      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserClubProfileFooter from './UserClubProfileFooter.vue';

export default {
  name: 'UserClubProfileMyFavorites',
  components: { UserClubProfileFooter },
  props: {
    serverVersion: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['user', 'company', 'userStatus']),
    currentClubCompanyFavorites() {
      let filteredSortedFavorites = this.user.favorite_offers.filter(favorite => {
        if (favorite.company == this.company._id || favorite.company.unique_name == this.company.unique_name) {
          return favorite;
        }
      });

      return filteredSortedFavorites.sort((a, b) => b.created_at - a.created_at);
    }
  },
  data() {
    return {
      favorites: [{name: "Ambilight modes", favorite: true}],
      receiptModalVisible: false,
      selectedImage: null
    };
  },
  methods: {
    openModal(file) {
      this.selectedImage = file;
      this.receiptModalVisible = true;
    },
    getMyProducts(companyId) {
      this.$store.dispatch('getMyProducts', { companyId: companyId }).then((data) => {
        this.products = data.products;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserProfile";

.card-container {
  display: grid;
  justify-content: space-between;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  gap: 23px;

  @media (max-width: 812px) {
    padding: 0px !important;
    flex-direction: column;
    align-content: center;
    grid-template-columns: repeat(1, 1fr);
  }
}

.--myproducts-title-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 20px 0;

  h2{
    font-family: var(--regular-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0px;
  }

}

a {
  color: #fff !important;
}

.title {
  font-family: var(--regular-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  color:#000;
}

.card {
  font-family: var(--bold-font-family);
  overflow: hidden;
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  background: #FFF;
  margin-right: 23px;
  margin-bottom: 23px;

  width: 100%;
  /* Small */
  box-shadow: 0px 2px 4px 0px rgba(31, 41, 55, 0.06), 0px 4px 6px 0px rgba(31, 41, 55, 0.10);

  .card-image {

    background-size: cover;
    background-position: center;

    width: 100%;
    height: 200px;
    max-height: auto;
    object-fit: cover;
  }
}

.card-header-wrapper {
  padding: 10px 10px 0 10px;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    border-bottom: 1px solid var(--enginio-fog-white, #F2F2F2);
  }
}
</style>
