const reminderEmail = `
<!DOCTYPE html>
<html
  lang="en"
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta name="x-apple-disable-message-reformatting" />
    <style>
      .emailBodyPButtonFull{background:#EA7801; text-decoration:none; padding:4px; color:#ffffff; border-radius:4px; display:inline-block; mso-padding-alt:0;text-underline-color:#ff3884;width:90%;text-align:center;margin:12px;width:90%;}
      .emailBodyConfirmedReceipt{margin: 0; padding: 0; word-spacing: normal; background-color: #F2F2F2;}
      .emailBodyConfirmedReceiptWrapper{text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; background-color: #F2F2F2;}
      .emailBodyConfirmedReceiptContainer{width: 100%; border: none; background: white; width: 90%; max-width: 600px; margin: 20px auto; border-radius: 10px; text-align: left; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #363636; padding: 0 0 12px 0;}
      .emailBodyConfirmedReceiptImage{width: 100%; max-width: 600px; text-decoration: none; color: #363636; border-radius: 10px 10px 0 0;}
      .emailBodyConfirmedReceiptContent{width: 80%; margin: 0 auto; text-align: center;}
      .emailBodyOngoingContent{margin: 0 auto; text-align: left; padding: 12px; background: #ffffff; margin-top: 12px;}
      .emailBodyConfirmedReceiptActivation{background: #F2F2F2; border-radius: 10px;}
      .emailBodyConfirmedReceiptHeader{padding: 8px;}
      .emailCardWrapper{display: flex; box-shadow: 4px 4px 8px 0px #0000001a; border-radius: 10px;}
      .emailCardImage{width: 150px; height: 150px; margin-right: 10px; border-radius: 10px;}
      .emailCardText{align-self: center;text-overflow: ellipsis; overflow: hidden; overflow-wrap: break-word;}
      .emailCardIcon{width: 24px; height: 24px; border-radius: 50%;}
      .emailFooter1{display: flex; justify-content: space-around; padding: 30px 0; font-size: 14px; border-top: 1px solid rgb(241, 241, 241);}
      .emailLink{color: #EA7801; text-decoration: none; font-weight: 700;}
    </style>
  </head>
  <body class="emailBodyConfirmedReceipt">
    <div
      class="emailBodyConfirmedReceiptWrapper"
      role="article"
      aria-roledescription="email"
      lang="en">
      <div
        class="emailBodyConfirmedReceiptContainer"
        role="presentation">
        <img
          class="emailBodyConfirmedReceiptImage"
          src="https://www.enginio.io/se/api/nar/file/enginio_email_logo_12345.png"
          alt="enginio email logo"/>
        <div class="emailBodyConfirmedReceiptContent">
          <h2>Hi {{receiptName}},</h2>
          <p><b>Don't forget to finish your campaign</b></p>

          <a class="emailBodyPButtonFull" href="{{activationUrl}}">Return to campaign</a>
        </div>
        <div class="emailBodyOngoingContent">
          <p>Your ongoing campaign:</p>
          <div class="emailCardWrapper">
            <img class="emailCardImage" src="{{offerMedia}}" alt="campaign image" />
            <div class="emailCardText">
              <img class="emailCardIcon" src="{{offerCompany11Icon}}" alt="">
              <div>{{offerName}}</div>
            </div>
          </div>
        </div>
        <div class="emailBodyOngoingContent">
          <div class="emailFooter1">
            <b>Need help?</b>
            <a href="{{offerCompanyFaqUrl}}" class="emailLink">Enginio - Help Center</a>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
`;

module.exports = reminderEmail;
