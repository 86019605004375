<template>
  <div class="file-explorer">
    <div class="file-explorer_container">
      <div class="file-explorer_header"><h2>{{ header }}</h2></div>
      <div
        v-for="goodiebag in goodiebags"
        :key="goodiebag._id"
        class="file-explorer_item"
        :class="{ highlight: selected == goodiebag._id}"
        @click="getGoodiebags(goodiebag)">
        <iconComponent symbol="folder" fill="black"/>
        <div class="file-explorer_item-name"><p>{{ goodiebag.name }}</p></div> 
        <iconComponent class="file-explorer_forward" symbol="forward" @click="$emit('openFolder', goodiebag)"/>
      </div>

      <div
        v-for="offer in offers"
        :key="offer._id"
        class="file-explorer_item"
        :class="{ highlight: selected == offer._id}"
        @click="selected = offer._id">
        <iconComponent symbol="file" />
        <div class="file-explorer_item-name"><p>{{ offer.first_header }}</p></div> 
        <iconComponent class="file-explorer_forward" symbol="forward" @click="$emit('openItem', offer)"/>
      </div>
    </div>

    <FileExplorerComponent
      v-if="childHeader"
      :header="childHeader"
      :goodiebags="childGoodiebags"
      :offers="childOffers"
      @openItem="$emit('openItem', $event)"
      @openFolder="$emit('openFolder', $event)"/>

  </div>
</template>

<script>
import goodiebagApi from './../../../api/goodiebag.api';

export default {
  name: 'FileExplorerComponent',
  props: {
    header: {
      type: String,
      required: true,
    },
    goodiebags: {
      type: [Array, Object],
      required: true,
    },
    offers: {
      type: Array,
      required: false,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: '',
      childHeader: '',
      childGoodiebags: [],
      childOffers: []
    };
  },
  methods: {
    getGoodiebags(goodiebag) {
      this.childHeader = undefined;
      goodiebagApi[`get${ this.admin ? 'Admin' : 'Company'}Goodiebag`]({ entityId: goodiebag._id }).then((data) => {
        this.selected = goodiebag._id;
        this.setEntities(goodiebag.name, data);
      });
    },
    setEntities(name, data) {
      const { goodiebag } = data;
      this.childHeader = name;
      this.childGoodiebags = goodiebag.childGoodiebags;
      this.childOffers = goodiebag.offers;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./FileExplorer.scss";
</style>