<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="offer.first_header"
      entity-type="Offer"
      :entity="offer"
      :admin="true"
      :breadcrumb="offer?.goodiebag?._id"
      @eventshowCloneModal="showModalCloneOffer(true)"/>

    <div class="admin-entity--subentity">
      <div class="subentity">
        <div class="admin-entity--title">Actvation link (PV)</div>
        <InputField
          label="Change the pvId"
          v-model="pvLink"/>
      </div>
    </div>

    <modal
      v-if="showCloneModal"
      :header="{text: `Create copies of '${offer.first_header}'`, textPosition: 'center', bgColor: '#F2F2F2'}"
      class="modal-view"
      modalType="fullscreen">
      <slot>
        <form novalidate class="md-layout" @submit.prevent="onSubmit">
          <div v-for="(clone, index) in clones" :key="index" class="md-layout-item md-small-size-100">
            <md-card>
              <md-card-content>
                <md-field>
                  <label>First Header:</label>
                  <md-input v-model="clone.first_header"></md-input>
                </md-field>
                <md-field>
                  <label>Unique name:</label>
                  <md-input v-model="clone.unique_name"></md-input>
                </md-field>
                <md-field>
                  <label>Market:</label>
                  <md-select v-model="clone.market">
                    <md-option
                      v-for="country in countriesList" v-bind:key="country.title"
                      :value="country.value">
                      {{country.title}}
                    </md-option>
                  </md-select>
                </md-field>
                <div v-if="clone.market" style="color: red">You cannot set company or collection if altering market (yet)</div>
                <md-field v-if="!clone.market">
                  <label>Company:</label>
                  <md-select v-model="clone.company_id">
                    <md-option
                      v-for="company in companies" v-bind:key="company._id"
                      :value="company._id">
                      {{company.name}}
                    </md-option>
                  </md-select>
                </md-field>
                <md-field v-if="!clone.market">
                  <label>Collection:</label>
                  <md-select v-model="clone.goodiebag_id">
                    <md-option
                      v-for="goodiebag in goodiebags" v-bind:key="goodiebag._id"
                      :value="goodiebag._id">
                      {{goodiebag.name}}
                    </md-option>
                  </md-select>
                </md-field>
              </md-card-content>

              <md-card-actions>
                <button @click="removeClone(index)">
                  <md-icon style="color: #f44336 !important;">delete</md-icon>
                </button>
              </md-card-actions>
            </md-card>
          </div>
        </form>
        <button class="btn btn-activate" @click.prevent="addNewClone">Add more copies</button>
        <div>
          <button class="btn btn-transparent" @click="dismissCloneModal()">Cancel</button>
          <button class="btn btn-activate" @click="createClones()">Save {{ clones.length }} copies</button>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import countriesList from '../assets/countries.json';

export default {
  name: 'AdminOffer',
  components: {
    EntityEditor,
    InputField,
  },
  props: ['offerId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters([
      "offer",
      "userStatus",
      "companies",
      "goodiebags"
    ]),
    prefilledClone() {
      return {
        first_header: "Copy of " + this.offer.first_header,
        unique_name: "copy-of-" + this.offer.unique_name,
        // market: this.offer.market,
        // info: this.offer.info,
        company_id: this.offer?.company?._id,
        goodiebag_id: this.offer?.goodiebag?._id,
      };
    },
  },
  watch: {
    offerId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      pvLink: '',
      showCloneModal: false,
      clones: [],
      countriesList,
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminOffer', { entityId: this.offerId }).then(() => {
        this.pvLink = `${this.windowOrigin}/pv/pvId/${this.offerId}`,
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Offer');
      });
    },
    getCompanies() {
      this.$store.dispatch('findAdminCompanies');
    },
    getGoodiebags() {
      this.$store.dispatch('findAdminGoodiebags', {});
    },
    showModalCloneOffer(show) {
      // Get list of all companies so admin can clone offer to another company
      this.getCompanies();
      this.getGoodiebags();

      //pre-fill at least 1 clone for a nice UX
      let starterClone = { ...this.prefilledClone };
      this.clones = [starterClone];
      this.showCloneModal = show;
    },
    dismissCloneModal() {
      // don't leave any left-overs
      this.clones = [];
      //close the dialog
      this.showCloneModal = false;
    },
    addNewClone() {
      // Cloning the Object with Spread Operator
      let exampleClone = { ...this.prefilledClone };
      this.clones.push(exampleClone);
    },
    removeClone(index) {
      this.clones.splice(index, 1);
    },
    createClones() {
      this.$store
      .dispatch("cloneAdminOffer", { entityId: this.offerId, clones: this.clones})
      .then((nrOfCreatedCopies) => {
        this.dismissCloneModal();
        this.setAlert("Succesfully created " + nrOfCreatedCopies + " copies!");
        this.$router.back();
      }).catch((err) => {
        this.setAlert("Failed to create copies with error: " + err);
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminOffer";
</style>
