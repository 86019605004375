<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h1>Terms and conditions</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h3>Terms and conditions</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div class="data-text">
          <div>
            <h3>General Terms of use for Enginio</h3>
          </div>
          <div>
            <b>1. General</b>
          </div>
          <div>1.1</div>
          <div>
            NRG Nordic Retail Group AB (”<b>NRG</b>” or <b>we</b>) provide a platform where you can take part of activities from different companies and brands (“<b>Enginio</b>”). In order to access the activities on Enginio, you need to register an account and become a member at Enginio.
            NRG and the Company (“<b>we</b>” or “<b>us</b>”) care about your privacy and want you to feel safe when you use Enginio and we process your personal data. 
          </div>
          <div>1.2</div>
          <div>
            <b></b>
            When you choose to register and thereby become a member at Enginio, it entails the following:<br>
            • You can log in and use Enginio in logged in mode where you can take part of offers, gather Enginio points, participate in contests and more (“<b>activities</b>”). The activities are provided by the different companies and brands at Enginio (“<b>partners</b>”)<br>
            • If you have consented, you will receive newsletters with information that we think is relevant for you, e.g. offers and discounts on products and services related to our partners<br>
            • You can choose to participate in surveys
          </div>
          <div>1.3</div>
          <div>
            <b></b>
            Enginio and the membership are provided by NRG Nordic Retail Group AB with company registration number 556608-5642. When you become a member, you have to accept these terms of use (“<b>terms</b>”) that apply to the membership and your use of Enginio and thereby undertake to follow them.
          </div>
          <div>
            <b>2. Enginio and the activities</b>
          </div>
          <div>2.1</div>
          <div>
            Enginio is accessible via NRG’s website se.enginio.io
          </div>
          <div>2.2</div>
          <div>
            The activities accessible on Enginio are provided by different partners. This means that the partners decide the terms for the participation and validity of each activity, including the terms for using so called Enginio points. When you take part of activities provided by our partners, you enter into a separate agreement with the partner regarding the activity. 
          </div>
          <div>2.3</div>
          <div>
            The activities available to you as a member are personal and valid for a limited time and according to the conditions stated in each offer.
          </div>
          <div>
            <b>3. Membership</b>
          </div>
          <div>3.1</div>
          <div>
            The membership is free and is entered into by creating an account where you state your first name, last name, email address and phone number. On Enginio, you can enter additional information to get customised offers and marketing.
          </div>
          <div>3.2</div>
          <div>
            By accepting the terms, you guarantee that you have understood the terms, that you have accepted them and that your information is correct. You are obliged to fill in correct and complete information. Furthermore, you are responsible for ensuring that the information we have about you is up to date and correct.
          </div>
          <div>
            <b>4. Personal data</b>
          </div>
          <div>
            NRG is responsible for the personal data that you provide in connection with your membership. NRG will process the personal data that you choose to provide as well as such information that we receive from our partners, e.g. information about the activities you participate in. NRG process your personal data primarily to administer your membership, enable you to access activities from our partners and to send you marketing. Read more about our personal data processing in our privacy policy
          </div>
          <div>
            <b>5. Your account</b>
          </div>
          <div>5.1</div>
          <div>
            Your account is personal, and you are responsible for ensuring that no one other than you can use your login information at Enginio. You may not disclose your login information to anyone, and you must ensure that any document containing login information is stored in such way that unauthorised persons cannot gain access to the information. If you suspect that someone unauthorised knows your login details, you need to contact NRG immediately to report this. 
          </div>
          <div>5.2</div>
          <div>
            If NRG suspects that your account or login information is being misused or if its use otherwise violates the terms, NRG has the right to suspend you from Enginio and withdraw your membership. Should the misuse have led to costs for NRG, you may be obliged to compensate NRG for these as far as possible according to what mandatory law allows. NRG also has the right to provide you with new login information for any reason. 
          </div>
          <div>
            <b>6. Use</b>
          </div>
          <div>6.1</div>
          <div>
            You are responsible for ensuring that your use of the membership does not cause harm or inconvenience to anyone. NRG complies with Swedish law and has the right to remove anything that is in any way inappropriate or illegal. NRG alone has the right to decide what is to be considered inappropriate. NRG does not take responsibility for content created by our partners. 
          </div>
          <div>6.2</div>
          <div>
            6.2 The right we grant you to use the membership and its content does not include a right to:<br>
              a) take part in all activities on Enginio, unless our partners and you have agreed on a right to participate;<br>
              b) use the membership and its contents for commercial use;<br>
              c) imitate the membership and its content;<br>
              d) spread activities to others to which you have had access as a member, e.g. discount codes;<br>
              e) allow anyone else to access your account or otherwise jeopardise the security of your account;<br>
              f) direct claims against Enginio due to any claims that our partners make against you in connection with activities with our partners;<br>
              g) provide incorrect information upon entering the agreement or during the term of the agreement; or<br>
              h) copy, imitate, download, resell or exploit the membership and its contents.
          </div>
          <div>6.3</div>
          <div>
            The actions listed above under 6.2.1.1a) - 6.2.1.1h) are not permitted. 
          </div>
          <div>
            <b>7. Term and termination of the membership</b>
          </div>
          <div>7.1</div>
          <div>
            The membership starts when you create your account and is valid until further notice. Your membership may be terminated in accordance with section 7.2-7.4.
          </div>
          <div>7.2</div>
          <div>
            Your membership ends automatically if you have been inactive for 18 months.
          </div>
          <div>7.3</div>
          <div>
            You can terminate your membership at any time. You do this by notifying us that you wish to terminate your membership. If you wish to terminate your membership, it will be terminated within a week from when we become aware of your request to terminate the membership. You can also unsubscribe from our newsletters at any time, but this does not mean that you stop being a member.
          </div>
          <div>7.4</div>
          <div>
            NRG reserves the right to terminate your membership immediately if you violate these terms.
          </div>
          <div>7.5</div>
          <div>
            The termination of the membership means that your information is deleted and that you no longer have access to the membership. You will thereafter not be able to log in to your account.
          </div>
          <div>
            <b>8. Intellectual property rights</b>
          </div>
          <div>8.1</div>
          <div>
            The membership, Enginio and its content, including the catalogue of partners and activities from partners as well as technical solutions behind these are protected by intellectual property rights and catalogue protection. All rights belong to NRG or any partners of NRG.
          </div>
          <div>8.2</div>
          <div>
            NRG´s content on Enginio, as well as catalogue and trademarks may not be used to a greater extent than is necessary for you to be able to use the membership in accordance with these terms and in the intended manner.
          </div>
          <div>
            <b>9. Liability</b>
          </div>
          <div>9.1</div>
          <div>
            NRG does not offer activities but does merely provide the possibility to use them at Enginio. This means that you enter into an agreement with NRG´s partners when you participate in activities that you have found at Enginio.
          </div>
          <div>9.2</div>
          <div>
            If you have any questions regarding an activity from NRG´s partner, you should contact the partner directly.
          </div>
          <div>9.3</div>
          <div>
            NRG´s intention is that information provided on Enginio always shall be correct and up-to-date and that our partners´ activities must be usable. However, NRG cannot guarantee that this always will be the case. The partner, not NRG, is responsible for the accuracy of its activities. Any agreement regarding a partner’s activity is always entered into between you and the partner and NRG cannot be held responsible for any damage or loss that has occurred within the framework of such agreement with NRG´s partners or for damage due to the partner´s bankruptcy or similar circumstance. As far as possible in accordance with all applicable law, NRG´s liability never covers indirect damages. 
          </div>
          <div>
            <b>10. Functionality and availability</b>
          </div>
          <div>10.1</div>
          <div>
            NRG strives to ensure that the membership and its content always work flawlessly and function with available standard browsers. Although it is NRG´s ambition, we cannot guarantee that the content will be provided completely without technical disturbances. NRG may at times need to restrict access due to e.g. service, support, security or for technical reasons.
          </div>
          <div>10.2</div>
          <div>
            In connection with you becoming a member at Enginio, you are given the opportunity to use digital content on Enginio. You do this by logging in to your account. The content is provided in accordance with the latest version of Enginio at all times. If technical updates are required, NRG will inform you of these and any consequences of failure to update.
          </div>
          <div>10.3</div>
          <div>
            Should any technical problems arise, or if you are dissatisfied in any way, please contact NRG as soon as possible. NRG´s contact details are listed below under Contact information. NRG always strives to find a solution to the problem as soon as possible. You have the right to make a complaint about Enginio up to and including two months after you have deregistered your account. In the event of an approved complaint, you are entitled to compensation in accordance with mandatory consumer protection legislation. 
          </div>
          <div>10.4</div>
          <div>
            In the event of technical problems, you should, to the extent that can be considered reasonable, work with NRG to determine if it is your hardware, software or network connections that are causing any problems. The obligation to cooperate is limited to the technically available means that are least intrusive for you.
          </div>
          <div>10.5</div>
          <div>
            You are responsible for protecting your technical equipment from unauthorised use, including the use of appropriate anti-virus software and firewall.
          </div>
          <div>
            <b>11. Right to change</b>
          </div>
          <div>11.1</div>
          <div>
            NRG has the right to change and restructure the membership and Enginio as well as technical and digital content if it is done at no cost to you and NRG has a valid reason for the change. NRG has a valid reason if the change occurs due to adaptation to a new technical environment, to an increased number of users or other important operational reasons and to improve the user experience. NRG will inform about changes in accordance with mandatory law.
          </div>
          <div>11.2</div>
          <div>
            We will inform you about the changes in accordance with mandatory consumer protection legislation.
          </div>
          <div>11.3</div>
          <div>
            You always have the right to terminate your membership at any time, regardless of whether NRG changes the content of or the access to the membership.
          </div>
          <div>
            <b>12. Right of withdrawal</b>
          </div>
          <div>
            According to EU consumer protection legislation, you have a right to withdraw from your agreement within 14 days from the day you become a member by creating an account. Since the membership is free, you can do this by deleting your account at any time. You can also contact us on the contact details listed below under Contact information Or use the Swedish Consumer Agency’s standard form for exercising the right of withdrawal which you can find here.
          </div>
          <div>
            <b>13. Amendment and transfer of the terms</b>
          </div>
          <div>13.1</div>
          <div>
            NRG reserves the right to make changes to these terms at any time. The latest updated version of the terms is published on Enginio. Changes apply from the time you accept the terms (in connection with the creation of a membership or login to your account), or 30 days after NRG has informed you of the changes. However, this does not apply if the change is due to changes in law or other statutes or on the authority´s order and which means that the change must apply immediately. Should you wish to terminate your membership when you become aware of any changes, you have the right to do so with immediate effect.
          </div>
          <div>13.2</div>
          <div>
            Adjusted terms will be published on Enginio.
          </div>
          <div>13.3</div>
          <div>
            By accepting the terms, you agree that NRG may transfer the agreement you have entered into regarding your membership to another company that is wholly or partly, directly or indirectly owned by NRG or to companies within the group to which NRG belongs at the time of the transfer.
          </div>
          <div>
            <b>14. Contact information</b>
          </div>
          <div>
            If you have any questions or want to file a complaint or make a claim, you can contact us at our email address support@enginio.io or our contact form here.
          </div>
          <div>
            <b>15. Governing aw and dispute resolution</b>
          </div>
          <div>15.1</div>
          <div>
            Disputes regarding the interpretation or application of these terms shall be settled in accordance with Swedish law and by Swedish courts. 
          </div>
          <div>15.2</div>
          <div>
            In the event of a dispute, we follow decisions from alternative dispute resolution bodies. You can contact the National Board for Consumer Disputes (ARN) through their website <a href="https://www.ARN.se" target="_blank">www.ARN.se</a> or by sending a letter to Box 174, 101 23 Stockholm.
          </div>
          <div>15.3</div>
          <div>
            You can also complain via EU´s web-based Dispute Resolution Service which is accessed <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">here</a>.
          </div>
          <div class="a-line"></div>
          <div>
            These terms have been adopted by NRG Nordic Retail Group AB 2022-03-01.
          </div>
        </div>
      </div>

      <div class="right-card">
        <div class="--container">
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.cookies' })">
            <div class="button-text">Cookies policy</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.visitors' })">
            <div class="button-text">Privacy Policy - Visitors</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.members' })">
            <div class="button-text">Privacy Policy - Members</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.participants' })">
            <div class="button-text">Privacy Policy - Participants</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link unclickable">
            <div class="button-text">Terms and conditions</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <!--<div
             class="data-link"
             @click="$router.push({ name: 'HomeData.companies' })">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeDataTerms',
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle('Terms and conditions');
    this.setCompany({});
  },
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/HomeDataTerms";
</style>
