<template>
  <div class="admin-content--container">
    <div class="admin-content--header">
      <h2>Links manager</h2>
    </div>

    <div class="btn-container">
      <div class="btn btn-activate download-btn">
        <iconComponent symbol="arrowRight"/>
        <p>Download selection</p>
      </div>
    </div>

    <InputField
      class="entity-editor__links-gallery-add-new"
      label="Company"
      inputType="select"
      v-model="selectedCompany"
      :selectOptions="companies"
      :selectSettings="{ optionTitle: 'name', optionKey: '_id' }"
      @input="selectCompany($event)" />

    <ListExplorer
      v-if="selectedCompany"
      :company="selectedCompany"
      :admin="true"
      :goodiebags="parentGoodiebags"/>

    <!--
    <div class="btn btn-activate" @click="addLink()">add links</div>
    <div class="btn btn-activate" @click="changeLink()">change links</div>
    <div class="btn btn-activate" @click="deleteLink()">delete links</div>
    <div class="btn btn-activate" @click="getCsvData()">Get CSV data</div>
    -->
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import InputField from '../stories/components/InputField/InputField.vue';
import ListExplorer from '../stories/components/ListExplorer/ListExplorer.vue';

export default {
  name: "AdminLinksManager",
  components: { InputField, ListExplorer },
  mixins: [BeforeEnterMixin],
  data() {
    return {
      selectedCompany: undefined,
      loadedPage: false,
    };
  },
  mounted() {
    this.findAdminCompanies('');
    this.setPageTitle('Admin', 'Links manager');
  },
  updated() {
  },
  computed: {
    ...mapGetters(["companies"]),
    parentGoodiebags() {
      if (this.selectedCompany && this.selectedCompany.goodiebags && this.selectedCompany.goodiebags.length) {
        let parentGoodiebags = [];
        for (let i = 0; i < this.selectedCompany.goodiebags.length; i++) {
          const goodiebag = this.selectedCompany.goodiebags[i];
          if (!goodiebag.parentGoodiebag) {
            parentGoodiebags.push(goodiebag);
          }
        }
        return parentGoodiebags;
      }
      return [];
    },
  },
  methods: {
    getCsvData() {
      const companyIds = ['6052149ba98b4a53e4898184', '5ff6b1e169f828729cb8249f'];
      const goodiebagIds = ['605214f3a98b4a51d689818a'];
      const offerIds = ['604e0e3ed3d70b736d145adc'];

      axios.put('/api/admin/links-manager/csv', {
        companyIds,
      }).then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `all-selected-company-links_${(new Date).toISOString()}.csv`;
        anchor.click();
      });

      axios.put('/api/admin/links-manager/csv', {
        goodiebagIds,
      }).then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `all-selected-goodiebag-links_${(new Date).toISOString()}.csv`;
        anchor.click();
      });

      axios.put('/api/admin/links-manager/csv', {
        offerIds,
      }).then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `all-selected-offer-links_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    findAdminCompanies(query) {
      this.$store.dispatch('findAdminCompanies', { query });
    },
    // Call this inside of the component 
    // Send the "getAdminGoodiebag" & the Api filename
    selectCompany(event) {
      let data = event;
      this.selectedCompany = data;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/AdminLinksManager";
</style>