var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadedPage)?_c('div',{staticClass:"admin-entity-content--container"},[(_vm.loadedPage && _vm.company._id)?_c('EntityEditor',{attrs:{"title":_vm.company.name,"entity-type":"Company","entity":_vm.company,"admin":true}}):_vm._e(),_c('div',{staticClass:"admin-entity--subentity"},[_c('div',{staticClass:"admin-entity--title"},[_vm._v("Child collections")]),(_vm.company._id)?_c('div',{staticClass:"admin-goodiebags-wrapper subentity"},[_vm._l((_vm.parentGoodiebags),function(goodiebag){return _c('div',{key:goodiebag._id,staticClass:"goodiebag-box",style:(`background-image: url(${goodiebag.media ? goodiebag.media.url : ''})`)},[_c('div',{staticClass:"goodiebag-live-status",class:{
            'goodiebag-live': goodiebag.public,
            'goodiebag-offline': !goodiebag.public,
          }}),_c('div',{staticClass:"goodiebag-box-text"},[_c('div',{staticClass:"goodiebag-box-name"},[_vm._v(_vm._s(goodiebag.name))]),(goodiebag.goodiebags && goodiebag.goodiebags.length)?_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(goodiebag.goodiebags.length)+" "+_vm._s(goodiebag.goodiebags.length === 1 ? 'collection' : 'collections')+" ")]):_vm._e(),(goodiebag.offers && goodiebag.offers.length)?_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(goodiebag.offers.length)+" "+_vm._s(goodiebag.offers.length === 1 ? 'activation' : 'activations')+" ")]):_vm._e(),_c('div',{staticClass:"goodiebag-box-fullscreen fullscreen-icon preview-action",on:{"click":function($event){return _vm.getGoodiebag(goodiebag._id)}}},[_c('iconComponent',{attrs:{"symbol":"fullscreen"}})],1),_c('div',{staticClass:"goodiebag-box-edit preview-action",on:{"click":function($event){return _vm.$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: goodiebag._id } })}}},[_c('iconComponent',{attrs:{"symbol":"pencil"}})],1)])])}),_c('div',{staticClass:"goodiebag-box goodiebag-box-add",on:{"click":function($event){_vm.showGoodiebagDialog = true}}},[_c('iconComponent',{attrs:{"symbol":"circledPlus"}})],1)],2):_vm._e(),_c('div',{staticClass:"admin-entity--title"},[_vm._v("PV Company")]),(_vm.pvCompany._id)?_c('div',{staticClass:"admin-goodiebags-wrapper subentity"},[_c('div',{staticClass:"goodiebag-box"},[_c('div',{staticClass:"goodiebag-live-status",class:{
            'goodiebag-live': _vm.pvCompany.public,
            'goodiebag-offline': !_vm.pvCompany.public,
          }}),_c('div',{staticClass:"goodiebag-box-text"},[_c('div',{staticClass:"goodiebag-box-name"},[_vm._v(_vm._s(_vm.pvCompany.name))]),(_vm.pvCompany.goodiebags && _vm.pvCompany.goodiebags.length)?_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(_vm.pvCompany.goodiebags.length)+" "+_vm._s(_vm.pvCompany.goodiebags.length === 1 ? 'collection' : 'collections')+" ")]):_vm._e(),(_vm.pvCompany.offers && _vm.pvCompany.offers.length)?_c('div',{staticClass:"goodiebag-box-info"},[_vm._v(" "+_vm._s(_vm.pvCompany.offers.length)+" "+_vm._s(_vm.pvCompany.offers.length === 1 ? 'activation' : 'activations')+" ")]):_vm._e(),_c('div',{staticClass:"goodiebag-box-fullscreen fullscreen-icon preview-action",on:{"click":function($event){return _vm.getGoodiebag(_vm.pvCompany._id)}}},[_c('iconComponent',{attrs:{"symbol":"fullscreen"}})],1),_c('div',{staticClass:"goodiebag-box-edit preview-action",on:{"click":function($event){return _vm.$router.push({ name: 'AdminPVCompany', params: { companyId: _vm.pvCompany._id } })}}},[_c('iconComponent',{attrs:{"symbol":"pencil"}})],1)])])]):_vm._e(),(_vm.showGoodiebagDialog)?_c('modal',{attrs:{"size":"xlarge","modalType":"fullscreen"},on:{"close":function($event){_vm.showGoodiebagDialog = false;}}},[_vm._t("default",function(){return [_c('EntityEditor',{attrs:{"entity-type":"Goodiebag","admin":true,"attach":_vm.attachGoodiebag}})]})],2):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }