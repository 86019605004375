import axios from 'axios';

// let findCompanyCall = false;
// let findGoodiebagCall = false;
// let getCompanyCall = false;
// let getParentCompanyCall = false;
// let getGoodiebagCall = false;
// let getOfferCall = false;
let getSuggestionsCall = false;

export default {
  findNarCompanies: (query, number) => {
    const data = {
      query,
      number,
    }
    /*if (findCompanyCall) {
      return;
    }
    findCompanyCall = true;*/

    return axios.put('/api/nar/companies', data)
      .then((response) => {
        // findCompanyCall = false;
        return {
          companies: response.data.companies != null
            ? response.data.companies : [],
        };
      });
  },

  findNarGoodiebags: (query, number) => {
    const data = {
      query,
      number,
    }
    /*if (findGoodiebagCall) {
      return;
    }
    findGoodiebagCall = true;*/

    return axios.put('/api/nar/goodiebags', data)
      .then((response) => {
        // findGoodiebagCall = false;
        return {
          goodiebags: response.data.goodiebags != null
            ? response.data.goodiebags : [],
        };
      });
  },

  getNarParentCompany: (data) => {
    /*if (getParentCompanyCall) {
      return;
    }
    getParentCompanyCall = true;*/

    return axios.get(`/api/nar/parent-companies/${data.parentCompanyId}`)
      .then((response) => {
        // getParentCompanyCall = false;
        return {
          parentCompany: response.data.parentCompany != null
            ? response.data.parentCompany : {},
        };
      });
  },

  getNarCompany: (data) => {
    /*if (getCompanyCall) {
      return;
    }
    getCompanyCall = true;*/

    return axios.get(`/api/nar/companies/${data.companyId}`)
      .then((response) => {
        // getCompanyCall = false;
        return {
          company: response.data.company != null
            ? response.data.company : {},
        };
      });
  },

  getNarGoodiebag: (data) => {
    /*if (getGoodiebagCall) {
      return;
    }
    getGoodiebagCall = true;*/

    return axios.get(`/api/nar/goodiebags/${data.goodiebagId}`)
      .then((response) => {
        // getGoodiebagCall = false;
        return {
          goodiebag: response.data.goodiebag != null
            ? response.data.goodiebag : {},
        };
      });
  },

  getNarOffer: (data) => {
    return axios.get(`/api/nar/offers/${data.offerId}`)
      .then((response) => {
        return {
          offer: response.data.offer != null
            ? response.data.offer : {},
        };
      });
  },

  findNarSuggestions: (data) => {
    if (getSuggestionsCall) {
      return new Promise((resolve, reject) => { reject('noop') });
    }
    getSuggestionsCall = true;

    return axios.put('/api/nar/suggestions', data)
      .then((response) => {
        getSuggestionsCall = false;
        return {
          goodiebags: response.data.goodiebags != null
            ? response.data.goodiebags : [],
          offers: response.data.offers != null
            ? response.data.offers : [],
        };
      });
  },
};
