import { render, staticRenderFns } from "./AdminHyperlink.vue?vue&type=template&id=1308cf88&scoped=true&"
import script from "./AdminHyperlink.vue?vue&type=script&lang=js&"
export * from "./AdminHyperlink.vue?vue&type=script&lang=js&"
import style0 from "./AdminHyperlink.vue?vue&type=style&index=0&id=1308cf88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1308cf88",
  null
  
)

export default component.exports