/* eslint-disable no-shadow, no-param-reassign */
import reviewApi from '../../api/pvReview.api';

// initial state
const state = {
  review: {},
  reviews: [],
};

// getters
const getters = {
  review: state => state.review,
  reviews: state => state.reviews,
};

// actions
const actions = {
  // ADMIN
  findAdminPVReviews({ commit }, payload) {
    return reviewApi.findAdminPVReviews(payload)
      .then((response) => {
        commit('REVIEWS', response.reviews);
      });
  },
};

// mutations
const mutations = {
  REVIEW(state, review) {
    state.review = review;
  },
  REVIEWS(state, reviews) {
    state.reviews = reviews;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
