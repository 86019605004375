<template>
  <div class="color-select-container">
    <p class="color-select-title">
      {{ title }}
    </p>
    <div class="color-select-inputs">
      <div class="color-picker-container">
        <input
          type="color"
          :id="pickerId"
          name="color-picker"
          @input="handleChange"
          :value="displayedValue"
          :style="{ backgroundColor: displayedValue || 'transparent' }"
          :class="{'input-not-selected': !displayedValue }"
        />
        <label :for="pickerId">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M2.92054 5.27324L2.88518 5.23788L2.84983 5.27324L2.21464 5.90842L2.17929 5.94378L2.21464 5.97913L8.96464 12.7291L9 12.7645L9.03536 12.7291L15.7854 5.97913L15.8207 5.94378L15.7854 5.90842L15.1502 5.27324L15.1148 5.23788L15.0795 5.27324L9 11.3527L2.92054 5.27324Z" fill="#111111" stroke="#111111" stroke-width="0.1"/>
          </svg>
        </label>
      </div>
      <input class="color-text-input" :value="displayedValue" @input="handleChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorSelect',
  props: {
    title: {
      type: String,
      default: 'Color',
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      pickerId: `color-picker-${Math.random().toString(36).substring(2, 9)}`,
      value: this.color ?? '',
    }
  },
  computed: {
    displayedValue() {
      const _value = this.color ?? this.inputValue ?? '';
      return ((_value.charAt(0) === '#' || !_value) ? '' : '#') + _value;
    }
  },
  methods: {
    handleChange(event) {
      const newValue = event.target.value.toUpperCase();
      this.$emit('select', newValue);
      this.inputValue = newValue;
    }
  },
}
</script>

<style lang="scss" scoped>
.color-select-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;

  .color-select-title {
    font-family: CircularXXWeb-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
  }

  .color-select-inputs {
    display: flex;
    gap: 12px;

    .color-picker-container {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #CCC;

      input {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        border: none;
      }

      .input-not-selected {
        border: 1px dashed #CCC;
      }

      input::-webkit-color-swatch {
        border: none;
        opacity: 0;
        cursor: pointer;
      }

      input::-moz-color-swatch {
        border: none;
        opacity: 0;
        cursor: pointer;
      }

      label {
        margin-bottom: 0;
        cursor: pointer;
      }
    }

    .color-text-input {
      width: 96px;
      padding: 12px;
      border-radius: 4px;
      border: 1px solid #CCC;

      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.014px;
      color: #1E293B;
    }
  }
}
</style>