/* eslint-disable */
import axios from 'axios';
// import store from '../store';
import router from '../router';
import EventBus from '../resources/eventBus';
// import AuthService from '../auth/AuthService';

// const auth = new AuthService();
// const { logout } = auth;

// intercept the global error
const interceptor = axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.__proto__.__CANCEL__) {
      return Promise.reject('canceled');
    }
    if (error.config && error.config.url
      && (error.config.url.includes('dp-insights')
      || error.config.url.includes('purchasevalidation')
      || error.config.url.includes('localhost:8003')
      || error.config.url.includes('/device')
      || error.config.url.includes('/api/nar/companies')
      || error.config.url.includes('/api/games')
      /*|| (!error.config.url.includes('localhost:8001')
      && !error.config.url.includes('enginio.'))*/)) {
      console.log('aborted axios interceptor', error.config.url);
      return Promise.reject(error);
    }

    let alertMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response.data;
    let seconds = 6;
    switch (error.response.status) {
      case 400:
        // message = error.response.data;
        break;
      case 401:
        // console.log('401 from', error.config.url);
        EventBus.$emit('feLogout');
        seconds = 2;
        // EventBus.$emit('setAlert', { message: 'Du loggades ut', close: 'OK', seconds });
        return Promise.reject(error);
      case 403:
        // message = error.response.data;
        break;
      case 404:
        // message = error.response.data;
        seconds = 8;
        break;
      case 409:
        alertMessage = 'Kunde inte ta bort detta, eftersom den används.';
        break;
      case 429:
        router.push({ name: 'Home429' });
        break;
      case 500:
        alertMessage = 'Server issue, contact support, support@enginio.io';
        break;
      default:
        alertMessage = 'Server issue, contact support, support@enginio.io';
    }

    EventBus.$emit('setAlert', { message: alertMessage, close: 'OK', seconds });
    return Promise.reject(error);
  });

export default { interceptor };
