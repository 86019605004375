
import { defineComponent, PropType } from 'vue';
import iconComponent from '../IconComponent/IconComponent.vue';
import { HeaderInterface, ContentInterface } from './Interfaces';

export default defineComponent({
  name: 'Collapsable',
  components: { iconComponent },
  data() {
    return {
      toggle: this.collapsed,
    };
  },
  props: {
   /**     
     * This prop is used to define the transition name,
     * the animation used is built-in in Vue
     * so no complicated JS is needed, just some CSS.
     * Read more about it here: https://v2.vuejs.org/v2/guide/transitions.html
     */
    slotTransName: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    /**     
     * This prop is used to define the transition name,
     * the animation used is built-in in Vue
     * so no complicated JS is needed, just some CSS.
     * Read more about it here: https://v2.vuejs.org/v2/guide/transitions.html
     */
    slotTransAppear: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**     
     * This prop is used to define the transition name,
     * the animation used is built-in in Vue
     * so no complicated JS is needed, just some CSS.
     * The options are: "default" | "in-out" | "out-in".
     * Read more about it here: https://v2.vuejs.org/v2/guide/transitions.html
     */
    slotTransMode: {
      type: String as PropType<string> | undefined,
      required: false,
      default: '',
    },
    /**
     * This prop defines the background color and
     * position of the text on the Header div
     */
    header: {
      type: Object as PropType<HeaderInterface>,
      required: true,
      default: () => ({
        bgColor: 'transparent',
        textPosition: 'left',
      }),
    },
    /**
     * This prop defines the background color and
     * position of the text on the Content div
     */
    content: {
      type: Object as PropType<ContentInterface>,
      required: false,
      default: () => ({
        bgColor: 'transparent',
        textPosition: 'center',
      }),
    },
    /**
     * This prop defines the default state of the component,
     * meaning if the content should be hidden by default or not
     * when entering a page.
     */
    collapsed: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  computed: {
    headerCss(): Record<string, unknown> {
      return {
        '--header-bg-color':
          typeof this.header.bgColor === 'undefined'
            ? 'transparent'
            : this.header.bgColor,
        '--header-text-align':
          typeof this.header.textPosition === 'undefined'
            ? 'left'
            : this.header.textPosition,
      };
    },
    contentCss(): Record<string, unknown> {
      return {
        '--content-bg-color':
          typeof this.content.bgColor === 'undefined'
            ? 'transparent'
            : this.content.bgColor,
        '--content-text-align':
          typeof this.content.textPosition === 'undefined'
            ? 'center'
            : this.content.textPosition,
      };
    },
  },
  methods: {
    emitToggle() {
      this.toggle = !this.toggle; 
    /**
     * This methods toggles the toggle variable 
     * from false to true and viceversa,
     * it also does an emit 
     * @event toggle
     * @type {boolean} boolean
     */
      this.$emit('toggle', this.toggle);
    }
  },
});
