<template>
  <WizardModal
    :show="show"
    :canSkipTo="nextStep"
    :heading="'Create company'"
    :index="index"
    :steps="['Company details', 'Landing page style']"
    @changeIndex="index = $event;"
    @close="$emit('close')"
    @disappeared="$emit('disappeared'); index = 0;"
    @finish="createCompany"
  >

    <div class="content" :class="{ 'images-step': this.index === 1 }">
      <div class="name-and-description" v-if="index === 0">
        <div class="input-container">
          <label :for="'name'">Company name</label>
          <input
            required
            :id="'name'"
            :type="'text'"
            v-model="company.name"
          />
          <span>Maximum characters: 50</span>
        </div>
        <div class="input-container">
          <label :for="'info'">Company description</label>
          <textarea
            required
            :id="'info'"
            :type="'text'"
            v-model="company.info">
          </textarea>
          <span>Maximum characters: 500</span>
        </div>
      </div>

      <div class="images-container" v-if="index === 1">
        <ImageSelect  :heading="'Background'"
                      :selected-image-file="company.background"
                      :aspect-ratio="'16:9'"
                      :showInfo=true
                      @imageSelected="company.background = $event"
                      @imageDeleted="company.background = null"
        class="background-image"/>

        <div class="images-container-row">
          <ImageSelect  :heading="'Logo'"
                        :selected-image-file="company.logo"
                        :aspect-ratio="'1:1'"
                        :showInfo=true
                        :width=273
                        :height=300
                        @imageSelected="company.logo = $event"
                        @imageDeleted="company.logo = null"
                        class="image"/>

          <ImageSelect  :heading="'Start page'"
                        :selected-image-file="company.startpage"
                        :aspect-ratio="'4:5'"
                        :showInfo=true
                        :width=273
                        :height=300
                        @imageSelected="company.startPage = $event"
                        @imageDeleted="company.startPage = null"
                        class="image"/>
        </div>
      </div>
    </div>
  </WizardModal>
</template>

<script>
import ImageSelect from '../ImageSelect/ImageSelect.vue';
import WizardModal from '../WizardModal/WizardModal.vue';
import axios from "axios";

export default {
  name: 'CompanyWizard',
  components: {
    WizardModal,
    ImageSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async createCompany() {
      try {
        const formData = new FormData();
        const companyInfo = {
          name: this.company.name,
          info: this.company.info,
        };
        formData.append('company', JSON.stringify(companyInfo));

        if (this.company.background !== null)
          formData.append('media', this.company.background.fileObject);

        if (this.company.logo !== null)
          formData.append('icon', this.company.logo.fileObject);

        if (this.company.startPage !== null)
          formData.append('thumbnail', this.company.startPage.fileObject);

        await axios.post(`/api/c/companies`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        this.setAlert('Company created successfully', 3);
        this.clearCompanyInfo();
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
    },
    clearCompanyInfo() {
      this.company = {
        name: '',
        info: '',
        background: null,
        logo: null,
        startPage: null,
      }
    }
  },
  computed: {
    nextStep() {
      if (this.company.name.length > 0)
        return undefined;

      if (this.company.name?.length > 1)
        return 1;

      return 0;
    }
  },
  data() {
    return {
      company: {
        name: '',
        info: '',
        background: null,
        logo: null,
        startPage: null,
      },
      index: 0,
      fullscreenSource: null,
      selectedBackgroundImage: null,
      selectedLogoImage: null,
      selectedStartPageImage: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  // flex: 1;
  padding: 24px 40px;
  background-color: #FFF;
  width: 994px;
  height: 500px;

  & > * {
    flex: 1;
  }

  &.images-step {
    height: 710px;
  }

  .name-and-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 40px 24px;

    & > * {
      width: 498px;
    }
  }

  .images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 24px;

    .background-image {
      height: 100%;
      width: 570px;
    }

    .images-container-row {
      display: flex;
      flex-direction: row;
      gap: 18px;

      .image {
        min-height: 295px;
        width: 276px;
      }
    }

  }


}
</style>
