/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import parentCompanyApi from '../../api/parentCompany.api';
import narApi from '../../api/nar.api';

// initial state
const state = {
  query: '',
  number: 8,
  parentCompany: {},

  parentCompanies: [],
  modalParentCompanies: [],
  status: '',
};

// getters
const getters = {
  query: state => state.query,
  number: state => state.number,
  parentCompany: state => state.parentCompany,

  parentCompanies: state => state.parentCompanies,
  modalParentCompanies: state => state.modalParentCompanies,
  status: state => state.status,
};

// actions
const actions = {
  findAdminParentCompanies({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    return parentCompanyApi.findAdminParentCompanies(payload)
      .then((response) => {
        commit('COMPANIES', response.parentCompanies);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
      });
  },

  findUserCompanies({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    commit('PARENT_COMPANY_QUERY', payload.query);
    return parentCompanyApi.findUserCompanies(payload.query, state.number)
      .then((response) => {
        commit('COMPANIES', response.parentCompanies);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminParentCompany({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    return parentCompanyApi.addAdminParentCompany(payload)
      .then((response) => {
        commit('ADD_PARENT_COMPANY', response.parentCompany);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
      });
  },

  addAdminParentCompanyMedia({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    return parentCompanyApi.addAdminParentCompanyMedia(payload)
      .then((response) => {
        commit('SAVE_PARENT_COMPANY', response.parentCompany);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
      });
  },

  getAdminParentCompany({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    return parentCompanyApi.getAdminParentCompany(payload)
      .then((response) => {
        commit('PARENT_COMPANY', response.parentCompany);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
      });
  },

  saveAdminParentCompany({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    return parentCompanyApi.saveAdminParentCompany(payload)
      .then((response) => {
        commit('PARENT_COMPANY', {});
        commit('PARENT_COMPANY', response.parentCompany);
        commit('SAVE_PARENT_COMPANY', response.parentCompany);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
      });
  },

  deleteAdminParentCompany({ commit }, payload) {
    commit('PARENT_COMPANY_STATUS', 'LOADING');
    return parentCompanyApi.deleteAdminParentCompany(payload.entityId)
      .then(() => {
        commit('DELETE_PARENT_COMPANY', payload.parentCompanyId);
        commit('PARENT_COMPANY_STATUS', 'FINISHED');
        return;
      });
  },

  // No auth
  getNarParentCompany({ commit }, payload) {
    if (!state.parentCompany || state.parentCompany._id !== payload.parentCompanyId) {
      commit('PARENT_COMPANY_STATUS', 'LOADING');
      return narApi.getNarParentCompany(payload)
        .then((response) => {
          commit('PARENT_COMPANY', response.parentCompany);
          commit('PARENT_COMPANY_STATUS', 'FINISHED');
        });
    } else {
      return false;
    }
  },
};

// mutations
const mutations = {
  SET_PARENT_COMPANY_NUMBER(state, number) {
    state.number = number;
  },
  PARENT_COMPANY_QUERY(state, query) {
    state.query = query;
  },
  COMPANIES(state, parentCompanies) {
    state.parentCompanies = parentCompanies;
    state.modalParentCompanies = [];
    for (let i = 0; i < state.parentCompanies.length; i += 1) {
      state.modalParentCompanies.push({
        value: state.parentCompanies[i]._id,
        text: state.parentCompanies[i].name,
      });
    }
  },
  PARENT_COMPANY(state, parentCompany) {
    state.parentCompany = parentCompany;
  },
  ADD_PARENT_COMPANY(state, parentCompany) {
    state.parentCompanies.unshift(parentCompany);
  },
  SAVE_PARENT_COMPANY(state, parentCompany) {
    for (let i = 0; i < state.parentCompanies.length; i += 1) {
      if (state.parentCompanies[i]._id === parentCompany._id) {
        Vue.set(state.parentCompanies, i, parentCompany);
      }
    }
  },
  DELETE_PARENT_COMPANY(state, parentCompanyId) {
    for (let i = 0; i < state.parentCompanies.length; i += 1) {
      if (state.parentCompanies[i]._id === parentCompanyId) {
        state.parentCompanies.splice(i, 1);
      }
    }
  },
  PARENT_COMPANY_STATUS(state, status) {
    state.status = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
