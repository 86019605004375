<template>
  <div class="admin-content--container">
    <iframe
      v-if="company.iframe_dashboard"
      :src="company.iframe_dashboard"
      frameborder="0" height="100%" width="100%"></iframe>
    <h2 v-else class="admin-no-dashboard-yet">
      There is no dashboard setup, yet!
    </h2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: "CompanyDashboard",
  mixins: [BeforeEnterMixin],
  mounted() {
  },
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang='scss' scoped>
@import "../assets/scss/pages/AdminDashboard";
</style>